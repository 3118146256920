import React, { Component } from "react";
import AdminSidebar from "../sidebar/admin_sidebar";
import AdminHeader from "../header/admin_header";
import config from "../../config/config.json";
import axios from "axios";
import { sweetAlert } from "../../utils/UniversalFunction";
import "./control.css";
import { authenticationService } from "../../_services/authentication";
const currentUser = authenticationService.currentUserValue;

export default class EditIndustries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: false,
      items: [],
      loading: false,
      typeId: "",
      title: "",
      description: "",
      industrytype: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    let uri = window.location.pathname.split("/");
    let id = uri[2];
    event.preventDefault();
    this.setState({ submitted: true });
    const { typeId, title, description } = this.state;
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    axios
      .post(
        config.API_URL + `updateIndustry/${id}`,
        {
          typeId: typeId,
          title: title,
          description: description,
        },
        { headers }
      )
      .then((response) => {
        sweetAlert("success", response.data.message);
      })
      .catch(function (error) {
        if (error.response) {
          sweetAlert("error", error.response.data.message);
        }
      });
  }

  componentDidMount() {
    let uri = window.location.pathname.split("/");
    let id = uri[2];
    fetch(config.API_URL + `getsingleindustry/${id}`)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded2: true,
            title: result.singleIndustry[0]?.title,
            description: result.singleIndustry[0]?.description,
            industrytype: result.singleIndustry[0]?.name,
          });
        },

        (error2) => {
          this.setState({
            isLoaded2: true,
            error2,
          });
        }
      );
  }

  render() {
    const { title, description, industrytype } = this.state;
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="col-sm-12">
                        <div className="color_div_on framwork_2">
                          <div className="business_detail">
                            <div className="">
                              <div className="saved_cards">
                                <form name="form" onSubmit={this.handleSubmit}>
                                  <div className="business_detail">
                                    <div className="heading">
                                      <h4>Update Industry</h4>
                                    </div>
                                    <hr className="line"></hr>

                                    <div className="row">
                                      <div className="col-lg-6 col-xs-6">
                                        <div className="form-group pb-3">
                                          <label
                                            htmlFor="industryName"
                                            className="mb-2"
                                          >
                                            Industry Name*
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control py-3"
                                            id="industryName"
                                            placeholder="Enter Industry Name"
                                            name="title"
                                            onChange={this.handleChange}
                                            value={title}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-xs-6">
                                        <div className="form-group pb-3">
                                          <label
                                            htmlFor="industryType"
                                            className="mb-2"
                                          >
                                            Industry Type*
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control py-3"
                                            id="industryType"
                                            placeholder="Industry Type"
                                            name="industrytype"
                                            disabled
                                            value={industrytype}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <label
                                        htmlFor="industryDescription"
                                        className="mb-2"
                                      >
                                        Industry Description*
                                      </label>
                                      <textarea
                                        type="text"
                                        className="form-control"
                                        id="industryDescription"
                                        placeholder="Write Industry Description"
                                        name="description"
                                        onChange={this.handleChange}
                                        value={description}
                                      />
                                    </div>
                                  </div>
                                  <div className="global_link mx-0 my-3">
                                    <button
                                      type="submit"
                                      className="page_width page_save"
                                    >
                                      UPDATE NOW
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
