import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import "../../Sector_Question_Manage/control.css";

import noDataFound from "../../../img/no_data_found.png";

export const SectorQuestionCategory = ({sectorQuestion}) => {
  return (
    <div className="sectorQuestion">
      {sectorQuestion?.length > 0 ? (
        <>       
          <div className="saved_cards">
            <div className="table_f mt-2" style={{overflow: "auto"}}>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr className="heading_color sticky_header">
                    <th>Sr.</th>
                    <th>Framework</th>
                    <th>Topic</th>
                    <th>Kpi</th>
                    <th>Entity</th>
                    <th>Question Type</th>
                    <th>Question Heading</th>
                    <th>Formula</th>
                    <th>Sector Question</th>                  
                  </tr>
                </thead>
                <tbody>
                  {sectorQuestion &&
                    sectorQuestion?.map((item, key) => (
                      <tr key={key}>
                        <td>{key + 1}</td>
                        <td>{item.framework_title}</td>
                        <td>{item.topic_title}</td>
                        <td>{item.kpi_title}</td>
                        <td>{item.entity}</td>
                        <td>{item.questionType}</td>
                        <td>{item.heading}</td>
                        <td>
                          {item.questionType === "tabular_question"
                            ? item?.formula && item?.formula.replace(/\\/g, "")
                            : item?.formula}
                        </td>
                        <td>{item.title}</td>
                     
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </div>
        </>
      ) : (
        <img className="noDataFound" src={noDataFound} alt="" srcset="" style={{height: "100%"}}/>
      )}
   
    </div>
  );
};
