/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import "../Company Admin/Setting/setting.css";
import Table from "react-bootstrap/Table";
import AdminSidebar from "../sidebar/admin_sidebar";
import AdminHeader from "../header/admin_header";
import Smartup_Tabbing from "./smartup_tabbing";

export default class SmartUPChannelDetails extends Component {
  render() {
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <Smartup_Tabbing />
                    <div className="col-sm-12">
                      <div className="SVG Stepper">
                        <div className="stepperr_design">
                          <div className="color_div_step my-4">
                            <div className="include">
                              <section className="forms">
                                <div className="row">
                                  <div className="col-md-12 col-xs-12">
                                    <form>
                                      <div className="business_detail">
                                        <div className="main_one">
                                          <div className="main_two">
                                            <h4>SmartUP Channel Details</h4>
                                          </div>
                                        </div>
                                        <hr className="line"></hr>
                                        <div className="form-group pb-3">
                                          <label htmlFor="exampleInputEmail1">
                                            Name
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            value="Compliance"
                                            disabled
                                            name="position"
                                            id="exampleInputEmail1"
                                            aria-describedby="emailHelp"
                                            placeholder="CEO"
                                          />
                                        </div>
                                        <div className="form-group pb-3">
                                          <label htmlFor="exampleInputPassword1">
                                            Description
                                          </label>
                                          <textarea
                                            type="email"
                                            className="form-control disableddd"
                                            id="exampleInputPassword1"
                                            placeholder="name"
                                            disabled
                                            name="email"
                                            value="This channel is all about compliance.	"
                                          />
                                        </div>
                                        <div className="row">
                                          <div className="col-lg-6 col-xs-6">
                                            <div className="form-group pb-3">
                                              <label htmlFor="exampleInputPassword1">
                                                Privacy
                                              </label>
                                              <input
                                                type="text"
                                                disabled
                                                value="Open"
                                                name="firstName"
                                                className="form-control"
                                                id="exampleInputPassword1"
                                                placeholder="John"
                                              />
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-xs-6">
                                            <div className="form-group pb-3">
                                              <label htmlFor="exampleInputPassword1">
                                                Auto Join
                                              </label>
                                              <input
                                                type="text"
                                                disabled
                                                value="Yes"
                                                name="lastName"
                                                className="form-control"
                                                id="exampleInputPassword1"
                                                placeholder="Cooper"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-lg-6 col-xs-6">
                                            <div className="form-group pb-3">
                                              <label htmlFor="exampleInputPassword1">
                                                Auto Publish
                                              </label>
                                              <input
                                                type="text"
                                                value="No"
                                                name="company_industry"
                                                disabled
                                                className="form-control"
                                                id="exampleInputPassword1"
                                                placeholder="Business"
                                              />
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-xs-6">
                                            <div className="form-group pb-3">
                                              <label htmlFor="exampleInputPassword1">
                                                Status
                                              </label>
                                              <input
                                                type="text"
                                                value="Online"
                                                disabled
                                                name="userCategory"
                                                className="form-control"
                                                id="exampleInputPassword1"
                                                placeholder="Partner"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="business_detail mt-3">
                                        <div className="heading">
                                          <h4>Channel Managers</h4>
                                        </div>
                                        <hr className="line"></hr>

                                        <Table striped bordered hover size="sm">
                                          <thead>
                                            <tr className="heading_color">
                                              <th style={{ width: "10%" }}>ID</th>
                                              <th>FULL NAME</th>
                                              <th>EMAIL </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>1</td>
                                              <td>Reliabilt</td>
                                              <td>abc@gmail.com</td>
                                            </tr>
                                            <tr>
                                              <td>2</td>
                                              <td>Electrolux</td>
                                              <td>sg567@gmail.com</td>
                                            </tr>
                                          </tbody>
                                        </Table>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </section>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
