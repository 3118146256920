/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Swal from "sweetalert2";
import React, { Component } from "react";
import { Button, Header, Modal } from "semantic-ui-react";
import axios from "axios";
import "./governancePage.css";
import moment from "moment";
import {sweetAlert} from '../../../../utils/UniversalFunction'
import { authenticationService } from "../../../../_services/authentication";
import config from "../../../../config/config.json";
import AdminSidebar from "../../../sidebar/admin_sidebar";
import AdminHeader from "../../../header/admin_header";
import GovernanceTabbing from "./governance_tabbing";

const BASE_URL = config.BASE_URL;
const currentUser = authenticationService.currentUserValue;
export default class social_policies_tabbing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModel: false,
      activeModal: "",
      activeModal1: "",
      isLoaded: true,
      socialPolicy: [],
      socialSpecificPolicy: [],
      policyComment: [],
      comment:"",
      policyid: [],
      uuid: "",
      setOpen: false,
      setOpen1: false,
      feedback: "",
      status: "",
      idd: "",
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenModal1 = this.handleOpenModal1.bind(this);
    this.handleCloseModal1 = this.handleCloseModal1.bind(this);
    this.goToPreviousPath = this.goToPreviousPath.bind(this);
    this.serverRequest = this.serverRequest.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.serverRequest2 = this.serverRequest2.bind(this);
    this.serverRequest3 = this.serverRequest3.bind(this);
    this.handleSubmit2 = this.handleSubmit2.bind(this);
    this.handleSubmit3 = this.handleSubmit3.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
    this.policyReevaluated = this.policyReevaluated.bind(this);
    this.socialSpecificPolicy = this.socialSpecificPolicy.bind(this);
  }

  handleChange(event) {
    const { value } = event.target;
    this.setState({
      feedback: value,
    });
  }

  handleChange1(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  goToPreviousPath() {
    this.props.history.goBack();
  }

  handleSubmit = (event) => {
    let getStatus = event.target.getAttribute("data-status");
    let getId = event.target.getAttribute("data-id");
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };

    Swal.fire({
      title: "Do you want to Approve Policy?",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Update",
      // denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(
            config.ADMIN_API_URL +
              `company/${this.state.uuid}/modules/governance/governance-policy/${getId}`,
            {
              status: getStatus,
            },
            {
              headers,
            }
          )
          .then((response) => {
            this.serverRequest2();
            this.socialSpecificPolicy();
            sweetAlert('success',response.data.message);	
          })
          .catch(function (response) {
            sweetAlert('error',response.data.message);
          });
      } else if (result.isDenied) {
        sweetAlert('info',"User Safe");
      }
    });
  };

  handleSubmit2 = (event) => {
    event.preventDefault();
    const { status, idd, feedback } = this.state;
    let getStatus = status;
    let getId = idd;
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    axios
      .post(
        config.ADMIN_API_URL +
          `company/${this.state.uuid}/modules/governance/governance-policy/${getId}`,
        {
          status: getStatus,
          feedback: feedback,
        },
        {
          headers,
        }
      )
      .then((response) => {
        this.onClose();
        this.serverRequest2();
        this.socialSpecificPolicy();
        sweetAlert('success',response.data.message);	
      })
      .catch(function (response) {
        sweetAlert('error',response.data.message);
      });
  };

  policyReevaluated = (event) => {
    let getStatus = event.target.getAttribute("data-status");
    let getId = event.target.getAttribute("data-id");
    console.log("this is attr", event.target);
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    axios
      .post(
        config.ADMIN_API_URL +
          `company/${this.state.uuid}/modules/updateReevaluatePolicy/${getId}`,
        {
          status: getStatus,
        },
        {
          headers,
        }
      )
      .then((response) => {
        this.onClose();
        this.serverRequest2();
        this.socialSpecificPolicy();
      })
      .catch(function (response) {
        sweetAlert('error',response.data.message);
      });
  };

  handleOpenModal(val) {
    this.setState({ activeModal: val });
    this.setState({ showModal: true });
  }
  handleOpenModal1(val) {
    this.setState({ activeModal1: val });
    this.setState({ showModal: true });
  }

  handleCloseModal1() {
    this.setState({ showModal: false });
    this.setState({ showModal: "" });
  }
  onClose() {
    this.setState({
      setOpen: false,
      setOpen1: false,
    });
  }
  setOpen(event) {
    let getStatus = event.target.getAttribute("data-status");
    let getId = event.target.getAttribute("data-id");
    this.setState({
      setOpen: true,
      status: getStatus,
      idd: getId,
    });
  }

  setOpen1(event) {
    let policyid = event.target.getAttribute("policyid");
    this.setState({
      setOpen1: true,
      policyid: policyid,
    });

    let commentor = this.state.id;

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };

    fetch(
      config.API_URL +
        `getGovernanceComments/${policyid}/${commentor}`,
      requestOptions
    )
      .then((res) => res.json())
      .then(
        (data) => {
          this.setState({
            isLoaded: false,
            policyComment: data.data,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  handleCloseModal() {
    this.setState({ showModal: false });
    this.setState({ showModal: "" });
  }

  handleSubmit3 = (event) => {
    event.preventDefault();
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    axios
      .post(
        config.API_URL +
          "sendGovernanceComments",
        {
          governanceTopicsID : this.state.policyid,
          commentor : this.state.id,
          role : localStorage.getItem("role"),
          comment : this.state.comment
        },
        {
          headers,
        }
      )
      .then((response) => {
        this.onClose();
        sweetAlert('success',response.data.message);	
      })
      .catch(function (response) {
        sweetAlert('error',response.data.message);
      });
  };

  serverRequest() {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };
    fetch(config.API_URL + "getGovernanceTopics", requestOptions)
      .then((res) => res.json())
      .then(
        (data) => {
          this.setState({
            isLoaded: false,
            socialPolicy: data.socialPolicy,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  serverRequest2() {
    let urlArr = window.location.pathname.split("/");
    const finalUUId = urlArr[urlArr.length - 3];
    this.setState({
      uuid: urlArr[urlArr.length - 3],
    });

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };

    fetch(
      config.ADMIN_API_URL +
        `company/${finalUUId}/modules/governance/governance-policy`,
      requestOptions
    )
      .then((res) => res.json())
      .then(
        (data) => {
          this.setState({
            isLoaded: false,
            socialPolicy: data.socialPolicy,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  serverRequest3(uuidd) {
    let uuid = uuidd;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };
    let parms = `company/${uuid}`;
    fetch(config.ADMIN_API_URL + parms, requestOptions)
      .then((res) => res.json())
      .then(
        (data) => {
          
          this.setState({
            id: data.data[0]?.id,
          });
          
        },
        (error) => {}
      );
  }

  socialSpecificPolicy(){
    let urlArr = window.location.pathname.split("/");
    const finalUUId = urlArr[urlArr.length - 3];
    this.setState({
      uuid: urlArr[urlArr.length - 3],
    });

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };

    fetch(
      config.ADMIN_API_URL +
        `company/modules/governance/specific-governance-policy/${finalUUId}`,
      requestOptions
    )
      .then((res) => res.json())
      .then(
        (data) => {
          this.setState({
            isLoaded: false,
            socialSpecificPolicy: data.socialPolicy,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  componentDidMount() {
    let urlArr = window.location.pathname.split("/");
    this.serverRequest3(urlArr[urlArr.length - 3]);
    this.setState({
      uuid:urlArr[urlArr.length - 3]
    })
    this.serverRequest2();
    this.socialSpecificPolicy();
  }
  render() {
    const { policyComment, socialPolicy,socialSpecificPolicy } = this.state;

    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <GovernanceTabbing />
                    <div className="col-sm-12">
                      <div className="strp_progess">
                        <div className="hol_rell">
                          <div className="steps-form">
                            <div className="steps-row setup-panel"></div>
                          </div>
                        </div>
                      </div>
                      <div className="SVG Stepper">
                        <div className="stepperr_design">
                          <div className="color_div_step">
                            <div className="include">
                              <div className="row setup-content" id="step-10">
                                <div className="col-md-12">
                                  {/* <h6 className="back_quninti">
                                    <NavLink className="back_text" to="#">
                                      <span className="step_icon">
                                        <i className="far fa-long-arrow-alt-left"></i>
                                      </span>
                                      Back
                                    </NavLink>
                                  </h6> */}
                                  <div className="">
                                    <h4 className="E_capital">
                                      Social Policies
                                    </h4>
                                    <div className="Statement">
                                      {socialPolicy.map((item, key) => (
                                        <div
                                          key={key}
                                          className="Statement_one"
                                        >
                                          <div className="Statement_2">
                                            <p className="statement_p">
                                              {item.question}
                                            </p>
                                            <textarea
                                                className="form-control box_layout"
                                                name="description"
                                                disabled
                                                value={item.description || item.description ? item.description : ""}
                                                placeholder="No Description"
                                            ></textarea>
                                          </div>
                                          <div className="Statement_two">
                                            <div className="statement_icon d-flex align-items-center">
                                              {item.docsFile !== "" && (
                                                <>
                                                  <span className="statement_pen">
                                                    {item.status === 1 && (
                                                      <>
                                                        <a
                                                          href="#"
                                                          data-status="0"
                                                          data-id={
                                                            item.savedIdd
                                                          }
                                                          onClick={
                                                            this
                                                              .policyReevaluated
                                                          }
                                                        >
                                                          <i
                                                            data-status="0"
                                                            data-id={
                                                              item.savedIdd
                                                            }
                                                            onClick={
                                                              this
                                                                .policyReevaluated
                                                            }
                                                            className="fas fa-pen"
                                                          >
                                                            <span
                                                              data-status="0"
                                                              data-id={
                                                                item.savedIdd
                                                              }
                                                              onClick={
                                                                this
                                                                  .policyReevaluated
                                                              }
                                                              className="mx-3"
                                                            >
                                                              Re-evaluate
                                                            </span>
                                                          </i>
                                                        </a>
                                                        <i
                                                          style={{
                                                            color: "green",
                                                          }}
                                                          className="fas fa-check-circle"
                                                        ></i>
                                                      </>
                                                    )}
                                                    {item.status === 2 && (
                                                      <>
                                                        <a
                                                          href={"#"}
                                                          data-status="0"
                                                          data-id={
                                                            item.savedIdd
                                                          }
                                                          onClick={
                                                            this
                                                              .policyReevaluated
                                                          }
                                                        >
                                                          <i
                                                            data-status="0"
                                                            data-id={
                                                              item.savedIdd
                                                            }
                                                            onClick={
                                                              this
                                                                .policyReevaluated
                                                            }
                                                            className="fas fa-pen"
                                                          >
                                                            <span
                                                              data-status="0"
                                                              data-id={
                                                                item.savedIdd
                                                              }
                                                              onClick={
                                                                this
                                                                  .policyReevaluated
                                                              }
                                                              className="mx-3"
                                                            >
                                                              Re-evaluate
                                                            </span>
                                                          </i>
                                                        </a>
                                                        <i
                                                          style={{
                                                            color: "red",
                                                          }}
                                                          className="fas fa-check-circle"
                                                        ></i>
                                                      </>
                                                    )}
                                                    {item.status === 0 && (
                                                      <>
                                                        <i
                                                          data-status="1"
                                                          data-id={
                                                            item.savedIdd
                                                          }
                                                          onClick={
                                                            this.handleSubmit
                                                          }
                                                          className="fas fa-check green"
                                                        ></i>
                                                        <i
                                                          data-status="2"
                                                          data-id={
                                                            item.savedIdd
                                                          }
                                                          onClick={(e) =>
                                                            this.setOpen(e)
                                                          }
                                                          className="fas fa-times red"
                                                        ></i>
                                                      </>
                                                    )}
                                                  </span>
                                                  <span className="">
                                                    <button
                                                      className="btn btn-link view-button"
                                                      variant="none"
                                                      onClick={() =>
                                                        this.handleOpenModal(
                                                          "login"
                                                        )
                                                      }
                                                    >
                                                      <a
                                                        href={
                                                          BASE_URL +
                                                          item.docsFile
                                                        }
                                                        target="_blank"
                                                        download=""
                                                      >
                                                        <i className="fa fa-eye">
                                                          <span className="mx-2">
                                                            View
                                                          </span>
                                                        </i>
                                                      </a>
                                                    </button>
                                                  </span>
                                                </>
                                              )}
                                              {item.docsFile === "" && (
                                                <p>N/A</p>
                                              )}
                                              <div className="px-3">
                                              <a
                                              policyid= {item.id}
                                              onClick={(e) => this.setOpen1(e)}>
                                              <i policyid= {item.id} className="fa fa-comment">
                                              </i>
                                              </a>
                                            </div>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                      {socialSpecificPolicy.map((item, key) => (
                                        <div
                                          key={key}
                                          className="Statement_one"
                                        >
                                          <div className="Statement_2">
                                            <p className="statement_p">
                                              {item.question}
                                            </p>
                                            <textarea
                                                className="form-control box_layout"
                                                name="description"
                                                disabled
                                                value={item.description || item.description ? item.description : ""}
                                                placeholder="No Description"
                                            ></textarea>
                                          </div>
                                          <div className="Statement_two">
                                            <div className="statement_icon d-flex align-items-center">
                                              {item.docsFile !== "" && (
                                                <>
                                                  <span className="statement_pen">
                                                    {item.status === 1 && (
                                                      <>
                                                        <a
                                                          href="#"
                                                          data-status="0"
                                                          data-id={
                                                            item.savedIdd
                                                          }
                                                          onClick={
                                                            this
                                                              .policyReevaluated
                                                          }
                                                        >
                                                          <i
                                                            data-status="0"
                                                            data-id={
                                                              item.savedIdd
                                                            }
                                                            onClick={
                                                              this
                                                                .policyReevaluated
                                                            }
                                                            className="fas fa-pen"
                                                          >
                                                            <span
                                                              data-status="0"
                                                              data-id={
                                                                item.savedIdd
                                                              }
                                                              onClick={
                                                                this
                                                                  .policyReevaluated
                                                              }
                                                              className="mx-3"
                                                            >
                                                              Re-evaluate
                                                            </span>
                                                          </i>
                                                        </a>
                                                        <i
                                                          style={{
                                                            color: "green",
                                                          }}
                                                          className="fas fa-check-circle"
                                                        ></i>
                                                      </>
                                                    )}
                                                    {item.status === 2 && (
                                                      <>
                                                        <a
                                                          href={"#"}
                                                          data-status="0"
                                                          data-id={
                                                            item.savedIdd
                                                          }
                                                          onClick={
                                                            this
                                                              .policyReevaluated
                                                          }
                                                        >
                                                          <i
                                                            data-status="0"
                                                            data-id={
                                                              item.savedIdd
                                                            }
                                                            onClick={
                                                              this
                                                                .policyReevaluated
                                                            }
                                                            className="fas fa-pen"
                                                          >
                                                            <span
                                                              data-status="0"
                                                              data-id={
                                                                item.savedIdd
                                                              }
                                                              onClick={
                                                                this
                                                                  .policyReevaluated
                                                              }
                                                              className="mx-3"
                                                            >
                                                              Re-evaluate
                                                            </span>
                                                          </i>
                                                        </a>
                                                        <i
                                                          style={{
                                                            color: "red",
                                                          }}
                                                          className="fas fa-check-circle"
                                                        ></i>
                                                      </>
                                                    )}
                                                    {item.status === 0 && (
                                                      <>
                                                        <i
                                                          data-status="1"
                                                          data-id={
                                                            item.savedIdd
                                                          }
                                                          onClick={
                                                            this.handleSubmit
                                                          }
                                                          className="fas fa-check green"
                                                        ></i>
                                                        <i
                                                          data-status="2"
                                                          data-id={
                                                            item.savedIdd
                                                          }
                                                          onClick={(e) =>
                                                            this.setOpen(e)
                                                          }
                                                          className="fas fa-times red"
                                                        ></i>
                                                      </>
                                                    )}
                                                  </span>
                                                  <span className="">
                                                    <button
                                                      className="btn btn-link view-button"
                                                      variant="none"
                                                      onClick={() =>
                                                        this.handleOpenModal(
                                                          "login"
                                                        )
                                                      }
                                                    >
                                                      <a
                                                        href={
                                                          BASE_URL +
                                                          item.docsFile
                                                        }
                                                        target="_blank"
                                                        download=""
                                                      >
                                                        <i className="fa fa-eye">
                                                          <span className="mx-2">
                                                            View
                                                          </span>
                                                        </i>
                                                      </a>
                                                    </button>
                                                  </span>
                                                </>
                                              )}
                                              {item.docsFile === "" && (
                                                <p>N/A</p>
                                              )}
                                              <div className="px-3">
                                              <a
                                              policyid= {item.id}
                                              onClick={(e) => this.setOpen1(e)}>
                                              <i policyid= {item.id} className="fa fa-comment">
                                              </i>
                                              </a>
                                            </div>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <Modal open={this.state.setOpen} className="feedback2">
                <Modal.Header>Feedback</Modal.Header>
                <form onSubmit={this.handleSubmit2}>
                  <Modal.Content>
                    <Modal.Description>
                      <Header>Please enter your comments below</Header>

                      <div className="form-group">
                        <textarea
                          defaultValue={this.state.feedback}
                          onChange={this.handleChange}
                          className="form-control"
                        ></textarea>
                      </div>
                    </Modal.Description>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button
                      type="submit"
                    > Submit </Button>
                    <Button color="" onClick={() => this.onClose(false)}>
                      Cancel
                    </Button>
                  </Modal.Actions>
                </form>
              </Modal>
              <Modal open={this.state.setOpen1} className="feedback3">
                <Modal.Header>Comments</Modal.Header>
                <form onSubmit={this.handleSubmit3}>
                  <Modal.Content>
                    <Modal.Description>
                      <div className="commentsBox">
                      {policyComment.map((item, key) => (
                      <div 
                      className={
                        item.role === "company_admin"
                          ? "sender"
                          : "receiver"
                      }
                      >
                        <p
                        className={
                          item.role === "company_admin"
                            ? "senderMessage"
                            : "receiverMessage"
                        }
                        >{item.comment}
                        <span className="commentDate">
                        {moment(item.createdAt).utc().format('DD-MM-YYYY')}
                        </span>
                        </p>
                      </div>
                       ))}                      
                      </div>
                      <Header>Please enter your comments below</Header>
                      
                      <div className="form-group">
                        <textarea
                          name="comment"
                          onChange={this.handleChange1}
                          className="form-control"
                        ></textarea>
                      </div>
                    </Modal.Description>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button
                      type="submit"
                    > Send
                    </Button>
                    <Button onClick={() => this.onClose(false)}>
                      Cancel
                    </Button>
                  </Modal.Actions>
                </form>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
