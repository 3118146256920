import React, { useEffect, useState } from "react";
import { Dropdown, Form, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { apiCall } from "../../../_services/apiCall";
import { authenticationService } from "../../../_services/authentication";
import config from "../../../config/config.json";
import "../../Sector_Question_Manage/control.css";
import CreateSectorQuestionModal from "./CreateSectionModal";

export const Question = (props) => {
  const {
    frameworkData,
    financial_year_id,
    handleKPIChangeHandler,
    handleAddTopicQuestionChangeHandler,
  } = props;
  const [topics, setTopics] = useState([]);
  const [menulist, setMenuList] = useState([]);
  const [upadteTopic, setUpadteTopic] = useState(false);
  const [addTopic, setAddTopic] = useState(false);
  const closeUpdateFrameworkPopup = () => setUpadteTopic(false);
  const showAddTopicPopup = () => setAddTopic(true);
  const showUpdateTopicPopup = () => setUpadteTopic(true);
  const closeAddTopicPopup = () => setAddTopic(false);


  const getReportingQuestion = async (id) => {
    if(frameworkData?.id){
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}getReportingQuestion`,
      {},
      { module_ids:[ frameworkData?.id] },
      "GET"
    );
    setTopics(data?.data?.reverse());
    console.log("question", data?.data)
    }
  };

  const deleteQuestion = async( item)=>{
    console.log(item)
    let obj = {};
    obj.id = item?.question_id;
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}deleteReportingQuestion`,
      {},
      obj,
      "POST"
    );
    
    getReportingQuestion();
  }

  const handleEditQuestion =(item)=>{
    console.log("item",item)
  
  }

  useEffect(() => {
    const currentUser = authenticationService?.currentUserSubject?.getValue();
    const settingsMenu = currentUser?.data?.menu?.find(
      (item) => item?.url === "global_controls"
    );
    setMenuList(settingsMenu?.permissions);
    if (props.frameworkData.id) {
      getReportingQuestion(props.frameworkData.id);
    }
  }, [props.frameworkData.id]);

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <div className="heading">
          <h4 className="m-0">{frameworkData.title}</h4>
          <span className="global_link mx-0">

              <>
                <div
                  onClick={showAddTopicPopup}
                  className="mx-3 add_framework_control"
                >
                  <i className="fas fa-plus-square fs-lg" title="Add Topic"></i>
                  &nbsp;&nbsp;
                </div>
              </>

          </span>
        </div>
        <div>
          <input
            type="search"
            className="form-control w-100"
            placeholder="Search Topics"
            name="search"
          />
        </div>
      </div>
      <div className="table_f border-top mt-2 auto_scroll_by_design">
        <Table striped bordered className="mt-2 mb-0">
          <thead>
            <tr className="heading_color sticky_header">
              <th style={{ width: 50 }}>Sr.</th>
              <th>Question Title</th>
              <th>
                Question Type
              </th>
              <th style={{ width: 50 }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {topics?.map((item, key) => (
              <tr key={key}>
                <td>{key + 1}</td>
                <td>{item.title}</td>
                <td>{item.question_type}</td>
                <td className="text-center">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic"></Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={(e) =>
                          handleEditQuestion(item)
                        }
                      >
                        <i className="fas fa-plus-square"></i>&nbsp;&nbsp; Edit
                        Question
                      </Dropdown.Item>
                  
                      <Dropdown.Item
                        onClick={(e) => deleteQuestion(item)}
                      >
                        <i className="fas fa-eye"></i>&nbsp;&nbsp; Delete Question
                      </Dropdown.Item>
                      
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {/* ------------ Update Topic --------- */}
      {/* <Modal show={upadteTopic} onHide={closeUpdateFrameworkPopup}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Topic</Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <button
            className="link_bal_next_cancel"
            onClick={closeUpdateFrameworkPopup}
          >
            Cancel
          </button>
          <button className="link_bal_next" type="submit">
            Update
          </button>
        </Modal.Footer>
      </Modal> */}
      <Modal show={addTopic} onHide={closeAddTopicPopup} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <Form.Label>Add Question</Form.Label>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <CreateSectorQuestionModal
            module={frameworkData}
            // financial_year_id={financial_year_id}
          />
        </div>
      </Modal.Body>
      <Modal.Footer style={{ height: 65 }}> </Modal.Footer>
    </Modal>
      {/* <QuestionModal show={addTopic} onHide={closeAddTopicPopup} handleSubmit={handleSubmit} setTitle={setTitle} setTopicType={setTopicType} /> */}
    </div>
  );
};
