import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import "../../../Sector_Question_Manage/control.css";
import { NavLink } from "react-router-dom";
import { apiCall } from "../../../../_services/apiCall";
import config from "../../../../../src/config/config.json";
import { authenticationService } from "../../../../_services/authentication";
import { Dropdown } from "react-bootstrap";

export const Kpis = (props) => {
  const { topicData,currentAddFrameworkQuestionData, handleAddKpiQuestionChangeHandler} = props;
  const [kpis, setKpis] = useState([]);
  const [menulist, setMenuList] = useState([]);
  const callApi = async () => {
    if(currentAddFrameworkQuestionData?.topic_id && currentAddFrameworkQuestionData?.id){
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}getKpi`,
      {},
      { type: "QUESTION", topic_id:currentAddFrameworkQuestionData?.topic_id, framework_id:currentAddFrameworkQuestionData?.id},
      "GET"
    );
    setKpis(data?.data?.reverse());
    }
  };

  const deleteKPI = async (e, item) => {
    let obj = {};
    obj.id = item?.id;
    obj.topic_id = item?.topic_id;
    obj.topic_kpi_id = item?.topic_kpi_id;
    await apiCall(`${config.ADMIN_API_URL}deleteKpi`, {}, obj, "POST");
    callApi();
  };

  const handleClickAddKpiQuestionComponent= (addQuestionCopmonentData, tabId) => {
    handleAddKpiQuestionChangeHandler(addQuestionCopmonentData,currentAddFrameworkQuestionData, tabId)
  }

  useEffect(() => {
    if(currentAddFrameworkQuestionData.id)
    {
      callApi();
    }
  }, [currentAddFrameworkQuestionData.id, currentAddFrameworkQuestionData?.topic_id]);

  useEffect(() => {
    const currentUser = authenticationService?.currentUserSubject?.getValue();
    const settingsMenu = currentUser?.data?.menu?.find(
      (item) => item?.url === "global_controls"
    );
    setMenuList(settingsMenu?.permissions);
    callApi();
  }, []);
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <div className="heading">
          <h4 className="m-0">{topicData.title}</h4>
          <span className="global_link mx-0">
            {menulist?.includes("KPI_CREATE") && (
              <NavLink className="non_underline_link bold" to="/kpi/create">
                <div
                  className="mx-3 add_framework_control"
                  variant="none"
                  to="/kpi/create"
                >
                  <i className="fas fa-plus-square fs-lg" title="Add Kpi"></i>&nbsp;&nbsp;
                </div>
              </NavLink>
            )}
          </span>
        </div>
        <div>
          <input
            type="search"
            className="form-control w-100"
            placeholder="Search Kpi"
            name="search"
          />
        </div>
      </div>
      <div className="table_f border-top mt-2 auto_scroll_by_design">
        <Table striped bordered className="mt-2 mb-0">
          <thead>
            <tr className="heading_color sticky_header">
              <th style={{ width: 50 }}>Sr.</th>
              <th>Kpi</th>
              <th style={{width : 50}}>Action</th>
            </tr>
          </thead>
          <tbody>
            {kpis?.map((item, key) => (
              <tr key={key}>
                <td>{key + 1}</td>
                <td>{item.title}</td>
                <td className="text-center">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={(e) => handleClickAddKpiQuestionComponent(item, "addQuestion")}>
                        <i className="fas fa-plus-square"></i>&nbsp;&nbsp; Add Question
                      </Dropdown.Item>
                      <Dropdown.Item onClick={(e) => handleClickAddKpiQuestionComponent(item, "questionList")}>
                        <i className="fas fa-eye"></i>&nbsp;&nbsp; View Question
                      </Dropdown.Item>
                      {item?.is_editable ? (
                        <>
                          {menulist?.includes("KPI_UPDATE") && (
                            <Dropdown.Item>
                              <i className="far fa-edit"></i>&nbsp;&nbsp; Edit Kpi
                            </Dropdown.Item>
                            )}
                          {menulist?.includes("KPI_DELETE") && (
                            <Dropdown.Item onClick={(e) => deleteKPI(e, item)}>
                              <span style={{color: 'red'}}>
                                <i className="fas fa-trash"></i>&nbsp;&nbsp; Delete Kpi
                              </span>
                            </Dropdown.Item>
                          )}
                        </>
                      ):""} 
                    </Dropdown.Menu>
                  </Dropdown> 
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};
