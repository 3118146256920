import React, { Component } from "react";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import Table from "react-bootstrap/Table";
import "../../Company Admin/Setting/setting.css";

export default class invoiceSupplierManagement extends Component {
  render() {
    console.log("v", this.props.location.pathname);
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="Introduction framwork_2">
                        <div className="text_Parts">
                          <div className="text_ntroion">
                            <h5 className="Intro">Invoice</h5>
                            <div className="row mt-5">
                              <div className="col-lg-3 col-sm-6 col-xs-12">
                                <div className="address">
                                  <h5>Billed To</h5>
                                  <p>Aden Matchett Vandelay Group LTD</p>
                                  <p>
                                    2 Court Square, new York, NY 12210 united
                                    States
                                  </p>
                                </div>
                              </div>
                              <div className="col-lg-3 col-sm-6 col-xs-12">
                                <div className="address mx-5">
                                  <h5>Invoice Date</h5>
                                  <p>06/01/2022</p>
                                  <div className="address">
                                    <h5>Due Date</h5>
                                    <p>08/02/2022</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-sm-6 col-xs-12">
                                <div className="address">
                                  <h5>Invoice Number</h5>
                                  <p>57192</p>
                                </div>
                              </div>
                              <div className="col-lg-3 col-sm-6 col-xs-12">
                                <div className="address">
                                  <h5>Amount Due (USD)</h5>
                                  <p className="amount">$200</p>
                                </div>
                              </div>
                            </div>
                            <hr className="line-hr"></hr>
                            <div className="row">
                              <div className="col-lg-12 col-xs-12">
                                <div className="table_f">
                                  <Table striped bordered hover size="sm">
                                    <thead>
                                      <tr className="heading_color">
                                        <th>Description</th>
                                        <th>Amount</th>
                                        <th>Qty</th>
                                        <th>Line Total</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>Subscription Plan</td>
                                        <td>$100</td>
                                        <td>1</td>
                                        <td>$100</td>
                                      </tr>
                                      <tr>
                                        <td>Subscription Plan</td>
                                        <td>$100</td>
                                        <td>1</td>
                                        <td>$100</td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                            </div>
                            <div className="table_footer">
                              <div className="footer-lable d-flex">
                                <label className="mx-5 font-weight-bold">
                                  Subtotal{" "}
                                </label>
                                <p>$200</p>
                              </div>
                              <div className="footer-lable d-flex">
                                <label className="mx-5 font-weight-bold">
                                  Tax (6%){" "}
                                </label>
                                <p>$30</p>
                              </div>
                              <div className="footer-lable d-flex">
                                <label className="mx-5 font-weight-bold">
                                  Amount Due (USD){" "}
                                </label>
                                <p>$230</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
