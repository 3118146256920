/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
// import Sidebar from "../../../sidebar/sidebar";
// import Header from "../../../header/header";
// import Moment from "react-moment";
import { sweetAlert } from "../../../../utils/UniversalFunction";
import image16 from "../../../../img/16.png";
import image17 from "../../../../img/image 17.png";
import image18 from "../../../../img/image 18.png";
import image19 from "../../../../img/Group 81311.png";
import { NavLink, Link } from "react-router-dom";
import config from "../../../../config/config.json";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import EnviornmentConsideration from "../../Component/Dashboard/enviornment_consideration";
import "./home.css";

import axios from "axios";

import { authenticationService } from "../../../../_services/authentication";
import AdminSidebar from "../../../sidebar/admin_sidebar";
import AdminHeader from "../../../header/admin_header";
import { apiCall } from "../../../../_services/apiCall";
import { Form, Table } from "react-bootstrap";
import FormulaModal from "../../../../component/formula";
const currentUser = authenticationService.currentUserValue;

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      criteria: props?.location?.aboutProps ?? [],
      isLoaded: true,
      finalIds: [],
      getSectorQuestionData: [],
      submitted: false,
      csvsubmitted: false,
      firstName: "",
      lastName: "",
      email: "",
      companyName: "",
      items: [], 
    
      answerArray: [],
     
    
    };
    // this.handleChange = this.handleChange.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
  }






  async componentDidMount() {
    try {
      const { isSuccess, data } = await apiCall(
        config.API_URL + "api/v1/blogs",
        {},
        {}
      );
      if (isSuccess) {
        this.setState({
          isLoaded: false,
          items: data?.result,
        });
      } else {
        this.setState({
          isLoaded: true,
        });
      }
    } catch (e) {
      console.log(e, "jitendar");
    }
  }

  render() {
    const { input, suggestions, showSuggestions, selectedSuggestionIndex } =
      this.state;
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              {/* <!-- main text --> */}
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="esg_score_title">
                        <div className="main_one">
                          <div className="main_two">
                            <h4>Your ESG Score</h4>
                          </div>
                          <div className="main_three">
                            <p>ESG Score Overview</p>
                          </div>
                        </div>
                      </div>
                      <div className="color_div">
                        <div className="main_text">
                          <div className="hd_process">
                            <img src={image19} className="img-fluid" alt="" />
                            <div className="pie-wrapper progress-95 style-2">
                              <span className="label">
                                400/1000
                                <span className="exc">Avarage</span>
                              </span>

                              <div className="pie">
                                <div className="left-side half-circle"></div>
                                <div className="right-side half-circle"></div>
                              </div>
                              <div className="font">
                                <i className="fas fa-caret-down"></i>
                              </div>
                              <div className="shadow"></div>
                            </div>
                          </div>
                          <div className="over_lor">
                            <div className="over_view">
                              <div className="over_top px-4 py-2">
                                <h5 className="model_text">Module</h5>
                              </div>
                              <div className="over_top px-4 py-2">
                                <h5 className="model_text">Average</h5>
                              </div>
                              <div className="over_top px-4 py-2">
                                <h5 className="model_text">Score</h5>
                              </div>
                            </div>
                            <div className="px-4 py-3 tabele_div">
                              <div className="inter_fats">
                                <div className="busins_point">
                                  <div className="bussins">
                                    <p className="prbus">
                                      Business Performance
                                    </p>
                                  </div>
                                  <div className="above_text">
                                    <p className="aver_icon">
                                      <i
                                        className="fa fa-caret-up tio"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Above Average
                                    </p>
                                  </div>
                                  <div className="text_number">
                                    <p className="nam_hol">0</p>
                                  </div>
                                </div>
                                <div className="f_Impr">
                                  <p className="Area_of">
                                    Area of Improvement:
                                  </p>
                                  <p className="Corpor">
                                    Conflict of interest , Corporate Governance
                                    Statement &amp; ESG on the board
                                  </p>
                                </div>
                              </div>
                              <hr className="ofice" />
                              {/* <!-- two --> */}
                              <div className="inter_fats">
                                <div className="busins_pointw">
                                  <div className="bussins">
                                    <p className="prbus">ESG Risk Management</p>
                                  </div>
                                  <div className="above_text">
                                    <p className="aver_icon">
                                      <i
                                        className="fa fa-caret-up tio"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Above Average
                                    </p>
                                  </div>
                                  <div className="text_number">
                                    <p className="nam_hol">0</p>
                                  </div>
                                </div>
                                <div className="f_Impr">
                                  <p className="Area_of">
                                    Area of Improvement:
                                  </p>
                                  <p className="Corpor">
                                    Answer sector questions &amp; report using
                                    GRI
                                  </p>
                                </div>
                              </div>
                              <hr className="ofice" />
                              {/* <!-- thre --> */}
                              <div className="inter_fats">
                                <div className="busins_pointw">
                                  <div className="bussins">
                                    <p className="prbus">
                                      Supplier Risk Management
                                    </p>
                                  </div>
                                  <div className="above_text">
                                    <p className="aver_icon">
                                      <i className="fas fa-caret-down"></i>Below
                                      Average
                                    </p>
                                  </div>
                                  <div className="text_number">
                                    <p className="nam_hol">0</p>
                                  </div>
                                </div>
                                <div className="f_Impr">
                                  <p className="Area_of">
                                    Area of Improvement:
                                  </p>
                                  <p className="Corpor">
                                    Improve supplier communication (if
                                    transparency is medium or low){" "}
                                  </p>
                                </div>
                              </div>
                              <hr className="ofice" />
                              {/* <!-- four --> */}
                              <div className="inter_fats">
                                <div className="busins_pointw">
                                  <div className="bussins">
                                    <p className="prbus">Carbon Footprint</p>
                                  </div>
                                  <div className="above_text">
                                    <p className="aver_icon">
                                      <i
                                        className="fas fa-caret-down"
                                        aria-hidden="true"
                                      ></i>
                                      Below Average
                                    </p>
                                  </div>
                                  <div className="text_number">
                                    <p className="nam_hol">0</p>
                                  </div>
                                </div>
                                <div className="f_Impr">
                                  <p className="Area_of">
                                    Area of Improvement:
                                  </p>
                                  <p className="Corpor">
                                    {" "}
                                    Complete scope 3 emissions{" "}
                                  </p>
                                </div>
                              </div>
                              <hr className="ofice" />
                              {/* <!-- five --> */}
                              <div className="inter_fats">
                                <div className="busins_pointw">
                                  <div className="bussins">
                                    <p className="prbus">
                                      Sustainable Development Goals
                                    </p>
                                  </div>
                                  <div className="above_text">
                                    <p className="aver_icon">
                                      <i className="fas fa-caret-down"></i>Below
                                      Average
                                    </p>
                                  </div>
                                  <div className="text_number">
                                    <p className="nam_hol">0</p>
                                  </div>
                                </div>
                                <div className="f_Impr">
                                  <p className="Area_of">
                                    Area of Improvement:
                                  </p>
                                  <p className="Corpor">
                                    {" "}
                                    Gender Equality , No Poverty &amp; Zero
                                    Hunger
                                  </p>
                                </div>
                              </div>
                              <div className="Download_lo">
                                <a className="Download_ur" href="#">
                                  Download your complete ESG Report
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div></div>
                    </div>
                    <div className="col-sm-6">
                      <div className="row_two_hol">
                        <div className="main_one">
                          <div className="main_two">
                            <h4>Key Supplier Risks</h4>
                          </div>
                          <div className="main_two">
                          <FormulaModal/>
                            {/* <button
                              onClick={() => this.handleOpenModalForFormula()}
                            >
                              Add Formula
                            </button> */}
                          </div>
                        </div>
                        <div className="color_div_key">
                          <div className="text_busses">
                            {/* <div className="your_Performance">
                              <p>
                                <i className="fas fa-ellipsis-v"></i>
                              </p>
                            </div> */}
                          </div>
                          <div className="table_row">
                            <div className="image-upload-wrap">
                              {/* <NavLink to="/supplier_management"> */}
                              <input
                                type="file"
                                className="file-upload-input"
                                name="supplierData"
                                // onChange={this.onFileChange}
                              />
                              <div className="drag-text">
                                <i className="far fa-file-alt"></i>
                                <h3>Upload Your Supplier Details</h3>
                              </div>
                            </div>
                            <div className="text-outside">
                              <p>
                                Upload or drag &amp; drop your <br />
                                documents for key supplier risks
                              </p>
                            </div>
                            <div className="meettable mt-3">
                              <Link
                                to="../../../../../../template.xlsx"
                                target="_blank"
                                className="page_save_min page_width_mini m-0"
                                type="button"
                              >
                                Download Template
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <!-- table tab --> */}
                      <div className="row_two_hol">
                        <div className="color_div p-0">
                          <div className="tabbedPanels">
                            {/* <!-- begins the tabbed panels / wrapper--> */}
                            <div className="panelContainer">
                              <EnviornmentConsideration />
                            </div>
                          </div>
                          {/* <!-- end panel 4 --> */}
                        </div>
                      </div>
                      <div className="">
                        <div className="color_div_Programs">
                          <div className="text_busses">
                            <div className="your_businefs">
                              <h4 className="progam_commend p-4">
                                Recommended Innovative Programs for you
                              </h4>
                            </div>
                          </div>
                          <div className="vertical_row">
                            <div className="progreas">
                              <h5 className="not_show">
                                Show the following if not supplied in the
                                modules:
                              </h5>
                              <div className="text_pros">
                                <ul className="sp_po">
                                  <li>
                                    <span className="pol">
                                      <strong>ESG Policy </strong>
                                    </span>
                                    <span className="ancy">
                                      Create an ESG policy to identify what ESG
                                      risks are prevalent in your business and
                                      who is managing these risks daily.
                                    </span>
                                    <a href="/governance" className="mb-3">
                                      Go To ESG Policy
                                    </a>
                                  </li>
                                  <li>
                                    <span className="pol">
                                      <strong>Carbon Footprint – </strong>
                                    </span>
                                    <span className="ancy">
                                      Carbon Footprint – Follow the guidelines
                                      to create and measure your carbon
                                      footprint so you know where you can reduce
                                      your impact on the planet
                                    </span>
                                    <a
                                      href="/carbon_footprint"
                                      className="mb-3"
                                    >
                                      Go To Carbon Footprint
                                    </a>
                                  </li>
                                  <li>
                                    <span className="pol">
                                      <strong>ESG reporting section:</strong>
                                    </span>
                                    <span className="ancy">
                                      ESG reportig section: GRESB, CDP, ISO,
                                      SASB, GRI. If none of these ticked: Report
                                      annually using a globally recognisable ESG
                                      framework.
                                    </span>
                                    <a href="/esg_reporting">
                                      Go To ESG Reporting
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- new add --> */}

                  <div className="camad">
                    <div className="color_div_Current">
                      <div className="color_rent mb-0">
                        <h6 className="home_text">Current News</h6>
                      </div>
                      <div className="row">
                        {/*items.slice(0, 6).map((item, key) =>
                          <div className="col-md-6 mt-5">
                            <div className="row">
                              <div className="col-sm-6">
                                <div className="curren">
                                  <div className="curren_text traning_image">
                                    <a target="_blank" rel="noreferrer" href={item.link}>
                                      <img src={item.image} />
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="curren">
                                  <div className="curren_hop">
                                    <p className="dete_time">
                                      <Moment
                                        format="MMM YYYY"
                                        withTitle
                                      >
                                        {item.created_at}
                                      </Moment>
                                    </p>
                                   
                                      <a target="_blank"  rel="noreferrer" href={item.link}>
                                        <h4 className="Austr_text mt-3">
                                          {item.title}
                                        </h4>{" "}
                                      </a>
                                    <p className="Pacific">
                                      {item.companyName}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            </div>
                          )*/}
                      </div>
                      <div className="flop_two">
                        <div className="comleted_dell home-align mt-3">
                          <NavLink to="/training">view all</NavLink>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- new add --> */}
                  {/* <!-- col-12 --> */}
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="row_two_hole">
                        <div className="color_div_KATE">
                          <div className="three_box">
                            <div className="row">
                              <div className="col-sm-4">
                                {/* <!-- Card --> */}
                                <NavLink
                                  to="/academy_detail"
                                  className="card_anchor"
                                >
                                  <article className="card animated fadeInUp">
                                    <div className="card-block image">
                                      <h6 className="text-muted">Academy</h6>
                                    </div>
                                    <div className="four_box">
                                      <img
                                        className="imagev_icon"
                                        src={image16}
                                        alt=""
                                      />
                                    </div>
                                    <div className="card-block">
                                      <div className="text_bill">
                                        <p className="card-text">
                                          New to the platform?
                                        </p>
                                        <p className="card-text">
                                          Get started with our Introductory
                                          video Courses.
                                        </p>
                                      </div>
                                    </div>
                                  </article>
                                </NavLink>
                                {/* <!-- .end Card --> */}
                              </div>
                              <div className="col-sm-4">
                                {/* <!-- Card --> */}
                                <NavLink
                                  to="/coaching_form"
                                  className="card_anchor"
                                >
                                  <article className="card animated fadeInUp op">
                                    <div className="card-block image">
                                      <h6 className="text-muted">Coaching</h6>
                                    </div>
                                    <div className="four_box">
                                      <img
                                        className="imagev_icon"
                                        src={image17}
                                        alt=""
                                      />
                                    </div>
                                    <div className="card-block">
                                      <div className="text_bill">
                                        <p className="card-text">
                                          Find your coach &amp; <br />
                                          accelerate your learning
                                          <br /> &amp; reporting.
                                        </p>
                                        {/* <!--  <p className="card-text">Find your coach and<br> accelerate your learning<br> and reporting.</p> --> */}
                                      </div>
                                    </div>
                                  </article>
                                </NavLink>
                                {/* <!-- .end Card --> */}
                              </div>
                              <div className="col-sm-4">
                                {/* <!-- Card --> */}
                                <NavLink
                                  to="/continuing_professional_development_detail"
                                  className="card_anchor"
                                >
                                  <article className="card animated fadeInUp oplo">
                                    <div className="card-block image">
                                      <h6 className="text-muted">
                                        Continuing Professional <br />
                                        Development
                                      </h6>
                                    </div>
                                    <div className="four_box">
                                      <img
                                        className="imagev_icon"
                                        src={image18}
                                        alt=""
                                      />
                                    </div>
                                    <div className="card-block">
                                      <div className="text_bill">
                                        <p className="card-text">
                                          See content exclusively for
                                          <br /> our members
                                        </p>
                                      </div>
                                    </div>
                                  </article>
                                </NavLink>
                                {/* <!-- .end Card --> */}
                              </div>
                            </div>
                          </div>
                          <div className="comleted_dell home-align mt-3">
                            <NavLink to="/training">view all</NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- col-12 --> */}
                </div>
              </section>
              {/* <!-- end main --> */}
            </div>
          
          </div>
        </div>
      </div>
    );
  }
}
