import React, { useState, useEffect } from "react";
import { Form, Table } from "react-bootstrap";

const TabularQuestionAddFormula = ({state, setState, dynamicErrors, handleKeyDownForFormula, handleOperatorChangeForFormula, handleSuggestionClickForFormula, selectedQuestion, operators }) => {
  const initializeArray = () => {
    const rows =
      selectedQuestion?.question_detail?.filter(
        (detail) => detail.option_type === "row"
      ).length || 0;
    const cols =
      selectedQuestion?.question_detail?.filter(
        (detail) => detail.option_type === "column"
      ).length || 0;

    return Array.from({ length: rows }, () => Array(cols).fill(""));
  };

  const [tableData, setTableData] = useState(initializeArray);

  useEffect(() => {
    // Reinitialize table data if the question changes
    setTableData(initializeArray);
  }, [selectedQuestion]);

//   const handleInputChange = (rowIndex, colIndex, value) => {
//     const newData = [...tableData];
//     newData[rowIndex][colIndex] = value;
//     setTableData(newData);
//   };

  const handleInputChange = (rowIndex, colIndex, value) => {
    setTableData(prevData => {
      const newData = [...prevData];
      newData[rowIndex] = [...newData[rowIndex]];
      newData[rowIndex][colIndex] = value;
      return newData;
    });
    setState((prevState) => ({
        ...prevState,
        input:tableData
    }))

}

//     setState((prevState) => ({
//         ...prevState,
//         input:tableData
    
//   }));
  return (
    selectedQuestion?.questionType === "tabular_question" && (
      <Form.Group className="mb-3" style={{ overflow: "auto" }} controlId={`question-${selectedQuestion.question_id}`}>
        <Form.Label>Add formula for {selectedQuestion.heading}</Form.Label>
        <div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>{selectedQuestion?.firstRowAndColumn || '#'}</th>
                {selectedQuestion?.question_detail
                  ?.filter(detail => detail.option_type === 'column')
                  .map((col, index) => (
                    <th key={index}>{col.option}</th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {selectedQuestion?.question_detail
                ?.filter(detail => detail.option_type === 'row')
                .map((row, rowIndex) => (
                    <tr key={rowIndex}>
                    <th>{row.option}</th>
                    {selectedQuestion?.question_detail
                      ?.filter(detail => detail.option_type === 'column')
                      .map((col, colIndex) => (
                        <td key={colIndex}>
                          <input
                            type="text"
                            className="input-for-formula"
                            name={`${selectedQuestion.question_id}R${rowIndex}C${colIndex}`}
                            value={tableData[rowIndex]?.[colIndex] || ''}
                            onChange={e => handleInputChange(rowIndex, colIndex, e.target.value)}
                            onKeyDown={e => handleKeyDownForFormula(e, `${selectedQuestion.question_id}R${rowIndex}C${colIndex}`)}
                          />
                          {dynamicErrors[`${selectedQuestion.question_id}R${rowIndex}C${colIndex}`]?.inValidQuestionError && (
                            <div className="error-message">
                              {dynamicErrors[`${selectedQuestion.question_id}R${rowIndex}C${colIndex}`].inValidQuestionError}
                            </div>
                          )}
                          {dynamicErrors[`${selectedQuestion.question_id}R${rowIndex}C${colIndex}`]?.inValidExpressionError && (
                            <div className="error-message">
                              {dynamicErrors[`${selectedQuestion.question_id}R${rowIndex}C${colIndex}`].inValidExpressionError}
                            </div>
                          )}
                          {dynamicErrors[`${selectedQuestion.question_id}R${rowIndex}C${colIndex}`]?.showSuggestions && (
                            <div
                              className={
                                dynamicErrors[`${selectedQuestion.question_id}R${rowIndex}C${colIndex}`].suggestions?.length > 0 &&
                                "formula__suggesstions"
                              }
                            >
                              {dynamicErrors[`${selectedQuestion.question_id}R${rowIndex}C${colIndex}`].suggestions.map((suggestion, index) => (
                                <div
                                  key={index}
                                  className={
                                    index === dynamicErrors[`${selectedQuestion.question_id}R${rowIndex}C${colIndex}`].selectedSuggestionIndex
                                      ? "selected"
                                      : ""
                                  }
                                  onClick={() =>
                                    handleSuggestionClickForFormula(
                                      `${selectedQuestion.question_id}R${rowIndex}C${colIndex}`,
                                      suggestion
                                    )
                                  }
                                >
                                  {suggestion}
                                </div>
                              ))}
                            </div>
                          )}
                          <Form.Label className="mt-3">Select Operator</Form.Label>
                          <Form.Control
                            as="select"
                            className="form-control"
                            onChange={event =>
                              handleOperatorChangeForFormula(
                                event,
                                `${selectedQuestion.question_id}R${rowIndex}C${colIndex}`
                              )
                            }
                          >
                            <option value="">Select Operator</option>
                            {operators.map((operator, index) => (
                              <option key={index} value={operator}>
                                {operator}
                              </option>
                            ))}
                          </Form.Control>
                        </td>
                      ))}
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </Form.Group>
    )
  );
};

export default TabularQuestionAddFormula;
