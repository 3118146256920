import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { Pagination, Icon } from "semantic-ui-react";
import Moment from "react-moment";
import "moment-timezone";
import "./companies.css";
import config from "../../../config/config.json";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import ConsultantTabbing from "./consultant_tabbing";
import { authenticationService } from "../../../_services/authentication";
const currentUser = authenticationService.currentUserValue;
export default class ConsultantbillingDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      loading: false,
      totalRows: 0,
      currentPage: 1,
      skip: 0,
      limit: 10,
      pageCount: 0,
      perPage: 10,
      totalData: 0,
      setStartDate: null,
      setEndDate: null,
      uuid: "",
    };
    // this.pageChange = this.pageChange.bind(this);
    this.serverRequest = this.serverRequest.bind(this);
    this.pageChange = this.pageChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
  }

  pageChange(e, data) {
    console.log("Handle Click", data);
    let page = data.activePage;
    let pageNo = page === 1 ? 0 : (page - 1) * this.state.limit;
    console.log("clicked", pageNo);
    this.setState({
      skip: pageNo,
    });
    this.serverRequest();
    // console.log("order", orders);
  }

  onDateChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
    let setStartDate = null;
    let setEndDate = null;
    if (this.state.setStartDate === null) {
      if (name === "setStartDate") {
        setStartDate = value;
      }
    } else {
      if (value !== this.state.setStartDate && name === "setStartDate") {
        setStartDate = value;
      } else {
        setStartDate = this.state.setStartDate;
      }
    }
    if (this.state.setEndDate === null) {
      if (name === "setEndDate") {
        setEndDate = value;
      }
    } else {
      if (value !== this.state.setEndDate && name === "setEndDate") {
        setEndDate = value;
      } else {
        setEndDate = this.state.setEndDate;
      }
    }
    if (setStartDate !== null && setEndDate !== null) {
      setTimeout(() => {
        this.serverRequest();
      }, 200);
    }
  }

  serverRequest() {
    const { skip, limit, setStartDate, setEndDate } = this.state;
    let urlArr = window.location.pathname.split("/");
    let uuid = urlArr[urlArr.length - 2];
    this.setState({
      uuid: uuid,
    });
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };
    const getLocalData = JSON.parse(localStorage.getItem("currentUser"));
    const getRole = getLocalData !== null ? getLocalData.data.role : "";
    let parms = `company/${uuid}/modules/billingDetails/getAllUserSubscriptions?skip=${skip}&limit=${limit}&startDate=${setStartDate}&endDate=${setEndDate}&current_role=${getRole}`;
   
    fetch(config.ADMIN_API_URL + parms, requestOptions)
      .then((res) => res.json())
      .then(
        (data) => {
          // console.log("data is here", data);
          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            totalData: data.data.totalCount,
            items: data.data.items,
          });
        },
        (error) => {}
      );
  }
  componentDidMount() {
    this.serverRequest();
  }
  render() {
    const { items } = this.state;
    console.log(items);
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <ConsultantTabbing />
                    <div className="col-sm-12">
                      {/* <div className="strp_progess">
                        <div className="hol_rell">
                          <div className="steps-form">
                            <div className="steps-row setup-panel"></div>
                          </div>
                        </div>
                      </div> */}
                      <div className="SVG Stepper">
                        <div className="stepperr_design">
                          <div className="color_div_step">
                            <div className="include">
                              <section className="d_text">
                                <div className="row">
                                  <div className="col-sm-12">
                                    <div className="business_detail">
                                      <div className="saved_cards">
                                        <div className="business_detail">
                                          <div className="heading">
                                            <div className="heading_wth_text"></div>
                                            <form>
                                              <div className="row">
                                                <div className="col-md-6 col-xs-12 mb-3">
                                                  <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">
                                                      Date From
                                                    </label>
                                                    <input
                                                      type="date"
                                                      onChange={
                                                        (this.handleChange,
                                                        this.onDateChange)
                                                      }
                                                      className="form-control date-picker"
                                                      name="setStartDate"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-md-6 col-xs-12 mb-3">
                                                  <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">
                                                      Date To
                                                    </label>
                                                    <input
                                                      type="date"
                                                      onChange={
                                                        (this.handleChange,
                                                        this.onDateChange)
                                                      }
                                                      className="form-control date-picker"
                                                      name="setEndDate"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </form>
                                          </div>
                                        </div>

                                        <div className="table_f">
                                          <Table
                                            striped
                                            bordered
                                            hover
                                            size="sm"
                                          >
                                            <thead>
                                              <tr className="heading_color">
                                                <th>INVOICE ID</th>
                                                <th>SUBSCRIPTION PLAN</th>
                                                <th>MONTH</th>
                                                <th>AMOUNT PAID</th>
                                                <th>INVOICE</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {items.map((item, key) => (
                                                <tr key={key}>
                                                  <td>{item.id}</td>
                                                  <td>{item.title}</td>
                                                  <td>
                                                    <Moment
                                                      format="MMM YYYY"
                                                      withTitle
                                                    >
                                                      {item.created_at}
                                                    </Moment>
                                                  </td>
                                                  <td>${item.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                  <td>
                                                    <NavLink
                                                      to={`/companies/${this.state.uuid}/invoice`}
                                                      className="table-tag"
                                                    >
                                                      <span>
                                                        <i className="fa fa-eye"></i>
                                                      </span>
                                                      View
                                                    </NavLink>
                                                  </td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </Table>
                                          <Pagination
                                            className="mx-auto pagination"
                                            defaultActivePage={1}
                                            onPageChange={this.pageChange}
                                            ellipsisItem={{
                                              content: (
                                                <Icon name="ellipsis horizontal" />
                                              ),
                                              icon: true,
                                            }}
                                            firstItem={{
                                              content: (
                                                <Icon name="angle double left" />
                                              ),
                                              icon: true,
                                            }}
                                            lastItem={{
                                              content: (
                                                <Icon name="angle double right" />
                                              ),
                                              icon: true,
                                            }}
                                            prevItem={{
                                              content: (
                                                <Icon name="angle left" />
                                              ),
                                              icon: true,
                                            }}
                                            nextItem={{
                                              content: (
                                                <Icon name="angle right" />
                                              ),
                                              icon: true,
                                            }}
                                            totalPages={Math.ceil(
                                              this.state.totalData /
                                                this.state.limit
                                            )}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
