import React, { Component } from "react";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import "../../Company Sub Admin/Pages/esg_reporting/esg_reporting.css";
import Multiselect from "multiselect-react-dropdown";

export default class home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  render() {
    return (
      <>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="color_div_on framwork_2">
                        <h5 className="frame">
                          Please select which ESG reporting Framework you want to use:
                        </h5>
                        <div className="Global">
                          <div className="row">
                            <div className="col-sm-12 col-xs-12">
                              <div className="border_box p-3">
                                <div className="wel_fel">
                                  <div className="row">
                                    <Multiselect
                                      isObject={false}
                                      onKeyPressFn={function noRefCheck() {}}
                                      onRemove={function noRefCheck() {}}
                                      onSearch={function noRefCheck() {}}
                                      onSelect={function noRefCheck() {}}
                                      placeholder={"Select Framework"}
                                      options={[
                                        "Framework 1",
                                        "Framework 2",
                                        "Framework 3",
                                        "Framework 4",
                                        "Framework 5",
                                      ]}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div><br></br>
                      <div className="color_div_on framwork_2">
                        <h5 className="frame">
                          Please select Framework's Topic you want to use:
                        </h5>
                        <div className="Global">
                          <div className="row">
                            <div className="col-sm-12 col-xs-12">
                              <div className="border_box p-3">
                                <div className="wel_fel">
                                  <div className="row">
                                    <div className="col-xxl-6 col-lg-6 col-md-12 col-12" >
                                      <div className="Global_text"  >
                                        <div className="form-check form-check-inline clobal_check input-padding">
                                          <input className="form-check-input input_one " name="frameworksUsed[]" type="checkbox" />
                                          <label className="form-check-label label_one" htmlFor={"xcfgvldskfjgosdfg"}> First Topic </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-xxl-6 col-lg-6 col-md-12 col-12" >
                                      <div className="Global_text"  >
                                        <div className="form-check form-check-inline clobal_check input-padding">
                                          <input className="form-check-input input_one " name="frameworksUsed[]" type="checkbox" />
                                          <label className="form-check-label label_one" htmlFor={"xcfgvldskfjgosdfg"}> Second Topic </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-xxl-6 col-lg-6 col-md-12 col-12" >
                                      <div className="Global_text"  >
                                        <div className="form-check form-check-inline clobal_check input-padding">
                                          <input className="form-check-input input_one " name="frameworksUsed[]" type="checkbox" />
                                          <label className="form-check-label label_one" htmlFor={"xcfgvldskfjgosdfg"}> Third Topic </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-xxl-6 col-lg-6 col-md-12 col-12" >
                                      <div className="Global_text"  >
                                        <div className="form-check form-check-inline clobal_check input-padding">
                                          <input className="form-check-input input_one " name="frameworksUsed[]" type="checkbox" />
                                          <label className="form-check-label label_one" htmlFor={"xcfgvldskfjgosdfg"}> Fourth Topic </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div><br></br>
                      <div className="color_div_on framwork_2">
                        <h5 className="frame">
                          Please select KPIs you want to use:
                        </h5>
                        <div className="Global">
                          <div className="row">
                            <div className="col-sm-12 col-xs-12">
                              <div className="border_box p-3">
                                <div className="wel_fel">
                                  <div className="row">
                                    <div className="col-xxl-6 col-lg-6 col-md-12 col-12" >
                                      <div className="Global_text"  >
                                        <div className="form-check form-check-inline clobal_check input-padding">
                                          <input className="form-check-input input_one " name="frameworksUsed[]" type="checkbox" />
                                          <label className="form-check-label label_one" htmlFor={"xcfgvldskfjgosdfg"}> First KPI </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-xxl-6 col-lg-6 col-md-12 col-12" >
                                      <div className="Global_text"  >
                                        <div className="form-check form-check-inline clobal_check input-padding">
                                          <input className="form-check-input input_one " name="frameworksUsed[]" type="checkbox" />
                                          <label className="form-check-label label_one" htmlFor={"xcfgvldskfjgosdfg"}> Second KPI </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-xxl-6 col-lg-6 col-md-12 col-12" >
                                      <div className="Global_text"  >
                                        <div className="form-check form-check-inline clobal_check input-padding">
                                          <input className="form-check-input input_one " name="frameworksUsed[]" type="checkbox" />
                                          <label className="form-check-label label_one" htmlFor={"xcfgvldskfjgosdfg"}> Third KPI </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-xxl-6 col-lg-6 col-md-12 col-12" >
                                      <div className="Global_text"  >
                                        <div className="form-check form-check-inline clobal_check input-padding">
                                          <input className="form-check-input input_one " name="frameworksUsed[]" type="checkbox" />
                                          <label className="form-check-label label_one" htmlFor={"xcfgvldskfjgosdfg"}> Fourth KPI </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </>
    );
  }
}
