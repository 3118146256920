/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import {Button, Form, Modal } from "react-bootstrap";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import { NavLink } from "react-router-dom";

export default class sectorQuestionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showModal: false,
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }
  handleCloseModal() {
    this.setState({ showModal: false });
  }
  handleShow() {
    this.setState({
      show: true,
    });
  }
  handleClose() {
    this.setState({
      show: false,
    });
  }

  render() {
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="Introduction framwork_2">
                        <div className="text_Parts">
                          <div className="text_ntroion">
                            <div className="variants">
                              <div className="file file--upload">
                                <label htmlFor="input-file">
                                  <i className="fa fa-upload" aria-hidden="true" ></i>
                                  Upload Report
                                </label>
                                <input type="file" accept=".pdf, .doc, .docx, .xlsx, .pptx, .ppt, .ott, .odp" id="input-file" name="governancePolicy" onChange={this.onFileChange} />
                              </div>
                              <NavLink className="outputFile" to={ "#" }  >
                                Preview
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frameworks framwork_2">
                        <div className="text_Parts">
                          <div className="col-md-12">
                            <h4 className="E_capital">Sector Question</h4>
                            <>
                              <div className="manag">
                                <div className="heading_h3 mb-3 mt-3">
                                    <span className="gement mt-4">
                                      Framework 1
                                    </span>
                                </div>
                                <h3 className="energy">1. What is Lorem Ipsum?</h3>
                                <p className="sumption">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                <hr className="related" />
                                
                                {/* <DownloadLink label="Download File" filename="One.csv" exportFile={() => One} /> */}
                                {/* <div className="text-end">
                                  <Button variant="outline-info mt-2" onClick={this.handleShow} >
                                    Request For Attatchment
                                  </Button>
                                </div> */}
                              </div>
                              <div className="manag">
                                <div className="heading_h3 mb-3 mt-3">
                                    <span className="gement mt-4">
                                      Framework 2
                                    </span>
                                </div>
                                <h3 className="energy">1. What is Lorem Ipsum?</h3>
                                <p className="sumption">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                <hr className="related" />
                                
                                {/* <DownloadLink label="Download File" filename="One.csv" exportFile={() => One} /> */}
                                <div className="text-end">
                                  <Button variant="outline-info mt-2" onClick={this.handleShow} >
                                    Request For Attatchment
                                  </Button>
                                </div>
                              </div>
                            </>
                          </div>
                        </div>
                      </div>
                      <Modal show={this.state.show} onHide={this.handleClose}>
                        <Modal.Header closeButton>
                          <Modal.Title>Request For Evidence</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1" >
                              <Form.Label>Add Note</Form.Label>
                              <Form.Control as="textarea" rows={3} />
                            </Form.Group>
                          </Form>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="outline-info" onClick={this.handleClose}>
                            Send
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
