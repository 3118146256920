import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import AdminHeader from "../../header/admin_header";
import AdminSidebar from "../../sidebar/admin_sidebar";
import { Question } from "./Question";
import { Questions } from "./Questions";
import TriggerQuestion from "./TriggerQuestion";
const QuestionsModule = (props) => {
  const [data, setData] = useState(props.data);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("framework");

  const [activeFramework, setActiveFramework] = useState(false);
  const [currentTopicData, setCurrentTopicData] = useState({});


  const handleTopicChangeHandler = (data, tabId) => {
    setActiveTab(tabId);
    setCurrentTopicData(data);
  };

  const handleAddFrameworkQuestionChangeHandler = (data) => {
  };



  const handleAddTopicQuestionChangeHandler = (data, framworkData) => {
    framworkData["topic_id"] = data.id;
  };


  const handleKPIChangeHandler = (
    data,
    finalYearData,
    frameworkData,
    tabId
  ) => {
    frameworkData["topic_id"] = data.id;
    setActiveTab(tabId);
    setData(finalYearData);
  };

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const handleFrameworkTabClose = (event, tabId) => {
    event.stopPropagation();
    if (activeTab === tabId) {
      setActiveTab("financial");
      setActiveFramework(false);

    }
  };



  return (
    <div>
      <AdminSidebar dataFromParent={location.pathname} />
      <AdminHeader />
      <div className="main_wrapper">
        <div className="inner_wraapper">
          <div className="color_div_on framwork_2">
            <div className="tab-container">
              <div className="tab-menu border-bottom mb-2 d-flex align-items-center justify-content-between">
                <ul>
                  {/* --------------- Financial Year Tab ----------------------- */}

                  <li>
                    <div
                      className={`tab-a ${
                        activeTab === "framework" ? "active-a" : ""
                      }`}
                      onClick={() => handleTabClick("framework")}
                      data-id="framework"
                    >
                        Reporting Modules
                    </div>
                  </li>

                  {/* --------------- Framework Tab ----------------------- */}

                  <li>
                    <div
                      className={`tab-a ${
                        activeTab === "reportingQuestion"
                          ? "active-a"
                          : "" ||
                            (activeFramework === true ? "d-block" : "d-none")
                      }`}
                      onClick={() => handleTabClick("reportingQuestion")}
                      data-id="reportingQuestion"
                    >
                      Reporting Question
                      <span
                        className="close_tab_cell"
                        title="Close Framework"
                        onClick={(e) => handleFrameworkTabClose(e, "reportingQuestion")}
                      >
                        <i className="fas fa-times-circle"></i>
                      </span>
                    </div>
                  </li>

                  <li>
                    <div
                      className={`tab-a ${
                        activeTab === "triggerQuestion"
                          ? "active-a"
                          : "" ||
                            (activeFramework === true ? "d-block" : "d-none")
                      }`}
                      onClick={() => handleTabClick("triggerQuestion")}
                      data-id="trigeerQuestion"
                    >
                      Trigger Questions
                      <span
                        className="close_tab_cell"
                        title="Close Framework"
                        onClick={(e) => handleFrameworkTabClose(e, "triggerQuestion")}
                      >
                        <i className="fas fa-times-circle"></i>
                      </span>
                    </div>
                  </li>

                </ul>
                <div>
                  <p>
                    <strong>
                      {data && data?.country_name} &nbsp;&nbsp;
                      {data && data?.financial_year_value}
                    </strong>
                  </p>
                </div>
              </div>
              {/* --------------- Financial Year Tab ----------------------- */}
              <div
                className="tab"
                data-id="framework"
                style={{
                  display: activeTab === "framework" ? "block" : "none",
                }}
              >
                <Questions
                  handleTopicChangeHandler={handleTopicChangeHandler}
                  handleAddFrameworkQuestionChangeHandler={
                    handleAddFrameworkQuestionChangeHandler
                  }
                />
              </div>
              {/* --------------- Framework Tab ----------------------- */}
              <div
                className="tab"
                data-id="topic"
                style={{
                  display: activeTab === "reportingQuestion" ? "block" : "none",
                }}
              >
                <Question
                frameworkData={currentTopicData}  
                handleKPIChangeHandler={handleKPIChangeHandler}
                handleAddTopicQuestionChangeHandler={
                  handleAddTopicQuestionChangeHandler
                  }
                />
              </div>

              <div
                className="tab"
                data-id="topic"
                style={{
                  display: activeTab === "triggerQuestion" ? "block" : "none",
                }}
              >
               
                <TriggerQuestion
                frameworkData={currentTopicData}  
                handleKPIChangeHandler={handleKPIChangeHandler}
                handleAddTopicQuestionChangeHandler={
                  handleAddTopicQuestionChangeHandler
                  }
                />
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionsModule;