/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { Component } from "react";
import { sweetAlert } from "../../../utils/UniversalFunction";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import "./companies.css";
import { NavLink } from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import "../../signup/signup.css";
import config from "../../../config/config.json";
import ReactTooltip from "react-tooltip";
import { setStore } from "../../../utils/UniversalFunction";
import {
  checkPasswordValidation,
  isValidEmail,
} from "../../../utils/UniversalFunction";
import { authenticationService } from "../../../_services/authentication";
import countriesData from "../Setting/FinactialYearTab/countries.json";
import { Col, Row } from "react-bootstrap";
const currentUser = authenticationService.currentUserValue;
const baseURL = config.baseURL;
export default class AddNewConsultant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      error2: null,
      isLoaded2: false,
      error3: null,
      isLoaded3: false,
      companyIndustoryItems: [],
      titleOrPositionsItems: [],
      industry_Category: [],
      company_industry: "",
      companyIndustry: [],
      items: [],
      user: {
        firstName: "",
        lastName: "",
        email: "",
        country: "",
        businessNumber: "",
        position: "",
        password: "",
        privacy: "1",
        chargeType: "",
        chargeValue: "",
        register_company_name: "",
        companyIndustry: "",
        privacyPolicy: "1",
        mobile: "",
      },
      type: "password",
      passwordValidation: false,
      passwordValidationMessage: "",
      emailvalidation: false,
      emailvalidationMessage: "",
      submitted: false,
      industryId: "",
      no_of_users: "",
      reference: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showHide = this.showHide.bind(this);
    this.getSubIndustry = this.getSubIndustry.bind(this);
    this.handleChangeForCompanyIndustry =
      this.handleChangeForCompanyIndustry.bind(this);
    this.handleChangeForUser = this.handleChangeForUser.bind(this);
  }
  getSubIndustry(id) {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };
    fetch(
      config.API_URL + `api/v1/getIndustriesOfCategoryId?id=${id}`,
      requestOptions
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded2: true,
            companyIndustry: result.companyIndustry,
          });
        },

        (error2) => {
          this.setState({
            isLoaded2: true,
            error2,
          });
        }
      );
  }

  showHide(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === "password" ? "input" : "password",
    });
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;

    this.setState({
      user: {
        ...user,
        [name]: value,
      },
    });
    if (name === "industrytype") {
      this.getSubIndustry(value);
    }
    if (name === "password") {
      let condition = checkPasswordValidation(value);
      if (condition === true) {
        this.setState({
          passwordValidation: true,
          passwordValidationMessage: "",
        });
      } else {
        this.setState({
          passwordValidation: false,
          passwordValidationMessage: condition,
        });
      }
    }
    if (name === "email") {
      let condition = isValidEmail(value);
      if (condition === true) {
        this.setState({
          emailValidation: true,
          emailValidationMessage: "",
        });
      } else {
        this.setState({
          emailValidation: false,
          emailValidationMessage: "Please check email format",
        });
      }
    }
  }

  handleChangeForCompanyIndustry(event) {
    let industryId = event.target.value;
    let industryTitle = event.target[event.target.selectedIndex].title;
    this.setState({
      industryId: industryId,
      company_industry: industryTitle,
    });
  }

  handleChangeForUser(event) {
    let no_of_users = event.target.value;
    this.setState({
      no_of_users: no_of_users,
    });
  }

  handleSubmit(event) {
    console.log("this.state", this.state);
    event.preventDefault();
    this.setState({ submitted: true });
    const { user, passwordValidation, emailValidation } = this.state;
    if (
      user.firstName &&
      user.lastName &&
      user.email &&
      user.country &&
      user.businessNumber &&
      user.password &&
      passwordValidation &&
      emailValidation &&
      user.register_company_name
    ) {

      axios
        .post(
          config.ADMIN_API_URL + "addCompany",
          {
            first_name: user.firstName,
            last_name: user.lastName,
            email: user.email,
            country_code: user.country,
            business_number: user.businessNumber,
            password: user.password,
            charge_type: user.chargeType,
            charge_value: user.chargeValue,
            register_company_name: user.register_company_name,
            mobile_number: user.mobile,
            user_type_code: "group_admin",
          },
          {
            headers: {
              Authorization: `Bearer ${currentUser.data.token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          sweetAlert("success", response.data.message);
          const pushToRoute = "/#/group_admin";

          const finalLink = baseURL + pushToRoute;

          setTimeout(() => {
            window.location.href = finalLink;
          }, 1000);
        })
        .catch(function (error) {
          if (error.response) {
            sweetAlert("error", error.response.data.message);
          }
        });
    } else {
      sweetAlert("error", "Please fill all input");
    }
  }

  componentDidMount() {
    let string = window.location.href.split("/").pop();
    let finalReference = string === "addnewcompany" ? "" : string;
    setStore("reference", finalReference);
    this.setState({
      reference: finalReference,
    });
    $(document).ready(function () {
      const prevBtns = document.querySelectorAll(".btn-prev");
      const nextBtns = document.querySelectorAll(".btn-next");
      const formSteps = document.querySelectorAll(".step-forms");
      let formStepsNum = 0;

      nextBtns.forEach((btn) => {
        btn.addEventListener("click", () => {
          formStepsNum++;
          updateFormSteps();
        });
      });

      prevBtns.forEach((btn) => {
        btn.addEventListener("click", () => {
          formStepsNum--;
          updateFormSteps();
        });
      });

      function updateFormSteps() {
        formSteps.forEach((formStep) => {
          formStep.classList.contains("step-forms-active") &&
            formStep.classList.remove("step-forms-active");
        });

        formSteps[formStepsNum].classList.add("step-forms-active");
      }
    });

    fetch(config.API_URL + "getAllCountries")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result.countries,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );

    fetch(config.API_URL + "getTitleOrPositions")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded3: true,
            titleOrPositionsItems: result.titleOrPositions,
          });
        },
        (error3) => {
          this.setState({
            isLoaded3: true,
            error3,
          });
        }
      );

    fetch(config.API_URL + "getIndustryCategories")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded2: true,
            industry_Category: result.industry_Category,
          });
        },

        (error2) => {
          this.setState({
            isLoaded2: true,
            error2,
          });
        }
      );
  }

  render() {
    const { user, submitted, type } = this.state;
    const { items, companyIndustry, titleOrPositionsItems, industry_Category } =
      this.state;
    return (
      <div>
        <form name="form" onSubmit={this.handleSubmit} >
          <div className="step-forms step-forms-active">
            <div className="ster_form">
              <Row>
                <Col md={12} className="mb-3">
                  <div className="form_sign">
                    <div
                      className={
                        "form-group fg" +
                        (submitted &&
                          !user.register_company_name
                          ? " has-error"
                          : "")
                      }
                    >
                      <input
                        className="form-control name_nf select_map"
                        required
                        placeholder="Enter Company name"
                        id="register_company_name"
                        type="text"
                        name="register_company_name"
                        value={
                          user.register_company_name
                        }
                        onChange={
                          this.handleChange
                        }
                      />
                      {submitted &&
                        !user.register_company_name && (
                          <div className="help-block">
                            Group Admin Name is
                            required
                          </div>
                        )}
                    </div>
                  </div>
                </Col>
                <Col md={6} className="mb-3">
                  <div
                    className={
                      "form-group fg" +
                      (submitted &&
                        !user.firstName
                        ? " has-error"
                        : "")
                    }
                  >
                    <input
                      className="form-control name_nf"
                      id="firstName"
                      type="text"
                      name="firstName"
                      placeholder="Enter First Name"
                      value={user.firstName}
                      onChange={
                        this.handleChange
                      }
                    />
                    {submitted &&
                      !user.firstName && (
                        <div className="help-block">
                          First Name is required
                        </div>
                      )}
                  </div>
                </Col>
                <Col md={6} className="mb-3">
                  <div
                    className={
                      "form-group fg" +
                      (submitted &&
                        !user.lastName
                        ? " has-error"
                        : "")
                    }
                  >
                    <input
                      className="form-control name_nf"
                      id="lastName"
                      type="text"
                      placeholder="Enter Last Name"
                      name="lastName"
                      value={user.lastName}
                      onChange={
                        this.handleChange
                      }
                    />
                    {submitted &&
                      !user.lastName && (
                        <div className="help-block">
                          Last Name is required
                        </div>
                      )}
                  </div>
                </Col>
                <Col md={6} className="mb-3">
                  <div className= "form-group fg">
                    <input
                      className="form-control name_nf"
                      id="mobile"
                      type="mobile"
                      name="mobile"
                      value={user.mobile}
                      placeholder="Enter Mobile No."
                      onChange={this.handleChange}
                    />
                  </div>
                </Col>
                <Col md={6} className="mb-3">
                  <div
                    className={
                      "form-group fg" +
                      (submitted && !user.email
                        ? " has-error"
                        : "")
                    }
                  >
                    <input
                      className="form-control name_nf"
                      id="email"
                      type="email"
                      name="email"
                      value={user.email}
                      placeholder="Enter Email Address"
                      onChange={this.handleChange}
                    />
                    {this.state.emailvalidation ===
                      false && (
                        <div className="help-block">
                          {
                            this.state
                              .emailvalidationMessage
                          }
                        </div>
                      )}
                    {submitted && !user.email && (
                      <div className="help-block">
                        Email is required
                      </div>
                    )}
                  </div>
                </Col>
                {/* <Col md={6} className="mb-3">

                  <div
                    className={
                      "form-group fg eye-frame" +
                      (submitted && !user.password
                        ? " has-error"
                        : "")
                    }
                  >
                    <label
                      className="st_name"
                      htmlFor="name"
                    >
                      Password&nbsp;
                      <span
                        data-tip
                        data-for="registerTip"
                      >
                        <i
                          className="fa fa-question-circle"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </label>
                    <ReactTooltip
                      id="registerTip"
                      place="top"
                      effect="solid"
                    >
                      <h6>Password Must :</h6>
                      <ul>
                        <li>
                          Have at least one lower
                          case character
                        </li>
                        <li>
                          Have at least one Capital
                          letter
                        </li>
                        <li>
                          Have at least one number
                        </li>
                        <li>
                          Have at least one special
                          character
                        </li>
                        <li>
                          Be at least 8 characters
                        </li>
                        <li>
                          Not be a common password
                        </li>
                      </ul>
                      <h6>
                        For Eg : <b>Password@123</b>
                      </h6>
                    </ReactTooltip>
                    <div className="form_inputC">
                      <input
                        className="form-control name_nf"
                        id="password"
                        type={type}
                        name="password"
                        placeholder="Enter Strong Password"
                        value={user.password}
                        onChange={this.handleChange}
                      />
                      {this.state
                        .passwordValidation ===
                        false && (
                          <div className="help-block">
                            {
                              this.state
                                .passwordValidationMessage
                            }
                          </div>
                        )}
                      {submitted &&
                        !user.password && (
                          <div className="help-block">
                            Password is required
                          </div>
                        )}
                      <span
                        className="eye-under"
                        onClick={this.showHide}
                      >
                        {this.state.type ===
                          "input" ? (
                          <i className="fas fa-eye-slash"></i>
                        ) : (
                          <i className="fas fa-eye"></i>
                        )}
                      </span>
                    </div>
                  </div>
                </Col> */}
                {/* <Col md={6} className="mb-3">
                  <div className={
                    "form-group fg" +
                    (submitted && !user.country
                      ? " has-error"
                      : "")
                  }
                  >
                    <label className="st_name" htmlFor="name" >
                      Main Country Of Operations
                    </label>

                    <select
                      id="country"
                      name="country"
                      required
                      className="form-control select_map"
                      value={user.iso}
                      onChange={this.handleChange}
                    >
                      <option
                        className="bold"
                        value="1"
                      >
                        Select Country
                      </option>
                      {countriesData &&
                        countriesData.map((country, index) => (
                          <option key={index} value={country?.isoCode}>{country.name}</option>
                        ))}
                    </select>
                    {submitted && !user.country && (
                      <div className="help-block">
                        Country Name is required
                      </div>
                    )}
                  </div>
                </Col> */}
                {/* <Col md={6} className="mb-3">
                  <div
                    className={
                      "form-group fg" +
                      (submitted &&
                        !user.businessNumber
                        ? " has-error"
                        : "")
                    }
                  >
                    <label
                      className="st_name"
                      htmlFor="name"
                    >
                      Business Number
                    </label>
                    <input
                      type="tel"
                      required
                      placeholder="Enter business number"
                      className="form-control name_nf select_map"
                      name="businessNumber"
                      maxLength="11"
                      id="businessNumber"
                      value={
                        user.businessNumbindustryIder
                      }
                      onChange={this.handleChange}
                    />
                    {submitted &&
                      !user.businessNumber && (
                        <div className="help-block">
                          Business Number is
                          required
                        </div>
                      )}
                  </div>
                </Col> */}
                <Col md={12} className="mb-3">
                  <div className={
                    "form-group fg" +
                    (submitted
                      ? " has-error"
                      : "")
                  }
                  >
                    <select
                      id="chargeType"
                      required
                      name="chargeType"
                      className="form-control select_map name_nf"
                      value={user.chargeType}
                      onChange={this.handleChange}
                    >
                      <option className="bold">
                        Select Charge Type
                      </option>
                      <option value="FIXED">
                        fixed
                      </option>
                      <option value="PERCENT">
                        percentage
                      </option>
                    </select>

                    {user.chargeType === "FIXED" && (
                      <div className="mt-3">
                        <input
                          className="form-control select_map name_nf"
                          id="chargeValue"
                          type="number"
                          name="chargeValue"
                          placeholder="Enter Fixed Charge"
                          value={user.chargeValue}
                          onChange={
                            this.handleChange
                          }
                        />
                      </div>
                    )}

                    {user.chargeType ===
                      "PERCENT" && (
                        <div className="mt-3">
                          <input
                            className="form-control select_map name_nf"
                            id="chargeValue"
                            type="number"
                            name="chargeValue"
                            placeholder="Enter Percentage (%) Charge"
                            value={user.chargeValue}
                            onChange={(event) => {
                              let val = parseInt(
                                event.target.value
                              );
                              if (
                                val <= 100 ||
                                event.target.value ===
                                ""
                              ) {
                                this.handleChange(
                                  event
                                );
                              }
                            }}
                            max={100}
                            onBlur={(event) => {
                              if (
                                event.target.value ===
                                ""
                              ) {
                                this.setState({
                                  user: {
                                    ...user,
                                    chargeValue: 0,
                                  },
                                });
                              }
                            }}
                            required
                            pattern="\d+"
                          />
                        </div>
                      )}
                    {submitted &&
                      !user.chargeType && (
                        <div className="help-block">
                          Charge Type is required
                        </div>
                      )}
                  </div>
                </Col>
              </Row>
              <hr className="h-auto"/>
              <div className="view_bottoma justify-content-end">
                <span
                  type="submit"
                  value="Submit"
                  className="page_width page_save d-block"
                  onClick={(e) =>
                    this.handleSubmit(e)
                  }
                >
                  Register
                </span>
                {/* {  console.log(user, "useruseruseruseruser") }
                {user.chargeType &&
                user.register_company_name &&
                user.businessNumber &&
                user.country &&
                user.firstName &&
                    user.lastName &&
                    user.email &&
                    user.password ? (
                    <span
                      type="submit"
                      value="Submit"
                      className="page_width page_save d-block"
                      onClick={(e) =>
                        this.handleSubmit(e)
                      }
                    >
                      Register
                    </span>
                  ) : (
                    <button
                      type="submit"
                      disabled
                      value="Submit"
                      id="submit-form"
                      className="btn btn-prev"
                    >
                      Register
                    </button>
                  )} */}
              </div>
            </div>
          </div>
          <div className="step-forms">
            <div className="ster_form">

              <div className="mb-2">
                <div
                // className={
                //   "form-group fg" +
                //   (submitted && !user.position
                //     ? " has-error"
                //     : "")
                // }
                >
                  <label
                    className="st_name"
                    htmlFor="name"
                  >
                    Title or Position
                  </label>
                  <select
                    id="position"
                    name="position"
                    className="form-control select_map"
                    value={user.position}
                    onChange={this.handleChange}
                  >
                    <option className="bold">
                      Select Title Position
                    </option>
                    {titleOrPositionsItems.map(
                      (titleOrPositionsItem) => (
                        <option
                          key={
                            titleOrPositionsItem.id
                          }
                        >
                          {
                            titleOrPositionsItem.title
                          }
                        </option>
                      )
                    )}
                  </select>
                  {/* {submitted &&
                    !user.position && (
                      <div className="help-block">
                        Title or Position is
                        required
                      </div>
                    )} */}
                </div>
              </div>


              <div className="mb-2">
                <div className="row">
                  <div className="btns-group view_bottoma mt-5">
                    <NavLink
                      to="#"
                      className="btn btn-prev"
                    >
                      Previous
                    </NavLink>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {user.firstName &&
                      user.lastName &&
                      user.position &&
                      user.email &&
                      user.password ? (
                      <span
                        type="submit"
                        value="Submit"
                        className="page_width page_save d-block"
                        onClick={(e) =>
                          this.handleSubmit(e)
                        }
                      >
                        Register
                      </span>
                    ) : (
                      <button
                        type="submit"
                        disabled
                        value="Submit"
                        id="submit-form"
                        className="btn btn-prev"
                      >
                        Register
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
