import React, { useEffect, useState } from "react";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import { useLocation } from "react-router-dom";
import { Form, Modal, Table } from "react-bootstrap";
import config from "../../../config/config.json";
import { apiCall } from "../../../_services/apiCall";
import Loader from "../../loader/Loader";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

const Notification = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [currentPlanData, setCurrentPlanData] = useState(null);
  const [currentEmail, setCurrentEmail] = useState("");
  const [remark, setRemark] = useState("");
  const [brokerList, setBrokerList] = useState([]);

  const [showReamrk, setShowRemark] = useState(false);
  const handleCloseReamrk = () => setShowRemark(false);

  const handleShowRemark = (email) => {
    setCurrentEmail(email)
    setShowRemark(true);
  }

  const handleShow = (plan_data) => {
    setCurrentPlanData(plan_data);
    setShow(true);
  };

  const getInvitedCompany = async () => {
    const { isSuccess, data } = await apiCall(
      config.ADMIN_API_URL + "getInvitationDetails",
      {},
      { user_type_code: "broker" }
    );

    if (isSuccess) {
      setBrokerList(data?.data);
    }
  };

  const updateInvitationStatus = async (status, emailId) => {
    console.log("status, emailId>>", status, emailId);
    let body = {
      email: emailId === undefined ? currentEmail : emailId,
      status: status,
      user_type_code: 'broker',
      remark: remark
    };
    const { isSuccess, data } = await apiCall(
      config.ADMIN_API_URL + "updateInvitationStatus",
      {},
      body,
      "POST"
    );
    if (isSuccess) {
      getInvitedCompany();
      handleCloseReamrk();
    }
  }

  useEffect(() => {
    getInvitedCompany();
  }, []);

  return (
    <div>
      <AdminSidebar dataFromParent={useLocation.pathname} />
      <AdminHeader />
      <div className="main_wrapper">
        <div className="inner_wraapper">
          <div className="hol_rell">
            <div className="steps-form">
              <div className="steps-row setup-panel">
                <div className="tabs-top setting_admin my-0">
                  <ul>
                    <li>
                      <NavLink to={"#"} className="activee" title="Click To View" >Notification </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="color_div_on framwork_2 pt-0">
              <div className="business_detail">
                <div className="table-responsive">
                  <div className="heading d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex align-items-center gap-2">
                      <h4>Notification List</h4>
                    </div>
                    <input
                      type="search"
                      className="form-control w-25"
                      placeholder="Search Company, Category, Industry &amp; Business Number"
                      name="search"
                      onChange={(e) => this.applyGlobalSearch(e)}
                    />
                  </div>
                  <div className="table___height">
                    {brokerList && brokerList?.length > 0 ?
                      <Table bordered striped style={{ whiteSpace: "nowrap" }} className="m-0">
                        <thead>
                          <tr className="heading_color sticky_header">
                            <th>Sr.</th>
                            <th>Name | (Email)</th>
                            <th>Company Name | (Email)</th>
                            <th>Price</th>
                            <th>Commission</th>
                            <th>Status</th>
                            <th className="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {brokerList &&
                            brokerList?.map((item, index) => (
                              <tr key={index}>
                                {console.log("item", item)}
                                <td>{index + 1}</td>
                                <td>
                                  {item?.broker_data?.first_name}{" "}
                                  {item?.broker_data?.last_name} | (
                                  {item?.broker_data?.email})
                                </td>
                                <td>
                                  {item?.company_name} | ({item?.email})
                                </td>
                                <td>
                                  {item?.plan_data?.currency}
                                  {item?.plan_data?.price}
                                </td>
                                <td>{item?.broker_data?.broker_commision}%</td>
                                <td>{item?.status}</td>
                                <td className="hstack gap-3 justify-content-center">
                                  <div style={{color: "blue", cursor: "pointer"}} onClick={(e) => handleShow(item?.plan_data)} title="View Plan"><i className="fas fa-eye"></i></div>
                                  <div style={{color: "green", cursor: "pointer"}} onClick={(e) => updateInvitationStatus("ACCEPTED", item?.email)} title="Accept"><i class="fas fa-check-circle"></i></div>
                                  <div style={{color: "red", cursor: "pointer"}} onClick={(e) => handleShowRemark(item?.email)} title="Reject"><i class="fas fa-times-circle"></i></div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                      :
                      <Loader />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ----------------- View Plan Reamrk ----------------------- */}
      {currentPlanData && (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Plan Detail</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="pricing-table turquoise h-100">
              <h2>{currentPlanData?.title}</h2>
              <div className="price-tag">
                <span className="symbol">{currentPlanData?.currency}</span>
                <span className="amount">{currentPlanData?.price}</span>
                <span className="after">/{currentPlanData?.renewal_type}</span>
              </div>
              {console.log("currentPlanData>>", currentPlanData)}
              <div className="mt-0 mb-1 border-bottom"></div>
              <div className="d-flex align-items-center justify-content-between">
                <span>Financial Year</span>
                <span>{currentPlanData?.year}</span>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <span>Global Framework for Supplier</span>
                <span>
                  {JSON.parse(currentPlanData?.plan_data).ALLOW_GLOBAL_FRAMEWORK}
                </span>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <span>No. of Users</span>
                <span>{JSON.parse(currentPlanData?.plan_data).NO_USERS}</span>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <span>No. of Framework (Can be create)</span>
                <span>{JSON.parse(currentPlanData?.plan_data).NO_FRAMEWORK}</span>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <span>No. of Suppliers (Can be create)</span>
                <span>{JSON.parse(currentPlanData?.plan_data).NO_SUPPLIER}</span>
              </div>
              <div className="pricing-features h-100">
                <div className="mt-2 mb-1 border-bottom"></div>
                <div className="feature">Framework Features :</div>
                <div className="framework_feature">
                  <ul>
                    {console.log("adfsdgdrhjkhfhgdsartfy", JSON.parse(currentPlanData.plan_data))}
                    {JSON.parse(currentPlanData.plan_data).MAPPED_FRAMEWORK &&
                      JSON.parse(currentPlanData.plan_data).MAPPED_FRAMEWORK?.map(
                        (item) => (
                          <>
                            <li key={item.id}>{item.id}</li>
                          </>
                        )
                      )}
                  </ul>
                </div>
                <div className="feature">Menu Features :</div>
                <div className="framework_feature">
                  <ul>
                    {JSON.parse(currentPlanData.plan_data).MAPPED_MENUS &&
                      JSON.parse(currentPlanData.plan_data).MAPPED_MENUS?.map((item) => (
                        <>
                          <li key={item.id}>{item.id}</li>
                        </>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {/* ---------------------- Open Reject Reamrk Popup ------------------*/}
      <Modal show={showReamrk} onHide={handleCloseReamrk}>
        <Modal.Header closeButton>
          <Modal.Title>Remark</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            as="textarea"
            onChange={(e) => setRemark(e.target.value)}
            placeholder="Leave a comment here"
            style={{ height: '100px' }}
          />
        </Modal.Body>
        <Modal.Footer>
          <button className="link_bal_next_cancel" onClick={handleCloseReamrk}>
            Cancel
          </button>
          <button className="link_bal_next" onClick={(e) => updateInvitationStatus("REJECTED")} >
            Send
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Notification;
