import React, { Component } from 'react';
import EnviornmentTabbing from '../Dashboard/enviornment_tabbing';
import SocialConsideration from '../Dashboard/social_consideration';
import {Tabs, Tab} from "react-bootstrap";
import './enviornment_consideration.css';

export default class enviornment_consideration extends Component {
   
    render() {
         return (
                <div>
                    <Tabs defaultActiveKey="first" id="step-9" className="mb-3 enviornment_consideration">
                        <Tab eventKey="first" title="Environmental Considerations"  className="enviornment_tab">
                            <EnviornmentTabbing/>
                        </Tab>
                        <Tab eventKey="second" title="Social Considerations" className="enviornment_tab">
                            <SocialConsideration/>
                        </Tab>
                    </Tabs>
                </div> 
            )
        }
    }               