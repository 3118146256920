import React, { Component } from "react";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import { NavLink } from "react-router-dom";
import "../companies/companies.css";

export default class supplierManagementField extends Component {
  render() {
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="color_div_on framwork_2 mt-3">
                      <div className="pb4 supp4">
                        <label htmlFor="exampleInputEmail1" className="my-3">
                          Please select criteria to assess -
                        </label>
                        <div className="input-group mb-3">
                          <span className="caret-down">
                            <i className="fa fa-caret-down"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control dropdown-toggle px-3"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            placeholder="Select Criteria"
                          />
                          <div className="dropdown-menu">
                            <div className="dropdown-item form d-flex justify-content-between">
                              <span>Supplier Code of Conduct</span>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="radioNoLabel"
                                id="radioNoLabel1"
                                value=""
                                aria-label="..."
                              />
                            </div>

                            <div className="dropdown-item form d-flex justify-content-between">
                              <span>ESG Policy</span>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="radioNoLabel"
                                id="radioNoLabel1"
                                value=""
                                aria-label="..."
                              />
                            </div>

                            <div className="dropdown-item form d-flex justify-content-between">
                              <span>Anti-bribery & Corruption Policy</span>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="radioNoLabel"
                                id="radioNoLabel1"
                                value=""
                                aria-label="..."
                              />
                            </div>

                            <div className="dropdown-item form d-flex justify-content-between">
                              <span>Conflict of Interest</span>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="radioNoLabel"
                                id="radioNoLabel1"
                                value=""
                                aria-label="..."
                              />
                            </div>

                            <div className="dropdown-item form d-flex justify-content-between">
                              <span>Work, Health and Safety Policy</span>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="radioNoLabel"
                                id="radioNoLabel1"
                                value=""
                                aria-label="..."
                              />
                            </div>

                            <div className="dropdown-item form d-flex justify-content-between">
                              <span>Human Rights and Labour Policy</span>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="radioNoLabel"
                                id="radioNoLabel1"
                                value=""
                                aria-label="..."
                              />
                            </div>
                          </div>
                        </div>
                        <div className="global_link m-0">
                          <NavLink
                            to="/admin/AddQuestion"
                            className="page_width page_save"
                          >
                            EDIT FORM
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tagl d-flex justify-content-center my-3">
                    <p> Oops! No Questions Added Yet.</p>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
