import React, { Component } from "react";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import { NavLink } from "react-router-dom";
import "../companies/companies.css";

export default class QuestionForm extends Component {
  render() {
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="color_div_on framwork_2">
                      <div className="heading">
                        <div className="question_form">
                          <div className="pb4 w-100">
                            <label htmlFor="exampleInputEmail1">
                              Please select criteria to assess -
                            </label>
                            <div className="input-group my-3">
                              <span className="caret-down">
                                <i className="fa fa-caret-down"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control dropdown-toggle m-0 p-2"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                placeholder="Text Area"
                              />
                              <div className="dropdown-menu">
                                <div className="dropdown-item form d-flex justify-content-between">
                                  <span>Text Answer</span>
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="radioNoLabel"
                                    id="radioNoLabel1"
                                    value=""
                                    aria-label="..."
                                  />
                                </div>

                                <div className="dropdown-item form d-flex justify-content-between">
                                  <span>Dropdown</span>
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="radioNoLabel"
                                    id="radioNoLabel1"
                                    value=""
                                    aria-label="..."
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="global_link mt-4">
                            <button className="link_bal_next page_width mx-3">
                              COPY URL
                            </button>
                            <NavLink
                              to="/admin/QuestionForm"
                              className="page_width page_save"
                            >
                              EDIT FORM
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="color_div_on framwork_2 mt-3">
                      <div className="heading">
                        <h4>Environmental Topics</h4>
                      </div>
                      <div className="make_forms my-4">
                        <div className="form-group">
                          <label htmlFor="exampleFormControlTextarea1">
                            Are your suppliers in an industry with high GHG
                            Emissions?
                          </label>
                          <textarea
                            className="form-control my-3"
                            id="exampleFormControlTextarea1"
                            rows="3"
                          ></textarea>
                        </div>
                      </div>
                      <div className="make_forms my-4">
                        <div className="pb4">
                          <label htmlFor="exampleFormControlTextarea1">
                            Are your suppliers involved in any other ecological
                            impact?
                          </label>
                          <div className="input-group m-0 my-3">
                            <span className="caret-down">
                              <i className="fa fa-caret-down"></i>
                            </span>
                            <input
                              type="text"
                              className="form-control dropdown-toggle m-0 p-2"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              placeholder=""
                            />
                            <div className="dropdown-menu">
                              <div className="dropdown-item form d-flex justify-content-between">
                                <span>Text Answer</span>
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="radioNoLabel"
                                  id="radioNoLabel1"
                                  value=""
                                  aria-label="..."
                                />
                              </div>

                              <div className="dropdown-item form d-flex justify-content-between">
                                <span>Dropdown</span>
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="radioNoLabel"
                                  id="radioNoLabel1"
                                  value=""
                                  aria-label="..."
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="heading mt-5">
                        <h4>Social Topics</h4>
                      </div>
                      <div className="make_forms my-4">
                        <div className="form-group">
                          <label htmlFor="exampleFormControlTextarea1">
                            Do your suppliers recognise and report Human Rights
                            Management?
                          </label>
                          <textarea
                            className="form-control my-3"
                            id="exampleFormControlTextarea1"
                            rows="3"
                          ></textarea>
                        </div>
                      </div>
                      <div className="make_forms my-4">
                        <div className="form-group">
                          <label htmlFor="exampleFormControlTextarea1">
                            Do your suppliers have strategies for Employee
                            Engagement?
                          </label>
                          <textarea
                            className="form-control my-3"
                            id="exampleFormControlTextarea1"
                            rows="3"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
