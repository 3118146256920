import React, { Component } from "react";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import { NavLink } from "react-router-dom";
import "../companies/companies.css";

export default class AddQuestion extends Component {
  render() {
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="color_div_on framwork_2">
                      <div className="heading">
                        <h4>ESG Policy</h4>
                        <div className="global_link">
                          <button className="link_bal_next page_width mx-3">
                            CANCEL
                          </button>
                          <NavLink
                            to="/admin/QuestionForm"
                            className="page_width page_save"
                          >
                            SAVE
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="color_div_on framwork_2 mt-3">
                      <div className="heading">
                        <div className="form-head">
                          <label htmlFor="exampleInputEmail1">
                            Section Title:
                          </label>
                          <div className="form-group">
                            <input
                              type="email"
                              className="form-control"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              placeholder="Enter email"
                            ></input>
                            <span>
                              <i className="fa fa-trash"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="form-head my-5">
                        <div className="form-group">
                          <textarea
                            className="form-control m-0"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Enter your question here ..."
                          />
                        </div>
                        <div className="pb4 mx-3">
                          <div className="input-group">
                            <span className="caret-down">
                              <i className="fa fa-caret-down"></i>
                            </span>
                            <input
                              type="text"
                              className="form-control dropdown-toggle m-0 p-2"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              placeholder="Text Area"
                            />
                            <div className="dropdown-menu">
                              <div className="dropdown-item form d-flex justify-content-between">
                                <span>Text Answer</span>
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="radioNoLabel"
                                  id="radioNoLabel1"
                                  value=""
                                  aria-label="..."
                                />
                              </div>

                              <div className="dropdown-item form d-flex justify-content-between">
                                <span>Dropdown</span>
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="radioNoLabel"
                                  id="radioNoLabel1"
                                  value=""
                                  aria-label="..."
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <span>
                          <i className="fa fa-trash"></i>
                        </span>
                      </div>
                      <div className="form-head">
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control mx-0"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Enter Option Here"
                          ></input>
                          <span>
                            <i className="fa fa-trash mx-3"></i>
                          </span>
                        </div>
                      </div>
                      <div className="add_text d-flex my-2">
                        <NavLink to="" className="supploi mx-3">
                          + Add Option
                        </NavLink>
                      </div>
                    </div>
                    <div className="add_text d-flex justify-content-center my-3">
                      <NavLink to="" className="supploi mx-3">
                        + Add Question
                      </NavLink>
                      <NavLink to="" className="supploi mx-3">
                        + Add Section
                      </NavLink>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="color_div_on framwork_2 mt-3">
                      <div className="heading">
                        <div className="form-head">
                          <label htmlhtmlhtmlhtmlhtmlhtmlFor="exampleInputEmail1">
                            Section Title:
                          </label>
                          <div className="form-group">
                            <input
                              type="email"
                              className="form-control"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              placeholder="Enter email"
                            ></input>
                            <span>
                              <i className="fa fa-trash"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="form-head my-5">
                        <div className="form-group">
                          <textarea
                            className="form-control m-0"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Enter your question here ..."
                          />
                        </div>
                        <div className="pb4 mx-3">
                          <div className="input-group">
                            <span className="caret-down">
                              <i className="fa fa-caret-down"></i>
                            </span>
                            <input
                              type="text"
                              className="form-control dropdown-toggle m-0 p-2"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              placeholder="Text Area"
                            />
                            <div className="dropdown-menu">
                              <div className="dropdown-item form d-flex justify-content-between">
                                <span>Text Answer</span>
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="radioNoLabel"
                                  id="radioNoLabel1"
                                  value=""
                                  aria-label="..."
                                />
                              </div>

                              <div className="dropdown-item form d-flex justify-content-between">
                                <span>Dropdown</span>
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="radioNoLabel"
                                  id="radioNoLabel1"
                                  value=""
                                  aria-label="..."
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <span>
                          <i className="fa fa-trash"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="add_text d-flex justify-content-center my-3">
                  <NavLink to="" className="supploi mx-3">
                    + Add Question
                  </NavLink>
                  <NavLink to="" className="supploi mx-3">
                    + Add Section
                  </NavLink>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
