/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { Component } from "react";
import axios from "axios";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import { NavLink } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { sweetAlert } from '../../../utils/UniversalFunction'
import { authenticationService } from "../../../_services/authentication";
import "./companies.css";
import { Pagination, Icon } from "semantic-ui-react";

import config from "../../../config/config.json";
import { apiCall } from "../../../_services/apiCall";
import Loader from "../../loader/Loader";
const currentUser = authenticationService.currentUserValue;
export default class RegisteredBrokers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: false,
      country: false,
      industry: false,
      category: false,
      activeModal: "",
      showModal: false,
      email: "",
      submitted: false,
      items: [],
      items1: [],
      loading: false,
      totalRows: 0,
      currentPage: 1,
      skip: 0,
      startDate: "",
      endDate: "",
      limit: 10,
      countryFilter: "",
      industryFilter: "",
      categoryFilter: "",
      orderByName: "id",
      orderByValue: "DESC",
      search: "",
      pageCount: 0,
      totalData: 0,
      countriesList: [],
      industryList: [],
      searchKey: "",
      plan_id: ""
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.pageChange = this.pageChange.bind(this);
    this.serverRequest = this.serverRequest.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.applySorting = this.applySorting.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
  }

  handleOpenModal(val) {
    this.setState({ activeModal: val });
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
    this.setState({ showModal: "" });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true });

    const { email, plan_id } = this.state;
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    if (email) {
      axios
        .post(
          config.API_URL + "api/v1/sendInviteForCompany",
          {
            email: email,
            plan_id: plan_id
          },
          { headers }
        )
        .then((response) => {
          sweetAlert("success", response?.data?.message);
          setTimeout(() => {
            this.handleCloseModal();
            this.setState({
              email: "",
              plan_id: ""
            });
            this.setState({ submitted: false });
          }, 1000);
        })
        .catch(function (error) {
          if (error?.response) {
            sweetAlert("error", error?.response?.data?.message);
          }
        });
    }
  }

  applyFilter(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState({
      [name]: value,
    });
  }

  applyGlobalSearch(e) {
    let value = e.target.value;
    this.setState({
      searchKey: value,
    });
    setTimeout(() => {
      this.serverRequest();
    }, 200);
  }

  applySorting(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState({
      orderByName: name,
      orderByValue: value,
    });
  }

  clearFilter(e) {
    let name = e.target.name;
    this.setState({
      [name]: "",
      orderByName: "id",
      orderByValue: "DESC",
    });
    setTimeout(() => {
      this.serverRequest();
    }, 500);
  }

  async serverRequest() {
    const {
      skip,
      limit,
      countryFilter,
      industryFilter,
      categoryFilter,
      orderByName,
      orderByValue,
      searchKey,
      showModal,
    } = this.state;

    if (showModal === true) {
      this.setState({
        showModal: false,
      });
    }
    const { isSuccess, data } = await apiCall(config.ADMIN_API_URL + "getBrokers", {}, {}, "GET");
    if (isSuccess) {
      this.setState({
        pageCount: Math.ceil(data.length / this.state.perPage),
        totalData: data?.count,
        items1: data?.data
      });
    }
  }

  pageChange(e, data) {
    let page = data.activePage;
    let pageNo = page === 1 ? 0 : (page - 1) * this.state.limit;
    this.setState({
      skip: pageNo,
    });
    this.serverRequest();
  }

  componentDidMount() {
    this.setState({ items: [] });
    this.serverRequest();
  }

  render() {
    const { items, items1, countriesList, industryList } = this.state;
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="hol_rell">
              <div className="steps-form">
                <div className="steps-row setup-panel">
                  <div className="tabs-top setting_admin my-0">
                    <ul>
                      <li>
                        <NavLink className="activee" to={"/brokers/registered_brokers"}> Registered Brokers </NavLink>
                      </li>
                      <li>
                        <NavLink to={"/brokers/invited_brokers"} title="Click To View" > Invited Brokers </NavLink>
                      </li>
                    </ul>
                  </div>
                  <input
                    type="search"
                    className="form-control w-25 mx-2"
                    placeholder="Search Company, Category, Industry &amp; Business Number"
                    name="search"
                    onChange={(e) => this.applyGlobalSearch(e)}
                  />
                </div>
              </div>
              <div className="color_div_on framwork_2 pt-0">
                <div className="business_detail">
                  <div className="table-responsive">
                    <div className="table___height">
                      {items1 && items1?.length > 0 ?
                        <Table striped bordered style={{ textWrap: 'nowrap' }} className="m-0">
                          <thead>
                            <tr className="heading_color sticky_header">
                              <th style={{ width: 55 }}>Id</th>
                              <th>First Name</th>
                              <th>Last Name</th>
                              <th>Email</th>
                              <th>Mobile No.</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {items1?.map((item, key) => (
                              <tr key={key}>
                                <td>{key + 1}</td>
                                <td>{item?.first_name}</td>
                                <td>{item?.last_name}</td>
                                <td>{item?.email}</td>
                                <td>{item?.mobile_number}</td>
                                <td>
                                  <span className="red bold">
                                    {item?.status === false &&
                                      "Inactive"}
                                  </span>
                                  <span className="green bold">
                                    {item?.status === true &&
                                      "Active"}
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        :
                        <Loader />
                      }
                      {/* <Pagination
                        className="mx-auto pagination"
                        defaultActivePage={1}
                        onPageChange={this.pageChange}
                        ellipsisItem={{
                          content: (
                            <Icon name="ellipsis horizontal" />
                          ),
                          icon: true,
                        }}
                        firstItem={{
                          content: (
                            <Icon name="angle double left" />
                          ),
                          icon: true,
                        }}
                        lastItem={{
                          content: (
                            <Icon name="angle double right" />
                          ),
                          icon: true,
                        }}
                        prevItem={{
                          content: <Icon name="angle left" />,
                          icon: true,
                        }}
                        nextItem={{
                          content: <Icon name="angle right" />,
                          icon: true,
                        }}
                        totalPages={Math.ceil(
                          this.state.totalData / this.state.limit
                        )}
                      /> */}

                      <Modal
                        animation={true}
                        size="md"
                        className="modal_box"
                        shadow-lg="border"
                        show={
                          this.state.showModal &&
                          this.state.activeModal === "industry"
                        }
                      >
                        <div className="modal-lg">
                          <Modal.Header className="pb-0">
                            <Button
                              variant="outline-dark"
                              onClick={this.handleCloseModal}
                            >
                              <i className="fa fa-times"></i>
                            </Button>
                          </Modal.Header>
                          <div className="modal-body vekp pt-0">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="pb4">
                                  <div className="py-3">
                                    <div className="form-check-inline">
                                      <label
                                        className="form-check-label"
                                        htmlFor="flexCheckChecked"
                                      >
                                        {" "}
                                        Sort: A - Z{" "}
                                      </label>
                                      <input
                                        className="form-check-input mx-3"
                                        type="radio"
                                        name="inlineRadioOptions"
                                        id="inlineRadio1"
                                        value="option1"
                                      />
                                    </div>
                                    <div className="form-check-inline">
                                      <label
                                        className="form-check-label"
                                        htmlFor="flexCheckChecked"
                                      > Sort: Z - A
                                      </label>
                                      <input
                                        className="form-check-input mx-3"
                                        type="radio"
                                        name="inlineRadioOptions"
                                        id="inlineRadio2"
                                        value="option1"
                                      />
                                    </div>
                                  </div>

                                  <div className="input-group mb-3">
                                    <select
                                      className="form-control dropdown-toggle"
                                      name="industryFilter"
                                      onChange={(e) =>
                                        this.applyFilter(e)
                                      }
                                    >
                                      <option value="">
                                        Select Industry
                                      </option>

                                      {industryList?.map((item2, key) => (
                                        <option
                                          key={key}
                                          value={item2?.title}
                                        >
                                          {item2.title}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="cenlr">
                                    <button
                                      className="page_save page_width"
                                      name="industryFilter"
                                      onClick={this.clearFilter}
                                    >
                                      CLEAR
                                    </button>
                                    <button
                                      className="page_save page_width"
                                      onClick={this.serverRequest}
                                    >
                                      APPLY
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal>

                      <Modal
                        animation={true}
                        size="md"
                        className="modal_box"
                        shadow-lg="border"
                        show={
                          this.state.showModal &&
                          this.state.activeModal === "category"
                        }
                      >
                        <div className="modal-lg">
                          <Modal.Header className="pb-0">
                            <Button
                              variant="outline-dark"
                              onClick={this.handleCloseModal}
                            >
                              <i className="fa fa-times"></i>
                            </Button>
                          </Modal.Header>
                          <div className="modal-body vekp pt-0">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="pb4">
                                  <div className="py-3">
                                    <div className="form-check-inline">
                                      <label
                                        className="form-check-label"
                                        htmlFor="flexCheckChecked"
                                      >
                                        {" "}
                                        Sort: A - Z{" "}
                                      </label>
                                      <input
                                        className="form-check-input mx-3"
                                        type="radio"
                                        name="inlineRadioOptions"
                                        id="inlineRadio3"
                                        value="option1"
                                      />
                                    </div>
                                    <div className="form-check-inline">
                                      <label
                                        className="form-check-label"
                                        htmlFor="flexCheckChecked"
                                      >
                                        {" "}
                                        Sort: Z - A{" "}
                                      </label>
                                      <input
                                        className="form-check-input mx-3"
                                        type="radio"
                                        name="inlineRadioOptions"
                                        id="inlineRadio4"
                                        value="option1"
                                      />
                                    </div>
                                  </div>

                                  <div className="input-group mb-3">
                                    <select
                                      type="text"
                                      className="form-control dropdown-toggle"
                                      placeholder="Search"
                                      name="categoryFilter"
                                      onChange={(e) =>
                                        this.applyFilter(e)
                                      }
                                    >
                                      <option value="">
                                        Select Category
                                      </option>
                                      <option value="Partner">
                                        Partner
                                      </option>
                                      <option value="Business Account">
                                        Business Account
                                      </option>
                                    </select>
                                  </div>
                                  <div className="cenlr">
                                    <button
                                      className="page_save page_width"
                                      name="categoryFilter"
                                      onClick={this.clearFilter}
                                    >
                                      CLEAR
                                    </button>
                                    <button
                                      className="page_save page_width"
                                      onClick={this.serverRequest}
                                    >
                                      APPLY
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal>

                      <Modal
                        animation={true}
                        size="md"
                        className="modal_box"
                        shadow-lg="border"
                        show={
                          this.state.showModal &&
                          this.state.activeModal === "country"
                        }
                      >
                        <div className="modal-lg">
                          <Modal.Header className="pb-0">
                            <Button
                              variant="outline-dark"
                              onClick={this.handleCloseModal}
                            >
                              <i className="fa fa-times"></i>
                            </Button>
                          </Modal.Header>
                          <div className="modal-body vekp pt-0">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="pb4">
                                  <div className="py-3">
                                    <div className="form-check-inline">
                                      <label
                                        className="form-check-label"
                                        htmlFor="flexCheckChecked"
                                      >
                                        {" "}
                                        Sort: A - Z{" "}
                                      </label>
                                      <input
                                        className="form-check-input mx-3"
                                        type="radio"
                                        name="country"
                                        value="ASC"
                                        onChange={(e) =>
                                          this.applySorting(e)
                                        }
                                      />
                                    </div>
                                    <div className="form-check-inline">
                                      <label
                                        className="form-check-label"
                                        htmlFor="flexCheckChecked"
                                      >
                                        {" "}
                                        Sort: Z - A{" "}
                                      </label>
                                      <input
                                        className="form-check-input mx-3"
                                        type="radio"
                                        name="country"
                                        value="DESC"
                                        onChange={(e) =>
                                          this.applySorting(e)
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="input-group mb-3">
                                    <select
                                      className="form-control dropdown-toggle"
                                      placeholder="Search"
                                      name="countryFilter"
                                      onChange={(e) =>
                                        this.applyFilter(e)
                                      }
                                    >
                                      <option value="">
                                        Select Country
                                      </option>

                                      {countriesList?.map((item, key) => (
                                        <option
                                          key={key}
                                          value={item?.nicename}
                                        >
                                          {item?.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="cenlr">
                                    <button
                                      className="page_save page_width"
                                      name="countryFilter"
                                      onClick={this.clearFilter}
                                    >
                                      CLEAR
                                    </button>
                                    <button
                                      className="page_save page_width"
                                      onClick={this.serverRequest}
                                    >
                                      APPLY
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}