/**
 * <ToolbarGroupItem />
 */

import React, { useReducer } from "react";

function ToolbarGroupItem(props) {
  const { name, group, renderItem } = props;

  const [show, setShow] = useReducer((show) => !show, false);

  const classShow = "collapse" + (show ? " show" : "");

  return (
    <li>
      <div className="toolbar-group-item">
        <button
          className="btn btn-link btn-block text-left"
          type="button"
          onClick={setShow}
        >
          {name}
        </button>

        <div className={classShow}>
          <ul>{group.map(renderItem)}</ul>
        </div>
      </div>
    </li>
  );
}

export default ToolbarGroupItem;
