import React from "react";
import { Table, Modal, Button, Form } from "react-bootstrap";
import { apiCall } from "../../_services/apiCall";
import { useEffect, useState } from "react";
import config from "../../config/config.json";
import TabularQuestionAddFormula from "./TabularQuestionAddFormula";

const AddFormulaModal = ({
  callApi,
  popupVisible,
  selectedQuestion,
  handleClosePopup,
  sectorQuestion,
}) => {
  console.log("question", selectedQuestion);
  const [viewHistoryModal, setViewHistoryModal] = useState(false);

  const [dynamicErrors, setDynamicErrors] = useState({});
  const [inputValues, setInputValues] = useState({});
  const [state, setState] = useState({
    // createFormulaModal: false,
    // viewHistoryModal: false,
    input: "",
    suggestions: [],
    selectedSuggestionIndex: 0,
    showSuggestions: false,
    operators: [
      "+",
      "-",
      "*",
      "/",
      "=",
      "<",
      ">",
      "!",
      "&",
      "|",
      "^",
      "%",
      "(",
      ")",
      "{",
      "}",
      "[",
      "]",
    ],
    answerArray: [],
    getSectorQuestionData: [],
    inValidQuestionError: "",
    inValidExpressionError: "",
    formulaHistoryData: [],
  });

  const rows =
    selectedQuestion?.question_detail?.filter(
      (item) => item.option_type === "row"
    ) || [];
  const columns =
    selectedQuestion?.question_detail?.filter(
      (item) => item.option_type === "column"
    ) || [];

  const getSectorQuestion = async () => {
    const apiUrl = sectorQuestion
      ? `${config.ADMIN_API_URL}getSectorQuestionForFormula?&framework_ids=${sectorQuestion[0]?.frameworkId}&type=ALL`
      : `${config.ADMIN_API_URL}getSectorQuestionForFormula?type=ALL`;

    try {
      const response = await apiCall(apiUrl, {}, {}, "GET");

      if (response.isSuccess) {
        const suggestionData = response?.data?.data;
        console.log("sugggg", suggestionData);

        const updatedState = {
          ...state,
          answerArray: response?.data?.data,
          getSectorQuestionData: suggestionData
            .map((item) => {
              if (item?.questionType === "tabular_question") {
                let rowIndex = 1;
                let columnIndex = 1;
                const result1 = [];
                const result2 = [];
                for (const questionDetailItem of item.question_detail) {
                  if (questionDetailItem.option_type === "column") {
                    result1.push(`C${columnIndex}`);
                    columnIndex++;
                  } else if (questionDetailItem.option_type === "row") {
                    result2.push(`R${rowIndex}`);
                    rowIndex++;
                  }
                }

                const combinedArray = [];

                for (const r2 of result2) {
                  for (const r1 of result1) {
                    combinedArray.push("Q" + item?.question_id + r2 + r1);
                  }
                }

                return combinedArray;
              } else {
                return ["Q" + item?.question_id];
              }
            })
            .flat(),
        };
        setState(updatedState);
      } else {
        console.error("API call unsuccessful");
      }
    } catch (error) {
      console.error("Error occurred during API call:", error);
    }
  };

  useEffect(() => {
    getFormulaHistory();
    getSectorQuestion();
  }, []);
  const {
    // createFormulaModal,
    // viewHistoryModal,
    input,
    suggestions,
    selectedSuggestionIndex,
    showSuggestions,
    operators,
    answerArray,
    getSectorQuestionData,
    inValidQuestionError,
    inValidExpressionError,
    formulaHistoryData,
  } = state;

  const handleSuggestionClickForFormula = (name, suggestion) => {
    const input = inputValues[name];
    const { suggestions } = state;

    // Regular expression to identify operators and keywords
    const operatorsAndBrackets = /[+\-*\/=<>!&|^%(){}\[\]]+|if/g;
    const operatorsAndBracket = /[+\-*\/=<>!&|^%{}[\]]+|(\()+|if/g;
    const operatorMatches = input.match(operatorsAndBracket);

    // Check if the input contains an equal sign (=)
    if (input.includes("=")) {
      setDynamicErrors((prevErrors) => ({
        ...prevErrors,
        [name]: {
          showSuggestions: false,
          inValidExpressionError: "",
          inValidQuestionError: "Equal to (=) is not allowed",
        },
      }));
      return;
    }

    // Variable to store the updated input
    let updatedInput;

    // If there are any operators or brackets in the input
    if (operatorMatches) {
      // Get the last operator/bracket in the current input
      const lastOperator = operatorMatches[operatorMatches.length - 1];

      // Find the index of this last operator/bracket in the input
      const lastOperatorIndex = input.lastIndexOf(lastOperator);

      // Update the input by appending the suggestion after the last operator
      updatedInput = input.substring(0, lastOperatorIndex + 1) + suggestion;
      console.log("nnn", updatedInput);
    } else {
      // If no operators/brackets are found, just set the updated input to the suggestion
      updatedInput = suggestion;
      console.log("ttt", updatedInput);
    }

    // Initialize error messages
    let questionError = "";
    let expressionError = "";

    // Validate the input to ensure it doesn't contain invalid question references
    if (updatedInput) {
      const expressionParts = updatedInput
        .split(operatorsAndBrackets)
        .filter((part) => part.length > 0);

      // Check if all parts are included in the suggestions
      const allPartsInAnswerArray = expressionParts.every((part) =>
        suggestions.includes(part)
      );

      // If not all parts are valid, set an error message
      if (!allPartsInAnswerArray) {
        questionError = "Invalid Question Reference";
      }

      // For now, we are not evaluating the formula expression itself
      // since we are only interested in identifying and validating references
      // expressionError could be set here if we were evaluating formulas
    }

    // Update the state with any errors
    setDynamicErrors((prevErrors) => ({
      ...prevErrors,
      [name]: {
        showSuggestions: false,
        inValidExpressionError: expressionError,
        inValidQuestionError: questionError,
      },
    }));

    // Update the input values state with the new input
    setInputValues((prevState) => ({
      ...prevState,
      [name]: updatedInput,
    }));

    setState((prevState) => ({
      ...prevState,
      input: updatedInput,
    }));
  };

  // const handleSuggestionClickForFormula = (name, suggestion) => {
  //   const input = inputValues[name];
  //   const { suggestions } = state;
  //   const operatorsAndBrackets = /[+\-*\/=<>!&|^%(){}\[\]]+|if/g;
  //   const operatorsAndBracket = /[+\-*\/=<>!&|^%{}[\]]+|(\()+|if/g;
  //   const operatorMatches = input.match(operatorsAndBracket);
  //   let updatedInput;

  //   if (operatorMatches) {
  //     const lastOperator = operatorMatches[operatorMatches.length - 1];
  //     const lastOperatorIndex = input.lastIndexOf(lastOperator);
  //     updatedInput = input.substring(0, lastOperatorIndex + 1) + suggestion;
  //   } else {
  //     updatedInput = suggestion;
  //   }

  //   const extractedSecondPartExpression = updatedInput.includes("=")
  //     ? updatedInput.split("=")[1]
  //     : null;
  //   let questionError = "";
  //   let expressionError = "";
  //   if (extractedSecondPartExpression) {
  //     const expressionParts = extractedSecondPartExpression
  //       .split(operatorsAndBrackets)
  //       .filter((part) => part.length > 0);

  //     const allPartsInAnswerArray = expressionParts.every((part) =>
  //       suggestions.includes(part)
  //     );

  //     if (allPartsInAnswerArray) {
  //     } else {
  //       questionError = "Invalid Question Number";
  //     }

  //     const modifiedExpression = extractedSecondPartExpression.replace(
  //       /Q|R|C/g,
  //       "1"
  //     );
  //     try {
  //       eval(modifiedExpression);
  //     } catch (error) {
  //       expressionError = "Invalid Formula Expression";
  //     }
  //   }

  //   setDynamicErrors((prevErrors) => ({
  //     ...prevErrors,
  //     [name]: {
  //       showSuggestions: false,
  //       inValidExpressionError: expressionError,
  //       inValidQuestionError: questionError,
  //     },
  //   }));
  //   setInputValues((prevState) => ({
  //     ...prevState,
  //     [name]: updatedInput,
  //   }));
  // };

  const handleOperatorChangeForFormula = (e, name) => {
    const selectedOperator = e.target.value;
    console.log("selectedddd", selectedOperator);
    const { operators } = state;
    // const operatorRegex = new RegExp(`[${operators.join("\\")}]`);
    // const hasOperator = operatorRegex.test(inputValues[name]);
    // let updatedInput;
    // if (hasOperator) {
    //   updatedInput = inputValues[name].replace(
    //     /[-+*/=!<>&|^%()[\]{}]+$/,
    //     selectedOperator
    //   );
    //   console.log("uuu",updatedInput)x
    // }

    const operatorRegex = new RegExp(`[${operators.join("\\")}]$`);
    const hasOperator = operatorRegex.test(inputValues[name]);

    let updatedInput;
    if (hasOperator) {
      updatedInput = inputValues[name].replace(operatorRegex, selectedOperator);
      console.log("ttt", updatedInput);
    } else {
      updatedInput = inputValues[name] + selectedOperator;
      console.log("nnn", updatedInput);
    }
    setInputValues((prevState) => ({
      ...prevState,
      [name]: updatedInput,
    }));
  };

  const saveSectorQuestionForFormula = async () => {
    const apiUrl = `${config.ADMIN_API_URL}saveSectorQuestionForFormula`;

    console.log("formula");
    try {
      const response = await apiCall(
        apiUrl,
        {},
        { formula: state.input, question_id: selectedQuestion.question_id },
        "POST"
      );

      if (response.isSuccess) {
        // window.location.href = config.baseURL + "/#/questions_framework_wise";
        callApi();
        handleClosePopup()
        
      } else {
        console.error("API call unsuccessful");
      }
    } catch (error) {
      console.error("Error occurred during API call:", error);
    }
  };

  //   const handleInputChangeForFormula = (event) => {
  //     const value = event.target.value;
  //     const name = event.target.name;
  //     console.log("Input Change - Value:", value, "Name:", name);

  //     const userInput = event.target.value;
  //     const operatorsAndBrackets = /[+\-*\/=<>!&|^%(){}\[\]]+|if/g;

  //     // Split user input by operators and brackets
  //     const userInputArray = userInput.split(operatorsAndBrackets);
  //     const lastInput = userInputArray[userInputArray.length - 1].trim();

  //     const extractedExpression = userInput.includes("=")
  //       ? userInput.split("=")[0]
  //       : null;

  //     console.log("Extracted Expression:", extractedExpression);

  //     const extractedSecondPartExpression = userInput.includes("=")
  //       ? userInput.split("=")[1]
  //       : null;

  //     console.log("Extracted Second Part Expression:", extractedSecondPartExpression);

  //     let questionID;
  //     let suggestionValues = [];

  //     if (extractedExpression) {
  //       const match = extractedExpression.match(/Q(\d+)/);
  //       if (match) {
  //         questionID = parseInt(match[1], 10);
  //         console.log("Extracted Question ID:", questionID);
  //       } else {
  //         console.warn("No valid question ID found in expression");
  //       }
  //     }

  //     if (questionID) {
  //       const questionObject = state.answerArray.find(
  //         (obj) => obj.question_id === questionID
  //       );

  //       if (questionObject) {
  //         if (
  //           questionObject?.questionType === "tabular_question" ||
  //           questionObject?.questionType === "quantitative"
  //         ) {
  //           suggestionValues = state.answerArray
  //             .map((item) => {
  //               if (item.questionType === "quantitative_trends") return null;

  //               if (item.questionType === "tabular_question") {
  //                 let rowIndex = 1;
  //                 let columnIndex = 1;
  //                 const result1 = [];
  //                 const result2 = [];

  //                 for (const questionDetailItem of item.question_detail) {
  //                   if (questionDetailItem.option_type === "column") {
  //                     result1.push(`C${columnIndex}`);
  //                     columnIndex++;
  //                   } else if (questionDetailItem.option_type === "row") {
  //                     result2.push(`R${rowIndex}`);
  //                     rowIndex++;
  //                   }
  //                 }

  //                 const combinedArray = [];
  //                 for (const r2 of result2) {
  //                   for (const r1 of result1) {
  //                     combinedArray.push("Q" + item?.question_id + r2 + r1);
  //                   }
  //                 }
  //                 return combinedArray;
  //               } else {
  //                 return "Q" + item?.question_id;
  //               }
  //             })
  //             .filter((value) => value !== null);
  //         }
  //       } else {
  //         console.warn("No matching question object found for the given ID");
  //       }
  //     }

  //     let tempSuggestionValues;
  //     if (suggestionValues.length === 0) {
  //       tempSuggestionValues = state.getSectorQuestionData;
  //     } else {
  //       tempSuggestionValues = suggestionValues.flat();
  //     }

  //     const filteredSuggestions = tempSuggestionValues?.filter((suggestion) => {
  //       if (suggestion && lastInput) {
  //         return (
  //           suggestion.toLowerCase().includes(lastInput.toLowerCase()) &&
  //           suggestion !== extractedExpression
  //         );
  //       }
  //       return false;
  //     });

  //     let questionError = "";
  //     let expressionError = "";

  //     if (extractedSecondPartExpression) {
  //       const expressionParts = extractedSecondPartExpression
  //         .split(operatorsAndBrackets)
  //         .filter((part) => part.length > 0);

  //       const allPartsInAnswerArray = expressionParts.every((part) =>
  //         tempSuggestionValues.includes(part)
  //       );

  //       if (!allPartsInAnswerArray) {
  //         questionError = "Invalid Question Number";
  //       }

  //       const modifiedExpression = extractedSecondPartExpression.replace(
  //         /Q|R|C/g,
  //         "1"
  //       );

  //       try {
  //         eval(modifiedExpression);
  //       } catch (error) {
  //         expressionError = "Invalid Formula Expression";
  //       }
  //     }

  //     setDynamicErrors((prevErrors) => ({
  //       ...prevErrors,
  //       [name]: {
  //         suggestions: filteredSuggestions,
  //         showSuggestions: true,
  //         inValidExpressionError: expressionError,
  //         inValidQuestionError: questionError,
  //       },
  //     }));

  //     setInputValues((prevState) => ({
  //       ...prevState,
  //       [name]: value,
  //     }));
  //   };

  const handleInputChangeForFormula = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    console.log("Input Change - Value:", value, "Name:", name);

    // Regular expression to identify operators and keywords
    const operatorsAndBrackets = /[+\-*\/=<>!&|^%(){}\[\]]+|if/g;

    // Split the input value by operators and brackets to extract question references
    const userInputArray = value.split(operatorsAndBrackets);
    const lastInput = userInputArray[userInputArray.length - 1]?.trim() || "";

    const extractedExpression = value.includes("=")
      ? value.split("=")[0]
      : null;

    // Check if the input contains an equal sign (=)
    const isEqualsPresent = value.includes("=");

    // Initialize suggestions array
    let suggestionValues = [];
    const tempSuggestionValues = state.getSectorQuestionData || [];

    // Filtered suggestions based on the last input part
    const filteredSuggestions = tempSuggestionValues.filter((suggestion) => {
      return (
        suggestion &&
        lastInput &&
        suggestion.toLowerCase().includes(lastInput.toLowerCase())
      );
    });

    let questionError = "";
    let expressionError = "";

    if (isEqualsPresent) {
      questionError = "Equal to (=) is not allowed";
    } else if (value) {
      // Validate each part to ensure it is a recognized question reference
      const expressionParts = value
        .split(operatorsAndBrackets)
        .filter((part) => part.length > 0);

      // Validate if all parts are valid question references
      const allPartsValid = expressionParts.every((part) =>
        tempSuggestionValues.includes(part)
      );

      if (!allPartsValid) {
        questionError = "Invalid Question Reference";
      }

      // For now, we are not evaluating the formula expression itself
      // since we are only interested in identifying and validating references

      // Set expression error if needed
      // expressionError = "Formula evaluation not supported in this version";
    }

    // Update the dynamic errors state
    setDynamicErrors((prevErrors) => ({
      ...prevErrors,
      [name]: {
        suggestions: filteredSuggestions,
        showSuggestions: true,
        inValidExpressionError: expressionError,
        inValidQuestionError: questionError,
      },
    }));

    // Update the input values state
    setInputValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setState((prevState) => ({
      ...prevState,
      input: value,
    }));
  };

  const handleKeyDownForFormula = (e, name) => {
    const { suggestions } = state;
    const selectedSuggestionIndex = dynamicErrors[name]?.selectedSuggestionIndex;
  
    // Log the key pressed and the current input state
    console.log(`Key pressed: ${e.key}`);
    console.log(`Current input value: ${inputValues[name]}`);
    console.log(`Current suggestions:`, suggestions);
    console.log(`Current selected suggestion index: ${selectedSuggestionIndex}`);
  
    if (e.key === "Enter") {
      console.log("Enter key pressed");
      
      // Log the suggestion that will be selected
      console.log(`Selected suggestion: ${suggestions[selectedSuggestionIndex]}`);
      
      setDynamicErrors((prevErrors) => ({
        ...prevErrors,
        [name]: {
          showSuggestions: false,
        },
      }));
      
      setInputValues((prevState) => ({
        ...prevState,
        [name]: suggestions[selectedSuggestionIndex],
      }));
      
      // Log the updated state after selection
      console.log("Suggestions closed and input value updated");
  
    } else if (e.key === "ArrowUp" && selectedSuggestionIndex > 0) {
      console.log("ArrowUp key pressed");
      
      setDynamicErrors((prevErrors) => ({
        ...prevErrors,
        [name]: {
          selectedSuggestionIndex: selectedSuggestionIndex - 1,
        },
      }));
      
      // Log the new selected index after moving up
      console.log(`New selected suggestion index: ${selectedSuggestionIndex - 1}`);
    
    } else if (
      e.key === "ArrowDown" &&
      selectedSuggestionIndex < suggestions.length - 1
    ) {
      console.log("ArrowDown key pressed");
      
      setDynamicErrors((prevErrors) => ({
        ...prevErrors,
        [name]: {
          selectedSuggestionIndex: selectedSuggestionIndex + 1,
        },
      }));
      
      // Log the new selected index after moving down
      console.log(`New selected suggestion index: ${selectedSuggestionIndex + 1}`);
    } else {
      console.log("Other key pressed or no action taken");
    }
  };
  

  // const handleKeyDownForFormula = (e, name) => {
  //   const { suggestions } = state;
  //   const selectedSuggestionIndex =
  //     dynamicErrors[name]?.selectedSuggestionIndex;
  //   if (e.key === "Enter") {
  //     setDynamicErrors((prevErrors) => ({
  //       ...prevErrors,
  //       [name]: {
  //         showSuggestions: false,
  //       },
  //     }));
  //     setInputValues((prevState) => ({
  //       ...prevState,
  //       [name]: suggestions[selectedSuggestionIndex],
  //     }));
  //   } else if (e.key === "ArrowUp" && selectedSuggestionIndex > 0) {
  //     setDynamicErrors((prevErrors) => ({
  //       ...prevErrors,
  //       [name]: {
  //         selectedSuggestionIndex: selectedSuggestionIndex - 1,
  //       },
  //     }));
  //   } else if (
  //     e.key === "ArrowDown" &&
  //     selectedSuggestionIndex < suggestions.length - 1
  //   ) {
  //     setDynamicErrors((prevErrors) => ({
  //       ...prevErrors,
  //       [name]: {
  //         selectedSuggestionIndex: selectedSuggestionIndex + 1,
  //       },
  //     }));
  //   }
  // };

  const handleOpenModalForHistoryFormula = () => {
    getFormulaHistory();
    setViewHistoryModal(true);
  };

  const handleViewHistoryClose = () => {
    setViewHistoryModal(false);
  };

  const getFormulaHistory = async () => {
    const apiUrl = `${config.ADMIN_API_URL}getFormulaHistory`;

    try {
      const response = await apiCall(apiUrl, {}, {}, "GET");

      if (response.isSuccess) {
        const updatedState = {
          ...state,
          formulaHistoryData: response.data.data,
        };
        setState(updatedState);
      } else {
        console.error("Failed to fetch formula history:", response.message);
      }
    } catch (error) {
      console.error("Error occurred during API call:", error);
    }
  };
  console.log("selected", selectedQuestion);
  return (
    <>
      <Modal fullscreen={true} show={popupVisible} onHide={handleClosePopup}>
        <Modal.Header closeButton>
          <div className=" w-100 d-flex align-items-center justify-content-between">
            <Modal.Title>
              <div> Add Formula</div>
            </Modal.Title>
            <div
              className="view_formula_history"
              onClick={() => handleOpenModalForHistoryFormula()}
            >
              View History
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          {selectedQuestion?.questionType === "yes_no" && (
            <Form.Group
              className="mb-2"
              controlId={`question-${selectedQuestion.question_id}`}
            >
              <Form.Label>
                Add Formula for {selectedQuestion.heading}
              </Form.Label>
              <Form.Control
                autoFocus
                name={`${selectedQuestion.question_id}`}
                type="text"
                value={inputValues[`${selectedQuestion.question_id}`] || ""}
                onChange={(event) =>
                  handleInputChangeForFormula(
                    event,
                    selectedQuestion.question_id
                  )
                }
                onKeyDown={(event) =>
                  handleKeyDownForFormula(
                    event,
                    `${selectedQuestion.question_id}`
                  )
                }
                placeholder="Type something..."
                autoComplete="off"
              />

              {dynamicErrors[`${selectedQuestion.question_id}`] &&
                dynamicErrors[`${selectedQuestion.question_id}`]
                  .inValidQuestionError && (
                  <div className="error-message">
                    {
                      dynamicErrors[`${selectedQuestion.question_id}`]
                        .inValidQuestionError
                    }
                  </div>
                )}
              {dynamicErrors[`${selectedQuestion.question_id}`] &&
                dynamicErrors[`${selectedQuestion.question_id}`]
                  .inValidExpressionError && (
                  <div className="error-message">
                    {
                      dynamicErrors[`${selectedQuestion.question_id}`]
                        .inValidExpressionError
                    }
                  </div>
                )}
              {dynamicErrors[`${selectedQuestion.question_id}`] &&
                dynamicErrors[`${selectedQuestion.question_id}`]
                  .showSuggestions && (
                  <div
                    className={
                      dynamicErrors[`${selectedQuestion.question_id}`]
                        .suggestions &&
                      dynamicErrors[`${selectedQuestion.question_id}`]
                        .suggestions.length > 0 &&
                      "formula__suggesstions"
                    }
                  >
                    {dynamicErrors[
                      `${selectedQuestion.question_id}`
                    ].suggestions.map((suggestion, index) => (
                      <div
                        key={index}
                        className={
                          index ===
                          dynamicErrors[`${selectedQuestion.question_id}`]
                            .selectedSuggestionIndex
                            ? "selected"
                            : ""
                        }
                        onClick={(e) =>
                          handleSuggestionClickForFormula(
                            `${selectedQuestion.question_id}`,
                            suggestion
                          )
                        }
                      >
                        {suggestion}
                      </div>
                    ))}
                  </div>
                )}
              <Form.Label className="mt-3">Select Operator</Form.Label>
              <Form.Control
                as="select"
                className="form-control"
                onChange={(event) =>
                  handleOperatorChangeForFormula(
                    event,
                    `${selectedQuestion.question_id}`
                  )
                }
              >
                <option value="">Select Operator</option>
                {operators.map((operator, index) => (
                  <option key={index} value={operator}>
                    {operator}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}
          {selectedQuestion?.questionType === "qualitative" && (
            <Form.Group
              className="mb-2"
              controlId={`question-${selectedQuestion.question_id}`}
            >
              <Form.Label>
                Add Formula for {selectedQuestion.heading}
              </Form.Label>
              <Form.Control
                autoFocus
                name={`${selectedQuestion.question_id}`}
                type="text"
                value={inputValues[`${selectedQuestion.question_id}`] || ""}
                onChange={(event) =>
                  handleInputChangeForFormula(
                    event,
                    selectedQuestion.question_id
                  )
                }
                onKeyDown={(event) =>
                  handleKeyDownForFormula(
                    event,
                    `${selectedQuestion.question_id}`
                  )
                }
                placeholder="Type something..."
                autoComplete="off"
              />

              {dynamicErrors[`${selectedQuestion.question_id}`] &&
                dynamicErrors[`${selectedQuestion.question_id}`]
                  .inValidQuestionError && (
                  <div className="error-message">
                    {
                      dynamicErrors[`${selectedQuestion.question_id}`]
                        .inValidQuestionError
                    }
                  </div>
                )}
              {dynamicErrors[`${selectedQuestion.question_id}`] &&
                dynamicErrors[`${selectedQuestion.question_id}`]
                  .inValidExpressionError && (
                  <div className="error-message">
                    {
                      dynamicErrors[`${selectedQuestion.question_id}`]
                        .inValidExpressionError
                    }
                  </div>
                )}
              {dynamicErrors[`${selectedQuestion.question_id}`] &&
                dynamicErrors[`${selectedQuestion.question_id}`]
                  .showSuggestions && (
                  <div
                    className={
                      dynamicErrors[`${selectedQuestion.question_id}`]
                        .suggestions &&
                      dynamicErrors[`${selectedQuestion.question_id}`]
                        .suggestions.length > 0 &&
                      "formula__suggesstions"
                    }
                  >
                    {dynamicErrors[
                      `${selectedQuestion.question_id}`
                    ].suggestions.map((suggestion, index) => (
                      <div
                        key={index}
                        className={
                          index ===
                          dynamicErrors[`${selectedQuestion.question_id}`]
                            .selectedSuggestionIndex
                            ? "selected"
                            : ""
                        }
                        onClick={(e) =>
                          handleSuggestionClickForFormula(
                            `${selectedQuestion.question_id}`,
                            suggestion
                          )
                        }
                      >
                        {suggestion}
                      </div>
                    ))}
                  </div>
                )}
              <Form.Label className="mt-3">Select Operator</Form.Label>
              <Form.Control
                as="select"
                className="form-control"
                onChange={(event) =>
                  handleOperatorChangeForFormula(
                    event,
                    `${selectedQuestion.question_id}`
                  )
                }
              >
                <option value="">Select Operator</option>
                {operators.map((operator, index) => (
                  <option key={index} value={operator}>
                    {operator}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}
          {selectedQuestion?.questionType === "quantitative" && (
            <Form.Group
              className="mb-2"
              controlId={`question-${selectedQuestion.question_id}`}
            >
              <Form.Label>
                Add Formula for {selectedQuestion.heading}
              </Form.Label>
              <Form.Control
                autoFocus
                name={`${selectedQuestion.question_id}`}
                type="text"
                value={inputValues[`${selectedQuestion.question_id}`] || ""}
                onChange={(event) =>
                  handleInputChangeForFormula(
                    event,
                    selectedQuestion.question_id
                  )
                }
                onKeyDown={(event) =>
                  handleKeyDownForFormula(
                    event,
                    `${selectedQuestion.question_id}`
                  )
                }
                placeholder="Type something..."
                autoComplete="off"
              />

              {dynamicErrors[`${selectedQuestion.question_id}`] &&
                dynamicErrors[`${selectedQuestion.question_id}`]
                  .inValidQuestionError && (
                  <div className="error-message">
                    {
                      dynamicErrors[`${selectedQuestion.question_id}`]
                        .inValidQuestionError
                    }
                  </div>
                )}
              {dynamicErrors[`${selectedQuestion.question_id}`] &&
                dynamicErrors[`${selectedQuestion.question_id}`]
                  .inValidExpressionError && (
                  <div className="error-message">
                    {
                      dynamicErrors[`${selectedQuestion.question_id}`]
                        .inValidExpressionError
                    }
                  </div>
                )}
              {dynamicErrors[`${selectedQuestion.question_id}`] &&
                dynamicErrors[`${selectedQuestion.question_id}`]
                  .showSuggestions && (
                  <div
                    className={
                      dynamicErrors[`${selectedQuestion.question_id}`]
                        .suggestions &&
                      dynamicErrors[`${selectedQuestion.question_id}`]
                        .suggestions.length > 0 &&
                      "formula__suggesstions"
                    }
                  >
                    {dynamicErrors[
                      `${selectedQuestion.question_id}`
                    ].suggestions.map((suggestion, index) => (
                      <div
                        key={index}
                        className={
                          index ===
                          dynamicErrors[`${selectedQuestion.question_id}`]
                            .selectedSuggestionIndex
                            ? "selected"
                            : ""
                        }
                        onClick={(e) =>
                          handleSuggestionClickForFormula(
                            `${selectedQuestion.question_id}`,
                            suggestion
                          )
                        }
                      >
                        {suggestion}
                      </div>
                    ))}
                  </div>
                )}
              <Form.Label className="mt-3">Select Operator</Form.Label>
              <Form.Control
                as="select"
                className="form-control"
                onChange={(event) =>
                  handleOperatorChangeForFormula(
                    event,
                    `${selectedQuestion.question_id}`
                  )
                }
              >
                <option value="">Select Operator</option>
                {operators.map((operator, index) => (
                  <option key={index} value={operator}>
                    {operator}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}
          {selectedQuestion?.questionType === "quantitative_trends" && (
            <Form.Group
              className="mb-2"
              controlId={`question-${selectedQuestion.question_id}`}
            >
              <Form.Label>
                Add Formula for {selectedQuestion.heading}
              </Form.Label>
              <Form.Control
                autoFocus
                name={`${selectedQuestion.question_id}`}
                type="text"
                value={inputValues[`${selectedQuestion.question_id}`] || ""}
                onChange={(event) =>
                  handleInputChangeForFormula(
                    event,
                    selectedQuestion.question_id
                  )
                }
                onKeyDown={(event) =>
                  handleKeyDownForFormula(
                    event,
                    `${selectedQuestion.question_id}`
                  )
                }
                placeholder="Type something..."
                autoComplete="off"
              />

              {dynamicErrors[`${selectedQuestion.question_id}`] &&
                dynamicErrors[`${selectedQuestion.question_id}`]
                  .inValidQuestionError && (
                  <div className="error-message">
                    {
                      dynamicErrors[`${selectedQuestion.question_id}`]
                        .inValidQuestionError
                    }
                  </div>
                )}
              {dynamicErrors[`${selectedQuestion.question_id}`] &&
                dynamicErrors[`${selectedQuestion.question_id}`]
                  .inValidExpressionError && (
                  <div className="error-message">
                    {
                      dynamicErrors[`${selectedQuestion.question_id}`]
                        .inValidExpressionError
                    }
                  </div>
                )}
              {dynamicErrors[`${selectedQuestion.question_id}`] &&
                dynamicErrors[`${selectedQuestion.question_id}`]
                  .showSuggestions && (
                  <div
                    className={
                      dynamicErrors[`${selectedQuestion.question_id}`]
                        .suggestions &&
                      dynamicErrors[`${selectedQuestion.question_id}`]
                        .suggestions.length > 0 &&
                      "formula__suggesstions"
                    }
                  >
                    {dynamicErrors[
                      `${selectedQuestion.question_id}`
                    ].suggestions.map((suggestion, index) => (
                      <div
                        key={index}
                        className={
                          index ===
                          dynamicErrors[`${selectedQuestion.question_id}`]
                            .selectedSuggestionIndex
                            ? "selected"
                            : ""
                        }
                        onClick={(e) =>
                          handleSuggestionClickForFormula(
                            `${selectedQuestion.question_id}`,
                            suggestion
                          )
                        }
                      >
                        {suggestion}
                      </div>
                    ))}
                  </div>
                )}
              <Form.Label className="mt-3">Select Operator</Form.Label>
              <Form.Control
                as="select"
                className="form-control"
                onChange={(event) =>
                  handleOperatorChangeForFormula(
                    event,
                    `${selectedQuestion.question_id}`
                  )
                }
              >
                <option value="">Select Operator</option>
                {operators.map((operator, index) => (
                  <option key={index} value={operator}>
                    {operator}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}
          {selectedQuestion?.questionType === "tabular_question" && (
            // <Form.Group
            //   className="mb-3"
            //   style={{ overflow: "auto" }}
            //   controlId={`question-${selectedQuestion.question_id}`}
            // >
            //   <Form.Label>
            //     Add formula for {selectedQuestion.heading}
            //   </Form.Label>
            //   <div>
            //     <Table striped bordered hover>
            //       <thead>
            //         <tr>
            //           <th>{selectedQuestion?.firstRowAndColumn || "#"}</th>
            //           {/* Extract column headers */}
            //           {selectedQuestion?.question_detail
            //             ?.filter((detail) => detail.option_type === "column")
            //             .map((col, index) => (
            //               <th key={index}>{col.option}</th>
            //             ))}
            //         </tr>
            //       </thead>
            //       <tbody>
            //         {selectedQuestion?.question_detail
            //           ?.filter((detail) => detail.option_type === "row")
            //           .map((row, rowIndex) => (
            //             <tr key={rowIndex}>
            //               <th>{row.option}</th>
            //               {/* Render input fields for each column */}
            //               {selectedQuestion.question_detail
            //                 ?.filter(
            //                   (detail) => detail.option_type === "column"
            //                 )
            //                 .map((col, colIndex) => (
            //                   <td key={colIndex}>
            //                     <input
            //                       type="text"
            //                       className="input-for-formula"
            //                       name={`${selectedQuestion.question_id}R${rowIndex}C${colIndex}`}
            //                       value={
            //                         inputValues[
            //                           `${selectedQuestion.question_id}R${rowIndex}C${colIndex}`
            //                         ] || ""
            //                       }
            //                       onChange={(event) =>
            //                         handleInputChangeForFormula(
            //                           event,
            //                           selectedQuestion.question_id,
            //                           rowIndex,
            //                           colIndex
            //                         )
            //                       }
            //                       onKeyDown={(event) =>
            //                         handleKeyDownForFormula(
            //                           event,
            //                           `${selectedQuestion.question_id}R${rowIndex}C${colIndex}`
            //                         )
            //                       }
            //                     />
            //                     {dynamicErrors[
            //                       `${selectedQuestion.question_id}R${rowIndex}C${colIndex}`
            //                     ] &&
            //                       dynamicErrors[
            //                         `${selectedQuestion.question_id}R${rowIndex}C${colIndex}`
            //                       ].inValidQuestionError && (
            //                         <div className="error-message">
            //                           {
            //                             dynamicErrors[
            //                               `${selectedQuestion.question_id}R${rowIndex}C${colIndex}`
            //                             ].inValidQuestionError
            //                           }
            //                         </div>
            //                       )}
            //                     {dynamicErrors[
            //                       `${selectedQuestion.question_id}R${rowIndex}C${colIndex}`
            //                     ] &&
            //                       dynamicErrors[
            //                         `${selectedQuestion.question_id}R${rowIndex}C${colIndex}`
            //                       ].inValidExpressionError && (
            //                         <div className="error-message">
            //                           {
            //                             dynamicErrors[
            //                               `${selectedQuestion.question_id}R${rowIndex}C${colIndex}`
            //                             ].inValidExpressionError
            //                           }
            //                         </div>
            //                       )}
            //                     {dynamicErrors[
            //                       `${selectedQuestion.question_id}R${rowIndex}C${colIndex}`
            //                     ] &&
            //                       dynamicErrors[
            //                         `${selectedQuestion.question_id}R${rowIndex}C${colIndex}`
            //                       ].showSuggestions && (
            //                         <div
            //                           className={
            //                             dynamicErrors[
            //                               `${selectedQuestion.question_id}R${rowIndex}C${colIndex}`
            //                             ].suggestions &&
            //                             dynamicErrors[
            //                               `${selectedQuestion.question_id}R${rowIndex}C${colIndex}`
            //                             ].suggestions.length > 0 &&
            //                             "formula__suggesstions"
            //                           }
            //                         >
            //                           {dynamicErrors[
            //                             `${selectedQuestion.question_id}R${rowIndex}C${colIndex}`
            //                           ].suggestions.map((suggestion, index) => (
            //                             <div
            //                               key={index}
            //                               className={
            //                                 index ===
            //                                 dynamicErrors[
            //                                   `${selectedQuestion.question_id}R${rowIndex}C${colIndex}`
            //                                 ].selectedSuggestionIndex
            //                                   ? "selected"
            //                                   : ""
            //                               }
            //                               onClick={() =>
            //                                 handleSuggestionClickForFormula(
            //                                   `${selectedQuestion.question_id}R${rowIndex}C${colIndex}`,
            //                                   suggestion
            //                                 )
            //                               }
            //                             >
            //                               {suggestion}
            //                             </div>
            //                           ))}
            //                         </div>
            //                       )}
            //                     <Form.Label className="mt-3">
            //                       Select Operator
            //                     </Form.Label>
            //                     <Form.Control
            //                       as="select"
            //                       className="form-control"
            //                       onChange={(event) =>
            //                         handleOperatorChangeForFormula(
            //                           event,
            //                           `${selectedQuestion.question_id}R${rowIndex}C${colIndex}`
            //                         )
            //                       }
            //                     >
            //                       <option value="">Select Operator</option>
            //                       {operators.map((operator, index) => (
            //                         <option key={index} value={operator}>
            //                           {operator}
            //                         </option>
            //                       ))}
            //                     </Form.Control>
            //                   </td>
            //                 ))}
            //             </tr>
            //           ))}
            //       </tbody>
            //     </Table>
            //   </div>
            // </Form.Group>
            <TabularQuestionAddFormula setState={setState} state={state} dynamicErrors={dynamicErrors} handleSuggestionClickForFormula={handleSuggestionClickForFormula} handleOperatorChangeForFormula={handleOperatorChangeForFormula} handleKeyDownForFormula={handleKeyDownForFormula}selectedQuestion={selectedQuestion} operators={operators}/>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePopup}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => saveSectorQuestionForFormula()}
          >
            Add Formula
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={viewHistoryModal} onHide={handleViewHistoryClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Formula</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Question ID</th>
                <th>Question Type</th>
                <th>Formula</th>
              </tr>
            </thead>
            <tbody>
              {formulaHistoryData &&
                formulaHistoryData.map((selectedQuestion, index) => (
                  <tr key={index}>
                    <td>{selectedQuestion.questionId}</td>
                    <td>{selectedQuestion.questionType}</td>
                    <td>{selectedQuestion.formula}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddFormulaModal;
