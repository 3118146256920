/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import Table from "react-bootstrap/Table";
import { NavLink } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Pagination, Icon } from "semantic-ui-react";
import "../../Company Sub Admin/Pages/supplier_management/supplier_management.css";


export default class supplierManagementDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeModal: "",
      activeModal1: "",
  
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenModal1 = this.handleOpenModal1.bind(this);
    this.handleCloseModal1 = this.handleCloseModal1.bind(this);
  }

  handleOpenModal(val) {
    this.setState({ activeModal: "login" });
    this.setState({ showModal: true});
  }

  handleCloseModal() {
    this.setState({ showModal: false });
    this.setState({ showModal: "" });
  }

  handleOpenModal1(val) {
    this.setState({ activeModal1: "login" });
    this.setState({ showModal1: true});
    
  }

  handleCloseModal1() {
    this.setState({ showModal1: false });
    this.setState({ showModal1: "" });
  }

  render() {
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="color_div_on framwork_2">
                        <div className="business_detail">
                          <div className="saved_cards">
                            <div className="business_detail">
                              <div className="heading">
                                <div className="heading_wth_text">
                                  <h4>SUPPLIER DETAIL</h4>
                                </div>
                                <form>
                                  <div className="row" style={{alignItems:"center"}}>
                                    <div className="col-md-5 col-xs-12">
                                      <div className="form-group">
                                        <input
                                          type="date"
                                          onChange={
                                            (this.handleChange,
                                            this.onDateChange)
                                          }
                                          className="form-control date-picker"
                                          name="setStartDate"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-1 col-xs-12">
                                      <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">
                                          to
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-6 col-xs-12">
                                      <div className="form-group">
                                        <input
                                          type="date"
                                          onChange={
                                            (this.handleChange,
                                            this.onDateChange)
                                          }
                                          className="form-control date-picker"
                                          name="setEndDate"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <hr></hr>
                            <div className="table_f table-responsive">
                              <Table striped bordered hover size="sm">
                                <thead>
                                  <tr className="heading_color">
                                    <th>ID</th>
                                    <th>Supplier</th>
                                    <th>Date Added</th>
                                    <th>Industry</th>
                                    {/* <th>Criteria Assessed</th> */}
                                    <th>Response</th>
                                    <th>Identified Risk</th>
                                    <th>Comments</th>
                                    <th>Logs</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>1</td>
                                    <td>
                                      Supplier 1
                                    </td>
                                    <td>{moment().utc().format('DD-MM-YYYY')}</td>
                                    <td>Coal</td>
                                    {/* <td>{item.followingCriteria}</td> */}
                                    <td>
                                      <div className="d-flex">
                                        <span className="upload mb-4 upload-document">
                                          <i className="fas fa-upload"></i>
                                          <input
                                            className="upload_files download_files_supplier"
                                            type="file"
                                            name="uploadImage"
                                            accept='.pdf, .doc, .docx, .xlsx, .pptx, .ppt, .ott, .odp'
                                            data-id=""
                                            onChange=""
                                          />
                                        </span>
                                        <NavLink className="mx-3" target="_blank" to="">
                                          <span className="upload mb-4 upload-document">
                                            <i className="fas fa-download"></i>   
                                          </span>
                                        </NavLink>
                                      </div>  
                                    </td>
                                    <td>
                                      <select className="form-select select-s" aria-label="Default select example" >
                                        <option>Select</option>
                                        <option value="1">High</option>
                                        <option value="2">Medium</option>
                                        <option value="3">Low</option>
                                      </select>
                                    </td>
                                    <td>
                                      <span className="global_link mx-0">
                                        <button className="btn btn-link supply-addd" variant="none" onClick={(e) => this.handleOpenModal(e) } >
                                          <span className="view-eye supply-add" > 
                                            <i className="fa fa-pencil mx-2" ></i> ADD
                                          </span>
                                        </button>
                                      </span>
                                    </td>
                                    <td>
                                      <NavLink to="#" onClick={(e) => this.handleOpenModal1(e) }>
                                        <i className="fa fa-info center"> </i>
                                      </NavLink>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                              <Pagination
                                className="mx-auto pagination"
                                defaultActivePage={1}
                                onPageChange={this.pageChange}
                                ellipsisItem={{
                                  content: <Icon name="ellipsis horizontal" />,
                                  icon: true,
                                }}
                                firstItem={{
                                  content: <Icon name="angle double left" />,
                                  icon: true,
                                }}
                                lastItem={{
                                  content: <Icon name="angle double right" />,
                                  icon: true,
                                }}
                                prevItem={{
                                  content: <Icon name="angle left" />,
                                  icon: true,
                                }}
                                nextItem={{
                                  content: <Icon name="angle right" />,
                                  icon: true,
                                }}
                                totalPages={Math.ceil(
                                  this.state.totalData / this.state.limit
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <Modal animation={true} size="md" className="modal_box" shadow-lg="border" show={this.state.showModal && this.state.activeModal === "login"} >
          <div className="modal-lg">
            <Modal.Header className="pb-0">
              <Button variant="outline-dark" onClick={this.handleCloseModal}>
                <i className="fa fa-times"></i>
              </Button>
            </Modal.Header>
            <div className="modal-body vekp pt-0">
              <div className="row">
                <div className="col-md-12">
                  <div className="pb3">
                    <h5 className="feedbackTitle">Feedback</h5>
                      <div className="mb-3">
                        <textarea className="form-control" placeholder="Please enter your comments below" rows="3" > Text </textarea>
                      </div>
                      <div className="group_link d-flex">
                        <button className="page_save page_width" type="submit"> SAVE </button>
                        <button type="button" className="link_bal_next  page_width" onClick={this.handleCloseModal} > Cancel </button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal animation={true} size="md" className="modal_box" shadow-lg="border" show={this.state.showModal1 && this.state.activeModal1 === "login"} >
          <div className="modal-lg">
            <Modal.Header className="pb-0">
              <Button variant="outline-dark" onClick={this.handleCloseModal1}>
                <i className="fa fa-times"></i>
              </Button>
            </Modal.Header>
            <div className="modal-body vekp pt-0">
              <div className="row">
                <div className="col-md-12">
                  <div className="pb3">
                    <h4>Feedback Logs</h4>
                    <form>
                      <div className="mb-3 p-3">
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr className="heading_color">
                              <th>ID</th>
                              <th>Feedbacks / Comments</th>
                              <th>Updated date</th>
                            </tr>
                          </thead>
                          <tbody>
                              <tr>
                                <td>1</td>
                                <td> text </td>
                                <td>{moment().utc().format('DD-MM-YYYY')}</td>
                              </tr>
                          </tbody>
                        </Table>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
