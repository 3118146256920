import React from 'react';
import AccessDenied from '../AccessDenied/AccessDenied';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state to indicate an error has occurred
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      // Render the access denied page
      return <AccessDenied />;
    }

    // Render the children components as normal
    return this.props.children;
  }
}

export default ErrorBoundary;