import React, { useEffect, useState } from "react";
import { Dropdown, Modal, Table } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import "../../../Sector_Question_Manage/control.css";
import config from "../../../../config/config.json";
import { apiCall } from "../../../../_services/apiCall";
import { authenticationService } from "../../../../_services/authentication";

export const Frameworks = (props) => {
  const {
    financial_year_id,
    handleTopicChangeHandler,
    handleAddFrameworkQuestionChangeHandler,
  } = props;

  const [frameworks, setFrameworks] = useState([]);
  const [menulist, setMenuList] = useState([]);
  const location = useLocation();
  const [data] = useState(location?.state?.item);
  const [title, setTitle] = useState(data?.title);
  const [upadteFramework, setUpadteFramework] = useState(false);
  const [addFramework, setAddFramework] = useState(false);
  const closeUpdateFrameworkPopup = () => setUpadteFramework(false);
  const showAddFrameworkPopup = () => setAddFramework(true);
  const showUpdateFrameworkPopup = () => setUpadteFramework(true);
  const closeAddFrameworkPopup = () => setAddFramework(false);

  const [frameWorkTitle, setFrameworkTitle] = useState("");

  const handleFrameworkSubmit = async (e) => {
    e.preventDefault();
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}createFramework`,
      {},
      { title: frameWorkTitle,
        financial_year_id: financial_year_id?.id },
      "POST"
    );
    if (isSuccess) {
      setTitle("");
      callApi();
      closeAddFrameworkPopup()
    }
  };

  const callApi = async () => {
    if(financial_year_id?.id){
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}getFramework`,
      {},
      { type: "CUSTOM", financial_year_id: financial_year_id?.id },
      "GET"
    );
    setFrameworks(data?.data?.reverse());
    }
  };

  const deleteFramework = async (e, item) => {
    let obj = {};
    obj.id = item?.id;
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}deleteFramework`,
      {},
      obj,
      "POST"
    );
    callApi();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let obj = {};
    obj.id = data.id;
    obj.title = title;
    const { isSuccess } = await apiCall(
      `${config.ADMIN_API_URL}updateFramework`,
      {},
      obj,
      "POST"
    );
    if (isSuccess) {
      window.location.href = config.baseURL + `/#/frameworks`;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "title":
        setTitle(value);
        break;
      default:
        break;
    }
  };
  const handleClickTopicComponent = (topicComponentData, activeTab) => {
    handleTopicChangeHandler(topicComponentData, financial_year_id, activeTab);
  };

  const handleClickAddFrameworkQuestionComponent = (
    addQuestionCopmonentData
  ) => {
    handleAddFrameworkQuestionChangeHandler(addQuestionCopmonentData);
  };
  useEffect(() => {
    const currentUser = authenticationService?.currentUserSubject?.getValue();
    const settingsMenu = currentUser?.data?.menu?.find(
      (item) => item?.url === "global_controls"
    );
    setMenuList(settingsMenu?.permissions);
    callApi();
  }, [financial_year_id?.id]);
  useEffect(() => {
    const currentUser = authenticationService?.currentUserSubject?.getValue();
    const settingsMenu = currentUser?.data?.menu?.find(
      (item) => item?.url === "global_controls"
    );
    setMenuList(settingsMenu?.permissions);
    callApi();
  }, []);

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <div className="heading">
          <h4 className="m-0">
            <strong>Framework List</strong>
          </h4>
          {menulist?.includes("FRAMEWORK_CREATE") && (
            <div
              onClick={showAddFrameworkPopup}
              className="mx-3 add_framework_control"
            >
              <i className="fas fa-plus-square fs-lg" title="Add Framework"></i>
              &nbsp;&nbsp;
            </div>
          )}
        </div>
        <div>
          <input
            type="search"
            className="form-control w-100"
            placeholder="Search Framework"
            name="search"
          />
        </div>
      </div>
      <div className="table_f border-top mt-2 auto_scroll_by_design">
        <Table striped bordered className="mt-2 mb-0">
          <thead>
            <tr className="heading_color sticky_header">
              <th style={{ width: 50 }}>Sr.</th>
              <th>Framework</th>
              <th style={{ width: 50 }}>Action</th>
            </tr>
          </thead>
          {frameworks && frameworks?.length > 0 ? (
            <tbody>
              {frameworks &&
                frameworks?.map((item, key) => (
                  <tr key={key}>
                    <td>{key + 1}</td>
                    <td>{item?.title}</td>
                    <td className="text-center">
                      <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic"></Dropdown.Toggle>
                        <Dropdown.Menu>
                          {/* <Dropdown.Item
                            onClick={(e) =>
                              handleClickAddFrameworkQuestionComponent(item)
                            }
                          >
                            <i className="fas fa-plus-square"></i>&nbsp;&nbsp;
                            Add Question
                          </Dropdown.Item> */}
                          {/* <Dropdown.Item onClick={(e) => handleClickTopicComponent(item, "questionList")}>
                            <i className="fas fa-eye"></i>&nbsp;&nbsp; View
                            Question
                          </Dropdown.Item> */}
                          <Dropdown.Item
                            onClick={(e) => handleClickTopicComponent(item, "topic")}
                          >
                            <i className="fas fa-eye"></i>&nbsp;&nbsp; View
                            Topics
                          </Dropdown.Item>
                          {item?.is_editable ? (
                            <>
                              {menulist?.includes("FRAMEWORK_UPDATE") && (
                                <Dropdown.Item
                                  onClick={showUpdateFrameworkPopup}
                                >
                                  <i className="far fa-edit"></i>&nbsp;&nbsp;
                                  Edit Framework
                                </Dropdown.Item>
                              )}
                              {menulist?.includes("FRAMEWORK_DELETE") && (
                                <Dropdown.Item
                                  onClick={(e) => deleteFramework(e, item)}
                                >
                                  <span style={{ color: "red" }}>
                                    <i className="fas fa-trash"></i>&nbsp;&nbsp;
                                    Delete Framework
                                  </span>
                                </Dropdown.Item>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td>--</td>
                <td>No Data Found</td>
                <td>--</td>
              </tr>
            </tbody>
          )}
        </Table>
      </div>

      {/* ------------ Update Framework --------- */}
      <Modal show={upadteFramework} onHide={closeUpdateFrameworkPopup}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Framework</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form name="form" onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id=" questionHeading"
                value={title}
                placeholder="Enter Framework Heading"
                name="heading"
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="link_bal_next_cancel"
            onClick={closeUpdateFrameworkPopup}
          >
            Cancel
          </button>
          <button className="link_bal_next" type="submit">
            Update
          </button>
        </Modal.Footer>
      </Modal>

      {/* ------------ Add Framework --------- */}
      <Modal show={addFramework} onHide={closeAddFrameworkPopup}>
        <Modal.Header closeButton>
          <Modal.Title>Add Framework</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group pb-3">
            <label htmlFor="title" className="mb-2">
              Framework Heading
            </label>
            <input
              type="text"
              className="form-control"
              id="title"
              placeholder="Enter Question Heading or Leave This Options"
              name="heading"
              onChange={(e) => setFrameworkTitle(e.target.value)}
              required
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="link_bal_next_cancel"
            onClick={closeAddFrameworkPopup}
          >
            Cancel
          </button>
          <button className="link_bal_next" type="submit" onClick={handleFrameworkSubmit}>
            Add
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
