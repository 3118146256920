import React, { Fragment, useEffect, useState } from "react";
import AdminSidebar from "../sidebar/admin_sidebar";
import AdminHeader from "../header/admin_header";
// import { InputGroup } from "react-bootstrap";
import { useLocation } from "react-router-dom";
// import { sectorQuestionService } from "../../_services/admin/global-controls/sectorQuestionService";
// import { topicService } from "../../_services/admin/global-controls/topicService";
// import { companyService } from "../../_services/admin/global-controls/companyService";
// import { frameworkService } from "../../_services/admin/global-controls/frameworkService";
// import axios from "axios";
import config from "../../config/config.json";
import swal from "sweetalert";
import { Col, Form, Row, Table } from "react-bootstrap";
// import { KpiService } from "../../_services/admin/global-controls/kpiService";
import { apiCall } from "../../_services/apiCall";
import Accordion from "react-bootstrap/Accordion";
import FormulaModal from "../../component/formula";
export const CreateSectorQuestion = (props) => {
  const { currentFrameworkData, currentTopicData, topicData } = props;
  const location = useLocation();
  // let history = useHistory ();
  
  const [inputFields, setInputFields] = useState([
    {
      question_type: "",
      questionHeading: "",
      question: "",
      question_detail: {},
      formula: "",
      graph_applicable: "NO",
    },
  ]);
  const [framework, setFramework] = useState(null);
  const [entity, setEntity] = useState("");
  const [openFormulaMOdal, setOpenFormulaMOdal] = useState(false);
  const [formulaData, setFormulaData] = useState(null);
  // const [readingValue, setReadingValue] = useState("");
  const [frameworks, setFrameworks] = useState("");
  const [topics, setTopics] = useState("");
  const [topic, setTopic] = useState("");
  const [kpis, setKpis] = useState([]);
  const [kpi, setKpi] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [currindex, setCurrIndex] = useState(0);
  const [rowData, setRow] = useState([]);
  const [columnData, setColumn] = useState([]);

  // const [selectedCompany,setSelectedCompany] = useState(0);
  // const [company,setCompany] = useState([]);
  // const [token] = useState(JSON.parse(localStorage.getItem('currentUser')));

  const callApi = async () => {
        // let response = await companyService.getCompany();
    // setCompany(response?.data);
    // let fresponse = await frameworkService.getFrameworks("QUESTION");
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}getFramework`,
      {},
      { type: "QUESTION" },
      "GET"
    );
    if (isSuccess) {
      setFrameworks(data?.data);
    }
    // setFrameworks(fresponse?.data);
  };

  // const handleEntityChange=async(e)=>{
  //   let val = e;
  //   setEntity(val);
  //   axios
  //     .get(`${config.ADMIN_API_URL}getFramework`, {
  //       // params: {
  //       //   type:"ADD_DATA_AVAILABLE",
  //       //   entity: val
  //       // },
  //       headers: {
  //         'Authorization': 'Bearer ' + token?.data?.token,
  //         'Content-Type': 'application/json'
  //       },
  //     })
  //     .then((response) => {
  //       setFrameworks(response?.data?.data);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }
  const handleFirstRowAndColumn = (event, index) => {
    const values = [...inputFields];
    values[index]["firstRowAndColumn"] = event.target.value;
    setInputFields(values);
  };
  const handleEnableGraph = (event, index) => {
    const values = [...inputFields];
    values[index]["graph_applicable"] = event.target.value;
    setInputFields(values);
  };
  const handleFormula = (event, index) => {
    const values = [...inputFields];
    values[index]["formula"] = event.target.value;
    setInputFields(values);
  };
  const handleChange = (event, index) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    if (event.target.name === "question_type") {
      if (event.target.value === "tabular_question") {
        values[index]["question_detail"] = {
          row: [""],
          column: [""],
        };
      } else if (event.target.value === "quantitative_trends") {
        values[index]["question_detail"] = {
          reading_value: [""],
        };
      } else if (event.target.value === "quantitative") {
        values[index]["question_detail"] = {
          mcqs: [""],
        };
      } else if (event.target.value === "") {
        values[index]["question_detail"] = {};
      } else {
        values[index]["question_detail"] = {};
      }
    }
    setInputFields(values);
  };

  // adds new input
  const handleAdd = () => {
    setCurrIndex(currindex + 1);
    setInputFields([
      ...inputFields,
      {
        question_type: "",
        questionHeading: "",
        question: "",
        question_detail: {},
        formula: "",
        graph_applicable: "NO",
      },
    ]);
  };

  // removes input
  const handleRemove = (index) => {
    if (inputFields.length !== 1) {
      const values = [...inputFields];
      values.splice(index, 1);
      setInputFields(values);
    }
  };

  const handleChangeFramework = (value) => {
    value = value.split("-");
    setFramework(value[0]);
    getTopics(value[0]);
  };


  const handleChangeTopic = (value) => {
    value = value.split("-");
    setTopic(value[0]);
    getKpisByTopicId(value[0], framework);
  };

  const handleChangeKpi = (value) => {
    setKpi(value);
  };

  async function getTopics(frameworkId) {
    // let response = await topicService.getTopicsAccordingToFramework("QUESTION",
    //   frameworkId
    // );
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}getTopic`,
      {},
      { type: "QUESTION", framework_id: frameworkId },
      "GET"
    );
    if (isSuccess) {
      setTopics(data?.data);
    }
    // console.log("topic",response)
    // setTopics(response?.data);
  }
  async function getKpisByTopicId(topic, framework) {
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}getKpi`,
      {},
      { type: "QUESTION", topic_id: topic, framework_id: framework },
      "GET"
    );
    if (isSuccess) {
      setKpis(data?.data);
    }
    // let response = await KpiService.getKpisByTopicId("QUESTION",topic);
    // setKpis(response?.data);
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    setOpenFormulaMOdal(false);
    let obj = {};
    obj.questions = inputFields;
    obj.framework_id = framework;
    obj.topic_id = topic;
    obj.kpi_id = kpi;
    obj.entity = entity;

    // obj.readingValue="l"
    // obj.company_id=selectedCompany;
    // console.log(inputFields,"inputFields");
    if (inputFields.length > 0) {
      for (let k = 0; k < inputFields.length; k++) {
        if (inputFields[k].question_type === "") {
          swal({
            icon: "error",
            title: "Failed",
            text: "Please select question type.",
            timer: 2000,
          });
          break;
        }
      }
    }
    const { isSuccess,data } = await apiCall(
      `${config.ADMIN_API_URL}createSectorQuestion`,
      {},
      obj,
      "POST"
    );
    if (isSuccess) {
      // window.location.href = config.baseURL + "/#/questions_framework_wise";
      setFormulaData(data?.data)
      setOpenFormulaMOdal(true);
    }
    // axios.post(`${config.ADMIN_API_URL}createSectorQuestion`,obj,{
    //   headers: {
    //     Authorization: `Bearer ${token?.data?.token}`,
    //     "Content-Type": "application/json",
    //   },
    // }).then((response) =>{
    //   // console.log(response);
    //   swal({
    //     icon: "success",
    //     title: "success",
    //     text: "Question Successfully created",
    //     timer: 1000,
    //   });
    //   // history.push("/questions_framework_wise");
    // }).catch((error) =>{
    //   console.log(error.response);
    //   swal({
    //     icon: "error",
    //     title: "Failed",
    //     text: error?.response?.data?.message,
    //     timer: 2000,
    //   });
    // })
    // // sectorQues tionService.createSectorQuestion(obj);
  };

  const addQuestionDetailLabel = (e, key, index) => {
    e.preventDefault();
    const values = [...inputFields];
    values[index]["question_detail"][key].push("");
    setInputFields(values);
  };

  const removeQuestionDetailLabel = (e, key, i, index) => {
    e.preventDefault();
    const values = [...inputFields];

    let row = values[index]["question_detail"][key];
    if (row.length < 2) {
      return false;
    }
    row.splice(i, 1);
    setInputFields(values);
  };

  const handleQuestionDetailLabelChange = (event, key, i, index) => {
    event.preventDefault();
    const values = [...inputFields];
    values[index]["question_detail"][key][i] = event.target.value;
    setInputFields(values);
    setColumn(inputFields[0]?.question_detail?.column);
    setRow(inputFields[0]?.question_detail?.row);
  };

  const handleCellChange = (event, rowIndex, columnIndex, index) => {
    const values = [...inputFields];
    const updatedData = tableData[index].map((row, i) =>
      i === rowIndex
        ? row.map((cell, j) => (j === columnIndex ? event.target.value : cell))
        : row
    );
    values[index]["formula"] = updatedData;
    const updatedTableData = [...tableData];
    updatedTableData[index] = updatedData;

    setTableData(updatedTableData);
    // setInputFields(values);
    // setTableData(updatedData);
  };
  useEffect(() => {
    const values = [...inputFields];
    let rowData = values[currindex]?.question_detail?.row;
    let columnData = values[currindex]?.question_detail?.column;
    const filledRowDataLength = rowData?.filter((item) => item !== "").length;
    const filledColumnDataLength = columnData?.filter(
      (item) => item !== ""
    ).length;
    const updatedTableData = [...tableData];
    updatedTableData[currindex] = Array(filledRowDataLength).fill(
      Array(filledColumnDataLength).fill("")
    );
    setTableData(updatedTableData);
    // setTableData(
    //   Array(filledRowDataLength).fill(
    //     Array(filledColumnDataLength).fill("")
    //   )
    // );
  }, [inputFields]);
  useEffect(() => {
    if(currentFrameworkData.kpi_id)
    {
   setKpi(currentFrameworkData.kpi_id)
    }
  }, [currentFrameworkData.kpi_id]);
  useEffect(() => {
    if(currentFrameworkData.topic_id)
    {
      setTopic(currentFrameworkData.topic_id);
      getKpisByTopicId(currentFrameworkData.topic_id, currentFrameworkData.id);
    }
  }, [currentFrameworkData.topic_id]);
  useEffect(() => {
    if(currentFrameworkData.id)
    {
      setFramework(currentFrameworkData.id);
      getTopics(currentFrameworkData.id);
    }
  }, [currentFrameworkData.id]);
  

  useEffect(() => {
    callApi();
    // handleEntityChange(entity);
  }, []);

  return (
    <div>
      <div className="saved_cards">
        <form
          name="form"
          onSubmit={(e) => handleSubmit(e)}
        >
          <div className="add_Question_section_panel">
            <Row>
              <Col md={6}>
                <div className="form-group mb-2">
                  <Form.Label htmlFor="industryType">
                    Select Framework*
                  </Form.Label>
                  <Form.Select
                    name="tab_name"
                    value={currentFrameworkData.id}
                    onChange={(e) =>
                      handleChangeFramework(
                        e.target.value
                      )
                    }
                    disabled
                    className="select_one form-control industrylist"
                    required
                  >
                    <option value="">
                      Select Framework
                    </option>
                    {frameworks &&
                      frameworks?.map((item, key) => (
                        <option
                          key={key}
                          value={`${item?.id}`}
                        >
                          {item?.title}
                        </option>
                      ))}
                  </Form.Select>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group mb-2">
                  <Form.Label htmlFor="industryType">
                    Select Topic*
                  </Form.Label>
                  {topics.length > 0 ? (
                    <Form.Select
                      name="tab_name"
                      value={currentFrameworkData.topic_id}
                      disabled
                      onChange={(e) =>
                        handleChangeTopic(e.target.value)
                      }
                      className="select_one form-control industrylist"
                      required={topics.length > 0}
                    >
                      <option value="">
                        Select Topic
                      </option>
                      {topics &&
                        topics?.map((item, key) => (
                          <option
                            key={key}
                            value={`${item?.id}`}
                          >
                            {item?.title}
                          </option>
                        ))}
                    </Form.Select>
                  ) : (
                    <select
                      name="tab_name"
                      onChange={(e) =>
                        handleChangeTopic(e.target.value)
                      }
                      className="select_one form-control industrylist"
                      required={topics.length > 0}
                      disabled
                    >
                      <option value="">
                        Select Topics
                      </option>
                    </select>
                  )}
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group mb-2">
                  <label htmlFor="industryType">
                    Select KPI*
                  </label>
                  {kpis.length > 0 ? (
                    <select
                      name="tab_name"
                      value={currentFrameworkData.kpi_id}
                      disabled
                      onChange={(e) =>
                        handleChangeKpi(e.target.value)
                      }
                      className="select_one form-control industrylist"
                      required={kpis.length > 0}
                    >
                      <option value="">Select KPI</option>

                      {kpis &&
                        kpis?.map((item, key) => (
                          <option
                            key={key}
                            value={item?.id}
                          >
                            {" "}
                            {item?.title}{" "}
                          </option>
                        ))}
                    </select>
                  ) : (
                    <select
                      name="tab_name"
                      onChange={(e) =>
                        handleChangeKpi(e.target.value)
                      }
                      className="select_one form-control industrylist"
                      required={kpis.length > 0}
                      disabled
                    >
                      <option value="">
                        Select Kpis
                      </option>
                    </select>
                  )}
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group mb-2">
                  <label htmlFor="industryType">
                    Select Entity*
                  </label>
                  <select
                    name="tab_name"
                    id=""
                    onChange={(e) =>
                      setEntity(e.target.value)
                    }
                    className="select_one form-control industrylist"
                    required
                  >
                    <option value="">
                      Select Entity
                    </option>
                    <option value="company">
                      Company
                    </option>
                    <option value="supplier">
                      Supplier
                    </option>
                  </select>
                </div>
              </Col>
              {/* <div className="col-lg-6 col-xs-6">
                <div className="form-group pb-3">
                  <label
                    htmlFor="industryType"
                    className="mb-2"
                  >
                    Select Question Type*
                  </label>
                  <Fragment>
                    <select
                      className="select_one form-control industrylist"
                      required
                      onChange={(e) =>
                        setQuestionType(e.target.value)
                      }
                    >
                      <option value="">
                        Select Question Type
                      </option>
                      <option value="qualitative">
                        Qualitative
                      </option>
                      <option value="yes_no" title="">
                        Yes/No
                      </option>
                      <option value="quantitative">
                        Quantitative
                      </option>
                      <option value="tabular_question">
                        Tabular Question
                      </option>
                      <option value="quantitativeTrends">
                        Quantitative Trands
                      </option>
                    </select>
                    {questionType ===
                      "quantitativeTrends" ? (
                      <div className="mt-2">
                        <p className="energy">
                          Reading value
                        </p>
                        <InputGroup className="mb-3">
                          <select
                            name="tab_name"
                            onChange={(e) =>
                              setReadingValue(
                                e.target.value
                              )
                            }
                            className="select_one form-control industrylist"
                            style={{ width: "30%" }}
                          >
                            <option value="">
                              {" "}
                              Select Value{" "}
                            </option>
                            <option value="l">l</option>
                            <option value="kWh">
                              {" "}
                              kWh{" "}
                            </option>
                            <option value="kg">kg</option>
                            <option value="mcf">
                              {" "}
                              Mcf (1000 cubic feet){" "}
                            </option>
                          </select>
                        </InputGroup>
                      </div>
                    ) : (
                      ""
                    )}
                    {questionType ===
                      "tabular_question" ? (
                      <>
                        <div className="mt-2">
                          <label
                            htmlFor="industryType"
                            className="mb-2"
                          >
                            Add rows
                          </label>
                          {row.length > 0 && row.map((currElement, index) => {
                            return <div className="d-flex">
                              <input type="text"
                                className="form-control  py-3 w-75"
                                value={currElement}
                                placeholder={`add row label ${index + 1}`}
                                onChange={(e) => handleRowLabelChange(e, index)}
                              />
                              <button className="page_save page_width" onClick={(e) => addRowLabel(e)}>Add</button>
                              <button className="page_save page_width" onClick={(e) => removeRowLabel(e, index)}>Remove</button>
                            </div>
                          })}
                        </div>
                        <div className="mt-2">
                          <label
                            htmlFor="industryType"
                            className="mb-2"
                          >
                            Add columns
                          </label>
                          {column.length > 0 && column.map((currElement, index) => {
                            return <div className="d-flex">
                              <input type="text"
                                className="form-control py-3"
                                value={currElement}
                                placeholder={`add column label ${index + 1}`}
                                onChange={(e) => handleColumnLabelChange(e, index)}
                              />
                              <button className="page_save page_width" onClick={(e) => addColumnLabel(e)}>Add</button>
                              <button className="page_save page_width" onClick={(e) => removeColumnLabel(e, index)}>Remove</button>
                            </div>
                          })}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </Fragment>
                </div>
              </div> */}
            </Row>
            {inputFields?.map((item, index) => (
              <>
                <div className="mb-2">
                  <label htmlFor="industryType">
                    {" "}
                    Select Question Type*{" "}
                  </label>
                  <select
                    className="select_one form-control industrylist"
                    name="question_type"
                    value={item.question_type}
                    onChange={(event) =>
                      handleChange(event, index)
                    }
                  >
                    <option>Select Question Type</option>
                    <option value="qualitative">
                      Qualitative
                    </option>
                    <option value="yes_no" title="">
                      Yes/No
                    </option>
                    <option value="quantitative">
                      Quantitative
                    </option>
                    <option value="tabular_question">
                      Tabular Question
                    </option>
                    <option value="quantitative_trends">
                      Quantitative Trends
                    </option>
                  </select>
                </div>
                {item?.question_type === "qualitative" ||
                item?.question_type === "yes_no" ||
                item?.question_type === "quantitative" ||
                item?.question_type ===
                  "tabular_question" ||
                item?.question_type ===
                  "quantitative_trends" ? (
                  <div className="mb-2">
                    <label htmlFor="title">
                      {" "}
                      Question Heading{" "}
                    </label>
                    <input
                      type="text"
                      name="questionHeading"
                      className="form-control py-3"
                      placeholder="Enter Question Heading or Leave This Options"
                      value={item.questionHeading}
                      onChange={(event) =>
                        handleChange(event, index)
                      }
                    />
                  </div>
                ) : (
                  ""
                )}
                {/* {(item?.question_type ===
                  "quantitative" ||
                  item?.question_type ===
                  "quantitative_trends") && (
                    // <FormulaModal question/>
                    // <div className="mb-2 ">
                    //   <label
                    //     htmlFor="title"
                    //     className="mb-2 mt-2"
                    //   > Formula </label>
                    //   <input
                    //     type="text"
                    //     name="questionHeading"
                    //     className="form-control py-3"
                    //     placeholder="Enter Formula"
                    //     value={item.formula}
                    //     onChange={(event) =>
                    //       handleFormula(event, index)
                    //     }
                    //   />
                    // </div>
                  )} */}

                {item.question_type ===
                  "tabular_question" && (
                  <div className="mb-2">
                    <label htmlFor="industryType">
                      Enable Graph*
                    </label>
                    <select
                      className="select_one form-control industrylist"
                      name="question_type"
                      value={item?.graph_applicable}
                      onChange={(event) =>
                        handleEnableGraph(event, index)
                      }
                    >
                      <option value="NO">NO</option>
                      <option value="YES">YES</option>
                    </select>
                  </div>
                )}
                {item?.question_type === "qualitative" ||
                item?.question_type === "yes_no" ||
                item?.question_type === "quantitative" ||
                item?.question_type ===
                  "tabular_question" ||
                item?.question_type ===
                  "quantitative_trends" ? (
                  <div className="mb-2">
                    <label htmlFor="question">
                      Sector Question*
                    </label>
                    <textarea
                      type="text"
                      name="question"
                      className="form-control"
                      placeholder="Write Sector Question title"
                      value={item.question}
                      onChange={(event) =>
                        handleChange(event, index)
                      }
                    />
                  </div>
                ) : (
                  ""
                )}
                {item.question_type ===
                "quantitative_trends" ? (
                  <>
                    <div className="mb-2">
                      <label htmlFor="industryType">
                        Reading Value Options
                      </label>
                      {item.question_detail
                        ?.reading_value &&
                        item.question_detail.reading_value.map(
                          (currElement, i) => {
                            return (
                              <div className="d-flex">
                                <input
                                  type="text"
                                  className="form-control py-3"
                                  value={currElement}
                                  placeholder={`Add Reading value label ${
                                    i + 1
                                  }`}
                                  onChange={(e) =>
                                    handleQuestionDetailLabelChange(
                                      e,
                                      "reading_value",
                                      i,
                                      index
                                    )
                                  }
                                />
                                {/* <button className="page_save page_width" onClick={(e) => addQuestionDetailLabel(e,'reading_value',index)}>Add</button>
                            <button className="page_save page_width" onClick={(e) => removeQuestionDetailLabel(e,'reading_value',i, index)}>Remove</button> */}
                              </div>
                            );
                          }
                        )}
                    </div>
                    {/* <div className="mt-2">
                      <p className="energy">
                        Reading value
                      </p>
                      <InputGroup className="mb-3">
                        <select
                          name="tab_name"
                          onChange={(e) =>
                            setReadingValue(
                              e.target.value
                            )
                          }
                          className="select_one form-control industrylist"
                          style={{ width: "30%" }}
                        >
                          <option value="">
                            {" "}
                            Select Value{" "}
                          </option>
                          <option value="l">l</option>
                          <option value="kWh">
                            {" "}
                            kWh{" "}
                          </option>
                          <option value="kg">kg</option>
                          <option value="mcf">
                            {" "}
                            Mcf (1000 cubic feet){" "}
                          </option>
                        </select>
                      </InputGroup>
                    </div> */}
                  </>
                ) : (
                  ""
                )}
                {/* {item.question_type ===
                    "quantitative" ? (
                      <>
                        <div className="mt-2">
                        <label
                          htmlFor="industryType"
                          className="mb-2"
                        >
                          Reading Value
                        </label>
                        {item.question_detail?.mcqs && item.question_detail.mcqs.map((currElement, i) => {
                          return <div className="d-flex">
                            <input type="text"
                              className="form-control  py-3 w-75"
                              value={currElement}
                              placeholder={`Add Reading value`}
                              onChange={(e) => handleQuestionDetailLabelChange(e,'mcqs', i,index)}
                            />
                            <button className="page_save page_width" onClick={(e) => addQuestionDetailLabel(e,'mcqs',index)}>Add</button>
                            <button className="page_save page_width" onClick={(e) => removeQuestionDetailLabel(e,'mcqs',i, index)}>Remove</button>
                          </div>
                        })}
                      </div>
                  
                    </>
                  ) : (
                    ""
                  )} */}
                {item.question_type ===
                "tabular_question" ? (
                  <>
                    <label htmlFor="industryType">
                      Add rows
                    </label>
                    {item.question_detail?.row &&
                      item.question_detail.row.map(
                        (currElement, i) => {
                          return (
                            <div
                              className="d-flex mb-2 justify-content-between"
                              style={{ gap: "20px" }}
                            >
                              <input
                                type="text"
                                className="form-control"
                                value={currElement}
                                placeholder={`add row label ${
                                  i + 1
                                }`}
                                onChange={(e) =>
                                  handleQuestionDetailLabelChange(
                                    e,
                                    "row",
                                    i,
                                    index
                                  )
                                }
                              />
                              <button
                                className="new_button_style-green"
                                style={{ width: "60px" }}
                                onClick={(e) =>
                                  addQuestionDetailLabel(
                                    e,
                                    "row",
                                    index
                                  )
                                }
                              >
                                <i
                                  className="fas fa-plus-circle"
                                  style={{
                                    fontSize: "20px",
                                  }}
                                ></i>
                              </button>
                              <button
                                className="new_button_style-red"
                                style={{ width: "60px" }}
                                onClick={(e) =>
                                  removeQuestionDetailLabel(
                                    e,
                                    "row",
                                    i,
                                    index
                                  )
                                }
                              >
                                <i
                                  className="fas fa-trash-alt"
                                  style={{
                                    fontSize: "17px",
                                  }}
                                ></i>
                              </button>
                            </div>
                          );
                        }
                      )}
                    <label
                      htmlFor="industryType"
                      className="mb-2"
                    >
                      Add columns
                    </label>
                    {item.question_detail?.column &&
                      item.question_detail?.column.map(
                        (currElement, i) => {
                          return (
                            <div
                              className="d-flex mb-2 justify-content-between"
                              style={{ gap: "20px" }}
                            >
                              <input
                                type="text"
                                className="form-control"
                                value={currElement}
                                placeholder={`add column label ${
                                  index + 1
                                }`}
                                onChange={(e) =>
                                  handleQuestionDetailLabelChange(
                                    e,
                                    "column",
                                    i,
                                    index
                                  )
                                }
                              />
                              <button
                                className="new_button_style-green"
                                style={{ width: "60px" }}
                                onClick={(e) =>
                                  addQuestionDetailLabel(
                                    e,
                                    "column",
                                    index
                                  )
                                }
                              >
                                <i
                                  className="fas fa-plus-circle"
                                  style={{
                                    fontSize: "20px",
                                  }}
                                ></i>
                              </button>
                              <button
                                className="new_button_style-red"
                                style={{ width: "60px" }}
                                onClick={(e) =>
                                  removeQuestionDetailLabel(
                                    e,
                                    "column",
                                    i,
                                    index
                                  )
                                }
                              >
                                <i
                                  className="fas fa-trash-alt"
                                  style={{
                                    fontSize: "17px",
                                  }}
                                ></i>
                              </button>
                            </div>
                          );
                        }
                      )}
                    <div className="mb-2">
                      <label htmlFor="title">
                        Add first row and first column
                      </label>
                      <input
                        type="text"
                        name="questionHeading"
                        className="form-control py-3"
                        placeholder="Enter Name of first row and first column"
                        value={item?.firstRowAndColumn}
                        onChange={(event) =>
                          handleFirstRowAndColumn(
                            event,
                            index
                          )
                        }
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
                {/* {item.question_type ===
                  "tabular_question" && (
                    <div className="mb-2">
                      <label
                        htmlFor="industryType"
                        className="my-2"
                      >
                        Add formula
                      </label>
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            Add Formula
                          </Accordion.Header>
                          <Accordion.Body>
                            <div>
                              <Table striped bordered hover>
                                <thead>
                                  <tr>
                                    <th>{item?.firstRowAndColumn || "#"}</th>
                                    {columnData?.map(
                                      (item, index) => (
                                        <th key={index}>{item}</th>
                                      )
                                    )}
                                  </tr>
                                </thead>
                                <tbody>
                                  {tableData[index].map(
                                    (row, rowIndex) => (
                                      <tr key={rowIndex}>
                                        {rowData?.map(
                                          (item, index) => (
                                            rowIndex === index ?
                                              <td key={index}>{item}</td>
                                              : ""
                                          )
                                        )}
                                        {row.map(
                                          (
                                            cell,
                                            columnIndex
                                          ) => (
                                            <td
                                              key={
                                                columnIndex
                                              }
                                            >
                                              <input
                                                type="text"
                                                className="input-for-formula"
                                                value={cell}
                                                onChange={(
                                                  event
                                                ) =>
                                                  handleCellChange(
                                                    event,
                                                    rowIndex,
                                                    columnIndex,
                                                    index
                                                  )
                                                }
                                              />
                                            </td>
                                          )
                                        )}
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  )} */}
                {inputFields.length >= 2 ? (
                  <div>
                    <button
                      type="button"
                      className="remove new_button_style-red mb-2 "
                      onClick={(e) => handleRemove(index)}
                    >
                      <i className="fas fa-trash-alt"></i>{" "}
                      Delete
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </>
            ))}
            {/* {inputFields?.map((item, index) => (
              <div className="form-inline" key={index}>
                <label
                  htmlFor="title"
                  className="mb-2 mt-2"
                >
                  {" "}
                  Question Heading{" "}
                </label>
                <input
                  type="text"
                  name="questionHeading"
                  className="form-control py-3"
                  placeholder="Enter Question Heading or Leave This Options"
                  value={item.questionHeading}
                  onChange={(event) =>
                    handleChange(event, index)
                  }
                />
                <label
                  htmlFor="question"
                  className="mb-2 mt-2"
                >
                  {" "}
                  Sector Question*{" "}
                </label>
                <textarea
                  type="text"
                  name="question"
                  className="form-control"
                  placeholder="Write Sector Question title"
                  value={item.question}
                  onChange={(event) =>
                    handleChange(event, index)
                  }
                />
                <button
                  type="button"
                  className="remove page_width page_save mt-2"
                  onClick={(e) => handleRemove(index)}
                >
                  Remove
                </button>
              </div>
            ))} */}
          </div>
          <div
            className="d-flex"
            style={{ justifyContent: "space-between" }}
          >
            <div className="global_link mx-0">
              <button
                type="button"
                className="link_bal_next page_width white"
                onClick={(e) => handleAdd()}
              >
                ADD More
              </button>
            </div>
            <div className="global_link mx-0">
              <button
                type="submit"
                className="link_bal_next page_width white"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="d-none">
        {openFormulaMOdal && (
          <FormulaModal openFormulaMOdal={openFormulaMOdal} formulaData={formulaData}/>
        )}
      </div>
    </div>
  );
};
