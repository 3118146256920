import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AdminSidebar from '../../sidebar/admin_sidebar';
import AdminHeader from '../../header/admin_header';


export const SendInvite = () => {
  const [role, setRole] = useState('');
  const [email, setEmail] = useState('');
  const history = useHistory();

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('sdfghj', { role, email });
    history.push('/invite_user');
  };

  return (
    <div>
      <AdminSidebar />
      <AdminHeader />
      <div className="main_wrapper">
        <div className="inner_wraapper">
          <div className="container-fluid">
            <section className="d_text">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="col-sm-12">
                      <div className="color_div_on framwork_2">
                        <div className="business_detail">
                          <div className="">
                            <div className="saved_cards">
                              <form name="form" onSubmit={handleSubmit}>
                                <div className="row">
                                  <div className="form-group col-6">
                                    <label htmlFor="industryType" className="mb-2">
                                      {' '}
                                      Role{' '}
                                    </label>
                                    <select
                                      value={role}
                                      onChange={(event) =>
                                        setRole(event.target.value)
                                      }
                                      className="select_one industrylist"
                                    >
                                      <option key="company" value="company">
                                        Company
                                      </option>
                                      <option key="supplier" value="supplier">
                                        Supplier
                                      </option>
                                      <option
                                        key="group-admin"
                                        value="group-admin"
                                      >
                                        Group-Admin
                                      </option>
                                      <option
                                        key="super-admin"
                                        value="super-admin"
                                      >
                                        Super-Admin
                                      </option>
                                    </select>
                                  </div>
                                  <div className="form-group col-6">
                                    <label className="mb-2"> Email </label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder="email"
                                      value={email}
                                      onChange={(event) =>
                                        setEmail(event.target.value)
                                      }
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="global_link mx-0 my-3">
                                  <button
                                    type="submit"
                                    className="page_width page_save"
                                  >
                                    INVITE SEND
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};
