import React, { Component } from "react";
import AdminSidebar from "../sidebar/admin_sidebar";
import AdminHeader from "../header/admin_header";
import config from "../../config/config.json";
import axios from "axios";
import { sweetAlert } from "../../utils/UniversalFunction";
import "./control.css";
import { authenticationService } from "../../_services/authentication";
const currentUser = authenticationService.currentUserValue;

export default class AddGlobalInnovativeProgram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: false,
      items: [],
      innovativeProgram: [],
      loading: false,
      uuid: "",
      title: "",
      description: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    const { title, description } = this.state;
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    axios
      .post(
        config.API_URL + "createInnovativeProgrammes",
        {
          title: title,
          uuid: "null",
          description: description,
        },
        { headers }
      )
      .then((response) => {
        sweetAlert("success", response.data.message);
        setTimeout(() => {
          this.props.history.push(`/innovative_programmes`);
        }, 1000);
      })
      .catch(function (error) {
        if (error.response) {
          sweetAlert("error", error.response.data.message);
        }
      });
  }

  componentDidMount() {
    
  }

  render() {
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="col-sm-12">
                        <div className="color_div_on framwork_2">
                          <div className="business_detail">
                            <div className="">
                              <div className="saved_cards">
                                <form name="form" onSubmit={this.handleSubmit}>
                                  <div className="business_detail">
                                    <div className="heading">
                                      <h4>Add Innovative Program</h4>
                                    </div>
                                    <hr className="line"></hr>

                                    <div className="row">
                                      <div className="col-lg-12 col-xs-12">
                                        <div className="form-group mb-4">
                                          <label htmlFor="title">
                                            Innovative Program Title*
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control py-3"
                                            id="title"
                                            placeholder="Enter Innovative Program Title (Max 500 Words)"
                                            name="title"
                                            onChange={this.handleChange}
                                          />
                                        </div>
                                      </div>
                                      <div className="form-group mb-4">
                                        <label htmlFor="question">
                                          Innovative Program Description*
                                        </label>
                                        <textarea
                                          type="text"
                                          className="form-control"
                                          id="description"
                                          placeholder="Write Innovative Program Description (Max 5000 Words)"
                                          name="description"
                                          onChange={this.handleChange}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="global_link mx-0 my-3">
                                    <button
                                      type="submit"
                                      className="page_width page_save"
                                    >
                                      ADD NOW
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
