import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
export default class boardSkillPage extends Component {
  render() {
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="governance">
                        <div className="text_Parts">
                          <div className="text_governance">
                            <h5 className="Supplier_Manage">Introduction</h5>
                            <p className="suppliers_gover">
                              It is good governance for a company to create a
                              skills matrix in relation to its board of
                              directors. A skills matrix identifies the skills,
                              knowledge, experience and capabilities desired of
                              a board to enable it to meet both the current and
                              future challenges of the entity. The creation of a
                              board skills matrix is an opportunity for
                              considered reflection and productive discussion on
                              how the board of directors is constituted
                              currently and also how it believes it should best
                              be constituted in the future to align with the
                              strategic objectives of the entity.
                            </p>
                          </div>
                          <div className="bord_enb">
                            <h4 className="exis_skills">
                              Identifying the existing skills on the board
                            </h4>
                          </div>
                          <div className="Assessment_opm">
                            <div className="Assessment_ones">
                              <h4 className="ident">
                                A skills matrix identifies:
                              </h4>
                              <p className="experience_and">
                                <span className="policy_icon">
                                  <i className="fal fa-chevron-right"></i>
                                </span>
                                The current skills, knowledge, experience and
                                capabilities of the board, and{" "}
                              </p>
                              <p className="experience_and">
                                <span className="policy_icon">
                                  <i className="fal fa-chevron-right"></i>
                                </span>
                                Any gaps in skills or competencies that can be
                                addressed in future director appointments.{" "}
                              </p>
                              <p className="experience_and">
                                A company needs to consider possible approaches
                                it could take to identifying the existing skills
                                and competencies on the board.
                              </p>
                            </div>
                          </div>
                          <div className="Assessment_two">
                            <div className="Assessment_ones">
                              <h4 className="ssess_h">
                                These may include the following:
                              </h4>
                              <p className="experience_and">
                                <span className="policy_icon">
                                  <i className="fal fa-chevron-right"></i>
                                </span>
                                The chair, with the assistance of the company
                                secretary, may review the competencies and
                                experience of each board member, with the chair
                                making an initial assessment of the existing
                                skills for review by the board.{" "}
                              </p>
                              <p className="experience_and">
                                <span className="policy_icon">
                                  <i className="fal fa-chevron-right"></i>
                                </span>
                                Each director may be supplied with a
                                questionnaire asking them to self-assess and
                                identify their competencies, skills and
                                experience.
                              </p>
                              <p className="experience_and">
                                <span className="policy_icon">
                                  <i className="fal fa-chevron-right"></i>
                                </span>
                                The nomination committee may review and assess
                                the competencies and skills of each board
                                member, either following completion of a
                                questionnaire by each director or by some other
                                method. The nomination committee could be
                                charged with ongoing oversight of the process of
                                board composition and renewal.
                              </p>
                            </div>
                          </div>

                          <div className="global_link m-0">
                            <NavLink
                              to="/admin/boardSkillDetail"
                              className="page_save page_width"
                            >
                              next
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
