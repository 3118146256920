import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import "../../../Sector_Question_Manage/control.css";
import { apiCall } from "../../../../_services/apiCall";
import config from "../../../../config/config.json";
import { authenticationService } from "../../../../_services/authentication";
import { Dropdown, Modal } from "react-bootstrap";

export const Topics = (props) => {
  const {
    frameworkData,
    financial_year_id,
    handleKPIChangeHandler,
    handleAddTopicQuestionChangeHandler,
  } = props;
  const [topics, setTopics] = useState([]);
  const [menulist, setMenuList] = useState([]);
  const [upadteTopic, setUpadteTopic] = useState(false);
  const [addTopic, setAddTopic] = useState(false);
  const closeUpdateFrameworkPopup = () => setUpadteTopic(false);
  const showAddTopicPopup = () => setAddTopic(true);
  const showUpdateTopicPopup = () => setUpadteTopic(true);
  const closeAddTopicPopup = () => setAddTopic(false);
  const [title, setTitle] = useState("");
  const [topicType, setTopicType] = useState("Mandatory");

  const [framework, setFramework] = useState("");
  const [frameworks, setFrameworks] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    let obj = {};
    obj.title = title;
    obj.framework_id = frameworkData?.id;
    obj.is_mendatory = topicType === "Mandatory" ? "YES" : "NO";
    const { isSuccess } = await apiCall(
      `${config.ADMIN_API_URL}createTopic`,
      {},
      obj,
      "POST"
    );
    if (isSuccess) {
      setTitle("");
      callApi(props.frameworkData.id);
      closeAddTopicPopup();
    }
  };

  const callApi = async (id) => {
    if(frameworkData?.id){
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}getTopic`,
      {},
      { type: "CUSTOM", framework_id: frameworkData?.id },
      "GET"
    );
    setTopics(data?.data?.reverse());
    }
  };

  const deleteTopic = async (e, item) => {
    let obj = {};
    obj.framework_id = item?.framework_id;
    obj.id = item?.id;
    obj.framework_topic_id = item?.framework_topic_id;
    await apiCall(`${config.ADMIN_API_URL}deleteTopic`, {}, obj, "POST");
    callApi();
  };

  const handleClickKPIComponent = (KPIComponentData, activetab) => {
    handleKPIChangeHandler(KPIComponentData, financial_year_id, frameworkData, activetab);
  };

  const handleClickAddTopicQuestionComponent = (addQuestionCopmonentData) => {
    handleAddTopicQuestionChangeHandler(
      addQuestionCopmonentData,
      frameworkData
    );
  };

  useEffect(() => {
    const currentUser = authenticationService?.currentUserSubject?.getValue();
    const settingsMenu = currentUser?.data?.menu?.find(
      (item) => item?.url === "global_controls"
    );
    setMenuList(settingsMenu?.permissions);
    if (props.frameworkData.id) {
      callApi(props.frameworkData.id);
    }
  }, [props.frameworkData.id]);

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <div className="heading">
          <h4 className="m-0">{frameworkData.title}</h4>
          <span className="global_link mx-0">
            {menulist?.includes("TOPIC_CREATE") && (
              <>
                <div
                  onClick={showAddTopicPopup}
                  className="mx-3 add_framework_control"
                >
                  <i className="fas fa-plus-square fs-lg" title="Add Topic"></i>
                  &nbsp;&nbsp;
                </div>
                {/* <NavLink className="non_underline_link bold" to="/topic/create">
                  <div className="mx-3 add_framework_control" to="/topic/create">
                    <i className="fas fa-plus-square fs-lg" title="Add Topic"></i>&nbsp;&nbsp;
                  </div>
                </NavLink> */}
              </>
            )}
          </span>
        </div>
        <div>
          <input
            type="search"
            className="form-control w-100"
            placeholder="Search Topics"
            name="search"
          />
        </div>
      </div>
      <div className="table_f border-top mt-2 auto_scroll_by_design">
        <Table striped bordered className="mt-2 mb-0">
          <thead>
            <tr className="heading_color sticky_header">
              <th style={{ width: 50 }}>Sr.</th>
              <th>Topic</th>
              <th style={{ width: 50 }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {topics?.map((item, key) => (
              <tr key={key}>
                <td>{key + 1}</td>
                <td>{item.title}</td>
                <td className="text-center">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic"></Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={(e) =>
                          handleClickAddTopicQuestionComponent(item)
                        }
                      >
                        <i className="fas fa-plus-square"></i>&nbsp;&nbsp; Add
                        Question
                      </Dropdown.Item>
                      <Dropdown.Item onClick={(e) => handleClickKPIComponent(item, "questionList")}>
                        <i className="fas fa-eye"></i>&nbsp;&nbsp; View Question
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => handleClickKPIComponent(item, "kpi")}
                      >
                        <i className="fas fa-eye"></i>&nbsp;&nbsp; View Kpi
                      </Dropdown.Item>
                      {item?.is_editable ? (
                        <>
                          {menulist?.includes("TOPIC_UPDATE") && (
                            <Dropdown.Item onClick={showUpdateTopicPopup}>
                              <i className="far fa-edit"></i>&nbsp;&nbsp; Edit
                              Topic
                            </Dropdown.Item>
                          )}
                          {menulist?.includes("TOPIC_DELETE") && (
                            <Dropdown.Item
                              onClick={(e) => deleteTopic(e, item)}
                            >
                              <span style={{ color: "red" }}>
                                <i className="fas fa-trash"></i>&nbsp;&nbsp;
                                Delete Topic
                              </span>
                            </Dropdown.Item>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {/* ------------ Update Topic --------- */}
      <Modal show={upadteTopic} onHide={closeUpdateFrameworkPopup}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Topic</Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <button
            className="link_bal_next_cancel"
            onClick={closeUpdateFrameworkPopup}
          >
            Cancel
          </button>
          <button className="link_bal_next" type="submit">
            Update
          </button>
        </Modal.Footer>
      </Modal>

      {/* ------------ Add Topic --------- */}
      <Modal show={addTopic} onHide={closeAddTopicPopup}>
        <Modal.Header closeButton>
          <Modal.Title>Add Topic</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="question" className="mb-2">
              Topic Heading
            </label>
            <input
              type="text"
              className="form-control"
              id="title"
              placeholder="Enter Question Heading or Leave This Options"
              name="heading"
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="questionHeading" className="mb-2">
              Topic Type
            </label>
            <select
              className="form-control input-height"
              id="exampleInputPassword1"
              name="topic_type"
              onChange={(e) => setTopicType(e.target.value)}
              required
            >
              <option value="" disabled>
                Please Select Topic Type
              </option>
              <option value="Mandatory">Mandatory</option>
              <option value="Voluntary">Voluntary</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="link_bal_next_cancel" onClick={closeAddTopicPopup}>
            Cancel
          </button>
          <button
            className="link_bal_next"
            type="submit"
            onClick={handleSubmit}
          >
            Add
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
