import React from "react";
import { Nav, Tab } from "react-bootstrap";
import DemoBar from "./demobar";
// eslint-disable-next-line no-unused-vars
import FormBuilder from "./src/index";
import * as variables from "./variables";

// Add our stylesheets for the demo.
require("./scss/application.scss");

const url = "/api/formdata";
const saveUrl = "/api/formdata";

// const TestComponent = () => <h2>Hello</h2>;

// const MyInput = React.forwardRef((props, ref) => {
//   const { name, defaultValue, disabled } = props;
//   return (
//     <>
//       <label style={{ marginRight: '1rem' }}><b>{ props.data.label }</b></label>
//       <input ref={ref} name={name} defaultValue={defaultValue} disabled={disabled} />;
//     </>
//   );
// });

// Registry.register('MyInput', MyInput);
// Registry.register('TestComponent', TestComponent);

// const items = [{
//     key: 'Header',
//   }, {
//     key: 'TextInput',
//   }, {
//     key: 'TextArea',
//   }, {
//     key: 'RadioButtons',
//   }, {
//     key: 'Checkboxes',
//   }, {
//     key: 'Image',
//   },
//   {
//     group_name: 'Multi Column Row',
//     key: 'TwoColumnRow'
//   },
//   {
//     group_name: 'Multi Column Row',
//     key: 'ThreeColumnRow'
//   },
//   {
//     group_name: 'Multi Column Row',
//     key: 'FourColumnRow',
//     element: 'MultiColumnRow',
//   },
//   {
//     group_name: 'Multi Column Row',
//     key: 'FiveColumnRow',
//     element: 'MultiColumnRow',
//   },
//   {
//     group_name: 'Multi Column Row',
//     key: 'SixColumnRow',
//     element: 'MultiColumnRow',
//   },
//   {
//     group_name: 'Custom Element',
//     key: 'TestComponent',
//     element: 'CustomElement',
//     component: TestComponent,
//     type: 'custom',
//     field_name: 'test_component',
//     name: 'Something You Want',
//     icon: 'fa fa-cog',
//     static: true,
//     props: { test: 'test_comp' },
//     label: 'Label Test',
//   },
//   {
//     group_name: 'Custom Element',
//     key: 'MyInput',
//     element: 'CustomElement',
//     component: MyInput,
//     type: 'custom',
//     forwardRef: true,
//     bare: true,
//     field_name: 'my_input_',
//     name: 'My Input',
//     icon: 'fa fa-cog',
//     props: { test: 'test_input' },
//     label: 'Label Input',
//   },
// ];

const FormBuilders = () => {
  return (
    <>
      <Tab.Container id="left-tabs-example" defaultActiveKey="link-1">
        <Nav variant="tabs" defaultActiveKey="/home" className="builderTabs">
          <Nav.Item>
            <Nav.Link eventKey="link-1" className="builderTabItems">
              Editor
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link eventKey="link-2" className="builderTabItems">
              CRF View
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content>
          <Tab.Pane eventKey="link-1">
            <FormBuilder.ReactFormBuilder
              // variables={variables}
              url={url}
              saveUrl={saveUrl}
              locale="en"
              // saveAlways={false}
              saveAlways={true}
              // toolbarItems={items}
            />
          </Tab.Pane>

          <Tab.Pane eventKey="link-2">
            <DemoBar variables={variables} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default FormBuilders;
