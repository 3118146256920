import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Image } from "react-bootstrap";
import AdminSidebar from "../../../sidebar/admin_sidebar";
import AdminHeader from "../../../header/admin_header";
import "../../../Company Sub Admin/Pages/management/management.css";
import { Form } from "react-bootstrap";
import Clip from "../../../../img/clip.svg";
import Eye from "../../../../img/eye.svg";
import "../../../sidebar/common.css";

export default class CompanyAudit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uuid: "",
      uri: "",
    };
  }
  componentDidMount() {
    let urlArr = window.location.pathname.split("/");
    this.setState({
      uuid: urlArr[urlArr.length - 2],
      uri: urlArr[urlArr.length - 1],
    });
  }
  render() {
    const { uuid } = this.state;
    return (
      <div>
        <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
        <div
            className={
              localStorage.getItem("role") === "company_admin"
                ? "tabs-tops my_profile_menus audit_tabs"
                : "tabs-tops my_profile_menus setting_tab_d audit_tabs"
            }
          >
            <ul>
              <li>
                <NavLink to={`/companies/${uuid}/company_audit`} className="activee">Company</NavLink>
              </li>
              <li>
                <NavLink to={`/companies/${uuid}/supplier_listing`}>
                  {" "}
                  Supplier
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="col-sm-12">
                        <div className="color_div_on framwork_2">
                          <div className="business_detail">
                            <div className="heading">
                              <h4 className="Environmental_text font-heading">
                                Company Audit
                              </h4>
                            </div>
                          </div>
                          <hr></hr>
                          <div className="col-md-12">
                          <Form>
                            <div className="heading_h3 mt-3">
                              <span className="gement">
                                Customer Privacy &amp; Technology Standards
                              </span>
                            </div>
                            <Form.Group
                              className="mb-3 form_audit_d"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="energy mb-3 font-increase">
                                Q1. What is the company's strategy to secure
                                customer's personal health information records
                                and other personally identifiable information?
                              </Form.Label>
                              <div className="attachment_with_icons">
                                <span>
                                  <Image className="img-fluid pdf" src={Clip} />
                                </span>
                                <span className="icon_with_function">
                                  <NavLink to="#">
                                    <Image className="img-fluid" src={Eye} />
                                  </NavLink>
                                </span>
                              </div>
                            </Form.Group>
                            <Form.Group
                              className="mb-3 form_audit_d"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="energy mb-3 font-increase">
                                Q2. What is the company's strategy to secure
                                customer's personal health information records
                                and other personally identifiable information?
                              </Form.Label>
                              <div className="attachment_with_icons">
                                <span>
                                  <Image className="img-fluid pdf" src={Clip} />
                                </span>
                                <span className="icon_with_function">
                                  <NavLink to="#">
                                    <Image className="img-fluid" src={Eye} />
                                  </NavLink>
                                </span>
                              </div>
                            </Form.Group>
                            <Form.Group
                              className="mb-3 form_audit_d"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="energy mb-3 font-increase">
                                Q3. What is the company's strategy to secure
                                customer's personal health information records
                                and other personally identifiable information?
                              </Form.Label>
                              <div className="attachment_with_icons">
                                <span>
                                  <Image className="img-fluid pdf" src={Clip} />
                                </span>
                                <span className="icon_with_function">
                                  <NavLink to="#">
                                    <Image className="img-fluid" src={Eye} />
                                  </NavLink>
                                </span>
                              </div>
                            </Form.Group>
                            <Form.Group
                              className="mb-3 form_audit_d"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="energy mb-3 font-increase">
                                Q4. What is the company's strategy to secure
                                customer's personal health information records
                                and other personally identifiable information?
                              </Form.Label>
                              <div className="attachment_with_icons">
                                <span>
                                  <Image className="img-fluid pdf" src={Clip} />
                                </span>
                                <span className="icon_with_function">
                                  <NavLink to="#">
                                    <Image className="img-fluid" src={Eye} />
                                  </NavLink>
                                </span>
                              </div>
                            </Form.Group>
                            <Form.Group
                              className="mb-3 form_audit_d"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="energy mb-3 font-increase">
                                Q5. What is the company's strategy to secure
                                customer's personal health information records
                                and other personally identifiable information?
                              </Form.Label>
                              <div className="attachment_with_icons">
                                <span>
                                  <Image className="img-fluid pdf" src={Clip} />
                                </span>
                                <span className="icon_with_function">
                                  <NavLink to="#">
                                    <Image className="img-fluid" src={Eye} />
                                  </NavLink>
                                </span>
                              </div>
                            </Form.Group>
                            <Form.Group
                              className="mb-3 form_audit_d"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="energy mb-3 font-increase">
                                Q6. What is the company's strategy to secure
                                customer's personal health information records
                                and other personally identifiable information?
                              </Form.Label>
                              <div className="attachment_with_icons">
                                <span>
                                  <Image className="img-fluid pdf" src={Clip} />
                                </span>
                                <span className="icon_with_function">
                                  <NavLink to="#">
                                    <Image className="img-fluid" src={Eye} />
                                  </NavLink>
                                </span>
                              </div>
                            </Form.Group>
                          </Form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}
