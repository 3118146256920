import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "./billing.css";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import config from "../../../config/config.json";
import Table from "react-bootstrap/Table";
import { apiCall } from "../../../_services/apiCall";
import { PermissionContext } from "../../../context/PermissionContext";
import { authenticationService } from "../../../_services/authentication";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import countriesData from "../Setting/FinactialYearTab/countries.json";
import yearsData from "../Setting/FinactialYearTab/year.json";
import slashYearsData from "../Setting/FinactialYearTab/slash-year.json";
import NoPlanHere from "../../../img/no-results.png";
import Loader from "../../loader/Loader";
export default class BillingNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: false,
      loder: false,
      isVisible: true,
      activeModal: "",
      items: [],
      plan_user: "",
      title: "",
      price: "",
      tier: "",
      NO_USERS: "",
      NO_OF_SUB_ADMINS: "",
      NO_OF_DATA_ADMINS: "",
      NO_OF_AUDITOR: "",
      NO_OF_SUBADMIN_AUDITOR: "",
      NO_FRAMEWORK: "",
      MAPPED_FRAMEWORK: [],
      MAPPED_MENUS: [],
      financial_year: "",
      country_code: "",
      values: [{ value: null }],
      selectedPackage: [],
      selectedItems1: [],
      selectedItems2: [],
      menuList: [],
      loading: false,
      showdropdown1: false,
      showdropdown2: false,
      SubscriptionData: [],
      subscriptionId: "",
      plan_type: "company",
      renewable_type: "",
      // renewable_type: "YEARLY",
      is_invitable: "0",
      NO_SUPPLIER: "",
      ALLOW_GLOBAL_FRAMEWORK: "",
      data: [],
      role: " ",
      financialYear: "",
      year: "",
      show: false,
      email: "",
      discount: "",
      companyName: "",
      frameworkTitle: [],
      menuTitle: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleChange3 = this.fetchSubscriptionAttributes.bind(this);

    this.handleInviteCompanyByBroker =
      this.handleInviteCompanyByBroker.bind(this);
  }

  fetchSubscriptionAttributes = async (event) => {
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}fetchSubscriptionAttributes`,
      {},
      { plan_user: "company" },
      "GET"
    );
    if (isSuccess) {
      console.log("new Method", data);
      this.setState({ data: data?.data });
    }
  };

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (value !== prevProps.value) {
      this.fetchSubscriptionAttributes();
    }
  }

  handleChange2(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleChange(event) {
    const { checked } = event.target;
    let id = event.target.getAttribute("data-id");
    if (checked) {
      this.setState({
        selectedPackage: [...this.state.selectedPackage, id],
      });
    } else {
      let tempuser = this.state.selectedPackage?.filter(
        (item) => Number(item) !== Number(id)
      );
      this.setState({
        selectedPackage: tempuser,
      });
    }
    let obj = {};
    obj._id = id;
    obj.status = checked;
  }
  handleCountryChange(event) {
    const selectedCountryCode = event.target.value;
    const selectedCountry = countriesData.find(
      (country) => country.isoCode === selectedCountryCode
    );

    if (selectedCountry) {
      this.setState({
        country_code: selectedCountryCode,
        financialYear: selectedCountry.financialYear,
      });
    } else {
      this.setState({
        country_code: "",
        financialYear: "",
      });
    }
  }
  handleYearChange(event) {
    const tmpYear = event.target.value;

    if (tmpYear) {
      this.setState({
        year: tmpYear,
      });
    } else {
      this.setState({
        year: "",
      });
    }
  }
  handleFrameworkChange(event, attribute_list) {
    if (event.target.checked) {
      const allItems1 = attribute_list.map((item) => item.id);
      const allItemsTitle = attribute_list.map((item) => item.title);
      this.setState({
        selectedItems1: [...allItems1],
        frameworkTitle: [...allItemsTitle],
      });
    } else {
      this.setState({
        selectedItems1: [],
        frameworkTitle: [],
      });
    }
  }
  handleFrameworkPreviousChange(event, itemID, title) {
    if (event.target.checked) {
      this.setState((prevState) => ({
        selectedItems1: [...prevState.selectedItems1, itemID],
        frameworkTitle: [...prevState.frameworkTitle, title],
      }));
    } else {
      this.setState((prevState) => ({
        selectedItems1: prevState.selectedItems1.filter((id) => id !== itemID),
        frameworkTitle: prevState.frameworkTitle.filter(
          (item) => !item.toLowerCase().includes(title.toLowerCase())
        ),
      }));
    }
  }
  handleMenuChange(event, attribute_list) {
    if (event.target.checked) {
      const allItems2 = attribute_list.map((item) => item.id);
      const allmenuItemsTitle = attribute_list.map((item) => item.title);
      this.setState({
        selectedItems2: [...allItems2],
        menuTitle: [...allmenuItemsTitle],
      });
    } else {
      this.setState({
        selectedItems2: [],
        menuTitle: [],
      });
    }
  }
  handleMenuPreviousChange(event, itemID, title) {
    if (event.target.checked) {
      this.setState((prevState) => ({
        selectedItems2: [...prevState.selectedItems2, itemID],
        menuTitle: [...prevState.menuTitle, title],
      }));
    } else {
      this.setState((prevState) => ({
        selectedItems2: prevState.selectedItems2.filter((id) => id !== itemID),
        menuTitle: prevState.menuTitle.filter(
          (item) => !item.toLowerCase().includes(title.toLowerCase())
        ),
        // menuTitle: prevState.menuTitle.filter((item) => !item.toLowerCase().includes(title.toLowerCase())),
      }));
    }
  }
  fetchgetAllSubscription = async () => {
    this.setState({ loder: true });
    const getLocalData = JSON.parse(localStorage.getItem("currentUser"));
    const getRole = getLocalData !== null ? getLocalData.data.role : "";
    // console.log("fetchgetAllSubscription");
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}getAllSubscription`,
      {},
      { subscription_type: "All" },
      "GET"
    );
    this.setState({ loder: false });
    if (isSuccess) {
      this.setState({ SubscriptionData: data?.data });
    }
  };
  // async getAllCountries() {
  //   const { isSuccess, data } = await apiCall(
  //     `${config.API_URL}getAllCountries`,
  //     {},
  //     {},
  //     "GET"
  //   );
  //   if (isSuccess) {
  //     console.log("apiCall Data", data);
  //     this.setState({ countries: data?.countries });
  //   }
  // }

  async componentDidMount() {
    const currentUser = authenticationService?.currentUserSubject?.getValue();
    const settingsMenu = currentUser?.data?.menu?.find(
      (item) => item?.url === "settings"
    );
    // console.log(currentUser?.data?.currentRole, "currentUser?.data");
    this.setState({ menuList: settingsMenu?.permissions });
    this.setState({ role: currentUser?.data?.currentRole });
    // this.getAllCountries();
    this.fetchSubscriptionAttributes();
    this.fetchgetAllSubscription();
  }

  async handleInviteCompanyByBroker(event) {
    event.preventDefault();
    const { email, plan_id, discount, companyName } = this.state;
    let body = {
      email: email,
      plan_id: plan_id,
      discount: discount,
      company_name: companyName,
    };
    if (email) {
      this.setState({ loading: true });
      const { isSuccess, data } = await apiCall(
        config.API_URL + "api/v1/sendInviteForCompany",
        {},
        body,
        "POST"
      );

      this.setState({
        loading: false, email: "",
        discount: "",
        companyName: "",
      });

      if (isSuccess) {
        this.handleClose();
      }
    }
  }

  async handleSubmit(event, id) {
    event.preventDefault();
    const {
      title,
      plan_user,
      price,
      renewable_type,
      NO_USERS,
      NO_OF_SUB_ADMINS,
      NO_OF_DATA_ADMINS,
      NO_OF_AUDITOR,
      NO_OF_SUBADMIN_AUDITOR,
      NO_SUPPLIER,
      NO_FRAMEWORK,
      selectedItems1,
      selectedItems2,
      financialYear,
      is_invitable,
      country_code,
    } = this.state;
    let obj = {};
    // console.log("selectedItems1", selectedItems1, selectedItems2);
    let planData = {
      NO_USERS: Number(NO_USERS),
      NO_FRAMEWORK: Number(NO_FRAMEWORK),
      NO_OF_SUB_ADMINS: Number(NO_OF_SUB_ADMINS),
      NO_OF_DATA_ADMINS: Number(NO_OF_DATA_ADMINS),
      NO_OF_AUDITOR: Number(NO_OF_AUDITOR),
      NO_OF_SUBADMIN_AUDITOR: Number(NO_OF_SUBADMIN_AUDITOR),
      MAPPED_FRAMEWORK: selectedItems1,
      MAPPED_MENUS: selectedItems2,
      FINANCIAL_YEAR: financialYear,
      YEAR: this.state.year,
      NO_SUPPLIER: Number(NO_SUPPLIER),
      ALLOW_GLOBAL_FRAMEWORK: this.state.ALLOW_GLOBAL_FRAMEWORK,
    };
    // console.log(this.state.role,this.state.role === "broker", "planData");
    obj.title = title;
    obj.plan_user = "company";
    obj.price = price;
    obj.country_code = country_code;
    obj.renewable_type = renewable_type;
    obj.plan_data = planData;
    obj.is_invitable = this.state.role === "broker" ? 1 : is_invitable;

    if (this.state.subscriptionId === "") {
      const { isSuccess, data } = await apiCall(
        `${config.ADMIN_API_URL}createSubscriptionPlan`,
        {},
        obj,
        "POST"
      );
      this.setState({     
        title: "",
        price: "",
        NO_USERS: "",
        NO_OF_SUB_ADMINS: "",
        NO_OF_DATA_ADMINS: "",
        NO_OF_AUDITOR: "",
        NO_OF_SUBADMIN_AUDITOR: "",
        NO_FRAMEWORK: "",
        financial_year: "",
        country_code: "",
        selectedItems1: [],
        selectedItems2: [],
        menuTitle: [],
        year:"",
        frameworkTitle:[],
        renewable_type: "",
        })
      if (isSuccess) {

        this.handleCancel();
        this.fetchgetAllSubscription();
      }
    } else {
      obj.id = this.state.subscriptionId;
      const { isSuccess, data } = await apiCall(
        `${config.ADMIN_API_URL}updateSubscriptionPlan`,
        {},
        obj,
        "POST"
      );
      if (isSuccess) {
        this.handleCancel();
        this.fetchgetAllSubscription();
        this.setState({ subscriptionId: "" });
      }
    }
    setTimeout(() => {
      this.fetchgetAllSubscription();
    }, 2000);
  }

  editSubscriptionPlan = (e, data) => {
    this.setState({
      title: data?.title,
      subscriptionId: data?.id,
      price: data?.price,
      plan_user: data?.plan_user,
      financial_year: data?.plan_data?.FINANCIAL_YEAR,
      NO_FRAMEWORK: data?.plan_data?.NO_FRAMEWORK,
      MAPPED_FRAMEWORK: data?.plan_data?.MAPPED_FRAMEWORK?.map(
        (data) => data.id
      ),
      MAPPED_MENUS: data?.plan_data?.MAPPED_MENUS?.map((data) => data.id),
      NO_USERS: data?.plan_data?.NO_USERS,
      selectedItems1: data?.plan_data?.MAPPED_FRAMEWORK?.map((data) => data.id),
      selectedItems2: data?.plan_data?.MAPPED_MENUS?.map((data) => data.id),
      is_invitable: data?.is_invitable,
      NO_SUPPLIER: data?.plan_data?.NO_SUPPLIER,
      ALLOW_GLOBAL_FRAMEWORK: data?.plan_data?.ALLOW_GLOBAL_FRAMEWORK,
      country_code: data?.country_code,
    });
    window.scrollTo({ top: 100, left: 0, behavior: "smooth" });
  };

  handleCancel = () => {
    this.setState({
      title: "",
      price: "",
      financial_year: "",
      NO_FRAMEWORK: "",
      MAPPED_FRAMEWORK: [],
      MAPPED_MENUS: [],
      NO_USERS: "",
      selectedItems1: [],
      selectedItems2: [],
      is_invitable: "0",
      plan_type: "company",
      // renewable_type: "YEARLY",
      subscriptionId: "",
      NO_SUPPLIER: "",
      ALLOW_GLOBAL_FRAMEWORK: "",
      country_code: "",
    });
  };

  handleButtonClick = () => {
    this.setState((prevState) => ({
      isVisible: !prevState.isVisible,
    }));
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = (e, plan_id) => {
    e.preventDefault();
    this.setState({ show: true, plan_id: plan_id });
  };

  render() {
    const { show, data, selectedItems2 } = this.state;
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <PermissionContext.Consumer>
          {({ permissions }) => (
            <div className="main_wrapper">
              <div className="inner_wraapper">
                <div className="hol_rell">
                  <div className="steps-form">
                    <div className="steps-row setup-panel">
                      <div className="tabs-top setting_admin my-0">
                        <ul>
                          <li>
                            <NavLink to="/settings">My Profile</NavLink>
                          </li>
                          {this.state.menuList.includes(
                            "GET ALL SUBSCRIPTION",
                            "CREATE SUBSCRIPTION"
                          ) && (
                              <li>
                                <NavLink
                                  to="/settings/billing"
                                  className="activee"
                                >
                                  {" "}
                                  Billing{" "}
                                </NavLink>
                              </li>
                            )}
                          <li>
                            <NavLink to="/settings/access_management">
                              {" "}
                              Access{" "}
                            </NavLink>
                          </li>
                          {this.state.menuList.includes(
                            "CREATE_FINANCIAL_YEAR"
                          ) && (
                              <li>
                                <NavLink to="/settings/financial_year">
                                  Financial Year
                                </NavLink>
                              </li>
                            )}
                          {this.state.menuList.includes("CREATE SUB USER") && (
                            <li>
                              <NavLink to="/settings/sub-users">
                                Sub Users
                              </NavLink>
                            </li>
                          )}
                          <li>
                            <NavLink to="/settings/category">Category</NavLink>
                          </li>
                          <li>
                            <NavLink to="/settings/carbon_emission">
                              Carbon Emission
                            </NavLink>
                          </li>
                          <li>
                    <NavLink to="/settings/unit" >
                      Unit
                    </NavLink>
                  </li>
                        </ul>
                      </div>
                      <div className="hstack gap-2">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search Plan Name"
                          name="search"
                          onChange={(e) => this.applyGlobalSearch(e)}
                        />
                        <Button
                          variant={
                            this.state.isVisible === true ? "info" : "danger"
                          }
                          className="mx-2"
                          onClick={this.handleButtonClick}
                        >
                          {this.state.isVisible === true
                            ? "ADD A PLAN"
                            : "Close Section"}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="color_div_on framwork_2 pt-0">
                  <div className="table-responsive">
                    <div
                      className="table___height"
                      style={{ overflowX: "hidden" }}
                    >
                      <div className="pb3">
                        {!this.state.isVisible && (
                          <Row className="mb-4">
                            <Col md={4}>
                              <form onSubmit={this.handleSubmit}>
                                <div className="ist_component_for_billing">
                                  <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    placeholder="Subscription Plan Name"
                                    required
                                    name="title"
                                    disabled={this.state.subscriptionId !== ""}
                                    value={this.state.title}
                                    onChange={(e) =>
                                      this.setState({
                                        title: e.target.value,
                                      })
                                    }
                                  />

                                  <select
                                    disabled={this.state.subscriptionId !== ""}
                                    id="country"
                                    name="country"
                                    required
                                    className="form-control mb-2 biling-menu"
                                    value={this.state?.country_code}
                                    onChange={(e) =>
                                      this.handleCountryChange(e)
                                    }
                                  >
                                    <option className="bold">
                                      Select Country
                                    </option>
                                    {countriesData &&
                                      countriesData.map((country, index) => (
                                        <option
                                          key={index}
                                          value={country?.isoCode}
                                        >
                                          {country?.name}
                                        </option>
                                      ))}
                                  </select>
                                  <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    placeholder="Finanacial Year"
                                    required
                                    name="title"
                                    readOnly
                                    value={this.state.financialYear}
                                  />

                                  <input
                                    type="number"
                                    className="form-control mb-2"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    name="price"
                                    placeholder="Enter Amount"
                                    required
                                    value={this.state.price}
                                    onChange={(e) =>
                                      this.setState({
                                        price: e.target.value,
                                      })
                                    }
                                  />
                                  <select
                                    id="year"
                                    name="year"
                                    required
                                    className="form-control mb-2 biling-menu"
                                    value={this.state?.year}
                                    onChange={(e) => this.handleYearChange(e)}
                                  >
                                    <option className="bold">
                                      Select Year
                                    </option>
                                    {this.state.financialYear &&
                                      this.state.financialYear?.includes(
                                        "Jan"
                                      ) ? (
                                      <>
                                        {yearsData &&
                                          yearsData.map((years, index) => (
                                            <option
                                              key={index}
                                              value={years.year}
                                            >
                                              {years.year}
                                            </option>
                                          ))}
                                      </>
                                    ) : (
                                      <>
                                        {slashYearsData &&
                                          slashYearsData.map(
                                            (slashYears, index) => (
                                              <option
                                                key={index}
                                                value={slashYears.year}
                                              >
                                                {slashYears.year}
                                              </option>
                                            )
                                          )}
                                      </>
                                    )}
                                  </select>

                                  {this.state.role !== "broker" && (
                                    <select
                                      className="form-control mb-2 biling-menu"
                                      id="exampleFormControlSelect1"
                                      name="renewable_type"
                                      required
                                      disabled={
                                        this.state.subscriptionId !== ""
                                      }
                                      value={this.state.is_invitable}
                                      onChange={(e) =>
                                        this.setState({
                                          is_invitable: e.target.value,
                                        })
                                      }
                                    >
                                      <option value={0} selected>
                                        Public plan
                                      </option>
                                      <option value={1}>Private Plan</option>
                                    </select>
                                  )}

                                  <select
                                    className="form-control mb-2 biling-menu"
                                    id="exampleFormControlSelect1"
                                    name="renewable_type"
                                    required
                                    disabled={this.state.subscriptionId !== ""}
                                    value={this.state.renewable_type}
                                    onChange={(e) =>
                                      this.setState({
                                        renewable_type: e.target.value,
                                      })
                                    }
                                  >
                                    <option selected disabled>
                                      Select Plan Type
                                    </option>
                                    <option value={"MONTHLY"}>Monthly</option>
                                    <option value={"QUARTERLY"}>
                                      Quarterly
                                    </option>
                                    <option value={"HALF-YEARLY"}>
                                      Half-Yearly
                                    </option>
                                    <option value={"YEARLY"}>Yearly</option>
                                  </select>

                                  <select
                                    placeholder="Allow global framework for supplier assessment"
                                    className="form-control mb-2"
                                    required
                                    value={this.state.ALLOW_GLOBAL_FRAMEWORK}
                                    onChange={(e) =>
                                      this.setState({
                                        ALLOW_GLOBAL_FRAMEWORK: e.target.value,
                                      })
                                    }
                                  >
                                    <option selected disabled>
                                      Allow global framework for supplier
                                      assessment
                                    </option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                  </select>
                                  {data?.map((items, key) => (
                                    <div className="col-md-12 col-xs-12 mb-2">
                                      {items?.attribute_data_type ===
                                        "number" && (
                                          <div className="form-group">
                                            <input
                                              type="number"
                                              className="form-control"
                                              id="exampleInputEmail1"
                                              aria-describedby="emailHelp"
                                              name={items?.attribute_code}
                                              placeholder={
                                                "Select " +
                                                items.attribute_display_name
                                              }
                                              required
                                              value={
                                                this.state[items?.attribute_code]
                                              }
                                              onChange={(e) =>
                                                this.handleChange2(e)
                                              }
                                            />
                                          </div>
                                        )}
                                      <div className="form-group">
                                        {items?.attribute_data_type ===
                                          "list" &&
                                          items.attribute_code ===
                                          "MAPPED_FRAMEWORK" && (
                                            <div className="dropdown__sectoon">
                                              <div
                                                className="form-control"
                                                onClick={() =>
                                                  this.setState({
                                                    showdropdown1:
                                                      !this.state.showdropdown1,
                                                  })
                                                }
                                              >
                                                {this.state.selectedItems1
                                                  .length > 0
                                                  ? items?.attribute_list?.find(
                                                    (obj) =>
                                                      obj.id ===
                                                      this.state
                                                        .selectedItems1[0]
                                                  )?.title
                                                  : "Select" +
                                                  items.attribute_display_name}
                                              </div>
                                              {this.state.showdropdown1 && (
                                                <div
                                                  className="select__dropdown__wrapper"
                                                  style={{
                                                    width: "100%",
                                                    minHeight: "150px",
                                                    maxHeight: "150px",
                                                    position: "absolute",
                                                    zIndex: "10",
                                                    background: "#fff",
                                                  }}
                                                >
                                                  <div>
                                                    <input
                                                      type="checkbox"
                                                      style={{
                                                        color: "#777777",
                                                      }}
                                                      value={-1}
                                                      checked={
                                                        this.state
                                                          .selectedItems1
                                                          .length ===
                                                        items.attribute_list
                                                          .length +
                                                        1
                                                      }
                                                      onChange={(event) =>
                                                        this.handleFrameworkChange(
                                                          event,
                                                          items.attribute_list
                                                        )
                                                      }
                                                    />
                                                    All
                                                  </div>
                                                  {items?.attribute_list?.map(
                                                    (item, key) => {
                                                      return (
                                                        <div
                                                          key={key}
                                                          style={{
                                                            color:
                                                              "#777777 !important",
                                                          }}
                                                        >
                                                          <input
                                                            type="checkbox"
                                                            value={item?.id}
                                                            checked={this.state.selectedItems1.includes(
                                                              item?.id
                                                            )}
                                                            onChange={(event) =>
                                                              this.handleFrameworkPreviousChange(
                                                                event,
                                                                item?.id,
                                                                item?.title
                                                              )
                                                            }
                                                          />
                                                          {item?.title}
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        {items?.attribute_data_type ===
                                          "list" &&
                                          items.attribute_code ===
                                          "MAPPED_MENUS" && (
                                            <div className="dropdown__sectoon">
                                              <div
                                                className="form-control"
                                                style={{
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                                onClick={() =>
                                                  this.setState({
                                                    showdropdown2:
                                                      !this.state.showdropdown2,
                                                  })
                                                }
                                              >
                                                {this.state.selectedItems2
                                                  .length > 0
                                                  ? items?.attribute_list?.find(
                                                    (obj) =>
                                                      obj.id ===
                                                      this.state
                                                        .selectedItems2[0]
                                                  )?.title
                                                  : "Select" +
                                                  items.attribute_display_name}
                                              </div>
                                              {this.state.showdropdown2 && (
                                                <div
                                                  className="select__dropdown__wrapper"
                                                  style={{
                                                    width: "100%",
                                                    minHeight: "150px",
                                                    maxHeight: "150px",
                                                    position: "absolute",
                                                    zIndex: "10",
                                                    background: "#fff",
                                                  }}
                                                >
                                                  <div>
                                                    <input
                                                      type="checkbox"
                                                      style={{
                                                        color: "#777777",
                                                      }}
                                                      value={-1}
                                                      checked={
                                                        this.state
                                                          .selectedItems2
                                                          .length ===
                                                        items.attribute_list
                                                          .length +
                                                        1
                                                      }
                                                      onChange={(event) =>
                                                        this.handleMenuChange(
                                                          event,
                                                          items.attribute_list
                                                        )
                                                      }
                                                    />
                                                    All
                                                  </div>
                                                  {items &&
                                                    items?.attribute_list?.map(
                                                      (item, key) => {
                                                        return (
                                                          <div
                                                            key={key}
                                                            style={{
                                                              color:
                                                                "#777777 !important",
                                                            }}
                                                          >
                                                            <input
                                                              type="checkbox"
                                                              value={item?.id}
                                                              checked={selectedItems2.includes(
                                                                item?.id
                                                              )}
                                                              onChange={(
                                                                event
                                                              ) =>
                                                                this.handleMenuPreviousChange(
                                                                  event,
                                                                  item.id
                                                                )
                                                              }
                                                            />
                                                            {item?.title}
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                </div>
                                              )}
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  ))}
                                  <div className="d-flex gap-3 align-items-center">
                                    <Button
                                      variant="danger"
                                      onClick={this.handleCancel}
                                    >
                                      Cancel
                                    </Button>
                                    <Button type="submit" variant="info">
                                      {this.state?.subscriptionId === ""
                                        ? "Save"
                                        : "Update"}
                                    </Button>
                                  </div>
                                </div>
                              </form>
                            </Col>
                            <Col md={4}> 
                              <div className="pricing-table turquoise">
                                {this.state?.title && (
                                  <h2>{this.state?.title}</h2>
                                )}
                                <div className="price-tag">
                                  {this.state?.price && (
                                    <>
                                      <span className="symbol">₹</span>
                                      <span className="amount">
                                        {this.state?.price}
                                      </span>
                                    </>
                                  )}
                                  {this.state?.renewable_type && (
                                    <span className="after">
                                      /{this.state?.renewable_type}
                                    </span>
                                  )}
                                </div>
                                {this.state?.year && (
                                  <div className="d-flex align-items-center justify-content-between">
                                    <span>Financial Year</span>
                                    <span>{this.state?.year}</span>
                                  </div>
                                )}
                                <div className="pricing-features">
                                  {this.state?.frameworkTitle &&
                                    this.state?.frameworkTitle.length > 0 && (
                                      <>
                                        <hr className="mt-1 mb-0" />
                                        <div className="feature">
                                          Framework Features :
                                        </div>
                                        <div className="framework_feature">
                                          <ul>
                                            {this.state?.frameworkTitle &&
                                              this.state?.frameworkTitle?.map(
                                                (item) => (
                                                  <>
                                                    <li>{item}</li>
                                                  </>
                                                )
                                              )}
                                          </ul>
                                        </div>
                                      </>
                                    )}
                                  {this.state?.menuTitle &&
                                    this.state?.menuTitle.length > 0 && (
                                      <>
                                        <div className="feature">
                                          Menu Features :
                                        </div>
                                        <div className="framework_feature">
                                          <ul>
                                            {this.state?.menuTitle &&
                                              this.state?.menuTitle?.map(
                                                (item) => (
                                                  <>
                                                    <li>{item}</li>
                                                  </>
                                                )
                                              )}
                                          </ul>
                                        </div>
                                      </>
                                    )}
                                </div>
                                {this.state?.ALLOW_GLOBAL_FRAMEWORK && (
                                  <div className="d-flex align-items-center justify-content-between">
                                    <span>Global Framework for Supplier</span>
                                    <span>
                                      {this.state?.ALLOW_GLOBAL_FRAMEWORK ===
                                        "1"
                                        ? "Yes"
                                        : "No"}
                                    </span>
                                  </div>
                                )}
                                {this.state?.NO_USERS && (
                                  <div className="d-flex align-items-center justify-content-between">
                                    <span>No. of Users</span>
                                    <span>{this.state?.NO_USERS}</span>
                                  </div>
                                )}
                                {this.state?.NO_FRAMEWORK && (
                                  <div className="d-flex align-items-center justify-content-between">
                                    <span>
                                      No. of Framework (Can be create)
                                    </span>
                                    <span>{this.state?.NO_FRAMEWORK}</span>
                                  </div>
                                )}
                                {this.state?.NO_SUPPLIER && (
                                  <div className="d-flex align-items-center justify-content-between">
                                    <span>
                                      No. of Suppliers (Can be create)
                                    </span>
                                    <span>{this.state?.NO_SUPPLIER}</span>
                                  </div>
                                )}
                              </div>
                            </Col>
                          </Row>
                        )}
                        {this.state.loder ? (
                          <Loader />
                        ) : this.state?.SubscriptionData.length > 0 ? (
                          <Row>
                            {this.state?.SubscriptionData &&
                              this.state?.SubscriptionData?.map((item) => (
                                <Col
                                  xl={4}
                                  lg={4}
                                  md={6}
                                  sm={12}
                                  style={{ marginBottom: 60 }}
                                >
                                  <div className="pricing-table turquoise">
                                    {item?.title && <h2>{item?.title}</h2>}
                                    <div className="price-tag">
                                      {item.currency && (
                                        <span className="symbol">
                                          {item.currency}
                                        </span>
                                      )}
                                      {item.price && (
                                        <span className="amount">
                                          {item.price}
                                        </span>
                                      )}
                                      {item?.renewal_type && (
                                        <span className="after">
                                          /{item?.renewal_type}
                                        </span>
                                      )}
                                    </div>
                                    {item?.year && (
                                      <>
                                        <div className="mt-0 mb-1 border-bottom"></div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <span>Financial Year</span>
                                          <span>{item?.year}</span>
                                        </div>
                                      </>
                                    )}
                                    {item?.plan_data.ALLOW_GLOBAL_FRAMEWORK && (
                                      <div className="d-flex align-items-center justify-content-between">
                                        <span>
                                          Global Framework for Supplier
                                        </span>
                                        <span>
                                          {item?.plan_data
                                            .ALLOW_GLOBAL_FRAMEWORK === "1"
                                            ? "Yes"
                                            : "No"}
                                        </span>
                                      </div>
                                    )}
                                    {item?.plan_data.NO_USERS && (
                                      <div className="d-flex align-items-center justify-content-between">
                                        <span>No. of Users</span>
                                        <span>{item?.plan_data.NO_USERS}</span>
                                      </div>
                                    )}
                                    {item?.plan_data.NO_FRAMEWORK && (
                                      <div className="d-flex align-items-center justify-content-between">
                                        <span>
                                          No. of Framework (Can be create)
                                        </span>
                                        <span>
                                          {item?.plan_data.NO_FRAMEWORK}
                                        </span>
                                      </div>
                                    )}
                                    {item?.plan_data.NO_SUPPLIER && (
                                      <>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <span>
                                            No. of Suppliers (Can be create)
                                          </span>
                                          <span>
                                            {item?.plan_data.NO_SUPPLIER}
                                          </span>
                                        </div>
                                        <div className="mt-2 mb-1 border-bottom"></div>
                                      </>
                                    )}
                                    <div className="pricing-features">
                                      {item?.plan_data.MAPPED_FRAMEWORK && (
                                        <>
                                          <div className="feature">
                                            Framework Features :
                                          </div>
                                          <div className="framework_feature">
                                            <ul>
                                              {item?.plan_data
                                                .MAPPED_FRAMEWORK &&
                                                item?.plan_data.MAPPED_FRAMEWORK?.map(
                                                  (item) => (
                                                    <>
                                                      <li key={item.id}>
                                                        {item.title}
                                                      </li>
                                                    </>
                                                  )
                                                )}
                                            </ul>
                                          </div>
                                        </>
                                      )}
                                      {item?.plan_data.MAPPED_MENUS && (
                                        <>
                                          <div className="feature">
                                            Menu Features :
                                          </div>
                                          <div className="framework_feature">
                                            <ul>
                                              {item?.plan_data.MAPPED_MENUS &&
                                                item?.plan_data.MAPPED_MENUS?.map(
                                                  (item) => (
                                                    <>
                                                      <li key={item.id}>
                                                        {item.title}
                                                      </li>
                                                    </>
                                                  )
                                                )}
                                            </ul>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                    {/* {item?.portal_type &&
                                    item?.portal_type === "broker" && ( */}
                                    <div className="text-center my-4">
                                      <Button
                                        variant="info"
                                        onClick={(e) =>
                                          this.handleShow(e, item?.id)
                                        }
                                      >
                                        Invite Company
                                      </Button>
                                    </div>
                                    {/* )} */}
                                  </div>
                                </Col>
                              ))}
                          </Row>
                        ) : (
                          <div className="text-center">
                            <img
                              src={NoPlanHere}
                              alt="no-results"
                              className="w-25 h-50"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </PermissionContext.Consumer>
        <Modal show={show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Invite Company Form</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form name="form" onSubmit={this.handleInviteCompanyByBroker}>
              <Form.Group className="mb-2" controlId="formBasicEmail">
                <Form.Label>
                  Email Id <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  className="form-control"
                  type="email"
                  aria-expanded="false"
                  placeholder="Email"
                  name="email"
                  onChange={(e) =>
                    this.setState({
                      email: e.target.value,
                    })
                  }
                />
                {this.state.submitted && !this.state.email && (
                  <div className="help-block">Email is required</div>
                )}
              </Form.Group>
              <Form.Group className="mb-2" controlId="formBasicEmail">
                <Form.Label>
                  Company Name <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  className="form-control"
                  type="text"
                  aria-expanded="false"
                  placeholder="Enter Company Name"
                  name="companyName"
                  onChange={(e) =>
                    this.setState({
                      companyName: e.target.value,
                    })
                  }
                />
                {this.state.submitted && !this.state.companyName && (
                  <div className="help-block">Company Name is required</div>
                )}
              </Form.Group>
              <Form.Group className="mb-2" controlId="formBasicEmail">
                <Form.Label>
                  Discount <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  className="form-control"
                  type=""
                  aria-expanded="false"
                  placeholder="Discount"
                  value={this.state.discount}
                  name="discount"
                  onChange={(event) => {
                    let val = parseInt(event.target.value);
                    if (val <= 100 || event.target.value === "") {
                      this.setState({
                        discount: event.target.value,
                      });
                    }
                  }}
                  max={100}
                  onBlur={(event) => {
                    if (event.target.value === "") {
                      this.setState({
                        discount: 0,
                      });
                    }
                  }}
                  pattern="\d+"
                />
                {this.state.submitted && !this.state.discount && (
                  <div className="help-block">Discount is required</div>
                )}
              </Form.Group>
              <div className="d-flex align-items-center justify-content-end gap-3">
                <Button variant="danger" onClick={this.handleClose}>
                  Cancel
                </Button>
                {this.state?.loading ? (
                  <Button variant="info" disabled>
                    <Spinner animation="border" /> Sending...
                  </Button>
                ) : (
                  <Button variant="info" type="submit">
                    Send
                  </Button>
                )}
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
