import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import "../Sector_Question_Manage/control.css";
import { NavLink } from "react-router-dom";
import Form from "react-bootstrap/Form";
import axios from "axios";
import config from "../../config/config.json";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import noDataFound from "../../img/no_data_found.png";
import { authenticationService } from "../../_services/authentication";
import { apiCall } from "../../_services/apiCall";
import AddFormulaModal from "./AddFormulaModal";

export const SectorQuestionFrameworkWise = (props) => {
  const { currentFrameworkData, financial_year_id } = props;
  const [sectorQuestion, setSectorQuestion] = useState([]);
  const [menulist, setMenuList] = useState([]);
  const [token] = useState(JSON.parse(localStorage.getItem("currentUser")));
  const [editModalShow, setEditModalShow] = useState(false);
  const [prevFormula, setPrevFormula] = useState(false);
  const [currentFormula, setCurrentFormula] = useState("");
  const [currentTabularFormula, setCurrentTabularFormula] = useState([]);
  const [editableQuestionId, setEditableQuestionId] = useState("");
  const [editableQuestionType, setEditableQuestionType] = useState("");
  const [editableQuestionDetails, setEditableQuestionDetails] = useState("");
  const [financialYearId, setFinancialYearId] = useState("");
  const [financialYear, setFinancialYear] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const handleClose = () => setEditModalShow(false);

  const handleOpenPopup = (question) => {
    setSelectedQuestion(question);
    setPopupVisible(true);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
    setSelectedQuestion(null);
  };

  const handleEditFormula = async (
    formula,
    question_id,
    question_type,
    question_detail
  ) => {
    setPrevFormula(formula);
    setCurrentFormula(formula);
    setEditableQuestionId(question_id);
    setEditableQuestionType(question_type);
    setEditableQuestionDetails(question_detail);
    const numRows = question_detail.filter(
      (item) => item.option_type === "row"
    ).length;

    const numCols = question_detail.filter(
      (item) => item.option_type === "column"
    ).length;
    const initialData = Array.from({ length: numRows }, () =>
      Array.from({ length: numCols }, () => "")
    );

    setCurrentTabularFormula(initialData);
    setEditModalShow(true);
  };
  const handleEditUpdateFormula = async () => {
    const body = {
      formula:
        editableQuestionType === "tabular_question"
          ? currentTabularFormula
          : currentFormula,
      question_id: editableQuestionId,
      question_type: editableQuestionType,
    };
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}updateFormula`,
      {},
      body,
      "POST"
    );
    if (isSuccess) {
      setEditModalShow(false);
      setEditableQuestionId("");
      setCurrentFormula("");
      callApi();
    }
  };


  const callApi = async () => {
    setSectorQuestion([]);
    axios
      .get(
        `${config.ADMIN_API_URL}getSectorQuestion?type=ALL&financial_year_id=${financial_year_id?.id
        }&questionnaire_type=SQ${currentFrameworkData?.id
          ? `&framework_ids=${currentFrameworkData.id}`
          : ""
        }${currentFrameworkData?.topic_id
          ? `&topic_ids=${currentFrameworkData.topic_id}`
          : ""
        }${currentFrameworkData?.kpi_id
          ? `&kpi_ids=${currentFrameworkData.kpi_id}`
          : ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${token?.data?.token}`,
          },
        }
      )

      .then((response) => {
        console.log("response", response.data.data);
        let temp=response.data.data;
        setSectorQuestion(temp);
        console.log("sectorsss", sectorQuestion)
      })
      .catch((error) => {
        console.log(error, "response");
      });
  };

  const deleteSectorQuestion = async (question_mapping_id, question_id) => {
    const body = {
      question_mapping_id: question_mapping_id,
      question_id: question_id,
    };
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}deleteSectorQuestion`,
      {},
      body,
      "POST"
    );
    if (isSuccess) {
      callApi();
    }
  };
  const handleCellChange = (event, rowIndex, columnIndex) => {
    const value = event.target.value;
    setCurrentTabularFormula((prevData) => {
      const newData = prevData.map((row) => [...row]);
      if (newData[rowIndex]) {
        newData[rowIndex][columnIndex] = value;
      }
      return newData;
    });
  };
  useEffect(() => {
    getFinancialYear();
    const currentUser = authenticationService?.currentUserSubject?.getValue();
    const settingsMenu = currentUser?.data?.menu?.find(
      (item) => item?.url === "global_controls"
    );
    setMenuList(settingsMenu?.permissions);
  }, []);

  useEffect(() => {
    if (financial_year_id?.id) callApi();
  }, [financial_year_id?.id]);
  useEffect(() => {
    if (currentFrameworkData?.id) callApi();
  }, [
    currentFrameworkData?.id,
    currentFrameworkData?.topic_id,
    currentFrameworkData?.kpi_id,
  ]);

  const getFinancialYear = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}getFinancialYear`,
      {},
      { type: "SUPER_ADMIN", entity: 2 }
    );

    if (isSuccess) {
      setFinancialYear(data.data);
      if (data?.data?.length == 1) {
        setFinancialYearId(data.data[0].id);
        if (financialYearId) {
          callApi();
        }
      }
    }
  };

  return (
    <div className="sectorQuestion">
      {sectorQuestion ? (
        <>
          {/* <div className="d-flex align-items-center justify-content-between mb-2">
            <div className="heading">
              <h4>Sector Question List</h4>
            </div>
          </div> */}
          <div className="saved_cards">
            <div className="table_f mt-2 table__overflow__style">
              <Table striped bordered hover size="sm">
                <thead>
                  <tr className="heading_color sticky_header">
                    <th>Sr.</th>
                    <th>Framework</th>
                    <th>Topic</th>
                    <th>Kpi</th>
                    <th>Entity</th>
                    <th>Question Type</th>
                    <th>Question ID</th>
                    <th>Question Heading</th>
                    <th>
                      Add Formula
                      <i class="fas fa-plus-circle" id="add-icon" style={{ marginLeft: "10px" }}></i>
                    </th>
                    <th>Formula</th>
                    <th>Sector Question</th>
                    <th>Edit Formula </th>
                    <th>Delete </th>
                  </tr>
                </thead>
                <tbody>
                  {sectorQuestion &&
                    sectorQuestion?.map((item, key) => (
                      <tr key={key}>
                        <td>{key + 1}</td>
                        <td>{item.framework_title}</td>
                        <td>{item.topic_title}</td>
                        <td>{item.kpi_title}</td>
                        <td>{item.entity}</td>
                        <td>{item.questionType}</td>
                        <td>{item.question_id}</td>
                        <td>{item.heading}</td>
                        <td>
                          {item.formula === null ? (
                            <Button onClick={() => handleOpenPopup(item)}>+</Button>
                          ) : (
                            "N/A"
                          )}
                        </td>
                        <td>
                          {item.questionType === "tabular_question"
                            ? item?.formula && item?.formula.replace(/\\/g, "")
                            : item?.formula}
                        </td>
                        <td>{item.title}</td>

                        <td>
                          {item?.formula && (
                            <div
                              className="red"
                              onClick={(e) =>
                                handleEditFormula(
                                  item?.formula,
                                  item?.question_id,
                                  item?.questionType,
                                  item?.question_detail
                                )
                              }
                            >
                              <NavLink to="#" className="view_c">
                                <i
                                  className="fas fa-edit"
                                  title="Edit"
                                  style={{ color: "green" }}
                                ></i>
                              </NavLink>
                            </div>
                          )}
                        </td>
                        {item?.is_editable == "1" && (
                          <td
                            className="red"
                            onClick={(e) =>
                              deleteSectorQuestion(
                                item.question_mapping_id,
                                item.question_id
                              )
                            }
                          >
                            <i
                              className="fas fa-trash-alt"
                              title="Delete"
                              style={{ color: "red" }}
                            ></i>
                          </td>
                        )}
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </div>
        </>
      ) : (
        <img className="noDataFound" src={noDataFound} alt="" srcset="" />
      )}
      <Modal fullscreen={true} show={editModalShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Formula</Modal.Title>
        </Modal.Header>

        {editableQuestionType && editableQuestionType === "tabular_question" ? (
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                style={{ overflow: "auto" }}
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Previous Formula</Form.Label>
                <Table striped bordered hover style={{ textWrap: "nowrap" }}>
                  <thead>
                    <tr>
                      <th></th>
                      {editableQuestionDetails &&
                        editableQuestionDetails
                          .filter(function (item) {
                            return item.option_type === "column";
                          })
                          .map((col, ind) => <th key={ind}>{col.option}</th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {editableQuestionDetails &&
                      editableQuestionDetails
                        .filter(function (item) {
                          return item.option_type === "row";
                        })
                        .map((prow, indRow) => (
                          <tr key={indRow}>
                            <td className="input-for-formula">{prow.option}</td>
                            {JSON.parse(prevFormula)[indRow]?.map(
                              (cell, colIndex) => (
                                <td key={colIndex}>
                                  <input
                                    value={cell}
                                    type="text"
                                    className="input-for-formula"
                                    readOnly
                                  />
                                </td>
                              )
                            )}
                          </tr>
                        ))}
                  </tbody>
                </Table>
              </Form.Group>
              <Form.Group
                className="mb-3"
                style={{ overflow: "auto" }}
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Current Formula</Form.Label>
                <table className="table table-striped bordered hover">
                  <thead>
                    <tr>
                      <th></th>
                      {editableQuestionDetails
                        .filter((item) => item.option_type === "column")
                        .map((col, colIndex) => (
                          <th key={colIndex}>{col.option}</th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {editableQuestionDetails
                      .filter((item) => item.option_type === "row")
                      .map((prow, rowIndex) => (
                        <tr key={rowIndex}>
                          <td className="input-for-formula">{prow.option}</td>
                          {Array.from({
                            length: editableQuestionDetails.filter(
                              (item) => item.option_type === "column"
                            ).length,
                          }).map((_, colIndex) => (
                            <td key={colIndex}>
                              <input
                                key={colIndex}
                                value={
                                  currentTabularFormula[rowIndex]?.[colIndex] ||
                                  ""
                                }
                                type="text"
                                className="input-for-formula"
                                onChange={(event) =>
                                  handleCellChange(event, rowIndex, colIndex)
                                }
                              />
                            </td>
                          ))}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </Form.Group>
            </Form>
          </Modal.Body>
        ) : (
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Previous Formula</Form.Label>
                <Form.Control value={prevFormula} />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Current Formula</Form.Label>
                <Form.Control
                  autoFocus
                  type="text"
                  placeholder="Enter current formula"
                  required
                  onChange={(e) => setCurrentFormula(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
        )}

        <Modal.Footer>
          <Button variant="primary" onClick={handleEditUpdateFormula}>
            Update Formula
          </Button>
        </Modal.Footer>
      </Modal>
       
       <AddFormulaModal callApi={callApi} popupVisible={popupVisible} selectedQuestion={selectedQuestion} handleClosePopup={handleClosePopup} sectorQuestion={sectorQuestion}/>

    </div>
  );
};
