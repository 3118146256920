/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import { authenticationService } from "../../_services/authentication";
import { history } from "../../_helpers/history";
import { Link } from "react-router-dom";
import config from "../../config/config.json";
import "./header.css";
import { apiCall } from "../../_services/apiCall";
import { NavLink } from "react-bootstrap";
const currentUser = authenticationService.currentUserValue;

const ProfilePics = "https://res.cloudinary.com/dmklsntsw/image/upload/v1658480882/dummyPic.75a04487_fqfqey.png"
export default class header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logo: "",
    };
    this.goToPreviousPath = this.goToPreviousPath.bind(this);
    this.serverRequest = this.serverRequest.bind(this);
    this.redirection = this.redirection.bind(this);
  }
  goToPreviousPath() {
    window.history.back();
  }
  logout() {
    authenticationService.logout();
    history.push("/");
  }

  async serverRequest() {
    try {


      const { isSuccess, data } = await apiCall(config.ADMIN_API_URL + "settings", {}, {}, "GET")

      if (isSuccess) {
        this.setState({
          isLoaded: true,
          firstName: data?.result[0]?.first_name,
          lastName: data?.result[0]?.last_name,
          email: data?.result[0]?.email,
          logo:
            data?.result1[0]?.logo === null || data?.result1[0]?.logo === undefined
              ? ProfilePics
              : config.BASE_URL + data?.result1[0]?.logo,
        });
      }
      else {
        this.setState({
          isLoaded: false,
        });
      }
    } catch (e) {
      this.setState({
        isLoaded: false,
      });
      console.log("error")
    }


    // fetch(config.ADMIN_API_URL + "settings", { headers })
    //   .then((res) => res.json())
    //   .then(
    //     (data) => {
    //       // console.log("Hello", data.result[0]?.logo)
    //       this.setState({
    //         isLoaded: true,
    //         firstName: data?.result[0]?.first_name,
    //         lastName: data?.result[0]?.last_name,
    //         email: data?.result[0]?.email,
    //         logo:
    //           data?.result1[0]?.logo === null || data?.result1[0]?.logo === undefined
    //             ? ProfilePics
    //             : config.BASE_URL + data?.result1[0]?.logo,
    //       });
    //     },
    //     (error) => {
    //       localStorage.clear();
    //       window.location.href = "/";
    //       this.setState({
    //         isLoaded: true,
    //         error,
    //       });
    //     }
    //   );
  }
  componentDidMount() {
    this.serverRequest();
  }

  redirection() {
    window.location.href = "/";
  }
  render() {
    const { logo } = this.state;
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser === null) {
      this.redirection();
    }
    let fullUrl = window.location.href.split("/");
    let urr = "";
    if (fullUrl[3] === 'sub-users') {
      urr = fullUrl[3]
    } else {
      if (fullUrl[3] !== "user_detail") {
        urr = fullUrl.pop().toUpperCase();
      } else {
        urr = fullUrl[3];
      }
    }
    let finalResult = "";
    if (fullUrl[3] === 'sub-users') {
      finalResult = urr.replaceAll("-", " ");
    } else {
      finalResult = urr.replaceAll("_", " ");
    }
    if (fullUrl[4] === 'invoice') {
      finalResult = "Invoice"
    }

    if (finalResult === "SUPPLIERS FAST") {
      finalResult = "SUPPLIERS";
    }

    let uri = window.location.pathname.split("/");
    let path = uri[4];

    if (path === "governance-policy") {
      finalResult = "Governance Policy";
    }

    if (path === "social-policy") {
      finalResult = "Social Policy";
    }

    if (path === "cyber-and-policy") {
      finalResult = "Cyber & Policy";
    }

    if (path === "health-and-safety-policy") {
      finalResult = "Health & Safety Policy";
    }

    if (path === "environmental-policy") {
      finalResult = "Environmental Policy";
    }


    if (finalResult === "SUSTAINABLE") {
      finalResult = "SUSTAINABLE DEVELOPMENT GOALS";
    }

    if (fullUrl.pop() === 'details') {
      finalResult = "details";
    }

    return (
      <div>
        <div className="d-flex" id="wrapper">
          {/* <!-- Page content wrapper--> */}
          <div id="page-content-wrapper">
            {/* <!-- Top navigation--> */}
            <nav className="navbar navbar-expand-lg navclassName background">
              <div className="container-fluid">
                {/* <!--  <button className="desh" id="sidebarToggle">Deshboard</button> --> */}
                <div className="deahbord" id="sidebarToggle">
                  <h4 className="back_quninti back_quninti_2">
                    <a className="back_text" href="#" onClick={(e) => {
                      e.preventDefault();
                      this.goToPreviousPath();
                    }}>
                      <span className="step_icon">
                        <i className="far fa-long-arrow-left"></i>
                      </span>
                      {finalResult}
                    </a>
                  </h4>
                </div>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav ms-auto">

                    <li className="nav-item dropdown text_down mx-3">
                      <a
                        className="nav-link home_drop"
                        id="navbarDropdown"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <div className="activation-notification">
                          <NavLink to="/notification">
                            <i className="fas fa-bell"></i>
                          </NavLink>
                          <span className="badge">5</span>
                        </div>
                      </a>

                      <div
                        className="dropdown-menu dropdown-menu-end dropdown_menu"
                        aria-labelledby="navbarDropdown"
                        style={{ width: 500 }}
                      >
                        <div className="notification_section">
                          <div className="hstack border-bottom justify-content-between">
                            <h5>Notification</h5>
                            <div className="hstack gap-2">
                              <a href="#">Mark all as read</a>
                              <a href="#">Clear all</a>
                              <i class="fas fa-arrow-right"></i>
                            </div>
                          </div>
                          <div className="notification_body">
                            <div className="d-flex gap-4 border-bottom">
                              <i class="fas fa-user-tie"></i>
                              <div className="my-2">
                                <h4>ZOHO Mail</h4>
                                <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32</p>
                                <a href="#" className="p-0">Mark as read</a>
                                <a href="#" className="p-0 mx-2">Mark as unread</a>
                              </div>
                            </div><div className="d-flex gap-4 border-bottom">
                              <i class="fas fa-user-tie"></i>
                              <div className="my-2">
                                <h4>ZOHO Mail</h4>
                                <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32</p>
                                <a href="#" className="p-0">Mark as read</a>
                                <a href="#" className="p-0 mx-2">Mark as unread</a>
                              </div>
                            </div>
                            <div className="d-flex gap-4 border-bottom">
                              <i class="fas fa-user-tie"></i>
                              <div className="my-2">
                                <h4>ZOHO Mail</h4>
                                <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32</p>
                                <a href="#" className="p-0">Mark as read</a>
                                <a href="#" className="p-0 mx-2">Mark as unread</a>
                              </div>
                            </div>
                            <div className="d-flex gap-4 border-bottom">
                              <i class="fas fa-user-tie"></i>
                              <div className="my-2">
                                <h4>ZOHO Mail</h4>
                                <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32</p>
                                <a href="#" className="p-0">Mark as read</a>
                                <a href="#" className="p-0 mx-2">Mark as unread</a>
                              </div>
                            </div>
                            <div className="d-flex gap-4">
                              <i class="fas fa-user-tie"></i>
                              <div className="my-2">
                                <h4>ZOHO Mail</h4>
                                <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32</p>
                                <a href="#" className="p-0">Mark as read</a>
                                <a href="#" className="p-0 mx-2">Mark as unread</a>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="nav-item dropdown text_down">
                      <div className="image_round">
                        <img src={logo} className="image--coverq" />
                      </div>
                      <a
                        className="nav-link home_drop"
                        id="navbarDropdown"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {this.state.firstName + " " + this.state.lastName}
                        <i
                          className="fa fa-caret-down elly"
                          aria-hidden="true"
                        ></i>
                        <p className="text_p">Super Account</p>
                      </a>

                      <div
                        className="dropdown-menu dropdown-menu-end dropdown_menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <Link to="/settings" className="dropdown-item">
                          <i className="fa fa-user"></i>
                          <span>My Profile</span>
                        </Link>
                        {/*<Link to="/2fa" className="dropdown-item">
                          <i className="fa fa-lock"></i>
                          <span>Two Factor Auth</span>
                        </Link>
                        <Link to="/billing" className="dropdown-item">
                          <i className="fa fa-dollar-sign"></i>
                          <span>Subscription</span>
                        </Link>*/}
                        <Link
                          onClick={this.logout}
                          to="/"
                          className="dropdown-item"
                        >
                          <i className="fa fa-sign-out pull-right"></i>
                          <span>Logout</span>
                        </Link>

                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
            {/* <!-- Page content--> */}
          </div>
          {/* <!-- main --> */}
        </div>
      </div>
    );
  }
}
