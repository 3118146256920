import React, { Component } from "react";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import ellipse from "../../../img/Ellipse 37.png";
import Table from "react-bootstrap/Table";
import { NavLink } from "react-router-dom";
import "../../Company Admin/Setting/setting.css";

export default class supplier_detail extends Component {
  render() {
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="SVG Stepper">
                        <div className="stepperr_design">
                          <div className="color_div_step">
                            <div className="include">
                              <div className="row forms">
                                <div className="heading">
                                  <h4>Supplier Details</h4>
                                  <div className="text-right index">
                                    <span>
                                      <i className="fa fa-edit mx-3"></i>
                                    </span>
                                  </div>
                                </div>
                                <hr className="line mt-3"></hr>
                                <div className="col-md-8 col-xs-12">
                                  <form>
                                    <div className="business_detail">
                                      <div className="row my-3">
                                        <div className="col-lg-12 col-xs-12">
                                          <div className="form-group pb-3">
                                            <label htmlFor="exampleInputPassword1">
                                              Supplier Name
                                            </label>
                                            <input
                                              type="email"
                                              className="form-control"
                                              id="exampleInputPassword1"
                                              placeholder="Abstergo Ltd."
                                            />
                                          </div>
                                        </div>
                                        <div className="col-lg-12 col-xs-12">
                                          <div className="form-group pb-3">
                                            <label htmlFor="exampleInputPassword1">
                                              Contact Name
                                            </label>
                                            <input
                                              type="email"
                                              className="form-control"
                                              id="exampleInputPassword1"
                                              placeholder="Cameron Williamson"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-lg-12 col-xs-12">
                                          <div className="form-group pb-3">
                                            <label htmlFor="exampleInputPassword1">
                                              Corporate Email
                                            </label>
                                            <input
                                              type="email"
                                              className="form-control"
                                              id="exampleInputPassword1"
                                              placeholder="curtis.weaver@example.com"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                                <div className="col-lg-4 col-xs-12">
                                  <div className="upload_image">
                                    <img
                                      className="file-upload-image"
                                      src={ellipse}
                                      alt=""
                                    />
                                    <input
                                      className=""
                                      type="file"
                                      onChange="readURL(this);"
                                      accept="image/*"
                                    />
                                  </div>
                                  <div className="text-outside">
                                    <p>ID: 356DS543</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="color_div_step mt-3">
                            <div className="business_detail">
                              <div className="saved_cards">
                                <div className="business_detail">
                                  <div className="heading_wth_text">
                                    <div className="d-flex justify-content-between">
                                      <span className="d-flex">
                                        <span className="global_link mx-3">
                                          <button className="link_bal_next page_width white">
                                            <i className="fas fa-download white" />
                                          </button>
                                        </span>
                                        {/* <span className="global_link mx-0">
                                          <button className="link_bal_next page_width white">
                                            <i className="fas fa-sort-amount-up-alt white" />
                                          </button>
                                        </span> */}
                                      </span>
                                      <div className="form-group has-search one">
                                        <span className="fa fa-search form-control-feedback"></span>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Search..."
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="table_f">
                                  <Table striped bordered hover size="sm">
                                    <thead>
                                      <tr className="heading_color">
                                        <th style={{ width: "10%" }}>ID</th>
                                        <th style={{ width: "80%" }}>
                                          COMPANY NAME
                                        </th>
                                        <th style={{ width: "10%" }}>
                                          RESPONSE
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>1</td>
                                        <td>The Walt Disney Company</td>
                                        <td>
                                          <NavLink
                                            to="/admin/invoiceSupplierManagement"
                                            className="table-tag"
                                          >
                                            <span>
                                              <i className="fa fa-eye"></i>
                                            </span>
                                            View
                                          </NavLink>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>2</td>
                                        <td>Apple</td>
                                        <td>
                                          <NavLink
                                            to="/admin/invoiceSupplierManagement"
                                            className="table-tag"
                                          >
                                            <span>
                                              <i className="fa fa-eye"></i>
                                            </span>
                                            View
                                          </NavLink>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>3</td>
                                        <td>Louis Vuitton</td>
                                        <td>
                                          <NavLink
                                            to="/admin/invoiceSupplierManagement"
                                            className="table-tag"
                                          >
                                            <span>
                                              <i className="fa fa-eye"></i>
                                            </span>
                                            View
                                          </NavLink>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>4</td>
                                        <td>Gillette</td>
                                        <td>
                                          <NavLink
                                            to="/admin/invoiceSupplierManagement"
                                            className="table-tag"
                                          >
                                            <span>
                                              <i className="fa fa-eye"></i>
                                            </span>
                                            View
                                          </NavLink>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>5</td>
                                        <td>MasterCard</td>
                                        <td>
                                          <NavLink
                                            to="/admin/invoiceSupplierManagement"
                                            className="table-tag"
                                          >
                                            <span>
                                              <i className="fa fa-eye"></i>
                                            </span>
                                            View
                                          </NavLink>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>6</td>
                                        <td>MasterCard</td>
                                        <td>
                                          <NavLink
                                            to="/admin/invoiceSupplierManagement"
                                            className="table-tag"
                                          >
                                            <span>
                                              <i className="fa fa-eye"></i>
                                            </span>
                                            View
                                          </NavLink>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>7</td>
                                        <td>L'Oréal</td>
                                        <td>
                                          <NavLink
                                            to="/admin/invoiceSupplierManagement"
                                            className="table-tag"
                                          >
                                            <span>
                                              <i className="fa fa-eye"></i>
                                            </span>
                                            View
                                          </NavLink>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>8</td>
                                        <td>General Electric</td>
                                        <td>
                                          <NavLink
                                            to="/admin/invoiceSupplierManagement"
                                            className="table-tag"
                                          >
                                            <span>
                                              <i className="fa fa-eye"></i>
                                            </span>
                                            View
                                          </NavLink>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>9</td>
                                        <td>Johnson & Johnson</td>
                                        <td>
                                          <NavLink
                                            to="/admin/invoiceSupplierManagement"
                                            className="table-tag"
                                          >
                                            <span>
                                              <i className="fa fa-eye"></i>
                                            </span>
                                            View
                                          </NavLink>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>10</td>
                                        <td>Gillette</td>
                                        <td>
                                          <NavLink
                                            to="/admin/invoiceSupplierManagement"
                                            className="table-tag"
                                          >
                                            <span>
                                              <i className="fa fa-eye"></i>
                                            </span>
                                            View
                                          </NavLink>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                  <div className="pagination_billing justify-content-end">
                                    <ul>
                                      <li className="justify-align-left">
                                        <NavLink to="">
                                          <span>
                                            <i className="fal fa-arrow-left mx-3"></i>
                                          </span>
                                          Back
                                        </NavLink>
                                      </li>
                                      <li className="justify-align-center">
                                        <NavLink to="">
                                          Showing 1-10 of 1023
                                        </NavLink>
                                      </li>
                                      <li className="justify-align-right">
                                        <NavLink to="">
                                          Next
                                          <span>
                                            <i className="fal fa-arrow-right mx-3"></i>
                                          </span>
                                        </NavLink>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
