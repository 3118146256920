import { HashRouter as Router, Switch, Route } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import Home from "./component/Company Sub Admin/Pages/home/home";
import Login from "./component/Login/login";
import OTPVerify from "./component/Login/otpverify";
import ResetPass from "./component/Login/resetpass";
import AddSubAdmin from "./component/Login/addSubAdmin";
// import Signup from "./component/signup/signup";
import Privacy from "./component/signup/privacy";
import Sidebar from "./component/sidebar/sidebar";
import AdminSidebar from "./component/sidebar/admin_sidebar";
import Header from "./component/header/header";
import AdminHeader from "./component/header/admin_header";
import Invite from "./component/signup/invite";
import SuperSubAdminInvite from "./component/signup/SuperSubAdminInvite";
// import ZohoUserDetails from "./component/Zoho Form/ZohoUserDetails";
// import FormDetails from "./component/Zoho Form/formdetails";
import ClientReview from "./component/Zoho Form/ClientReview";
import EsgReportingPie from "./component/Company Admin/esg_repoting/esg_reporting_pie";
import setting from "./component/Company Admin/Setting/setting";
// import Checkout from "./component/Company Admin/component/setting/payment_success";
import invoice from "./component/Company Admin/Setting/invoice";
import user_detail from "./component/Company Admin/Setting/user_detail";
import Google2FA from "./component/Company Admin/component/setting/google2FA";
import dashboard from "./component/Company_Super_Admin/dashboard/dashboard";
import companies from "./component/Company_Super_Admin/companies/RegisterCompanies";
import Activities from "./component/Company_Super_Admin/companies/activities";
import IndustriesType from "./component/Industry_Manage/industriesType";
import Industries from "./component/Industry_Manage/industries";
import AddIndustries from "./component/Industry_Manage/addIndustries";
import ADDIndustryType from "./component/Industry_Manage/addIndustryType";
import EditIndustryType from "./component/Industry_Manage/editIndustryType";
import EditIndustries from "./component/Industry_Manage/editIndustries";
import SmartUPLearningRecord from "./component/SmartUp/smartup_learning_record";
import SmartUPMembership from "./component/SmartUp/smartup_membership";
import SmartUPUsers from "./component/SmartUp/smartup_users";
import SmartUPChannels from "./component/SmartUp/smartup_channels";
import SmartUPUserDetails from "./component/SmartUp/smartup_user_details";
import SmartUPChannelDetails from "./component/SmartUp/smartup_channel_details";
import SmartupTabbing from "./component/SmartUp/smartup_tabbing";
import CompaniesTabbingPage from "./component/Company_Super_Admin/companies/company_tabbing_page";
import ModuleDetail from "./component/Company_Super_Admin/companies/modul_detail";
import subAdmin from "./component/Company_Super_Admin/companies/subAdmin";
import AdminInvoice from "./component/Company_Super_Admin/companies/invoice";
// import SupplierManagement from "./component/Company_Super_Admin/supplier-management/supplier_management";
import SupplierDetail from "./component/Company_Super_Admin/supplier-management/supplier_detail";
import invoiceSupplierManagement from "./component/Company_Super_Admin/supplier-management/invoice";
import supplierManagementField from "./component/Company_Super_Admin/supplier-management/supplier_management_field";
import AddQuestion from "./component/Company_Super_Admin/supplier-management/AddQuestion";
import QuestionForm from "./component/Company_Super_Admin/supplier-management/QuestionForm";
import EsgSubAdmin from "./component/Company_Super_Admin/esg_sub_admin/EsgSubAdmin";
import Revenue from "./component/Company_Super_Admin/Revenue/Revenue";
import sectorQuestionPage from "./component/Company_Super_Admin/companies/sectorQuestionPage";
import governancePage from "./component/Company_Super_Admin/companies/governance";
import SupplierPage from "./component/Company_Super_Admin/companies/supplierPage";
// import supplierManagement from "./component/Company_Super_Admin/companies/supplierManagement";
import sustainablePage from "./component/Company_Super_Admin/companies/sustanaiblePage";
import carbonFootprintDetail from "./component/Company_Super_Admin/companies/carbonFootprintDetail";
import boardSkillPage from "./component/Company_Super_Admin/companies/boardSkillPage";
import boardSkillDetail from "./component/Company_Super_Admin/companies/boardSkillDetail";
import managementDetail from "./component/Company_Super_Admin/companies/managementDetails";
import EsgProduct from "./component/Company_Super_Admin/companies/EsgProduct";
import UserDetail from "./component/Company_Super_Admin/esg_sub_admin/UserDetail";
import StatusDetail from "./component/Company_Super_Admin/esg_sub_admin/StatusDetail";
import SupplierDetailPage from "./component/Company_Super_Admin/supplier-management/supplier_detail";
import SettingDetail from "./component/Company_Super_Admin/Setting/setting";
import SettingDetailPage from "./component/Company_Super_Admin/Setting/SettingDetailPage";
import GoogleTwoFA from "./component/Company_Super_Admin/Setting/twoFA";
import Introduction from "./component/Supplier/introduction";
import supplierForm from "./component/Supplier/supplierForm";
import SupplierQuestionDetail from "./component/Supplier/SupplierQuestionDetail";
import NotFoundRoute from "./component/Company Sub Admin/Pages/404";
import ScrollToTop from "./scrollToTop";
import CompaniesDetail from "./component/Company_Super_Admin/companies/company_detail";
import CompanyModule from "./component/Company_Super_Admin/companies/modules";
import Plans from "./component/Company_Super_Admin/companies/plans";
import ComapanySubAdmin from "./component/Company_Super_Admin/companies/subAdmin";
import ComapanybillingDetail from "./component/Company_Super_Admin/companies/billingDetail";
import GovernancePage from "./component/Company_Super_Admin/component/companies/governance";
import SocialPoliciesTabbing from "./component/Company_Super_Admin/component/companies/social_policies";
import CyberTechnologies from "./component/Company_Super_Admin/component/companies/cyber_technology";
import HealthSafetyPolicy from "./component/Company_Super_Admin/component/companies/health_safety_policy";
import EnviornmentalPolicyTabbing from "./component/Company_Super_Admin/component/companies/enviornmental_policy";
import OtpVerification from "./OtpVerification";
import AuditSupplier from "./component/Company_Super_Admin/companies/Audit/Supplier-List";
import NewPassword from "./component/Login/NewPassword";
import BillingNew from "./component/Company_Super_Admin/Setting/billingNew";
import SectorQuestionCategories from "./component/Sector_Question_Manage/sectorQuestioncategories";
import SectorQuestions from "./component/Sector_Question_Manage/SectorQuestions";
import { EditSectorQuestions } from "./component/Sector_Question_Manage/editSectorQuestions";
import AddNewCompany from "./component/Company_Super_Admin/companies/addNewCompany";
import Congratulations from "./component/Company_Super_Admin/companies/congratulations";
import CompanyInvoice from "./component/Company_Super_Admin/companies/companyInvoice";
import Massage from "./component/signup/massage";
import ResetMassage from "./component/Login/resetMassage";
import AddInnovativeProgram from "./component/programmes/addInnovativeProgram";
import EditInnovativeProgram from "./component/programmes/editInnovativeProgram";
import InnovativeLists from "./component/programmes/innovativeLists";
import AddGlobalInnovativeProgram from "./component/globalProgrammes/addGlobalInnovativeProgram";
import EditGlobalInnovativeProgram from "./component/globalProgrammes/editGlobalInnovativeProgram";
import GlobalInnovativeLists from "./component/globalProgrammes/globalInnovativeLists";
import Dashboard from "./component/Company_Super_Admin/companies/dashboard";
import EmailVerification from "./component/Verfications/EmailVerfications";
import AddIntroductionVideos from "./component/introductionVideos/addIntroductionVideos";
import EditIntroductionVideos from "./component/introductionVideos/editIntroductionVideos";
import IntroductionVideos from "./component/introductionVideos/introductionVideos";
import Policies from "./component/policiesManage/policies";
import GovernancePolicies from "./component/policiesManage/governancePolicies";
import AddNewPolicy from "./component/policiesManage/addNewPolicy";
import EditPolicy from "./component/policiesManage/editPolicy";
import CyberAndTechnologyPolicies from "./component/policiesManage/cyberTechnologyPolicies";
import SocialPolicies from "./component/policiesManage/socialPolicies";
import HelthAndSeftyPolicies from "./component/policiesManage/helthSeftyPolicies";
import EnvironmentalPolicies from "./component/policiesManage/environmentalPolicies";
import OtherPolicies from "./component/policiesManage/otherPolicies";
import CompanyPolicies from "./component/companyPoliciesManage/policies";
import AddNewCompanyPolicy from "./component/companyPoliciesManage/addNewPolicy";
import EditCompanyPolicy from "./component/companyPoliciesManage/editPolicy";
import GovernanceCompanyPolicies from "./component/companyPoliciesManage/governancePolicies";
import CyberAndTechnologyCompanyPolicies from "./component/companyPoliciesManage/cyberTechnologyPolicies";
import SocialCompanyPolicies from "./component/companyPoliciesManage/socialPolicies";
import HelthAndSeftyCompanyPolicies from "./component/companyPoliciesManage/helthSeftyPolicies";
import EnvironmentalCompanyPolicies from "./component/companyPoliciesManage/environmentalPolicies";
import OtherCompanyPolicies from "./component/companyPoliciesManage/otherPolicies";
import OthersPolicies from "./component/Company_Super_Admin/component/companies/other_policy";
import Requests from "./component/Company_Super_Admin/Requests/Requests";



import { SectorQuestionFrameworkWise } from "./component/Sector_Question_Manage/sectorQuestionindustrywise";
import CompanyAudit from "./component/Company_Super_Admin/companies/Audit/Company-Audit";
import SupplierAudit from "./component/Company_Super_Admin/companies/Audit/Supplier-Audit";
import supplierManagement from "./component/Company_Super_Admin/companies/supplierManagement";
import supplierManagementDetails from "./component/Company_Super_Admin/companies/supplierManagementDetails";
import { FinancialYear } from "./component/Company_Super_Admin/Setting/FinactialYearTab/financialYear";
import CreateFinancialYear from "./component/Company_Super_Admin/Setting/createFinancialYear";
import ConsultantDetail from "./component/Company_Super_Admin/companies/consultant_detail";
import ConsultantModule from "./component/Company_Super_Admin/companies/consultant_modules";
import ConsultantSubAdmin from "./component/Company_Super_Admin/companies/consultant_subAdmin";
import ConsultantbillingDetail from "./component/Company_Super_Admin/companies/consultant_billingDetail";
// import AddNewConsultant from "./component/Company_Super_Admin/companies/consultant_addNew";
import { CreateSectorQuestion } from "./component/Sector_Question_Manage/CreateSectorQuestion";
import esgReportingZais from "./component/Company_Super_Admin/companies/zaisreporting";
import CreateMeter from "./component/Company_Super_Admin/Setting/CreateMeter";
import CreateProcess from "./component/Company_Super_Admin/Setting/CreateProcess";
import EditMeter from "./component/Company_Super_Admin/Setting/EditMeter";
import { useContext, useEffect, useState } from "react";
import { PermissionContext } from "./context/PermissionContext";
import ErrorBoundary from "./ErrorBoundary/ErrorBoundary";
import { SendInvite } from "./component/Company_Super_Admin/Setting/SendInvite";
// import AddNewBroker from "./component/Company_Super_Admin/companies/addNewBroker";
import { authenticationService } from "./_services/authentication";
import { AccessManagement } from "./component/Company_Super_Admin/Setting/AccessManagement";
import { CreateFramework } from "./component/Company_Super_Admin/GlobalControl/Frameworks/Create";
import { EditFramework } from "./component/Company_Super_Admin/GlobalControl/Frameworks/update-framework";
import { FrameworkTopicKpiTabs } from "./component/Company_Super_Admin/GlobalControl/Frameworks/FrameworkTopicKpiTabs";
import { Topics } from "./component/Company_Super_Admin/GlobalControl/Topics/Topic";
import { EditTopic } from "./component/Company_Super_Admin/GlobalControl/Topics/update-topic";
import { CreateTopic } from "./component/Company_Super_Admin/GlobalControl/Topics/Create";
import { Kpis } from "./component/Company_Super_Admin/GlobalControl/KPI/Kpis";
import { CreateKpi } from "./component/Company_Super_Admin/GlobalControl/KPI/Create";
import { EditKpis } from "./component/Company_Super_Admin/GlobalControl/KPI/update-kpi";
import Notification from "./component/Company_Super_Admin/Notification/Notification";
import RegisterCompanies from "./component/Company_Super_Admin/companies/RegisterCompanies";
import InvitedCompanies from "./component/Company_Super_Admin/companies/InvitedCompanies";
import RegisteredBrokers from "./component/Company_Super_Admin/companies/RegisteredBrokers";
import InvitedBrokers from "./component/Company_Super_Admin/companies/InvitedBrokers";
import RegisteredGroupAdmin from "./component/Company_Super_Admin/companies/RegisteredGroupAdmin";
import InvitedGroupAdmin from "./component/Company_Super_Admin/companies/InvitedGroupAdmin";
import SettingCategories from "./component/Company_Super_Admin/Setting/SettingCategories";
import SettingCarbonEmission from "./component/Company_Super_Admin/Setting/SettingCarbonEmission";
import QuestionsModule from "./component/Company_Super_Admin/Questions Module/questions_module";
import OperationalModule from "./component/OperationalModule/operationalmodule";
import SectorModule from "./component/SectorModule/sector_module";
import Unit from "./component/Company_Super_Admin/Setting/Unit/unit";


class DebugRouter extends Router {
  constructor(props) {
    super(props);
    console.log("initial history is: ", JSON.stringify(this.history, null, 2));
    this.history.listen((location, action) => {
      console.log(
        `The current URL is ${location.pathname}${location.search}${location.hash}`
      );
      console.log(
        `The last navigation action was ${action}`,
        JSON.stringify(this.history, null, 2)
      );
    });
  }
}

export default function App() {
  let currentUser = authenticationService.currentUserSubject.getValue();
  const data = authenticationService.currentUser;
  const [menuList, setMenuList] = useState([]);
  const [settingList, setSettingList] = useState([]);
  const [globleControlList, setGlobleControlList] = useState([]);
  const { permissions, menuList1 } = useContext(PermissionContext);

  useEffect(() => {
    const settingsMenu = currentUser?.data?.menu?.find(
      (item) => item?.url === "settings"
    );
    setSettingList(settingsMenu?.permissions);
    const globleControlList = currentUser?.data?.menu?.find(
      (item) => item?.url === "global_controls"
    );
    setGlobleControlList(globleControlList?.permissions);
    data.subscribe((res) => {
      const menus = res?.data?.menu?.flatMap((item) => item?.caption);
      setMenuList(menus);
    });
  }, [currentUser]);
  return (
    <div className="App">
      <DebugRouter>
        <ErrorBoundary>
          <OtpVerification />
          <ScrollToTop />
          <Switch>
            <Route exact path="/" component={Login}></Route>
            <Route exact path="/home" component={Home}></Route>
            <Route exact path="/operational_module" component={OperationalModule}></Route>
            <Route exact path="/Login" component={Login}></Route>
            <Route exact path="/otpverify" component={OTPVerify}></Route>
            <Route exact path="/ResetPass" component={ResetPass}></Route>
            <Route exact path="/verify/:token" component={NewPassword}></Route>
            <Route exact path="/AddSubAdmin" component={AddSubAdmin}></Route>
            {/* <Route exact path="/signup" component={Signup}></Route> */}
            <Route exact path="/privacy_policy" component={Privacy}></Route>
            <Route exact path="/sidebar" component={Sidebar}></Route>
            <Route exact path="admin/sidebar" component={AdminSidebar}></Route>
            <Route exact path="/header" component={Header}></Route>
            <Route exact path="admin/header" component={AdminHeader}></Route>
            <Route exact path="/invite/:uuid" component={Invite}></Route>
            <Route exact path="/invite_user" component={SendInvite}></Route>
            <Route exact path="/verify_message" component={Massage}></Route>
            <Route exact path="/reset_massage" component={ResetMassage}></Route>
            <Route exact path="/notification" component={Notification}></Route>
            <Route exact path="/broker_admin" component={Notification}></Route>
            <Route
              exact
              path="/SubAdmin/invite/:uuid"
              component={SuperSubAdminInvite}
            ></Route>
            <Route
              exact
              path="/email/verify/:token"
              component={EmailVerification}
            ></Route>

            {/* <Route exact path="/checkout/:success/:sessionId" component={Checkout} ></Route> */}

            {/* Company Admin */}
            <Route exact path="/setting" component={setting}></Route>
            <Route
              exact
              path="/esg_reporting_pie"
              component={EsgReportingPie}
            ></Route>

            <Route exact path="/invoice" component={invoice}></Route>
            <Route
              exact
              path="/user_detail/:uuid"
              component={user_detail}
            ></Route>

            {/* Consultant  */}
            {menuList?.includes("Group Admin") && (
              <Route exact path="/group_admin/registered_group_admin" component={RegisteredGroupAdmin}></Route>
            )}

            {menuList?.includes("Group Admin") && (
              <Route exact path="/group_admin/invited_group_admin" component={InvitedGroupAdmin}></Route>
            )}

            <Route
              exact
              path="/consultant/:uuid/details"
              component={ConsultantDetail}
            ></Route>
            <Route
              exact
              path="/consultant/:uuid/modules"
              component={ConsultantModule}
            ></Route>
            <Route
              exact
              path="/consultant/:uuid/sub-accounts"
              component={ConsultantSubAdmin}
            ></Route>
            <Route
              exact
              path="/consultant/:uuid/plans"
              component={Plans}
            ></Route>
            <Route
              exact
              path="/consultant/:uuid/billing-details"
              component={ConsultantbillingDetail}
            ></Route>
            {/* <Route
              exact
              path="/add_new_consultant"
              component={AddNewConsultant}
            ></Route> */}

            {/* Broker  */}
            <Route exact path="/brokers/registered_brokers" component={RegisteredBrokers}></Route>
            <Route exact path="/brokers/invited_brokers" component={InvitedBrokers}></Route>
            {/* <Route
              exact
              path="/add_new_broker"
              component={AddNewBroker}
            ></Route> */}

            {/* Zais Reporting*/}

            {menuList?.includes("Reporting") && (
              <Route
                exact
                path="/esg_report"
                component={esgReportingZais}
              ></Route>
            )}

            {/* Company Super Admin */}
            {menuList?.includes("Dashboard") && (
              <Route exact path="/home" component={dashboard}></Route>
            )}
            {menuList?.includes("Companies") && (
              <Route exact path="/companies/registered_companies" component={RegisterCompanies}></Route>
            )}
            {menuList?.includes("Companies") && (
              <Route exact path="/companies/invited_companies" component={InvitedCompanies}></Route>
            )}

            <Route
              exact
              path="/add_new_company"
              component={AddNewCompany}
            ></Route>
            <Route
              exact
              path="/congratulations"
              component={Congratulations}
            ></Route>
            <Route
              exact
              path="/admin/CompaniesTabbingPage"
              component={CompaniesTabbingPage}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/details"
              component={CompaniesDetail}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/modules"
              component={CompanyModule}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/dashboard"
              component={Dashboard}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/sub-accounts"
              component={ComapanySubAdmin}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/billing-details"
              component={ComapanybillingDetail}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/plans"
              component={Plans}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/esg-reporting"
              component={ModuleDetail}
            ></Route>

            {/* Company Super Admin */}
            {menuList?.includes("Dashboard") && (
              <Route exact path="/home" component={dashboard}></Route>
            )}
            {menuList?.includes("Companies") && (
              <Route exact path="/companies" component={companies}></Route>
            )}
            <Route
              exact
              path="/add_new_company"
              component={AddNewCompany}
            ></Route>
            <Route
              exact
              path="/congratulations"
              component={Congratulations}
            ></Route>
            <Route
              exact
              path="/admin/CompaniesTabbingPage"
              component={CompaniesTabbingPage}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/details"
              component={CompaniesDetail}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/modules"
              component={CompanyModule}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/dashboard"
              component={Dashboard}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/sub-accounts"
              component={ComapanySubAdmin}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/billing-details"
              component={ComapanybillingDetail}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/plans"
              component={Plans}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/esg-reporting"
              component={ModuleDetail}
            ></Route>

            {/* <Route exact path="/companies/:uuid/company_listing" component={AuditSubAdmin}></Route> */}
            <Route
              exact
              path="/companies/:uuid/company_audit"
              component={CompanyAudit}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/supplier_listing"
              component={AuditSupplier}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/supplier_audit"
              component={SupplierAudit}
            ></Route>

            <Route exact path="/admin/subAdmin" component={subAdmin}></Route>
            <Route
              exact
              path="/companies/:uuid/invoice"
              component={CompanyInvoice}
            ></Route>
            <Route
              exact
              path="/admin/supplier_detail"
              component={SupplierDetail}
            ></Route>
            <Route
              exact
              path="/admin/invoiceSupplierManagement"
              component={invoiceSupplierManagement}
            ></Route>
            <Route
              exact
              path="/admin/supplierManagementField"
              component={supplierManagementField}
            ></Route>
            <Route
              exact
              path="/admin/AddQuestion"
              component={AddQuestion}
            ></Route>
            <Route
              exact
              path="/admin/QuestionForm"
              component={QuestionForm}
            ></Route>
            {settingList?.includes("CREATE SUB USER") && (
              <Route exact path="/settings/sub-users" component={EsgSubAdmin}></Route>
            )}
            {menuList?.includes("Revenue") && (
              <Route exact path="/revenue" component={Revenue}></Route>
            )}
            {menuList?.includes("Partners Request") && (
              <Route
                exact
                path="/partners_request"
                component={Requests}
              ></Route>
            )}
            <Route
              exact
              path="/revenue/invoice/:uuid"
              component={AdminInvoice}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/sector-question"
              component={sectorQuestionPage}
            ></Route>
            <Route
              exact
              path="/admin/governance"
              component={governancePage}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/governance/governance-policy"
              component={GovernancePage}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/governance/social-policy"
              component={SocialPoliciesTabbing}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/governance/cyber-and-policy"
              component={CyberTechnologies}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/governance/other_policy"
              component={OthersPolicies}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/governance/health-and-safety-policy"
              component={HealthSafetyPolicy}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/governance/environmental-policy"
              component={EnviornmentalPolicyTabbing}
            ></Route>
            {/* <Route exact path="/admin/supplierPage" component={SupplierPage} ></Route> */}
            <Route
              exact
              path="/companies/:uuid/modules/suppliers"
              component={SupplierPage}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/modules/supplier-management"
              component={supplierManagement}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/modules/supplier_management_detail"
              component={supplierManagementDetails}
            ></Route>

            {/* <Route exact path="/admin/sustainablePage" component={sustainablePage} ></Route> */}
            <Route
              exact
              path="/companies/:uuid/modules/sustainable"
              component={sustainablePage}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/modules/carbon-footprint"
              component={carbonFootprintDetail}
            ></Route>
            <Route
              exact
              path="/admin/boardSkillPage"
              component={boardSkillPage}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/modules/board-skills"
              component={boardSkillDetail}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/modules/management"
              component={managementDetail}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/modules/esg-products"
              component={EsgProduct}
            ></Route>
            <Route exact path="/sub-users/:uuid" component={UserDetail}></Route>
            <Route
              exact
              path="/sub-users/:uuid/companies"
              component={StatusDetail}
            ></Route>
            {/* <Route exact path="/admin/StatusDetail" component={StatusDetail} ></Route> */}
            <Route
              exact
              path="/admin/SupplierDetailPage"
              component={SupplierDetailPage}
            ></Route>
            {menuList?.includes("Settings") && (
              <Route exact path="/settings" component={SettingDetail}></Route>
            )}
            {settingList?.includes("CREATE_FINANCIAL_YEAR") && (
              <Route exact path="/settings/financial_year" component={FinancialYear}></Route>
            )}
            <Route
              exact
              path="/financial_year/create"
              component={CreateFinancialYear}
            ></Route>

            <Route exact path="/settings/category" component={SettingCategories} ></Route>
            <Route exact path="/settings/unit" component={Unit}></Route>
            {/* <Route exact path="/settings/question_module" component={QuestionModule} ></Route> */}
            <Route exact path="/settings/carbon_emission" component={SettingCarbonEmission} ></Route>
            {settingList?.includes(
              "GET ALL SUBSCRIPTION",
              "CREATE SUBSCRIPTION"
            ) && <Route exact path="/settings/billing" component={BillingNew}></Route>}
            {/* {settingList?.includes("CREATE_METER_ID") && (
            <Route exact path="/generator" component={GeneratorNew}></Route>
            )} */}
            <Route exact path="/settings/access_management" component={AccessManagement}></Route>
            <Route
              exact
              path="/generator/create"
              component={CreateMeter}
            ></Route>
            <Route
              exact
              path="/generator/:id/update_meter"
              component={EditMeter}
            ></Route>
            <Route
              exact
              path="/generator/create_process"
              component={CreateProcess}
            ></Route>
            <Route
              exact
              path="/generator/:id/update_process"
              component={EditMeter}
            ></Route>
            {/* <Route exact path="/2fa" component={TwoFaNew}></Route> */}

            <Route exact path="/admin/Google2FA" component={Google2FA}></Route>
            <Route exact path="/admin/twoFA" component={GoogleTwoFA}></Route>
            <Route
              exact
              path="/admin/SettingDetailPage"
              component={SettingDetailPage}
            ></Route>

            <Route
              exact
              path="/supplier/Introduction"
              component={Introduction}
            ></Route>
            <Route
              exact
              path="/supplier/supplierForm"
              component={supplierForm}
            ></Route>
            <Route
              exact
              path="/supplier/SupplierQuestionDetail"
              component={SupplierQuestionDetail}
            ></Route>
            <Route
            exact
            path="/reporting_module"
            component={QuestionsModule}>

            </Route>
            <Route
            exact
            path="/emission_module"
            component={SectorModule}>

            </Route>
            {menuList?.includes("Activities") && (
              <Route exact path="/activities" component={Activities}></Route>
            )}
            {menuList?.includes("Global Controls") && (
              <Route exact path="/global_controls" component={FrameworkTopicKpiTabs}></Route>
            )}

            {/* {menuList?.includes("Global Controls") && (
              <Route exact path="/global_controls/manage_global_control" component={ManageAndControls}></Route>
            )} */}

            {permissions.includes() && (
              <Route
                exact
                path="/industry_categories"
                component={IndustriesType}
              ></Route>
            )}
            <Route
              exact
              path="/industry/:id/industries_name"
              component={Industries}
            ></Route>
            <Route exact path="/add_industry" component={AddIndustries}></Route>
            <Route
              exact
              path="/industry/:id/update_industry"
              component={EditIndustries}
            ></Route>
            <Route
              exact
              path="/add_industry_type"
              component={ADDIndustryType}
            ></Route>
            <Route
              exact
              path="/industry/:id/update_industry_type"
              component={EditIndustryType}
            ></Route>

            <Route
              exact
              path="/sector_questions/:id/questions_tabs"
              component={SectorQuestionCategories}
            ></Route>
            {globleControlList?.includes("SECTOR_QUESTION_CREATE") && (
              <Route
                exact
                path="/questions_framework_wise"
                component={SectorQuestionFrameworkWise}
              ></Route>
            )}

            {/* {globleControlList?.includes(
              "FRAMEWORK_UPDATE",
              "FRAMEWORK_VIEW",
              "FRAMEWORK_DELETE",
              "FRAMEWORK_CREATE"
            ) && (
              <Route exact path="/global_controls/:id/frameworks" component={FrameworkTopicKpiTabs}></Route>
            )} */}
            {globleControlList?.includes("FRAMEWORK_CREATE") && (
              <Route
                exact
                path="/framework/create"
                component={CreateFramework}
              ></Route>
            )}
            {globleControlList?.includes("FRAMEWORK_UPDATE") && (
              <Route
                exact
                path="/frameworks/:id/update_framework"
                component={EditFramework}
              ></Route>
            )}

            {globleControlList?.includes("TOPIC_VIEW") && (
              <Route exact path="/topics" component={Topics}></Route>
            )}
            {globleControlList?.includes("TOPIC_CREATE") && (
              <Route exact path="/topic/create" component={CreateTopic}></Route>
            )}
            <Route
              exact
              path="/topics/:id/update_topic"
              component={EditTopic}
            ></Route>

            {globleControlList?.includes(
              "KPI_UPDATE",
              "KPI_VIEW",
              "KPI_DELETE",
              "KPI_CREATE"
            ) && <Route exact path="/kpi" component={Kpis}></Route>}
            {globleControlList?.includes("KPI_CREATE") && (
              <Route exact path="/kpi/create" component={CreateKpi}></Route>
            )}
            {globleControlList?.includes("KPI_UPDATE") && (
              <Route
                exact
                path="/kpi/:id/update_kpi"
                component={EditKpis}
              ></Route>
            )}

            <Route
              exact
              path="/sector_questions/:tab_name/:id/question_lists"
              component={SectorQuestions}
            ></Route>
            <Route
              exact
              path="/add_sector_questions"
              component={CreateSectorQuestion}
            ></Route>
            <Route
              exact
              path="/sector_questions/:id/update_sector_question"
              component={EditSectorQuestions}
            ></Route>
            <Route
              exact
              path="/admin/Smartup_Tabbing"
              component={SmartupTabbing}
            ></Route>
            <Route
              exact
              path="/admin/SmartUP/LearningRecords"
              component={SmartUPLearningRecord}
            ></Route>
            <Route exact path="/admin/SmartUP" component={SmartUPUsers}></Route>
            <Route
              exact
              path="/admin/SmartUP/Users"
              component={SmartUPUsers}
            ></Route>
            <Route
              exact
              path="/admin/SmartUP/Membership"
              component={SmartUPMembership}
            ></Route>
            <Route
              exact
              path="/admin/SmartUP/Channels"
              component={SmartUPChannels}
            ></Route>
            <Route
              exact
              path="/admin/SmartUP/Users/Details"
              component={SmartUPUserDetails}
            ></Route>
            <Route
              exact
              path="/admin/SmartUP/Channels/Details"
              component={SmartUPChannelDetails}
            ></Route>
            {/* <Route exact path="/Zoho-Form" component={FormDetails}></Route> */}
            <Route exact path="/ClientReview" component={ClientReview}></Route>
            {/* <Route exact path="/Zoho-Form/Details/:id/details" component={ZohoUserDetails} ></Route> */}
            <Route
              exact
              path="/companies/:uuid/add_innovative_programmes"
              component={AddInnovativeProgram}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/:id/update_innovative_programmes"
              component={EditInnovativeProgram}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/innovative_programmes"
              component={InnovativeLists}
            ></Route>

            <Route
              exact
              path="/add_innovative_programmes"
              component={AddGlobalInnovativeProgram}
            ></Route>
            <Route
              exact
              path="/program/:id/update_innovative_programmes"
              component={EditGlobalInnovativeProgram}
            ></Route>
            {globleControlList?.includes("KPI_CREATE") && (
              <Route
                exact
                path="/innovative_programmes"
                component={GlobalInnovativeLists}
              ></Route>
            )}

            <Route
              exact
              path="/add_introduction_videos"
              component={AddIntroductionVideos}
            ></Route>
            <Route
              exact
              path="/video/:id/update_introduction_video"
              component={EditIntroductionVideos}
            ></Route>
            {globleControlList?.includes("KPI_CREATE") && (
              <Route
                exact
                path="/introduction_videos"
                component={IntroductionVideos}
              ></Route>
            )}

            {globleControlList?.includes("KPI_CREATE") && (
              <Route exact path="/policies" component={Policies}></Route>
            )}
            <Route
              exact
              path="/add_new_policy"
              component={AddNewPolicy}
            ></Route>
            <Route
              exact
              path="/policy/:id/update_policy"
              component={EditPolicy}
            ></Route>
            <Route
              exact
              path="/governance_policies"
              component={GovernancePolicies}
            ></Route>
            <Route
              exact
              path="/cyber_&_technology_policies"
              component={CyberAndTechnologyPolicies}
            ></Route>
            <Route
              exact
              path="/social_policies"
              component={SocialPolicies}
            ></Route>
            <Route
              exact
              path="/health_&_sefty_policies"
              component={HelthAndSeftyPolicies}
            ></Route>
            <Route
              exact
              path="/environmental_policies"
              component={EnvironmentalPolicies}
            ></Route>
            <Route
              exact
              path="/other_policies"
              component={OtherPolicies}
            ></Route>

            <Route
              exact
              path="/companies/:uuid/company_policies"
              component={CompanyPolicies}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/add_new_company_policy"
              component={AddNewCompanyPolicy}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/:id/update_company_policy"
              component={EditCompanyPolicy}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/governance_company_policies"
              component={GovernanceCompanyPolicies}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/cyber_&_technology_company_policies"
              component={CyberAndTechnologyCompanyPolicies}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/social_company_policies"
              component={SocialCompanyPolicies}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/health_&_sefty_company_policies"
              component={HelthAndSeftyCompanyPolicies}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/environmental_company_policies"
              component={EnvironmentalCompanyPolicies}
            ></Route>
            <Route
              exact
              path="/companies/:uuid/other_company_policies"
              component={OtherCompanyPolicies}
            ></Route>
            {/* <Route path="**"  /> */}

            <Route path="*" component={NotFoundRoute} />
            {/* <Redirect path="**" to="/"/> */}
          </Switch>
        </ErrorBoundary>
        {/* </Router> */}
      </DebugRouter>
    </div>
  );
}
