import React, { useEffect, useState } from "react";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import config from "../../../config/config.json";
import { apiCall } from "../../../_services/apiCall";

export default function CreateMeter(props) {
  const [companyList, setcompanyList] = useState();
  const [companyId, setCompanyId] = useState();
  const [process, setProcess] = useState();
  const [error,setError] = useState('');  
  
  const callApi = async () => {
    const {isSuccess,data} = await apiCall(`${config.ADMIN_API_URL}getCompanyList`,{},{},"GET");
    if(isSuccess){
      setcompanyList(data?.data);
    }
  };

  useEffect(() => {
    callApi();
  }, []);

  const submitHandler = async(e) => {
    e.preventDefault();
    if(!companyId || !process){
        setError('All fields are required.')
        return;
    }
    const data1={    
        process:process, 
        company_id:companyId,
    }
    const {isSuccess,data} = await apiCall(`${config.ADMIN_API_URL}createProcessId`,{},data1,"POST");
    if(isSuccess){
      setCompanyId('');   
      setProcess(''); 
    }

  };
  return (
    <div>
      <AdminSidebar dataFromParent={props.location.pathname} />
      <AdminHeader />
      <div className="main_wrapper">
        <div className="inner_wraapper">
          <div className="container-fluid">
            <section className="d_text">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="col-sm-12">
                      <div className="color_div_on framwork_2">
                        <div className="business_detail">
                          <div className="">
                            <div className="saved_cards">
                              <form name="form">
                                <div className="business_detail">
                                  <div className="heading">
                                    <h4>Add Source</h4>
                                  </div>
                                  <hr className="line"></hr>
                                  <div className="row">
                                    <div className="col-lg-4 col-xs-12">
                                      <div className="form-group pb-3">
                                        <label
                                          htmlFor="industryType"
                                          className="mb-2"
                                        >
                                          Select Company*
                                        </label>
                                        <select
                                          name="tab_name"
                                          value={companyId}
                                          onChange={(e) =>
                                            setCompanyId(e.target.value)
                                          }
                                          className="select_one industrylist"
                                        >
                                          <option value="0" >All</option>
                                          {companyList &&
                                            companyList.map((item, key) => (
                                              <option key={key} value={item.id}>
                                                {item.register_company_name}
                                              </option>
                                            ))}
                                        </select>
                                      </div>
                                    </div>
                                 
                                    <div className="col-lg-4 col-xs-12">
                                      <div className="form-group ">
                                        <label
                                          htmlFor="industryType"
                                          className="mb-2"
                                        >
                                          Process
                                        </label>
                                        <input
                                          className="form-control"
                                          value={process}
                                          onChange={(e) =>
                                            setProcess(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                
                                  </div>
                                  {error && <>{error}</>}
                                </div>
                                <div className="global_link mx-0 my-3">
                                  <button
                                    className="page_width page_save"
                                    onClick={submitHandler}
                                  >
                                    ADD NOW
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
