import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import SupplierHeader from "../../component/header/supplierHeader";
import "../../component/Company Sub Admin/Pages/esg_reporting/esg_reporting.css";
export default class SupplierQuestionDetail extends Component {
  render() {
    return (
      <div>
        <SupplierHeader />
        <div className="main_wrapper2">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="d-flex justify-content-between">
                        <h6 className="back_quninti back_quninti_2">
                          <NavLink
                            className="back_text"
                            to="/supplier/supplierForm"
                          >
                            <span className="step_icon">
                              <i className="far fa-long-arrow-alt-left"></i>
                            </span>
                            Back to Edit
                          </NavLink>
                        </h6>
                      </div>
                      <div className="frameworks framwork_2">
                        <div className="col-md-12 d-flex justify-content-between align-items-center">
                          <div className="Capital_op">
                            <p className="m-0 font-weight-bold">
                              Please review your answers below and click submit
                              to confirm your submission:
                            </p>
                          </div>
                          <div className="global_link">
                            <button className="page_save page_width">
                              SUBMIT
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="frameworks framwork_2">
                        <div className="text_Parts">
                          <div className="col-md-12">
                            <div className="Capital_op">
                              <h4 className="E_capital">Lorem ipsum</h4>
                              <div className="manag">
                                <h3 className="energy">
                                  Q: Aliquip ex ea commodo consequate dolor in
                                  voluptate velit esse cillum dolore eu fugiat?
                                </h3>
                                <p className="sumption">
                                  Exea commodo consequat. Duis aute irure dolor
                                  in reprehenderit in voluptate velit esse
                                  cillum dolore eu fugiat nulla pariatur.
                                </p>
                                <hr className="related" />
                              </div>
                              <div className="manag my-5">
                                <h3 className="energy">
                                  Q: Excepteur sint occaecat cupidatat non
                                  proident, sunt in culpa qui officia des?
                                </h3>
                                <p className="sumption">
                                  Duis aute irure dolor
                                </p>
                                <hr className="related" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="frameworks framwork_2">
                        <div className="text_Parts">
                          <div className="col-md-12">
                            <div className="Capital_op">
                              <h4 className="E_capital">Dolor Sit</h4>
                              <div className="manag">
                                <h3 className="energy">
                                  Q: Aliquip ex ea commodo consequate dolor in
                                  voluptate velit esse cillum dolore eu fugiat?
                                </h3>
                                <p className="sumption">
                                  Exea commodo consequat. Duis aute irure dolor
                                  in reprehenderit in voluptate velit esse
                                  cillum dolore eu fugiat nulla pariatur.
                                </p>
                                <hr className="related" />
                              </div>
                              <div className="manag my-5">
                                <h3 className="energy">
                                  Q: Excepteur sint occaecat cupidatat non
                                  proident, sunt in culpa qui officia des?
                                </h3>
                                <p className="sumption">
                                  Exea commodo consequat. Duis aute irure dolor
                                  in reprehenderit in voluptate velit esse
                                  cillum dolore eu fugiat nulla pariatur.
                                </p>
                                <hr className="related" />
                              </div>
                              <div className="manag my-5">
                                <h3 className="energy">
                                  Q: Aliquip ex ea commodo consequate dolor in
                                  voluptate velit esse cillum dolore eu fugiat?
                                </h3>
                                <p className="sumption">
                                  Exea commodo consequat. Duis aute irure dolor
                                  in reprehenderit in voluptate velit esse
                                  cillum dolore eu fugiat nulla pariatur.
                                </p>
                                <hr className="related" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="frameworks framwork_2">
                        <div className="text_Parts">
                          <div className="col-md-12">
                            <div className="Capital_op">
                              <h4 className="E_capital">
                                Consectetur Adipiscin
                              </h4>
                              <div className="manag">
                                <h3 className="energy">
                                  Q: Aliquip ex ea commodo consequate dolor in
                                  voluptate velit esse cillum dolore eu fugiat?
                                </h3>
                                <p className="sumption">
                                  Exea commodo consequat. Duis aute irure dolor
                                  in reprehenderit in voluptate velit esse
                                  cillum dolore eu fugiat nulla pariatur.
                                </p>
                                <hr className="related" />
                              </div>
                              <div className="manag my-5">
                                <h3 className="energy">
                                  Q: Excepteur sint occaecat cupidatat non
                                  proident, sunt in culpa qui officia des?
                                </h3>
                                <p className="sumption">
                                  Duis aute irure dolor
                                </p>
                                <hr className="related" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="frameworks framwork_2">
                        <div className="text_Parts">
                          <div className="col-md-12">
                            <div className="Capital_op">
                              <h4 className="E_capital">
                                Nostrud Exercitation
                              </h4>
                              <div className="manag">
                                <h3 className="energy">
                                  Q: Aliquip ex ea commodo consequate dolor in
                                  voluptate velit esse cillum dolore eu fugiat?
                                </h3>
                                <p className="sumption">
                                  Exea commodo consequat. Duis aute irure dolor
                                  in reprehenderit in voluptate velit esse
                                  cillum dolore eu fugiat nulla pariatur.
                                </p>
                                <hr className="related" />
                              </div>
                              <div className="manag my-5">
                                <h3 className="energy">
                                  Q: Aliquip ex ea commodo consequate dolor in
                                  voluptate velit esse cillum dolore eu fugiat?
                                </h3>
                                <p className="sumption">
                                  Exea commodo consequat. Duis aute irure dolor
                                  in reprehenderit in voluptate velit esse
                                  cillum dolore eu fugiat nulla pariatur.
                                </p>
                                <hr className="related" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
