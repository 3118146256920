import React, { useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import { Accordion, Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { apiCall } from "../../../_services/apiCall";
import config from "../../../config/config.json";
import { authenticationService } from "../../../_services/authentication";
import { PermissionContext } from "../../../context/PermissionContext";

export const AccessManagement = () => {
  const location = useLocation();
  const [meterDataList, setMeterDataList] = useState();
  const { permissions } = useContext(PermissionContext);
  const [token] = useState(JSON.parse(localStorage.getItem("currentUser")));
  const [menulist, setMenuList] = useState([]);
  const callApi = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}getMeterId`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setMeterDataList(data?.data);
    }
  };

  useEffect(() => {
    const currentUser = authenticationService?.currentUserSubject?.getValue();
    const settingsMenu = currentUser?.data?.menu?.find(
      (item) => item?.url === "settings"
    );
    setMenuList(settingsMenu?.permissions);
    callApi();
  }, []);

  const deleteMeter = async (id) => {
    let obj = {};
    obj.id = id;
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}deleteMeterId`,
      {},
      obj,
      "POST"
    );
    if (isSuccess) {
      callApi();
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <AdminSidebar dataFromParent={location.pathname} />
      <AdminHeader />
      <div className="main_wrapper">
        <div className="inner_wraapper">
          <div className="hol_rell">
            <div className="steps-form">
              <div className="steps-row setup-panel">
                <div className="tabs-top setting_admin my-0">
                  <ul>
                    <li>
                      <NavLink to="/settings"> My Profile </NavLink>
                    </li>
                    {menulist.includes(
                      "GET ALL SUBSCRIPTION",
                      "CREATE SUBSCRIPTION"
                    ) && (
                        <li>
                          <NavLink to="/settings/billing"> Billing </NavLink>
                        </li>
                      )}
                    <li>
                      <NavLink to="/settings/access_management" className="activee">
                        {" "}
                        Access{" "}
                      </NavLink>
                    </li>
                    {menulist.includes(
                      "CREATE_FINANCIAL_YEAR"
                    ) && (
                        <li>
                          <NavLink to="/settings/financial_year">
                            Financial Year
                          </NavLink>
                        </li>
                      )}
                    {menulist.includes("CREATE SUB USER") && (
                      <li>
                        <NavLink to="/settings/sub-users">Sub Users</NavLink>
                      </li>
                    )}
                    <li>
                      <NavLink to="/settings/category">
                        Category
                      </NavLink>
                    </li>
                    <li>
                    <NavLink to="/settings/carbon_emission">
                      Carbon Emission
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/settings/unit">
                      Unit
                    </NavLink>
                  </li>
                  </ul>
                </div>
                <div className="hstack gap-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Management Name"
                    name="search"
                    onChange={(e) =>
                      this.applyGlobalSearch(e)
                    }
                  />
                  <Button
                    variant="info"
                    onClick={handleShow}
                    className="mx-2"
                  >
                    Create Management Group
                  </Button>
                </div>
              </div>
              <div className="color_div_on framwork_2 pt-0">
                <div className="business_detail">
                  <div className="table-responsive" style={{ overflowX: "hidden" }}>
                    <Row>
                      <Col md={4} style={{ borderRight: "1px dashed #c4c4c4" }}>
                        <div className="table___height" style={{ overflowX: "hidden" }}>
                          <Table striped bordered className="m-0" style={{ whiteSpace: "nowrap" }}>
                            <thead>
                              <tr style={{ background: "#ccc" }}>
                                <th style={{ width: 55 }}>Sr. No</th>
                                <th>Management Name</th>
                                <th style={{ width: 75 }}>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>1</td>
                                <td>Mark</td>
                                <td>
                                  <div className="hstack justify-content-center gap-2">
                                    <i class="fas fa-edit"></i>
                                    <i class="fas fa-trash-alt" style={{ color: "red" }}></i>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>2</td>
                                <td>Jacob</td>
                                <td>
                                  <div className="hstack justify-content-center gap-2">
                                    <i class="fas fa-edit"></i>
                                    <i class="fas fa-trash-alt" style={{ color: "red" }}></i>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>3</td>
                                <td>@twitter</td>
                                <td >
                                  <div className="hstack justify-content-center gap-2">
                                    <i class="fas fa-edit"></i>
                                    <i class="fas fa-trash-alt" style={{ color: "red" }}></i>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                      <Col md={8}>
                        <div className="access__group__section mb-2">
                          {/* -------------------- User Access management  ---------------------- */}
                          <div className="access__section">
                            <Accordion>
                              <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                  User management
                                </Accordion.Header>
                                <Accordion.Body className="border_box">
                                  <Row className="px-3">
                                    <Col md={4}>
                                      <Form.Check type="checkbox" id="check1" label="Edit" />
                                    </Col>
                                    <Col md={4}>
                                      <Form.Check
                                        type="checkbox"
                                        id="check2"
                                        label="Assign Client"
                                      />
                                    </Col>
                                    <Col md={4}>
                                      <Form.Check
                                        type="checkbox"
                                        id="check3"
                                        label="Assign Role"
                                      />
                                    </Col>
                                    <Col md={4}>
                                      <Form.Check
                                        type="checkbox"
                                        id="check4"
                                        label="View User Detail"
                                      />
                                    </Col>
                                    <Col md={4}>
                                      <Form.Check
                                        type="checkbox"
                                        id="check5"
                                        label="Change Status"
                                      />
                                    </Col>
                                    <Col md={4}>
                                      <Form.Check
                                        type="checkbox"
                                        id="check6"
                                        label="View History"
                                      />
                                    </Col>
                                    <Col md={4}>
                                      <Form.Check
                                        type="checkbox"
                                        id="check7"
                                        label="Download User table"
                                      />
                                    </Col>
                                  </Row>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>


                          </div>
                          {/* -------------------- Client Access management  ---------------------- */}
                          <div className="access__section">
                            <Accordion>
                              <Accordion.Item eventKey="0">
                                <Accordion.Header>Client management</Accordion.Header>
                                <Accordion.Body className="border_box">
                                  <Row className="px-3">
                                    <Col md={4}>
                                      <Form.Check
                                        type="checkbox"
                                        id="check8"
                                        label="Create"
                                      />
                                    </Col>
                                    <Col md={4}>
                                      <Form.Check type="checkbox" id="check9" label="Edit" />
                                    </Col>
                                    <Col md={4}>
                                      <Form.Check
                                        type="checkbox"
                                        id="check10"
                                        label="Change Status"
                                      />
                                    </Col>
                                    <Col md={4}>
                                      <Form.Check
                                        type="checkbox"
                                        id="check11"
                                        label="Invite Client"
                                      />
                                    </Col>
                                    <Col md={4}>
                                      <Form.Check
                                        type="checkbox"
                                        id="check12"
                                        label="View History"
                                      />
                                    </Col>
                                    <Col md={4}>
                                      <Form.Check
                                        type="checkbox"
                                        id="check13"
                                        label="Download User table"
                                      />
                                    </Col>
                                  </Row>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                          {/* -------------------- Group Admin Access management  ---------------------- */}
                          {/* <div className="access__section">
                            <p>
                              <strong>Group Admin management</strong>
                            </p>
                            <Row className="px-3">
                              <Col md={4}>
                                <Form.Check
                                  type="checkbox"
                                  id="check33"
                                  label="Create"
                                />
                              </Col>
                              <Col md={4}>
                                <Form.Check type="checkbox" id="check34" label="Edit" />
                              </Col>
                              <Col md={4}>
                                <Form.Check
                                  type="checkbox"
                                  id="check35"
                                  label="Change Status"
                                />
                              </Col>
                              <Col md={4}>
                                <Form.Check
                                  type="checkbox"
                                  id="check36"
                                  label="View History"
                                />
                              </Col>
                              <Col md={4}>
                                <Form.Check
                                  type="checkbox"
                                  id="check37"
                                  label="Download User Table"
                                />
                              </Col>
                            </Row>
                          </div> */}
                          {/* -------------------- Request Access management  ---------------------- */}
                          {/* <div className="access__section">
                            <p>
                              <strong>Request management</strong>
                            </p>
                            <Row className="px-3">
                              <Col md={4}>
                                <Form.Check
                                  type="checkbox"
                                  id="check14"
                                  label="Approve Client Creation Request"
                                />
                              </Col>
                              <Col md={4}>
                                <Form.Check
                                  type="checkbox"
                                  id="check15"
                                  label="Send Create Client Request"
                                />
                              </Col>
                              <Col md={4}>
                                <Form.Check
                                  type="checkbox"
                                  id="check16"
                                  label="Send user Creation Request"
                                />
                              </Col>
                              <Col md={4}>
                                <Form.Check
                                  type="checkbox"
                                  id="check17"
                                  label="Approve User Creation Request"
                                />
                              </Col>
                            </Row>
                          </div> */}
                          {/* -------------------- Framework Access management  ---------------------- */}
                          {/* <div className="access__section">
                            <p>
                              <strong>Framework management</strong>
                            </p>
                            <Row className="px-3">
                              <Col md={4}>
                                <Form.Check
                                  type="checkbox"
                                  id="check18"
                                  label="Create"
                                />
                              </Col>
                              <Col md={4}>
                                <Form.Check type="checkbox" id="check19" label="Edit" />
                              </Col>
                              <Col md={4}>
                                <Form.Check
                                  type="checkbox"
                                  id="check20"
                                  label="Delete"
                                />
                              </Col>
                            </Row>
                          </div> */}
                          {/* -------------------- Topic Access management  ---------------------- */}
                          {/* <div className="access__section">
                            <p>
                              <strong>Topic management</strong>
                            </p>
                            <Row className="px-3">
                              <Col md={4}>
                                <Form.Check
                                  type="checkbox"
                                  id="check21"
                                  label="Create"
                                />
                              </Col>
                              <Col md={4}>
                                <Form.Check type="checkbox" id="check22" label="Edit" />
                              </Col>
                              <Col md={4}>
                                <Form.Check
                                  type="checkbox"
                                  id="check23"
                                  label="Delete"
                                />
                              </Col>
                            </Row>
                          </div> */}
                          {/* -------------------- KPI Access management  ---------------------- */}
                          {/* <div className="access__section">
                            <p>
                              <strong>KPI management</strong>
                            </p>
                            <Row className="px-3">
                              <Col md={4}>
                                <Form.Check
                                  type="checkbox"
                                  id="check24"
                                  label="Create"
                                />
                              </Col>
                              <Col md={4}>
                                <Form.Check type="checkbox" id="check25" label="Edit" />
                              </Col>
                              <Col md={4}>
                                <Form.Check
                                  type="checkbox"
                                  id="check26"
                                  label="Delete"
                                />
                              </Col>
                            </Row>
                          </div> */}
                          {/* -------------------- Question Access management  ---------------------- */}
                          {/* <div className="access__section">
                            <p>
                              <strong>Question management</strong>
                            </p>
                            <Row className="px-3">
                              <Col md={4}>
                                <Form.Check
                                  type="checkbox"
                                  id="check27"
                                  label="Create"
                                />
                              </Col>
                              <Col md={4}>
                                <Form.Check type="checkbox" id="check28" label="Edit" />
                              </Col>
                              <Col md={4}>
                                <Form.Check
                                  type="checkbox"
                                  id="check29"
                                  label="Delete"
                                />
                              </Col>
                            </Row>
                          </div> */}
                          {/* -------------------- Subscription Access management  ---------------------- */}
                          {/* <div className="access__section">
                            <p>
                              <strong>Subscription management</strong>
                            </p>
                            <Row className="px-3">
                              <Col md={4}>
                                <Form.Check
                                  type="checkbox"
                                  id="check30"
                                  label="Create"
                                />
                              </Col>
                              <Col md={4}>
                                <Form.Check type="checkbox" id="check31" label="Edit" />
                              </Col>
                              <Col md={4}>
                                <Form.Check
                                  type="checkbox"
                                  id="check32"
                                  label="Delete"
                                />
                              </Col>
                            </Row>
                          </div> */}
                        </div>
                        <div className="text-end">
                          <Button variant="info">
                            Assign
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Management Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input className="form-control" type="text" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info">Create</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
