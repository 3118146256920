/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { Component } from "react";
import { sweetAlert } from "../../../utils/UniversalFunction";
import "./companies.css";
import "../../signup/signup.css";
import config from "../../../config/config.json";
// import ReactTooltip from "react-tooltip";
import {
  // checkPasswordValidation,
  isValidEmail,
} from "../../../utils/UniversalFunction";
import { apiCall } from "../../../_services/apiCall";
import { Col, Row } from "react-bootstrap";
const baseURL = config.baseURL;

export default class AddNewBroker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      error2: null,
      isLoaded2: false,
      error3: null,
      isLoaded3: false,
      items: [],
      user: {
        firstName: "",
        lastName: "",
        email: "",
        // password: "",
        mobile: "",
      },
      // type: "password",
      // passwordValidation: false,
      // passwordValidationMessage: "",
      emailvalidation: false,
      emailvalidationMessage: "",
      submitted: false,
      reference: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showHide = this.showHide.bind(this);
  }

  showHide(e) {
    e.preventDefault();
    e.stopPropagation();
    // this.setState({
    //   type: this.state.type === "password" ? "input" : "password",
    // });
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;

    this.setState({
      user: {
        ...user,
        [name]: value,
      },
    });
    if (name === "industrytype") {
      this.getSubIndustry(value);
    }
    // if (name === "password") {
    //   let condition = checkPasswordValidation(value);
    //   if (condition === true) {
    //     this.setState({
    //       passwordValidation: true,
    //       passwordValidationMessage: "",
    //     });
    //   } else {
    //     this.setState({
    //       passwordValidation: false,
    //       passwordValidationMessage: condition,
    //     });
    //   }
    // }
    if (name === "email") {
      let condition = isValidEmail(value);
      if (condition === true) {
        this.setState({
          emailValidation: true,
          emailValidationMessage: "",
        });
      } else {
        this.setState({
          emailValidation: false,
          emailValidationMessage: "Please check email format",
        });
      }
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    const { user, passwordValidation, emailValidation } = this.state;
    if (
      user.firstName &&
      user.lastName &&
      user.email &&
      // user.password &&
      user.mobile
    ) {
      const body = {
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email,
        // password: user.password,
        mobile_number: user.mobile,
      };
      const { isSuccess, data } = await apiCall(
        config.ADMIN_API_URL + "addBroker",
        {},
        body,
        "POST"
      );
      if (isSuccess) {
        const pushToRoute = "/brokers";
        const finalLink = baseURL + pushToRoute;
        setTimeout(() => {
          window.location.href = finalLink;
        }, 1000);
      }
    } else {
      sweetAlert("error", "Please fill all input");
    }
  }

  render() {
    const { user, submitted, type } = this.state;
    return (
      <div>
        <form name="form" onSubmit={this.handleSubmit}>
          <Row>
          <Col md={12} className="mb-3">
              <div className="form-group fg">
                <input className="form-control name_nf" id="company" type="company" name="company" placeholder="Company Name *" onChange={this.handleChange} />
              </div>
            </Col>
            <Col md={6} className="mb-3">
              <div className={"form-group fg" + (submitted && !user.firstName ? " has-error" : "")}>
                <input className="form-control name_nf" id="firstName" type="text" name="firstName" placeholder="Enter First Name *" value={user.firstName} onChange={this.handleChange} />
                {submitted && !user.firstName && (
                  <div className="help-block">First Name is required</div>
                )}
              </div>
            </Col>
            <Col md={6} className="mb-3">
              <div className="form-group fg">
                <input className="form-control name_nf" id="lastName" type="text" placeholder="Enter Last Name" name="lastName" value={user.lastName} onChange={this.handleChange} />
              </div>
            </Col>
            <Col md={6} className="mb-3">
              <div className="form-group fg">
                <input className="form-control name_nf" id="mobile" type="mobile" name="mobile" value={user.mobile} placeholder="Enter Mobile No. (Optional)" onChange={this.handleChange} />
              </div>
            </Col>
            <Col md={6} className="mb-3">
              <div className={"form-group fg" + (submitted && !user.email ? " has-error" : "")}>
                <input className="form-control name_nf" id="email" type="email" name="email" value={user.email} placeholder="Enter Email Address *" onChange={this.handleChange} />
                {this.state.emailvalidation === false && (
                  <div className="help-block"> {this.state.emailvalidationMessage} </div>
                )}
                {submitted && !user.email && (
                  <div className="help-block">Email is required</div>
                )}
              </div>
            </Col>
            <Col md={12} className="mb-3">
              <div className="form-group fg">
                <input className="form-control name_nf" id="comission" type="comission" name="comission" placeholder="Comission (%)" onChange={this.handleChange} />
              </div>
            </Col>
            
          </Row>
          <hr className="h-auto" />
          {/* <div className="mb-3">
            <div className={"form-group fg eye-frame" + (submitted && !user.password ? " has-error" : "")}>
              <label className="st_name" htmlFor="name"> Password&nbsp;
                <span data-tip data-for="registerTip">
                  <i className="fa fa-question-circle" aria-hidden="true"></i>
                </span>
              </label>
              <ReactTooltip id="registerTip" place="top" effect="solid">
                <h6>Password Must :</h6>
                <ul>
                  <li> Have at least one lower case character</li>
                  <li> Have at least one Capital letter</li>
                  <li>Have at least one number</li>
                  <li> Have at least one special character</li>
                  <li>Be at least 8 characters</li>
                  <li>Not be a common password</li>
                </ul>
                <h6>For Eg : <b>Password@123</b></h6>
              </ReactTooltip>
              <div className="form_inputC">
                <input className="form-control name_nf" id="password" type={type} name="password" placeholder="Enter Strong Password" value={user.password} onChange={this.handleChange} />
                {this.state.passwordValidation === false && (
                  <div className="help-block">{this.state.passwordValidationMessage}</div>
                )}
                {submitted && !user.password && (
                  <div className="help-block">Password is required</div>
                )}
                <span className="eye-under" onClick={this.showHide}>
                  {this.state.type === "input" ? (
                    <i className="fas fa-eye-slash"></i>
                  ) : (
                    <i className="fas fa-eye"></i>
                  )}
                </span>
              </div>
            </div>
          </div> */}
          <div className="add____broker">
            {user.firstName && user.lastName && user.mobile && user.email && user.password ? (
              <span type="submit" value="Submit" className="page_width page_save d-block" onClick={(e) => this.handleSubmit(e)}>Add</span>
            ) : (
              <button type="submit" disabled value="Submit" id="submit-form" className="btn"> Submit</button>
            )}
          </div>
        </form>
      </div>
    );
  }
}
