/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import config from "../../../config/config.json";
import { authenticationService } from "../../../_services/authentication";
import {sweetAlert} from '../../../utils/UniversalFunction'
const BackendBaseUrl = config.BASE_URL;
const currentUser = authenticationService.currentUserValue;
export default class carbonFootprintDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      scopeFirst: [],
      scopeSecond: [],
      scopeThird: [],
      report: [],
    };
    this.onFileChange = this.onFileChange.bind(this);
    this.getUploadedReportData = this.getUploadedReportData.bind(this);
  }

  onFileChange = (event) => {
    let urlArr = window.location.pathname.split("/");
    const finalUUId = urlArr[urlArr.length - 3];

    const formData = new FormData();
    // Update the formData object
    formData.append(
      "uploadImage",
      event.target.files[0],
      event.target.files[0].name
    );
    formData.append("section_name", "carbon_footprint");
    formData.append("sub_section", "carbon_footprint");
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    axios
      .post(
        `${config.ADMIN_API_URL}company/${finalUUId}/sector-question/upload/report`,
        formData,
        {
          headers,
        }
      )
      .then((response) => {
        sweetAlert('success',response.data.message);	
      })
      .catch(function (response) {
        sweetAlert('error',response.data.message);
      });
  };

  getUploadedReportData = () => {
    let urlArr = window.location.pathname.split("/");
    const finalUUId = urlArr[urlArr.length - 3];
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };
    fetch(
      `${config.ADMIN_API_URL}company/${finalUUId}/sector-question/download/report/carbon_footprint/carbon_footprint`,
      {
        headers,
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            report: result.data,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
    // company/:uuid/sector-question/download/report
  };

  componentDidMount() {
    this.getUploadedReportData();
    let urlArr = window.location.pathname.split("/");
    const finalUUId = urlArr[urlArr.length - 3];
    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };

    fetch(config.ADMIN_API_URL + `company/${finalUUId}/modules/carbonAnswer`, {
      headers,
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            scopeFirst: result.scopeFirst[0],
            scopeSecond: result.scopeSecond[0],
            scopeThird: result.scopeThird[0],
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  render() {
    const { scopeFirst, scopeSecond, scopeThird, report } = this.state;
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="color_div_on framwork_2">
                        <div className="text_Parts">
                          <div className="text_Description">
                            <div className="d-flex justify-content-between mb-3">
                              <h5 className="motor">Introduction</h5>
                            </div>
                            <p className="specialize">
                              A carbon footprint is an estimate of the total
                              output of greenhouse gas (GHG) emissions caused by
                              an organisation, event, product or person.
                            </p>
                            <p>
                              Calculating your carbon footprint helps set a
                              baseline. Following this up with annual
                              measurements provides you with a consistent and
                              accurate picture across your business.
                              Understanding your carbon footprint will help you
                              understand the activities that result in carbon
                              emissions and then take action to reduce them. As
                              well as helping the environment, managing your
                              carbon emissions can help you save money, cut
                              reputational risk and create new business
                              opportunities.
                            </p>
                          </div>
                          <div className="variants">
                            <div className='file file--upload'>
                              <label htmlFor='input-file'>
                                <i className="fa fa-upload" aria-hidden="true"></i>Upload Report
                              </label>
                              <input
                                // style={{ visibility: "hidden" }}
                                type="file"
                                id='input-file'
                                accept='.pdf, .doc, .docx, .xlsx, .pptx, .ppt, .ott, .odp'
                                name="governancePolicy"
                                onChange={this.onFileChange}
                              />
                            </div>
                            <a
                              className="outputFile"
                              target="_blank"
                              href={BackendBaseUrl + report[0]?.file ?? ""}
                            >
                              Preview
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12">
                      <div className="color_div_on framwork_2 mt-3">
                        <div className="text_Parts">
                          <div className="col-md-12">
                            <div className="Capital_op">
                              <h4 className="E_Emis">Summary</h4>
                            </div>
                            <div className="stent">
                              <div className="stent_one">
                                <div className="stent_table">
                                  <h4 className="scope">Scope</h4>
                                </div>
                                <div className="stent_table_one">
                                  <h4 className="scope">Activity Type</h4>
                                </div>
                                <div className="stent_table_two">
                                  <h4 className="scope">Year: 2021</h4>
                                </div>
                              </div>
                              <hr className="scope_gelop" />
                              <div className="stent_one">
                                <div className="stent_table">
                                  <h4 className="scope">Scope 1</h4>
                                </div>
                                <div className="stent_table_one">
                                  <p className="ghg">Building Consumption</p>
                                </div>
                                <div className="stent_table_two">
                                  <p className="ghg">
                                    {scopeFirst?.buildingConsumption}
                                  </p>
                                </div>
                              </div>
                              <div className="stent_one">
                                <div className="stent_table">
                                </div>
                                <div className="stent_table_one">
                                  <p className="ghg">Transport Consumption</p>
                                </div>
                                <div className="stent_table_two">
                                  <p className="ghg">
                                    {scopeFirst?.transportConsumption}
                                  </p>
                                </div>
                              </div>
                              <div className="stent_one">
                                <div className="stent_table">
                                </div>
                                <div className="stent_table_one">
                                  <p className="ghg">Refrigerants</p>
                                </div>
                                <div className="stent_table_two">
                                  <p className="ghg">
                                    {scopeFirst?.refrigerants}
                                  </p>
                                </div>
                              </div>
                              <div className="stent_one">
                                <div className="stent_table">
                                </div>
                                <div className="stent_table_one">
                                  <p className="ghg">Additional Information</p>
                                </div>
                                <div className="stent_table_two">
                                  <p className="ghg">
                                    {scopeFirst?.additionalInformation}
                                  </p>
                                </div>
                              </div>
                              <hr className="scope_gelop" />
                              <div className="stent_one">
                                <div className="stent_table">
                                  <h4 className="scope">Scope 1 Total</h4>
                                </div>
                                <div className="stent_table_one">
                                  <p className="ghg"></p>
                                </div>
                                <div className="stent_table_two">
                                  <p className="ghg">
                                    <strong>
                                      {Number(scopeFirst?.buildingConsumption) +
                                        Number(
                                          scopeFirst?.transportConsumption
                                        ) +
                                        Number(scopeFirst?.refrigerants) +
                                        Number(
                                          scopeFirst?.additionalInformation
                                        )}&nbsp;CO2-e
                                    </strong>
                                  </p>
                                </div>
                              </div>
                            </div>
                            {/* <!-- two tsble --> */}
                            <div className="stent">
                              <div className="stent_one">
                                <div className="stent_table">
                                  <h4 className="scope">Scope 2</h4>
                                </div>
                                <div className="stent_table_one">
                                  <p className="ghg">
                                    Electricity Consumption Type
                                  </p>
                                </div>
                                <div className="stent_table_two">
                                  <p className="ghg">
                                    {scopeSecond?.electricityConsumption}
                                  </p>
                                </div>
                              </div>
                              <div className="stent_one">
                                <div className="stent_table">
                                </div>
                                <div className="stent_table_one">
                                  <p className="ghg">Green Power 100%</p>
                                </div>
                                <hr className="scope_gelop" />
                                <div className="stent_table_two">
                                  <p className="ghg">
                                    {scopeSecond?.greenPower}
                                  </p>
                                </div>
                              </div>
                              <div className="stent_one">
                                <div className="stent_table">
                                </div>
                                <hr className="scope_gelop" />
                                <div className="stent_table_one">
                                  <p className="ghg">Some Green Power</p>
                                </div>
                                <div className="stent_table_two">
                                  <p className="ghg">
                                    {scopeSecond?.someGreenPower}
                                  </p>
                                </div>
                              </div>
                              <div className="stent_one">
                                <div className="stent_table">
                                </div>
                                <hr className="scope_gelop" />
                                <div className="stent_table_one">
                                  <p className="ghg">Market</p>
                                </div>
                                <div className="stent_table_two">
                                  <p className="ghg">{scopeSecond?.market}</p>
                                </div>
                              </div>
                              <hr className="scope_gelop" />
                              <div className="stent_one">
                                <div className="stent_table">
                                  <h4 className="scope">Scope 2 Total</h4>
                                </div>
                                <div className="stent_table_one">
                                  <p className="ghg"></p>
                                </div>
                                <div className="stent_table_two">
                                  <p className="ghg">
                                    <strong>
                                      {Number(
                                        scopeSecond?.electricityConsumption
                                      ) +
                                        Number(scopeSecond?.greenPower) +
                                        Number(scopeSecond?.someGreenPower) +
                                        Number(scopeSecond?.market)}&nbsp;CO2-e
                                    </strong>
                                  </p>
                                </div>
                              </div>
                            </div>
                            {/* <!-- there tsble --> */}
                            <div className="stent">
                              <div className="stent_one">
                                <div className="stent_table">
                                  <h4 className="scope">Scope 3</h4>
                                </div>
                                <div className="stent_table_one">
                                  <p className="ghg">Travel</p>
                                </div>
                                <div className="stent_table_two">
                                  <p className="ghg">{scopeThird?.travel}</p>
                                </div>
                              </div>
                              <div className="stent_one">
                                <div className="stent_table">
                                </div>
                                <div className="stent_table_one">
                                  <p className="ghg">Employee commuting</p>
                                </div>
                                <hr className="scope_gelop" />
                                <div className="stent_table_two">
                                  <p className="ghg">
                                    {scopeThird?.employeeCommuting}
                                  </p>
                                </div>
                              </div>
                              <hr className="scope_gelop" />
                              <div className="stent_one">
                                <div className="stent_table">
                                </div>
                                <hr className="scope_gelop" />
                                <div className="stent_table_one">
                                  <p className="ghg">Waste</p>
                                </div>
                                <div className="stent_table_two">
                                  <p className="ghg">{scopeThird?.waste}</p>
                                </div>
                              </div>
                              <hr className="scope_gelop" />
                              <div className="stent_one">
                                <div className="stent_table">
                                </div>
                                <hr className="scope_gelop" />
                                <div className="stent_table_one">
                                  <p className="ghg">
                                    Purchased goods & services
                                  </p>
                                </div>
                                <div className="stent_table_two">
                                  <p className="ghg">
                                    {scopeThird?.purchasedGoods}
                                  </p>
                                </div>
                              </div>
                              <hr className="scope_gelop" />
                              <div className="stent_one">
                                <div className="stent_table">
                                </div>
                                <hr className="scope_gelop" />
                                <div className="stent_table_one">
                                  <p className="ghg">Capital goods</p>
                                </div>
                                <div className="stent_table_two">
                                  <p className="ghg">
                                    {scopeThird?.capitalGoods}
                                  </p>
                                </div>
                              </div>
                              <hr className="scope_gelop" />
                              <div className="stent_one">
                                <div className="stent_table">
                                </div>
                                <div className="stent_table_one">
                                  <p className="ghg">
                                    Upstream and downstream <br />
                                    Transportation & Distribution
                                  </p>
                                </div>
                                <div className="stent_table_two">
                                  <p className="ghg">
                                    {scopeThird?.upstreamAndDownstream}
                                  </p>
                                </div>
                              </div>
                              <div className="stent_one">
                                <div className="stent_table">
                                </div>
                                <hr className="scope_gelop" />
                                <div className="stent_table_one">
                                  <p className="ghg">Investments</p>
                                </div>
                                <div className="stent_table_two">
                                  <p className="ghg">
                                    {scopeThird?.Investments}
                                  </p>
                                </div>
                              </div>
                              <hr className="scope_gelop" />
                              <div className="stent_one">
                                <div className="stent_table">
                                  <h4 className="scope">Scope 3 Total</h4>
                                </div>
                                <div className="stent_table_one">
                                  <p className="ghg"></p>
                                </div>
                                <div className="stent_table_two">
                                  <p className="ghg">
                                    <strong>
                                      {Number(scopeThird?.travel) +
                                        Number(scopeThird?.employeeCommuting) +
                                        Number(scopeThird?.waste) +
                                        Number(scopeThird?.purchasedGoods) +
                                        Number(scopeThird?.capitalGoods) +
                                        Number(
                                          scopeThird?.upstreamAndDownstream
                                        ) +
                                        Number(scopeThird?.Investments)}&nbsp;CO2-e
                                    </strong>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
