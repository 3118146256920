import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import config from "../../../config/config.json";
import { sweetAlert } from "../../../utils/UniversalFunction";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import { authenticationService } from "../../../_services/authentication";
import "../../Company Admin/Setting/setting.css";
import { apiCall } from "../../../_services/apiCall";
import { PermissionContext } from "../../../context/PermissionContext";
import { Card, Col, Form, Row } from "react-bootstrap";
import Loader from "../../loader/Loader";
const ProfilePics =
  "https://res.cloudinary.com/dmklsntsw/image/upload/v1658480882/dummyPic.75a04487_fqfqey.png";
const baseURL = config.baseURL;
let currentUser = authenticationService.currentUserValue;
export default class SettingDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      login: false,
      isLoaded: false,
      firstName: "",
      lastName: "",
      email: "",
      logo: "",
      id: "",
      password: "",
      menuList: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.serverRequest = this.serverRequest.bind(this);

    authenticationService.currentUserSubject.subscribe((e) => {
      currentUser = e;
    });
  }
  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    const { firstName, lastName, email, password } = this.state;
    const data1 = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
    };
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}updateProfile`,
      {},
      data1,
      "POST"
    );
    if (isSuccess) {
      setTimeout(() => {
        window.location.href = baseURL + "/#/settings";
      }, 1000);
    }
  }

  onFileChange = async (event) => {
    // let id = event.target.getAttribute("data-id");
    const formData = new FormData();
    // Update the formData object
    formData.append(
      "uploadImage",
      event.target.files[0],
      event.target.files[0].name
    );
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}uploadProfilePicture`,
      {},
      formData,
      "POST"
    );
    if (isSuccess) {
      sweetAlert("success", data.data.message);
      this.setState({ logo: data.data.data });
      this.serverRequest();
    }

    // const headers = {
    //   Authorization: `Bearer ${currentUser.data.token}`,
    //   Accept: "application/json",
    // };
    // axios
    //   .post(config.API_URL + "uploadProfilePicture", formData, { headers })
    //   .then((response) => {
    //     sweetAlert('success',response.data.message);
    //     this.setState({ logo: response.data.data });
    //     this.serverRequest();
    //   })
    //   .catch(function (response) {
    //     sweetAlert('error',response.data.message);
    //   });
  };

  async serverRequest() {
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}settings`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      this.setState({
        isLoaded: true,
        firstName: data?.result[0]?.first_name,
        lastName: data?.result[0]?.last_name,
        email: data?.result[0]?.email,
        logo:
          data?.result1[0]?.logo === null ||
            data?.result1[0]?.logo === undefined
            ? ProfilePics
            : config.BASE_URL + data?.result1[0]?.logo,
      });
    }
  }

  componentDidMount() {
    const currentUser = authenticationService?.currentUserSubject?.getValue();
    const settingsMenu = currentUser?.data?.menu?.find(
      (item) => item?.url === "settings"
    );
    this.setState({ menuList: settingsMenu?.permissions });
    this.serverRequest();
  }
  render() {
    const { firstName, lastName, email, logo, id, password } = this.state;

    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <PermissionContext.Consumer>
          {({ permissions }) => (
            <div className="main_wrapper">
              <div className="inner_wraapper">
                <div className="hol_rell">
                  <div className="steps-form">
                    <div className="steps-row setup-panel">
                      <div className="tabs-top setting_admin my-0">
                        <ul>
                          <li>
                            <NavLink
                              to="/settings"
                              className="activee"
                            >
                              {" "}
                              My Profile{" "}
                            </NavLink>
                          </li>

                          {this.state.menuList.includes(
                            "GET ALL SUBSCRIPTION",
                            "CREATE SUBSCRIPTION"
                          ) && (
                              <li>
                                <NavLink to="/settings/billing">
                                  {" "}
                                  Billing{" "}
                                </NavLink>
                              </li>
                            )}
                          <li>
                            <NavLink to="/settings/access_management">
                              Access
                            </NavLink>
                          </li>
                          {/* {this.state.menuList.includes(
                            "CREATE_METER_ID"
                          ) && (
                            <li>
                              <NavLink to="/generator">
                                Generator
                              </NavLink>
                            </li>
                          )} */}
                          {this.state.menuList.includes(
                            "CREATE_FINANCIAL_YEAR"
                          ) && (
                              <li>
                                <NavLink to="/settings/financial_year">
                                  Financial Year
                                </NavLink>
                              </li>
                            )}
                          {this.state.menuList.includes(
                            "CREATE SUB USER"
                          ) && (
                              <li>
                                <NavLink to="/settings/sub-users">
                                  Sub Users
                                </NavLink>
                              </li>
                            )}
                          <li>
                            <NavLink to="/settings/category">
                              Category
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/settings/carbon_emission">
                              Carbon Emission
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="settings/unit">
                            Unit
                            
                            </NavLink>
                          </li>
                          {/* <li>
                            <NavLink to="/settings/question_module">
                              Question Module
                            </NavLink>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="main-body">
                  {/* {this.state.logo === !null ? */}
                  <Row>
                    <Col md={4}>
                      <div className="color_div_on framwork_2 pt-0 pb-5">
                        <div className="d-flex flex-column align-items-center text-center">
                          <div className="upload_image">
                            <img
                              className="file-upload-image"
                              src={this.state.logo}
                              alt="your image"
                            />
                            <input
                              type="file"
                              name="uploadImage"
                              data-id={id}
                              onChange={this.onFileChange}
                            />
                          </div>
                          <div className="mt-3">
                            <h4>
                              {firstName} {lastName}
                            </h4>
                            <p className="text-secondary mb-1">{email}</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={8}>
                      <div className="color_div_on framwork_2 pt-0 pb-3">
                        <form name="form" onSubmit={this.handleSubmit}>
                          <div className="business_detail">
                            <Row>
                              <Col md={6}>
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  className="py-3"
                                  placeholder="First Name"
                                  onChange={this.handleChange}
                                  value={firstName}
                                />
                              </Col>
                              <Col md={6}>
                                <Form.Label> Last Name</Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  className="py-3"
                                  placeholder=" Last Name"
                                  onChange={this.handleChange}
                                  value={lastName}
                                />
                              </Col>
                              <Col md={12}>
                                <Form.Label>Corporate Email</Form.Label>
                                <Form.Control
                                  required
                                  type="email"
                                  className="py-3"
                                  placeholder="Corporate Email"
                                  onChange={this.handleChange}
                                  value={email}
                                />
                              </Col>
                              <Col md={12}>
                                <Form.Label>Reset Password</Form.Label>
                                <Form.Control
                                  required
                                  type="password"
                                  className="py-3"
                                  placeholder="Reset Password"
                                  onChange={this.handleChange}
                                  value={password}
                                />
                              </Col>
                            </Row>
                          </div>
                          <div className="global_link mx-0 mt-3">
                            <button
                              type="submit"
                              className="page_width page_save"
                            >
                              SAVE
                            </button>
                          </div>
                        </form>
                      </div>
                    </Col>

                  </Row>
                  {/* :
                    <Loader />
                  } */}
                </div>
                {/* <div className="SVG Stepper">
                  <div className="stepperr_design">
                    <div className="color_div_step div-color">
                      <div className="include">
                        <section className="forms">
                          <div className="row">
                            <div className="col-md-8 col-xs-12">
                              <form
                                name="form"
                                onSubmit={this.handleSubmit}
                              >
                                <div className="business_detail">
                                  <div className="heading">
                                    <h4>Admin Details</h4>
                                  </div>
                                  <hr className="line"></hr>

                                  <div className="row">
                                    <div className="col-lg-6 col-xs-6">
                                      <div className="form-group pb-3">
                                        <label htmlFor="exampleInputPassword1">
                                          First Name
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="exampleInputPassword1"
                                          placeholder="First Name"
                                          name="firstName"
                                          onChange={this.handleChange}
                                          value={firstName}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-xs-6">
                                      <div className="form-group pb-3">
                                        <label htmlFor="exampleInputPassword1">
                                          Last Name
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="exampleInputPassword2"
                                          placeholder="Last name"
                                          name="lastName"
                                          onChange={this.handleChange}
                                          value={lastName}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group pb-3">
                                    <label htmlFor="exampleInputEmail1">
                                      Corporate Email
                                    </label>
                                    <input
                                      type="email"
                                      className="form-control disableddd"
                                      id="exampleInputPassword3"
                                      placeholder="Email Address"
                                      disabled
                                      name="email"
                                      onChange={this.handleChange}
                                      value={email}
                                    />
                                  </div>
                                  <div className="form-group pb-3">
                                    <label htmlFor="exampleInputPassword1">
                                      Reset Password
                                    </label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      id="exampleInputPassword4"
                                      placeholder="Enter password for Change or leave this field"
                                      name="password"
                                      onChange={this.handleChange}
                                      value={password}
                                    />
                                  </div>
                                </div>
                                <div className="global_link mx-0 my-3">
                                  <button
                                    type="submit"
                                    className="page_width page_save"
                                  >
                                    SAVE
                                  </button>
                                </div>
                              </form>
                            </div>
                            <div className="col-lg-4 col-xs-12">
                              <div className="upload_image">
                                <img
                                  className="file-upload-image"
                                  src={logo}
                                  alt=""
                                />
                                <input
                                  type="file"
                                  accept=".jpg, .png, .jpeg"
                                  name="uploadImage"
                                  data-id={id}
                                  onChange={this.onFileChange}
                                />
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          )}
        </PermissionContext.Consumer>
      </div>
    );
  }
}
