import React, { useState } from "react";
import { Dropdown, Form, Placeholder, Table } from "react-bootstrap";
import countriesData from "../../Setting/FinactialYearTab/countries.json";
import { apiCall } from "../../../../_services/apiCall";
import config from "../../../../config/config.json";

const GlobalControlFinancialYear = (props) => {
  const {handleFrameworkChangeHandler}= props;
  const [selectedCountry, setSelectedCountry] = useState("Select Country");
  const [financialYearData, setFinancialYearData] = useState([]);
  const [financialYear, setFinancialYear] = useState(getIsoCode("India"));
  const [data, setData] = useState("");

  const getFinancialYearData = async (tmpIsoCode) => {
    const body = {
      iso_code: tmpIsoCode,
    };
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}getAllFinancialYear`,
      {},
      body
    );
    if (isSuccess) {
      setFinancialYearData(data?.data);
    }
  };

  function getFinancialYear(countryName) {
    const selectedCountry = countriesData.find(
      (country) => country.name.toLowerCase() === countryName.toLowerCase()
    );
    if (selectedCountry) {
      return selectedCountry.financialYear;
    } else {
      return "Country not found";
    }
  }

  function getIsoCode(countryName) {
    const selectedCountry = countriesData.find(
      (country) => country.name.toLowerCase() === countryName.toLowerCase()
    );
    if (selectedCountry) {
      return selectedCountry.isoCode;
    } else {
      return "ISO Code not found";
    }
  }

  const handleCountryChange = (e) => {
    const countryName = e.target.value;
    setSelectedCountry(countryName);
    setFinancialYear(getFinancialYear(countryName));
    const tmpIsoCode = getIsoCode(countryName);
    if (tmpIsoCode) {
      getFinancialYearData(tmpIsoCode);
    }
  };
  
  const handleClickComponent = (data, activeTab) => {
    setData(data);
    handleFrameworkChangeHandler(data, activeTab)
  };

  return (
    <div>
      <div className="business_detail">
        <div className="d-flex align-items-center justify-content-between">
          <div className="heading">
            <h4><strong> Global Control & Manage</strong></h4>
          </div>
          <div className="heading mb-2">
            <Form.Label className="w-75 m-0">
              Select Country :{" "}
            </Form.Label>
            <Form.Control
              as="select"
              value={selectedCountry}
              onChange={handleCountryChange}
            >
              <option>Select Country</option>
              {countriesData &&
                countriesData.map((country, index) => (
                  <option key={index}>{country.name}</option>
                ))}
            </Form.Control>
          </div>
          <div className="heading mb-2">
            <Form.Control
              type="text"
              disabled
              value={financialYear}
              className="form-control"
            />
          </div>
        </div>
      </div>
      {financialYearData && financialYearData?.length > 0 ? (
        <div className="saved_cards border-top">
          <div className="table_f table-responsive mt-2 fianncial_year_table_hight">
            <Table striped bordered className="mb-0">
              <thead>
                <tr className="heading_color sticky_header">
                  <th style={{ width: "5%" }}>Sr. No</th>
                  <th>Financial Year</th>
                  <th style={{ width: "5%" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {financialYearData.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td><Placeholder animation="glow">{item.financial_year_value}</Placeholder></td>
                    <td className="text-center">
                      <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic"></Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={(e) => handleClickComponent(item, "framework")}>
                            <i className="fas fa-eye"></i>&nbsp;&nbsp; View Framework
                          </Dropdown.Item>
                          {/* <Dropdown.Item onClick={(e) => handleClickComponent(item, "questionList")}>
                            <i className="fas fa-eye"></i>&nbsp;&nbsp; View Question
                          </Dropdown.Item> */}
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      ) : (
        <div className="saved_cards border-top">
          <div className="table_f table-responsive mt-2">
            <Table striped bordered>
              <thead>
                <tr className="heading_color">
                  <th style={{ width: "5%" }}>Sr. No</th>
                  <th>Financial Year</th>
                  <th>End Date</th>
                  <th style={{ width: "5%" }}>View</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>--</td>
                  <td>No Data Found (Please Select County)</td>
                  <td>--</td>
                  <td>--</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      )}
    </div>
  );
};
export default GlobalControlFinancialYear;
