import React from "react";
import { Table, Modal, Button, Form } from "react-bootstrap";
import { apiCall } from "../../../_services/apiCall";
import { useEffect, useState } from "react";
import config from "../../../config/config.json";
import TabularAddFormulaTrigger from "./TabularAddFormulaTrigger";

const AddFormTrigger = ({

  getTriggerQuestion,

  popupVisible,
  selectedQuestion,
  handleClosePopup,
  sectorQuestion,
}) => {
  const [viewHistoryModal, setViewHistoryModal] = useState(false);

  const [dynamicErrors, setDynamicErrors] = useState({});
  const [inputValues, setInputValues] = useState({});
  const [state, setState] = useState({
    // createFormulaModal: false,
    // viewHistoryModal: false,
    input: "",
    suggestions: [],
    selectedSuggestionIndex: 0,
    showSuggestions: false,
    operators: [
      "+",
      "-",
      "*",
      "/",
      "=",
      "<",
      ">",
      "!",
      "&",
      "|",
      "^",
      "%",
      "(",
      ")",
      "{",
      "}",
      "[",
      "]",
    ],
    answerArray: [],
    getSectorQuestionData: [],
    inValidQuestionError: "",
    inValidExpressionError: "",
    formulaHistoryData: [],
  });

  const rows =
    selectedQuestion?.question_detail?.filter(
      (item) => item.option_type === "row"
    ) || [];
  const columns =
    selectedQuestion?.question_detail?.filter(
      (item) => item.option_type === "column"
    ) || [];



  
  const {
    // createFormulaModal,
    // viewHistoryModal,
    input,
    suggestions,
    selectedSuggestionIndex,
    showSuggestions,
    operators,
    answerArray,
    getSectorQuestionData,
    inValidQuestionError,
    inValidExpressionError,
    formulaHistoryData,
  } = state;

  const handleSuggestionClickForFormula = (name, suggestion) => {
    const input = inputValues[name];
    const { suggestions } = state;

    // Regular expression to identify operators and keywords
    const operatorsAndBrackets = /[+\-*\/=<>!&|^%(){}\[\]]+|if/g;
    const operatorsAndBracket = /[+\-*\/=<>!&|^%{}[\]]+|(\()+|if/g;
    const operatorMatches = input.match(operatorsAndBracket);

    // Check if the input contains an equal sign (=)
    if (input.includes("=")) {
      setDynamicErrors((prevErrors) => ({
        ...prevErrors,
        [name]: {
          showSuggestions: false,
          inValidExpressionError: "",
          inValidQuestionError: "Equal to (=) is not allowed",
        },
      }));
      return;
    }

    // Variable to store the updated input
    let updatedInput;

    // If there are any operators or brackets in the input
    if (operatorMatches) {
      // Get the last operator/bracket in the current input
      const lastOperator = operatorMatches[operatorMatches.length - 1];

      // Find the index of this last operator/bracket in the input
      const lastOperatorIndex = input.lastIndexOf(lastOperator);

      // Update the input by appending the suggestion after the last operator
      updatedInput = input.substring(0, lastOperatorIndex + 1) + suggestion;
      console.log("nnn", updatedInput);
    } else {
      // If no operators/brackets are found, just set the updated input to the suggestion
      updatedInput = suggestion;
      console.log("ttt", updatedInput);
    }

    // Initialize error messages
    let questionError = "";
    let expressionError = "";

    // Validate the input to ensure it doesn't contain invalid question references
    if (updatedInput) {
      const expressionParts = updatedInput
        .split(operatorsAndBrackets)
        .filter((part) => part.length > 0);

      // Check if all parts are included in the suggestions
      const allPartsInAnswerArray = expressionParts.every((part) =>
        suggestions.includes(part)
      );

      // If not all parts are valid, set an error message
      if (!allPartsInAnswerArray) {
        questionError = "Invalid Question Reference";
      }

      // For now, we are not evaluating the formula expression itself
      // since we are only interested in identifying and validating references
      // expressionError could be set here if we were evaluating formulas
    }

    // Update the state with any errors
    setDynamicErrors((prevErrors) => ({
      ...prevErrors,
      [name]: {
        showSuggestions: false,
        inValidExpressionError: expressionError,
        inValidQuestionError: questionError,
      },
    }));

    // Update the input values state with the new input
    setInputValues((prevState) => ({
      ...prevState,
      [name]: updatedInput,
    }));

    setState((prevState) => ({
      ...prevState,
      input: updatedInput,
    }));
  };



 
  const handleOperatorChangeForFormula = (e, name) => {
    const selectedOperator = e.target.value;
    console.log("selectedddd", selectedOperator);
    const { operators } = state;
    // const operatorRegex = new RegExp(`[${operators.join("\\")}]`);
    // const hasOperator = operatorRegex.test(inputValues[name]);
    // let updatedInput;
    // if (hasOperator) {
    //   updatedInput = inputValues[name].replace(
    //     /[-+*/=!<>&|^%()[\]{}]+$/,
    //     selectedOperator
    //   );
    //   console.log("uuu",updatedInput)x
    // }

    const operatorRegex = new RegExp(`[${operators.join("\\")}]$`);
    const hasOperator = operatorRegex.test(inputValues[name]);

    let updatedInput;
    if (hasOperator) {
      updatedInput = inputValues[name].replace(operatorRegex, selectedOperator);
      console.log("ttt", updatedInput);
    } else {
      updatedInput = inputValues[name] + selectedOperator;
      console.log("nnn", updatedInput);
    }
    setInputValues((prevState) => ({
      ...prevState,
      [name]: updatedInput,
    }));
  };

  const saveSectorQuestionForFormula = async () => {
    console.log("sle",selectedQuestion)
    const apiUrl = `${config.ADMIN_API_URL}addFormulaForTrigger
 `;

    try {
      const response = await apiCall(
        apiUrl,
        {},
        { formula: state.input, 
          question_id: selectedQuestion.questionId },
        "POST"
      );

      if (response.isSuccess) {
getTriggerQuestion();      
        handleClosePopup();
      } else {
        console.error("API call unsuccessful");
      }
    } catch (error) {
      console.error("Error occurred during API call:", error);
    }
  };

  
 

  const handleInputChangeForFormula = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    console.log("Input Change - Value:", value, "Name:", name);

    // Regular expression to identify operators and keywords
    const operatorsAndBrackets = /[+\-*\/=<>!&|^%(){}\[\]]+|if/g;

    // Split the input value by operators and brackets to extract question references
    const userInputArray = value.split(operatorsAndBrackets);
    const lastInput = userInputArray[userInputArray.length - 1]?.trim() || "";

    const extractedExpression = value.includes("=")
      ? value.split("=")[0]
      : null;

    // Check if the input contains an equal sign (=)
    const isEqualsPresent = value.includes("=");

    // Initialize suggestions array
    let suggestionValues = [];
    const tempSuggestionValues = state.getSectorQuestionData || [];

    // Filtered suggestions based on the last input part
    const filteredSuggestions = tempSuggestionValues.filter((suggestion) => {
      return (
        suggestion &&
        lastInput &&
        suggestion.toLowerCase().includes(lastInput.toLowerCase())
      );
    });

    let questionError = "";
    let expressionError = "";

    if (isEqualsPresent) {
      questionError = "Equal to (=) is not allowed";
    } else if (value) {
      // Validate each part to ensure it is a recognized question reference
      const expressionParts = value
        .split(operatorsAndBrackets)
        .filter((part) => part.length > 0);

      // Validate if all parts are valid question references
      const allPartsValid = expressionParts.every((part) =>
        tempSuggestionValues.includes(part)
      );

      if (!allPartsValid) {
        questionError = "Invalid Question Reference";
      }

      // For now, we are not evaluating the formula expression itself
      // since we are only interested in identifying and validating references

      // Set expression error if needed
      // expressionError = "Formula evaluation not supported in this version";
    }

    // Update the dynamic errors state
    setDynamicErrors((prevErrors) => ({
      ...prevErrors,
      [name]: {
        suggestions: filteredSuggestions,
        showSuggestions: true,
        inValidExpressionError: expressionError,
        inValidQuestionError: questionError,
      },
    }));

    // Update the input values state
    setInputValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setState((prevState) => ({
      ...prevState,
      input: value,
    }));
  };

  const handleKeyDownForFormula = (e, name) => {
    const { suggestions } = state;
    const selectedSuggestionIndex =
      dynamicErrors[name]?.selectedSuggestionIndex;

    // Log the key pressed and the current input state
    console.log(`Key pressed: ${e.key}`);
    console.log(`Current input value: ${inputValues[name]}`);
    console.log(`Current suggestions:`, suggestions);
    console.log(
      `Current selected suggestion index: ${selectedSuggestionIndex}`
    );

    if (e.key === "Enter") {
      console.log("Enter key pressed");

      // Log the suggestion that will be selected
      console.log(
        `Selected suggestion: ${suggestions[selectedSuggestionIndex]}`
      );

      setDynamicErrors((prevErrors) => ({
        ...prevErrors,
        [name]: {
          showSuggestions: false,
        },
      }));

      setInputValues((prevState) => ({
        ...prevState,
        [name]: suggestions[selectedSuggestionIndex],
      }));

      // Log the updated state after selection
      console.log("Suggestions closed and input value updated");
    } else if (e.key === "ArrowUp" && selectedSuggestionIndex > 0) {
      console.log("ArrowUp key pressed");

      setDynamicErrors((prevErrors) => ({
        ...prevErrors,
        [name]: {
          selectedSuggestionIndex: selectedSuggestionIndex - 1,
        },
      }));

      // Log the new selected index after moving up
      console.log(
        `New selected suggestion index: ${selectedSuggestionIndex - 1}`
      );
    } else if (
      e.key === "ArrowDown" &&
      selectedSuggestionIndex < suggestions.length - 1
    ) {
      console.log("ArrowDown key pressed");

      setDynamicErrors((prevErrors) => ({
        ...prevErrors,
        [name]: {
          selectedSuggestionIndex: selectedSuggestionIndex + 1,
        },
      }));

      // Log the new selected index after moving down
      console.log(
        `New selected suggestion index: ${selectedSuggestionIndex + 1}`
      );
    } else {
      console.log("Other key pressed or no action taken");
    }
  };

 



  console.log("selected", selectedQuestion);
  return (
    <>
      <Modal fullscreen={true} show={popupVisible} onHide={handleClosePopup}>
        <Modal.Header closeButton>
          <div className=" w-100 d-flex align-items-center justify-content-between">
            <Modal.Title>
              <div> Add Formula</div>
            </Modal.Title>
           
          </div>
        </Modal.Header>
        <Modal.Body>
          {selectedQuestion?.questionType === "yes_no" && (
            <Form.Group
              className="mb-2"
              controlId={`question-${selectedQuestion.question_id}`}
            >
              <Form.Label>
                Add Formula for {selectedQuestion.heading}
              </Form.Label>
              <Form.Control
                autoFocus
                name={`${selectedQuestion.question_id}`}
                type="text"
                value={inputValues[`${selectedQuestion.question_id}`] || ""}
                onChange={(event) =>
                  handleInputChangeForFormula(
                    event,
                    selectedQuestion.question_id
                  )
                }
                onKeyDown={(event) =>
                  handleKeyDownForFormula(
                    event,
                    `${selectedQuestion.question_id}`
                  )
                }
                placeholder="Type something..."
                autoComplete="off"
              />

              {dynamicErrors[`${selectedQuestion.question_id}`] &&
                dynamicErrors[`${selectedQuestion.question_id}`]
                  .inValidQuestionError && (
                  <div className="error-message">
                    {
                      dynamicErrors[`${selectedQuestion.question_id}`]
                        .inValidQuestionError
                    }
                  </div>
                )}
              {dynamicErrors[`${selectedQuestion.question_id}`] &&
                dynamicErrors[`${selectedQuestion.question_id}`]
                  .inValidExpressionError && (
                  <div className="error-message">
                    {
                      dynamicErrors[`${selectedQuestion.question_id}`]
                        .inValidExpressionError
                    }
                  </div>
                )}
              {dynamicErrors[`${selectedQuestion.question_id}`] &&
                dynamicErrors[`${selectedQuestion.question_id}`]
                  .showSuggestions && (
                  <div
                    className={
                      dynamicErrors[`${selectedQuestion.question_id}`]
                        .suggestions &&
                      dynamicErrors[`${selectedQuestion.question_id}`]
                        .suggestions.length > 0 &&
                      "formula__suggesstions"
                    }
                  >
                    {dynamicErrors[
                      `${selectedQuestion.question_id}`
                    ].suggestions.map((suggestion, index) => (
                      <div
                        key={index}
                        className={
                          index ===
                          dynamicErrors[`${selectedQuestion.question_id}`]
                            .selectedSuggestionIndex
                            ? "selected"
                            : ""
                        }
                        onClick={(e) =>
                          handleSuggestionClickForFormula(
                            `${selectedQuestion.question_id}`,
                            suggestion
                          )
                        }
                      >
                        {suggestion}
                      </div>
                    ))}
                  </div>
                )}
              
            </Form.Group>
          )}
          {selectedQuestion?.questionType === "qualitative" && (
            <Form.Group
              className="mb-2"
              controlId={`question-${selectedQuestion.question_id}`}
            >
              <Form.Label>
                Add Formula for {selectedQuestion.heading}
              </Form.Label>
              <Form.Control
                autoFocus
                name={`${selectedQuestion.question_id}`}
                type="text"
                value={inputValues[`${selectedQuestion.question_id}`] || ""}
                onChange={(event) =>
                  handleInputChangeForFormula(
                    event,
                    selectedQuestion.question_id
                  )
                }
                onKeyDown={(event) =>
                  handleKeyDownForFormula(
                    event,
                    `${selectedQuestion.question_id}`
                  )
                }
                placeholder="Type something..."
                autoComplete="off"
              />

              {dynamicErrors[`${selectedQuestion.question_id}`] &&
                dynamicErrors[`${selectedQuestion.question_id}`]
                  .inValidQuestionError && (
                  <div className="error-message">
                    {
                      dynamicErrors[`${selectedQuestion.question_id}`]
                        .inValidQuestionError
                    }
                  </div>
                )}
              {dynamicErrors[`${selectedQuestion.question_id}`] &&
                dynamicErrors[`${selectedQuestion.question_id}`]
                  .inValidExpressionError && (
                  <div className="error-message">
                    {
                      dynamicErrors[`${selectedQuestion.question_id}`]
                        .inValidExpressionError
                    }
                  </div>
                )}
              {dynamicErrors[`${selectedQuestion.question_id}`] &&
                dynamicErrors[`${selectedQuestion.question_id}`]
                  .showSuggestions && (
                  <div
                    className={
                      dynamicErrors[`${selectedQuestion.question_id}`]
                        .suggestions &&
                      dynamicErrors[`${selectedQuestion.question_id}`]
                        .suggestions.length > 0 &&
                      "formula__suggesstions"
                    }
                  >
                    {dynamicErrors[
                      `${selectedQuestion.question_id}`
                    ].suggestions.map((suggestion, index) => (
                      <div
                        key={index}
                        className={
                          index ===
                          dynamicErrors[`${selectedQuestion.question_id}`]
                            .selectedSuggestionIndex
                            ? "selected"
                            : ""
                        }
                        onClick={(e) =>
                          handleSuggestionClickForFormula(
                            `${selectedQuestion.question_id}`,
                            suggestion
                          )
                        }
                      >
                        {suggestion}
                      </div>
                    ))}
                  </div>
                )}
              
            </Form.Group>
          )}
          {selectedQuestion?.questionType === "quantitative" && (
            <Form.Group
              className="mb-2"
              controlId={`question-${selectedQuestion.question_id}`}
            >
              <Form.Label>
                Add Formula for {selectedQuestion.heading}
              </Form.Label>
              <Form.Control
                autoFocus
                name={`${selectedQuestion.question_id}`}
                type="text"
                value={inputValues[`${selectedQuestion.question_id}`] || ""}
                onChange={(event) =>
                  handleInputChangeForFormula(
                    event,
                    selectedQuestion.question_id
                  )
                }
                onKeyDown={(event) =>
                  handleKeyDownForFormula(
                    event,
                    `${selectedQuestion.question_id}`
                  )
                }
                placeholder="Type something..."
                autoComplete="off"
              />

              {dynamicErrors[`${selectedQuestion.question_id}`] &&
                dynamicErrors[`${selectedQuestion.question_id}`]
                  .inValidQuestionError && (
                  <div className="error-message">
                    {
                      dynamicErrors[`${selectedQuestion.question_id}`]
                        .inValidQuestionError
                    }
                  </div>
                )}
              {dynamicErrors[`${selectedQuestion.question_id}`] &&
                dynamicErrors[`${selectedQuestion.question_id}`]
                  .inValidExpressionError && (
                  <div className="error-message">
                    {
                      dynamicErrors[`${selectedQuestion.question_id}`]
                        .inValidExpressionError
                    }
                  </div>
                )}
              {dynamicErrors[`${selectedQuestion.question_id}`] &&
                dynamicErrors[`${selectedQuestion.question_id}`]
                  .showSuggestions && (
                  <div
                    className={
                      dynamicErrors[`${selectedQuestion.question_id}`]
                        .suggestions &&
                      dynamicErrors[`${selectedQuestion.question_id}`]
                        .suggestions.length > 0 &&
                      "formula__suggesstions"
                    }
                  >
                    {dynamicErrors[
                      `${selectedQuestion.question_id}`
                    ].suggestions.map((suggestion, index) => (
                      <div
                        key={index}
                        className={
                          index ===
                          dynamicErrors[`${selectedQuestion.question_id}`]
                            .selectedSuggestionIndex
                            ? "selected"
                            : ""
                        }
                        onClick={(e) =>
                          handleSuggestionClickForFormula(
                            `${selectedQuestion.question_id}`,
                            suggestion
                          )
                        }
                      >
                        {suggestion}
                      </div>
                    ))}
                  </div>
                )}
            
            </Form.Group>
          )}
          {selectedQuestion?.questionType === "quantitative_trends" && (
            <Form.Group
              className="mb-2"
              controlId={`question-${selectedQuestion.question_id}`}
            >
              <Form.Label>
                Add Formula for {selectedQuestion.heading}
              </Form.Label>
              <Form.Control
                autoFocus
                name={`${selectedQuestion.question_id}`}
                type="text"
                value={inputValues[`${selectedQuestion.question_id}`] || ""}
                onChange={(event) =>
                  handleInputChangeForFormula(
                    event,
                    selectedQuestion.question_id
                  )
                }
                onKeyDown={(event) =>
                  handleKeyDownForFormula(
                    event,
                    `${selectedQuestion.question_id}`
                  )
                }
                placeholder="Type something..."
                autoComplete="off"
              />

              {dynamicErrors[`${selectedQuestion.question_id}`] &&
                dynamicErrors[`${selectedQuestion.question_id}`]
                  .inValidQuestionError && (
                  <div className="error-message">
                    {
                      dynamicErrors[`${selectedQuestion.question_id}`]
                        .inValidQuestionError
                    }
                  </div>
                )}
              {dynamicErrors[`${selectedQuestion.question_id}`] &&
                dynamicErrors[`${selectedQuestion.question_id}`]
                  .inValidExpressionError && (
                  <div className="error-message">
                    {
                      dynamicErrors[`${selectedQuestion.question_id}`]
                        .inValidExpressionError
                    }
                  </div>
                )}
              {dynamicErrors[`${selectedQuestion.question_id}`] &&
                dynamicErrors[`${selectedQuestion.question_id}`]
                  .showSuggestions && (
                  <div
                    className={
                      dynamicErrors[`${selectedQuestion.question_id}`]
                        .suggestions &&
                      dynamicErrors[`${selectedQuestion.question_id}`]
                        .suggestions.length > 0 &&
                      "formula__suggesstions"
                    }
                  >
                    {dynamicErrors[
                      `${selectedQuestion.question_id}`
                    ].suggestions.map((suggestion, index) => (
                      <div
                        key={index}
                        className={
                          index ===
                          dynamicErrors[`${selectedQuestion.question_id}`]
                            .selectedSuggestionIndex
                            ? "selected"
                            : ""
                        }
                        onClick={(e) =>
                          handleSuggestionClickForFormula(
                            `${selectedQuestion.question_id}`,
                            suggestion
                          )
                        }
                      >
                        {suggestion}
                      </div>
                    ))}
                  </div>
                )}
             
            </Form.Group>
          )}
          {selectedQuestion?.questionType === "tabular_question" && (
           
            <TabularAddFormulaTrigger
              setState={setState}
              state={state}
              dynamicErrors={dynamicErrors}
              handleSuggestionClickForFormula={handleSuggestionClickForFormula}
              handleOperatorChangeForFormula={handleOperatorChangeForFormula}
              handleKeyDownForFormula={handleKeyDownForFormula}
              selectedQuestion={selectedQuestion}
              operators={operators}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePopup}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => saveSectorQuestionForFormula()}
          >
            Add Formula
          </Button>
        </Modal.Footer>
      </Modal>
     
    </>
  );
};

export default AddFormTrigger;
