import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import "./companies.css";
import config from "../../../config/config.json";
import CompaniesTabbing from "../companies/companies_tabbing";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import { Pagination, Icon } from "semantic-ui-react";

import { authenticationService } from "../../../_services/authentication";
const currentUser = authenticationService.currentUserValue;
export default class ComapanySubAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      loading: false,
      totalRows: 0,
      currentPage: 1,
      skip: 0,
      limit: 10,
      pageCount: 0,
      perPage: 10,
      totalData: 0,
    };
    // this.pageChange = this.pageChange.bind(this);
    this.serverRequest = this.serverRequest.bind(this);
    this.pageChange = this.pageChange.bind(this);
  }

  pageChange(e, data) {
    console.log("Handle Click", data);
    let page = data.activePage;
    let pageNo = page === 1 ? 0 : (page - 1) * this.state.limit;
    console.log("clicked", pageNo);
    this.setState({
      skip: pageNo,
    });
    this.serverRequest();
    // console.log("order", orders);
  }

  serverRequest() {
    const { skip, limit } = this.state;
    console.log("location", window.location.href);
    let urlArr = window.location.pathname.split("/");
    let uuid = urlArr[urlArr.length - 2];
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };
    let parms = `getAllUserAccordingToCompany?uuid=${uuid}&offset=${skip}&limit=${limit}`;
    fetch(config.ADMIN_API_URL + parms, requestOptions)
      .then((res) => res.json())
      .then(
        (data) => {
          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            totalData: data.data.count,
            items: data.data.rows,
          });
        },
        (error) => {}
      );
  }

  componentDidMount() {
    this.serverRequest();
  }

  render() {
    const { items } = this.state;
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <CompaniesTabbing />
                    <div className="col-sm-12">
                      <div className="strp_progess">
                        <div className="hol_rell">
                          <div className="steps-form">
                            <div className="steps-row setup-panel"></div>
                          </div>
                        </div>
                      </div>
                      <div className="SVG Stepper">
                        <div className="stepperr_design">
                          <div className="color_div_step">
                            <div className="include">
                              <section className="d_text">
                                <div className="row">
                                  <div className="col-sm-12">
                                    <div className="saved_cards">
                                      <div className="table_f">
                                        <Table striped bordered hover size="sm">
                                          <thead>
                                            <tr className="heading_color">
                                              <th>ID</th>
                                              <th>SUB ACCOUNT NAME</th>
                                              <th>EMAIL</th>
                                              <th>TITLE</th>
                                              <th className="text-center">
                                                STATUS
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {items.map((item, key) => (
                                              <tr key={key}>
                                                <td>{key + 1}</td>
                                                <td>
                                                  {item.firstName +
                                                    " " +
                                                    item.lastName}
                                                </td>
                                                <td>{item.email}</td>
                                                <td>{item.userCategory}</td>
                                                <td>
                                                  <p style={{ color: "green" }}>
                                                    {"Active"}
                                                  </p>
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </Table>
                                        <Pagination
                                          className="mx-auto pagination"
                                          defaultActivePage={1}
                                          onPageChange={this.pageChange}
                                          ellipsisItem={{
                                            content: (
                                              <Icon name="ellipsis horizontal" />
                                            ),
                                            icon: true,
                                          }}
                                          firstItem={{
                                            content: (
                                              <Icon name="angle double left" />
                                            ),
                                            icon: true,
                                          }}
                                          lastItem={{
                                            content: (
                                              <Icon name="angle double right" />
                                            ),
                                            icon: true,
                                          }}
                                          prevItem={{
                                            content: <Icon name="angle left" />,
                                            icon: true,
                                          }}
                                          nextItem={{
                                            content: (
                                              <Icon name="angle right" />
                                            ),
                                            icon: true,
                                          }}
                                          totalPages={Math.ceil(
                                            this.state.totalData /
                                              this.state.limit
                                          )}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
