/* eslint-disable jsx-a11y/anchor-is-valid */
import Swal from "sweetalert2";
import React, { Component } from "react";
import { Header, Modal } from "semantic-ui-react";

import logo from "../../img/Zais_logo.png";
import { sweetAlert } from "../../utils/UniversalFunction";
import axios from "axios";
import "./login.css";
import "../sidebar/common.css";
import config from "../../config/config.json";
import { authenticationService } from "../../_services/authentication";
import { BehaviorSubject } from "rxjs";
import { NavLink } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
const baseURL = config.baseURL;
export default class signup extends Component {
  constructor(props) {
    super(props);
    if (authenticationService.currentUserValue) {
      this.props.history.push("/admin");
    }

    this.state = {
      user: {
        email: "",
        password: "",
        firstname: "",
        lastname: "",
        isLoggedIn: false,
        userInfo: {
          name: "",
          emailId: "",
        },
      },
      type: "password",
      submitted: false,
      emailForResetPassword: "",
      captchaIsVerified: false,
      loading: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleVerification = this.handleVerification.bind(this);
    this.showHide = this.showHide.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSubmit2 = this.handleSubmit2.bind(this);
  }

  handleOpenModal(val) {
    this.setState({ activeModal: val });
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
    this.setState({ showModal: "" });
  }
  showHide(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === "password" ? "input" : "password",
    });
  }

  onClose() {
    this.setState({
      setOpen: false,
    });
  }
  setOpen(event) {
    this.setState({
      setOpen: true,
    });
  }

  handleVerification(e) {
    this.setState({
      captchaIsVerified: true,
    });
  }

  // Logout Session and Update State
  logout = (response) => {
    console.log(response);
    let userInfo = {
      name: "",
      emailId: "",
    };
    this.setState({ userInfo, isLoggedIn: false });
  };

  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value,
      },
    });
  }

  handleChange2(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    let currentUserSubject = new BehaviorSubject(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    event.preventDefault();
    localStorage.removeItem("currentUser");
    currentUserSubject.next(null);
    this.setState({ submitted: true });
    const { user, captchaIsVerified } = this.state;
    let pushToRoute = "";
    // document.getElementById('user_captcha_input').value = "";
    // if (user.email && user.password && captchaIsVerified)
    if (user.email && user.password) {
      this.setState({ loading: true });
      await axios
        .post(
          config.API_URL + "auth/admin/login",
          {
            email: user.email,
            password: user.password,
            token: captchaIsVerified,
          },
          {
            headers: {
              portalType: "ADMIN",
            },
          }
        )
        .then((response) => {
          this.setState({ loading: false });
          Swal.fire({
            icon: "success",
            title: response.data.customMessage,
            showConfirmButton: true,
            timer: 1000,
          });
          let setResponse = {};
          setResponse.data = response.data;
          setResponse.data.role = response.data.user_type_code;
          // let setSecondResponse = response.data.data.twoFaStatus;
          let setThirdResponse = response.data.user_id;
          let setFourthResponse = response.data.user_type_code;
          let setFifthResponse = response.data.charge_type;
          let setSixthResponse = response.data.charge_value;
          localStorage.setItem("currentUser", JSON.stringify(setResponse));
          localStorage.setItem("userID", JSON.stringify(setThirdResponse));
          authenticationService.currentUserSubject.next(setResponse);
          // localStorage.setItem("2faStatus", JSON.stringify(setSecondResponse));
          localStorage.setItem("role", setFourthResponse);
          localStorage.setItem("chargeType", setFifthResponse);
          localStorage.setItem("chargeValue", setSixthResponse);
          // let check2faStatus = response.data.data.twoFaStatus === 0;
          // if (check2faStatus) {
          //   pushToRoute = "/OTPVerify";
          // } else {
          pushToRoute = "/#/home";
          // }
          window.location.href = baseURL + pushToRoute;
        })
        .catch(function (error) {
          if (error.response) {
            Swal.fire({
              icon: "error",
              title: "Please Provide Valid Credentials",
              showConfirmButton: true,
              timer: 3000,
            });
          }
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "Please fill all input",
        showConfirmButton: true,
        timer: 1500,
      });
    }
  }

  handleSubmit2(event) {
    event.preventDefault();
    const { emailForResetPassword } = this.state;
    if (emailForResetPassword) {
      axios
        .post(config.ADMIN_API_URL + "password/email", {
          email: emailForResetPassword,
        })
        .then((response) => {
          sweetAlert("success", response.data.message);
          window.location.reload(false);
        })
        .catch(function (error) {
          if (error.response) {
            sweetAlert("error", error.response.data.message);
          }
        });
    } else {
      sweetAlert("error", "Please fill Email Address");
    }
  }
  render() {
    const { user, submitted, type } = this.state;
    return (
      <div>
        <section className="login">
          <div className="login_part">
            <div className="Login_panel">
              <div className="text-center">
                <img src={logo} alt="logo" className="w-50" />
              </div>
              <h2 style={{ color: "#1f9ed1" }} className="text-center">
                <b>Login</b>
              </h2>
              <p className="text-center">
                Please fill out your information below to log into your
                dashboard
              </p>
              <form name="form" onSubmit={this.handleSubmit}>
                <div className="form-group mb-3">
                  <label className="st_name" htmlFor="name">
                    Email
                  </label>
                  <input
                    className="form-control p-3"
                    type="text"
                    name="email"
                    placeholder="Enter Register Email Address"
                    value={user.email}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="password-eye">
                  <div className="img-eye">
                    <span onClick={this.showHide}>
                      {this.state.type === "input" ? (
                        <i className="fas fa-eye-slash"></i>
                      ) : (
                        <i className="fas fa-eye"></i>
                      )}
                    </span>
                  </div>
                  <div className="form-group mb-3">
                    <label className="st_name" htmlFor="name">
                      Password
                    </label>
                    <input
                      className="form-control p-3"
                      type={type}
                      name="password"
                      placeholder="Enter your password"
                      value={user.password}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="text-end reset mb-3">
                  <span>
                    Forgot Password?{" "}
                    <NavLink className="aClass" to="/ResetPass">
                      Reset
                    </NavLink>
                  </span>
                </div>
                {/*<div className="make_form">
                  <div className="row">
                    <div className="col-md-6">
                        <div className="bacei">
                          <ReCAPTCHA
                            sitekey={env.GOOGLE_RECAPTCHA_SITE_KEY}
                            onChange={(e) => this.handleVerification(e)}
                          />
                        </div>
                    </div>
                  </div>
                          </div>*/}

                {/* <div className="view_bottoma">
                  <button type="submit" value="Submit" className="btn">
                    Login
                  </button>
                </div> */}
                {this.state?.loading ? (
                  <Button variant="info" className="w-100 p-3" disabled>
                    <Spinner animation="border" /> Loging...
                  </Button>
                ) : user.email && user.password ? (
                  <Button type="submit" className="w-100 p-3" variant="info">
                    <b>Login</b>
                  </Button>
                ) : (
                  <Button variant="secondary" className="w-100 p-3" disabled>
                    <b>Login</b>
                  </Button>
                )}
              </form>
            </div>
          </div>
        </section>

        <Modal open={this.state.setOpen} className="feedback2 feedback3">
          <Modal.Header>Reset Password</Modal.Header>
          <form>
            <Modal.Content>
              <Modal.Description>
                <Header>
                  Enter your registered email address below to receive a
                  password reset link.
                </Header>
                <div className="form-group">
                  <input
                    value={this.state.emailForResetPassword}
                    onChange={(e) => this.handleChange2(e)}
                    className="form-control padd"
                    name="emailForResetPassword"
                    placeholder="Enter your Register Email Address"
                    required
                  ></input>
                </div>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button
                className="link_bal_next"
                content="Send"
                onClick={(e) => this.handleSubmit2(e)}
              />
              <Button className="mx-3" onClick={() => this.onClose(false)}>
                Cancel
              </Button>
            </Modal.Actions>
          </form>
        </Modal>
      </div>
    );
  }
}
