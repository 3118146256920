/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { Component } from "react"
import AdminSidebar from "../../sidebar/admin_sidebar"
import AdminHeader from "../../header/admin_header"
import axios from "axios"
import { NavLink } from "react-router-dom"
import { Button, Col, Modal, Row } from "react-bootstrap"
import Table from "react-bootstrap/Table"
import { Pagination, Icon } from "semantic-ui-react"
import { authenticationService } from "../../../_services/authentication"
import { sweetAlert } from "../../../utils/UniversalFunction"
import "../companies/companies.css"
import config from "../../../config/config.json"
import Swal from "sweetalert2"
import { async } from "q"
import { apiCall } from "../../../_services/apiCall"
import { PermissionContext } from "../../../context/PermissionContext"
import Loader from "../../loader/Loader"
const currentUser = authenticationService.currentUserValue
const BackendBaseUrl = config.BASE_URL
export default class EsgSubAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loderStastus: false,
      login: false,
      country: false,
      industry: false,
      category: false,
      activeModal: "",
      showModal: false,
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      status: "1",
      role_id: "",
      role_name: "",
      submitted: false,
      search: "",
      pageCount: 0,
      totalData: 0,
      items: [],
      loading: false,
      startDate: "",
      endDate: "",
      totalRows: 0,
      currentPage: 1,
      skip: 0,
      limit: 10,
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.pageChange = this.pageChange.bind(this);
    this.serverRequest = this.serverRequest.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    // this.exportCSV = this.exportCSV.bind(this);
  }

  applyGlobalSearch(e) {
    let value = e?.target?.value;
    let name = e?.target?.name;
    this.setState({
      [name]: value,
    });
    setTimeout(() => {
      this.serverRequest();
    }, 200);
  }

  handleOpenModal(val) {
    this.setState({ activeModal: val });
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
    this.setState({ showModal: "" });
  }
  handleStatusChange(event) {
    const target = event?.target;
    const value = target.checked ? 1 : 0;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }
  handleChange(event) {
    const target = event?.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleUpdate = async (event) => {
    // event.preventDefault();
    this.setState({ submitted: true });

    const { firstName, lastName, email, mobile, status, id, role_id } =
      this.state;
    // const headers = {
    //   Authorization: `Bearer ${currentUser.data.token}`,
    //   Accept: "application/json",
    // };
    //{
    // axios
    //   .post(
    //     config.ADMIN_API_URL + "updateSubUser",
    //     {
    //       email: email,
    //       mobile_number: mobile,
    //       first_name: firstName,
    //       last_name: lastName,
    //       status: status,
    //       id: id,
    //       role_id,
    //       role_id,
    //     },
    //     { headers }
    //   )
    //   .then((response) => {
    // this.serverRequest();
    // Swal.fire({
    //   icon: "success",
    //   title: response.data.message,
    //   showConfirmButton: false,
    //   timer: 1000,
    // });
    // setTimeout(() => {
    //   this.handleCloseModal();
    //   this.handleUpdate();
    //   this.setState({ submitted: false });
    // }, 1000);
    // })
    // .catch(function (error) {
    //   if (error.response) {
    //     Swal.fire({
    //       icon: "error",
    //       title: error.response.data.message,
    //       showConfirmButton: false,
    //       timer: 1000,
    //     });
    //     this.serverRequest();
    //   }
    // });

    //}
    let obj = {};
    obj.email = email;
    obj.mobile_number = mobile;
    obj.first_name = firstName;
    obj.last_name = lastName;
    obj.status = status;
    obj.id = id;
    obj.role_id = role_id;
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}updateSubUser`,
      {},
      obj,
      "POST"
    );
    if (isSuccess) {
      this.serverRequest();
      this.handleCloseModal();
      this.setState({ submitted: false });
    }
  };
  async handleSubmit(event) {
    // event.preventDefault();
    this.setState({ submitted: true });
    const { firstName, lastName, email, mobile, role_id } = this.state;
    // console.log({
    //   email: email,
    //   mobile: mobile,
    //   first_name: firstName,
    //   last_name: lastName,
    //   role_id: role_id,
    // });
    // const headers = {
    //   Authorization: `Bearer ${currentUser?.data?.token}`,
    //   Accept: "application/json",
    // };
    // {
    //   axios
    //     .post(
    //       config.ADMIN_API_URL + "createSubUser",
    //       {
    //         email: email,
    //         mobile: mobile,
    //         first_name: firstName,
    //         last_name: lastName,
    //         role_id: role_id,
    //       },
    //       { headers }
    //     )
    //     .then((response) => {
    //       this.serverRequest();
    //       sweetAlert("success", response?.data?.message);
    //       setTimeout(() => {
    //         this.handleCloseModal();
    //         this.setState({
    //           firstName: "",
    //           lastName: "",
    //           email: "",
    //           mobile: "",
    //           submitted: false,
    //         });
    //         this.setState({ submitted: false });
    //       }, 1000);
    //       this.serverRequest();
    //     })
    //     .catch(function (error) {
    //       if (error.response) {
    //         sweetAlert("error", error?.response?.data?.message);
    //       }
    //     });
    // }
    let obj = {};
    obj.email = email;
    obj.mobile = mobile;
    obj.first_name = firstName;
    obj.last_name = lastName;
    obj.role_id = role_id;
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}createSubUser`,
      {},
      obj,
      "POST"
    );
    if (isSuccess) {
      this.serverRequest();
      this.handleCloseModal();
      this.setState({
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        submitted: false,
      });
      this.setState({ submitted: false });
    }
  }

  // exportCSV(event) {
  //   event.preventDefault();
  //   const requestOptions = {
  //     method: "POST",
  //     headers: {
  //       Authorization: `Bearer ${currentUser?.data?.token}`,
  //       "Content-Type": "application/json",
  //     },
  //   };
  //   fetch(`${config.ADMIN_API_URL}sub-admins/export`, requestOptions)
  //     .then((res) => res.json())
  //     .then(
  //       (result) => {
  //         this.setState({
  //           csvLink: result?.data,
  //         });
  //         let url = BackendBaseUrl + result?.data;
  //         window.open(url, "_blank");
  //       },
  //       (error) => {
  //         this.setState({
  //           isLoaded: true,
  //           error,
  //         });
  //       }
  //     );
  // }

  async serverRequest() {
    this.setState({ loderStastus: true });
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}getSubUsers`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      this.setState({ loderStastus: false });
      this.setState({
        isLoaded: true,
        totalCount: data.totalCount,
        items: data.data,
        list: data.data,
        pages: Math.floor(data?.data?.length / this.state.perPage),
      });
    }
    // const headers = {
    //   Authorization: `Bearer ${currentUser.data.token}`,
    //   Accept: "application/json",
    // };
    // fetch(`${config.ADMIN_API_URL}getSubUsers`, { headers })
    //   .then((res) => res.json())
    //   .then(
    //     (result) => {
    //       this.setState({
    //         isLoaded: true,
    //         totalCount: result.totalCount,
    //         items: result.data,
    //         list: result.data,
    //         pages: Math.floor(result?.data?.length / this.state.perPage),
    //       });
    //     },
    //     (error) => {
    //       this.setState({
    //         isLoaded: true,
    //         error,
    //       });
    //     }
    //   );
  }
  async getRoles() {
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}getRoles`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      this.setState({ options: data?.data });
    }
  }

  pageChange(e, data) {
    console.log("Handle Click", data);
    let page = data?.activePage;
    let pageNo = page === 1 ? 0 : (page - 1) * this.state.limit;
    console.log("clicked", pageNo);
    this.setState({
      skip: pageNo,
    });
    this.serverRequest();
  }

  componentDidMount() {
    this.setState({ items: [] });
    this.serverRequest();
    this.getRoles();
  }

  render() {
    const { items, loderStastus } = this.state;
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <PermissionContext.Consumer>
          {({ permissions }) => (
            <div className="main_wrapper">
              <div className="inner_wraapper">
                <div className="hol_rell">
                  <div className="steps-form">
                    <div className="steps-row setup-panel">
                      <div className="tabs-top setting_admin my-0">
                        <ul>
                          <li>
                            <NavLink to="/settings">
                              My Profile
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/settings/billing">
                              {" "}
                              Billing{" "}
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/settings/access_management"> Access </NavLink>
                          </li>
                          {/* <li>
                            <NavLink to="/generator">
                              Generator
                            </NavLink>
                          </li> */}
                          <li>
                            <NavLink to="/settings/financial_year">
                              Financial Year
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/settings/sub-users"
                              className="activee"
                            >
                              Sub Users
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/settings/category">
                              Category
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/settings/carbon_emission">
                              Carbon Emission
                            </NavLink>
                          </li>
                          <li>
                    <NavLink to="/settings/unit">
                      Unit
                    </NavLink>
                  </li>
                        </ul>
                      </div>
                      <div className="hstack gap-2">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search Sub-Users Name &amp; Email"
                          name="search"
                          onChange={(e) =>
                            this.applyGlobalSearch(e)
                          }
                        />
                        <Button
                          className="mx-2"
                          variant="info"
                          onClick={() =>
                            this.handleOpenModal(
                              "login"
                            )
                          }
                        >
                          Add Sub Users
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="color_div_on framwork_2 pt-0">
                  <div className="table-responsive">
                    <div className="table___height">
                      {!loderStastus ?
                        <Table striped bordered className="m-0" style={{ whiteSpace: "nowrap" }}>
                          <thead>
                            <tr className="heading_color">
                              <th style={{width: 55}}>Sr.</th>
                              <th>First Name</th>
                              <th>Last Name</th>
                              <th>Email</th>
                              <th>Mobile</th>
                              <th>Role Name</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          {items.length > 0 ?
                            <tbody>
                              {items?.map((item, key) => (
                                <tr key={key}>
                                  <td>{key + 1}</td>
                                  <td>{item?.first_name}</td>
                                  <td>{item?.last_name}</td>
                                  <td>{item?.email}</td>
                                  <td>{item?.mobile_number}</td>
                                  <td>{item?.role_name}</td>
                                  <td>
                                    {item.status === 1
                                      ? "Active"
                                      : "Inactive"}
                                  </td>
                                  <td>
                                    <button
                                      style={{
                                        border: "none",
                                        background: "none",
                                      }}
                                      onClick={() => {
                                        this.handleOpenModal(
                                          "update"
                                        );
                                        this.setState({
                                          id: item.id,
                                          role_id: item.role_id,
                                          role_name:
                                            item.role_name,
                                          firstName:
                                            item.first_name,
                                          lastName:
                                            item.last_name,
                                          email: item.email,
                                          mobile: item.mobile_number,
                                          status: item.status,
                                          submitted: false,
                                        });
                                      }}
                                    >
                                      <i className="fas fa-edit" title="Edit" style={{ color: 'green' }} />
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                            :
                            <tbody>
                              <tr>
                                <td colSpan={8} className="text-center">No Data Found</td>
                              </tr>
                            </tbody>
                          }
                        </Table>
                        :
                        <Loader />
                      }
                      {/* <Pagination
                      className="mx-auto pagination"
                      defaultActivePage={1}
                      onPageChange={this.pageChange}
                      ellipsisItem={{
                        content: (
                          <Icon name="ellipsis horizontal" />
                        ),
                        icon: true,
                      }}
                      firstItem={{
                        content: (
                          <Icon name="angle double left" />
                        ),
                        icon: true,
                      }}
                      lastItem={{
                        content: (
                          <Icon name="angle double right" />
                        ),
                        icon: true,
                      }}
                      prevItem={{
                        content: <Icon name="angle left" />,
                        icon: true,
                      }}
                      nextItem={{
                        content: (
                          <Icon name="angle right" />
                        ),
                        icon: true,
                      }}
                      totalPages={Math.ceil(
                        this.state.totalData /
                        this.state.limit
                      )}
                    /> */}
                      <Modal
                        animation={true}
                        size="md"
                        className="modal_box"
                        shadow-lg="border"
                        show={
                          this.state.showModal &&
                          this.state.activeModal === "login"
                        }
                      >
                        <div className="modal-lg">
                          <Modal.Header className="justify-content-between">
                            <Modal.Title>
                              <div className="pb3">
                                <h4>
                                  Add New Sub Accounts
                                </h4>
                              </div>
                            </Modal.Title>
                            <Button
                              variant="outline-dark"
                              onClick={this.handleCloseModal}
                            >
                              <i className="fa fa-times"></i>
                            </Button>
                          </Modal.Header>
                          <div className="modal-body">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="forms">
                                  <div className="form-group mb-3">
                                    <label
                                      htmlFor="firstName"
                                      className="form-label fw-bold"
                                    >
                                      Enter sub-user's
                                      information
                                    </label>
                                    <Row>
                                      <Col>
                                        <input
                                          className="form-control"
                                          aria-expanded="false"
                                          placeholder="First Name"
                                          value={
                                            this.state.firstName
                                          }
                                          name="firstName"
                                          type="text"
                                          onChange={
                                            this.handleChange
                                          }
                                        />
                                        {this.state.submitted &&
                                          !this.state
                                            .firstName && (
                                            <div className="help-block">
                                              First name is
                                              required
                                            </div>
                                          )}
                                      </Col>
                                      <Col>
                                        <input
                                          className="form-control"
                                          aria-expanded="false"
                                          placeholder="Last Name"
                                          value={
                                            this.state.lastName
                                          }
                                          name="lastName"
                                          type="text"
                                          onChange={
                                            this.handleChange
                                          }
                                        />
                                        {this.state.submitted &&
                                          !this.state
                                            .lastName && (
                                            <div className="help-block">
                                              Last name is
                                              required
                                            </div>
                                          )}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <input
                                          className="form-control"
                                          aria-expanded="false"
                                          placeholder="Email"
                                          value={
                                            this.state.email
                                          }
                                          name="email"
                                          type="email"
                                          onChange={
                                            this.handleChange
                                          }
                                        />
                                        {this.state.submitted &&
                                          !this.state.email && (
                                            <div className="help-block">
                                              Email is required
                                            </div>
                                          )}
                                      </Col>
                                      <Col>
                                        <input
                                          className="form-control"
                                          aria-expanded="false"
                                          placeholder="Mobile"
                                          value={
                                            this.state.mobile
                                          }
                                          name="mobile"
                                          type="tel"
                                          onChange={
                                            this.handleChange
                                          }
                                        />
                                        {this.state.submitted &&
                                          !this.state
                                            .mobile && (
                                            <div className="help-block">
                                              Mobile number is
                                              required
                                            </div>
                                          )}
                                      </Col>
                                    </Row>
                                    <select
                                      className="form-control select_map"
                                      aria-expanded="false"
                                      name="role_id"
                                      value={
                                        this.state.role_id
                                      }
                                      onChange={(event) => {
                                        this.handleChange(
                                          event
                                        )
                                        this.setState({
                                          role_id:
                                            event.target
                                              .value,
                                        })
                                      }}
                                    >
                                      {" "}
                                      <option>
                                        Select User Role
                                      </option>
                                      {this.state?.options?.map(
                                        (option) => (
                                          <option
                                            key={option?.id}
                                            value={
                                              option?.id
                                            }
                                          >
                                            {
                                              option.role_display_name
                                            }
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                  <div className="cenlr text-end">
                                    <button
                                      className="page_save page_width"
                                      onClick={() =>
                                        this.setState({
                                          firstName: "",
                                          lastName: "",
                                          email: "",
                                          mobile: "",
                                          submitted: false,
                                        })
                                      }
                                    >
                                      CANCEL
                                    </button>
                                    <button
                                      className="page_save page_width mx-3"
                                      onClick={
                                        this.handleSubmit
                                      }
                                    >
                                      SUBMIT
                                    </button>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal>
                      <Modal
                        animation={true}
                        size="md"
                        className="modal_box"
                        shadow-lg="border"
                        show={
                          this.state.showModal &&
                          this.state.activeModal === "update"
                        }
                      >
                        <div className="modal-lg">
                          <Modal.Header className="justify-content-between">
                            <Modal.Title>
                              <div className="pb3">
                                <h4>
                                  Update User Information
                                </h4>
                              </div>
                            </Modal.Title>
                            <Button
                              variant="outline-dark"
                              onClick={this.handleCloseModal}
                            >
                              <i className="fa fa-times"></i>
                            </Button>
                          </Modal.Header>
                          <div className="modal-body vekp pt-0">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="forms">
                                  <div className="form-group mb-3">
                                    <label
                                      htmlFor="firstName"
                                      className="form-label fw-bold"
                                    >
                                      Enter updated
                                      information
                                    </label>
                                    <Row>
                                      <Col>
                                        <input
                                          className="form-control"
                                          aria-expanded="false"
                                          placeholder="First Name"
                                          value={
                                            this.state.firstName
                                          }
                                          name="firstName"
                                          type="text"
                                          onChange={
                                            this.handleChange
                                          }
                                        />
                                        {this.state.submitted &&
                                          !this.state
                                            .firstName && (
                                            <div className="help-block">
                                              First name is
                                              required
                                            </div>
                                          )}
                                      </Col>
                                      <Col>
                                        <input
                                          className="form-control"
                                          aria-expanded="false"
                                          placeholder="Last Name"
                                          value={
                                            this.state.lastName
                                          }
                                          name="lastName"
                                          type="text"
                                          onChange={
                                            this.handleChange
                                          }
                                        />
                                        {this.state.submitted &&
                                          !this.state
                                            .lastName && (
                                            <div className="help-block">
                                              Last name is
                                              required
                                            </div>
                                          )}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <input
                                          className="form-control"
                                          aria-expanded="false"
                                          placeholder="Email"
                                          value={
                                            this.state.email
                                          }
                                          name="email"
                                          type="email"
                                          onChange={
                                            this.handleChange
                                          }
                                        />
                                        {this.state.submitted &&
                                          !this.state.email && (
                                            <div className="help-block">
                                              Email is required
                                            </div>
                                          )}
                                      </Col>
                                      <Col>
                                        <input
                                          className="form-control"
                                          aria-expanded="false"
                                          placeholder="Mobile"
                                          value={
                                            this.state.mobile
                                          }
                                          name="mobile"
                                          onChange={
                                            this.handleChange
                                          }
                                        />
                                        {this.state.submitted &&
                                          !this.state
                                            .mobile && (
                                            <div className="help-block">
                                              Mobile number is
                                              required
                                            </div>
                                          )}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <select
                                          className="form-control select_map"
                                          aria-expanded="false"
                                          value={
                                            this.state.role_id
                                          }
                                          name="role_id"
                                          type="text"
                                          onChange={
                                            this.handleChange
                                          }
                                        >
                                          {this.state?.options?.map(
                                            (option) => (
                                              <option
                                                key={option?.id}
                                                value={
                                                  option?.id
                                                }
                                              >
                                                {
                                                  option.role_display_name
                                                }
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </Col>
                                      <Col>
                                        <select
                                          className="form-control select_map"
                                          aria-expanded="false"
                                          value={
                                            this.state.status
                                          }
                                          name="status"
                                          type="text"
                                          onChange={
                                            this.handleChange
                                          }
                                        >
                                          <option value={1}>
                                            Active
                                          </option>
                                          <option value={0}>
                                            Inactive
                                          </option>
                                        </select>
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="cenlr">
                                    <button
                                      className="page_save page_width"
                                      onClick={
                                        this.handleUpdate
                                      }
                                    >
                                      UPDATE
                                    </button>
                                    <button
                                      className="page_save page_width"
                                      onClick={() =>
                                        this.setState({
                                          firstName: "",
                                          lastName: "",
                                          email: "",
                                          mobile: "",
                                          submitted: false,
                                        })
                                      }
                                    >
                                      CANCEL
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </PermissionContext.Consumer>
      </div>
    );
  }
}
