import React, { Component, useContext } from "react";
import { NavLink } from "react-router-dom";
import foot_Logo from "../../img/logol.png";
import head_Logo from "../../img/zais-logo.png";
import "./common.css";
import "./sidebar.css";
import { PermissionContext } from "../../context/PermissionContext";
import { Accordion } from "react-bootstrap";
import { authenticationService } from "../../_services/authentication";

export default class AdminSidebar extends Component {
  render() {
    const menu = this.props?.menu;
    let currentUser = authenticationService.currentUserSubject.getValue();
    // console.log(currentUser)

    const company = this.props?.dataFromParent?.split("/");
    const { Defaults } = this.props;
    const { Item, Header, Body } = Accordion;
    return (
      <div>
        <PermissionContext.Consumer>
          {({ menus }) => (
            <div className="d-flex" id="wrapper">
              {/* <!-- Sidebar--> */}
              <div className="border-end bg-white" id="sidebar-wrapper">
                <div className="sidebar-heading color_xl">
                  <div className="logo_text">
                    <NavLink to="/home">
                      <img src={head_Logo} alt="" />
                    </NavLink>
                  </div>
                </div>
                <div className="list-group list-group-flush">
                  <div className="route-dom">
                    <ul className="home_icon_img">
                      {currentUser?.data?.menu?.length
                        ? currentUser.data.menu?.map((data, index) => {
                            return (
                              <li key={index}>
                                {data?.sub_menu?.length > 0 ? (
                                  <Accordion defaultActiveKey={Defaults}>
                                    <Item eventKey="0">
                                      <Header className="list-group-item-light audit_heading">
                                        <i className={data.icon}></i>
                                        <span className="home_boom">
                                          {data?.caption}
                                        </span>
                                      </Header>
                                      <Body className="text_body">
                                        {data?.sub_menu?.map((menu, index) => {
                                          return (
                                            <NavLink
                                              key={index}
                                              to={`/${menu.url}`}
                                              className={
                                                company[1] === `${menu.url}`
                                                  ? "active list-group-item list-group-item-action list-group-item-light p-3 nop"
                                                  : "list-group-item list-group-item-action list-group-item-light p-3 nop"
                                              }
                                            >
                                              <i className={menu.icon}></i>
                                              <span className="home_boom">
                                                {menu.caption}
                                              </span>
                                            </NavLink>
                                          );
                                        })}
                                      </Body>
                                    </Item>
                                  </Accordion>
                                ) : (
                                  <NavLink
                                    activeClassName="active"
                                    to={`/${data?.url}`}
                                    className="list-group-item list-group-item-action list-group-item-light p-3 nop"
                                  >
                                    <i className={data?.icon}></i>
                                    <span className="home_boom">
                                      {data?.caption}
                                    </span>
                                  </NavLink>
                                )}
                              </li>
                            );
                          })
                        : menus?.map((data, index) => {
                            return (
                              <li key={index}>
                                {data?.sub_menu?.length > 0 ? (
                                  <Accordion defaultActiveKey={Defaults}>
                                    <Item eventKey="0">
                                      <Header className="list-group-item-light audit_heading">
                                        <i className={data.icon}></i>
                                        <span className="home_boom">
                                          {data?.caption}
                                        </span>
                                      </Header>
                                      <Body className="text_body">
                                        {data?.sub_menu?.map((menu, index) => {
                                          return (
                                            <NavLink
                                              key={index}
                                              to={`/${menu.url}`}
                                              className={
                                                company[1] === `${menu.url}`
                                                  ? "active list-group-item list-group-item-action list-group-item-light p-3 nop"
                                                  : "list-group-item list-group-item-action list-group-item-light p-3 nop"
                                              }
                                            >
                                              <i className={menu.icon}></i>
                                              <span className="home_boom">
                                                {menu.caption}
                                              </span>
                                            </NavLink>
                                          );
                                        })}
                                      </Body>
                                    </Item>
                                  </Accordion>
                                ) : (
                                  <NavLink
                                    activeClassName="active"
                                    to={`/${data?.url}`}
                                    className="list-group-item list-group-item-action list-group-item-light p-3 nop"
                                  >
                                    <i className={data?.icon}></i>
                                    <span className="home_boom">
                                      {data?.caption}
                                    </span>
                                  </NavLink>
                                )}
                              </li>
                            );
                          })}
                      <li>
                        <NavLink
                          activeClassName="active"
                          to={"/emission_module"}
                          className="list-group-item list-group-item-action list-group-item-light p-3 nop"
                        >
                          <i className=""></i>
                          <span className="home_boom">Emission Module</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          activeClassName="active"
                          to={"/reporting_module"}
                          className="list-group-item list-group-item-action list-group-item-light p-3 nop"
                        >
                          <i className=""></i>
                          <span className="home_boom">Reporting Module</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          activeClassName="active"
                          to={"/notification"}
                          className="list-group-item list-group-item-action list-group-item-light p-3 nop"
                        >
                          <i className=""></i>
                          <span className="home_boom">Notification</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="slill_bord">
                  <img src={foot_Logo} alt="" />
                </div>
              </div>
            </div>
          )}
        </PermissionContext.Consumer>
      </div>
    );
  }
}
