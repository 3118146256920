import React, { Component } from "react";
import AdminSidebar from "../sidebar/admin_sidebar";
import AdminHeader from "../header/admin_header";
import Table from "react-bootstrap/Table";
import { NavLink } from "react-router-dom";
import "./control.css";

export default class Policies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: false,
      loading: false,
    };
  }

  componentDidMount() {
    
  }

  render() {
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="color_div_on framwork_2">
                        <div className="business_detail">
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="heading">
                              <h4>Policies</h4>
                            </div>
                            <span className="global_link mx-0">
                              <NavLink
                                className="non_underline_link bold"
                                to="/add_new_policy"
                              >
                                <button
                                  className="link_bal_next page_width white"
                                  variant="none"
                                  to="/add_new_policy"
                                >
                                  ADD NEW POLICIES
                                </button>
                              </NavLink>
                            </span>
                          </div>
                          <hr className="line"></hr>
                          <div className="business_detail mb-4">
                            <div className="table_f">
                              <Table striped bordered hover size="sm">
                                <thead>
                                  <tr className="heading_color">
                                    <th style={{ width: "5%" }}>Sr.</th>
                                    <th>Policy Lists</th>
                                    <th style={{ width: "5%" }}>View </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>1.</td>
                                    <td>Governance Policies</td>
                                    <td>
                                      <NavLink
                                        className="non_underline_link bold view_c"
                                        to={"/governance_policies"}
                                      >
                                        <i className="fas fa-eye"></i>
                                      </NavLink>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>2.</td>
                                    <td>Social Policies</td>
                                    <td>
                                      <NavLink
                                        className="non_underline_link bold view_c"
                                        to={"/social_policies"}
                                      >
                                        <i className="fas fa-eye"></i>
                                      </NavLink>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>3.</td>
                                    <td>Cyber & Technology Policies</td>
                                    <td>
                                      <NavLink
                                        className="non_underline_link bold view_c"
                                        to={"/cyber_&_technology_policies"}
                                      >
                                        <i className="fas fa-eye"></i>
                                      </NavLink>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>4.</td>
                                    <td>Health & Safety Policies</td>
                                    <td>
                                      <NavLink
                                        className="non_underline_link bold view_c"
                                        to={"/health_&_sefty_policies"}
                                      >
                                        <i className="fas fa-eye"></i>
                                      </NavLink>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>5.</td>
                                    <td>Environmental Policies</td>
                                    <td>
                                      <NavLink
                                        className="non_underline_link bold view_c"
                                        to={"/environmental_policies"}
                                      >
                                        <i className="fas fa-eye"></i>
                                      </NavLink>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>6.</td>
                                    <td>Other Policies</td>
                                    <td>
                                      <NavLink
                                        className="non_underline_link bold view_c"
                                        to={"/other_policies"}
                                      >
                                        <i className="fas fa-eye"></i>
                                      </NavLink>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
