import React from 'react';
import "../AccessDenied/accessDenied.css"

function AccessDenied() {
  return (
    <div className="access-denied-container">
      <h1 className="access-denied-title">Access Denied</h1>
      <p className="access-denied-message">
        You do not have permission to access this page.
      </p>
    </div>
  );
}

export default AccessDenied;