/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { Component } from "react";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import Table from "react-bootstrap/Table";
import { authenticationService } from "../../../_services/authentication";
import "./companies.css";
import { apiCall } from "../../../_services/apiCall";
import config from "../../../config/config.json";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "../../loader/Loader";
import NoDataFound from "../../../img/no_data_found.png"
export default class InvitedCompanies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loderStastus: false,
      activeModal: {},
      showModal: false,
      paymentTrue: true,
      email: "",
      companyName: "",
      submitted: false,
      items: [],
      items1: [],
      companyData: [],
      broker: [],
      loading: false,
      totalRows: 0,
      currentPage: 1,
      skip: 0,
      startDate: "",
      endDate: "",
      limit: 100,
      countryFilter: "",
      industryFilter: "",
      categoryFilter: "",
      orderByName: "id",
      orderByValue: "DESC",
      search: "",
      pageCount: 0,
      totalData: 0,
      totalData1: 0,
      countriesList: [],
      industryList: [],
      searchKey: "",
      plan_id: "",
      discount: "",
      brokerId: "",
      companyId: "",
      compData: [],
      userType: "",
      userRole: "",
      data1: [],
      frameworks: [],
      finYear: "",
      selectedIds: [],
      selectedFinIds: [],
      updateType: "",
      status: "",
      chargeValue: "",
      chargeType: "",
      invoiceCharge: "",
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.serverRequest = this.serverRequest.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.applySorting = this.applySorting.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
  }

  handleOpenModal(val) {
    this.setState({ activeModal: val });
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
    this.setState({ showModal: "" });

  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    const { email, plan_id, discount, brokerId, companyName } = this.state;
    let body = {
      email: email,
      plan_id: plan_id,
      discount: discount,
      company_name: companyName,
    };
    if (email) {
      const { isSuccess, data } = await apiCall(
        config.API_URL + "api/v1/sendInviteForCompany",
        {},
        body,
        "POST"
      );
      console.log(isSuccess, data);
      if (isSuccess) {
        setTimeout(() => {
          this.handleCloseModal();
          this.setState({ email: "", plan_id: "" });
          this.setState({ submitted: false });
        }, 1000);
        this.getInvitedCompany();
      }
    }
  }
  applyFilter(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState({
      [name]: value,
    });
  }

  applyGlobalSearch(e) {
    let value = e.target.value;
    this.setState({
      searchKey: value,
    });
    setTimeout(() => {
      this.serverRequest();
    }, 200);
  }

  applySorting(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState({
      orderByName: name,
      orderByValue: value,
    });
  }

  clearFilter(e) {
    let name = e.target.name;
    this.setState({
      [name]: "",
      orderByName: "id",
      orderByValue: "DESC",
    });
    setTimeout(() => {
      this.serverRequest();
    }, 500);
  }
  async updateInvitationStatus(emailId, status) {
    let body = {
      email: emailId,
      status: status,
    };
    const { isSuccess, data } = await apiCall(
      config.ADMIN_API_URL + "updateInvitationStatus",
      {},
      body,
      "POST"
    );
    if (isSuccess) {
      this.serverRequest();
    }
  }
  async serverRequest() {
    this.setState({ loderStastus: true });
    const { isSuccess, data } = await apiCall(
      config.ADMIN_API_URL + "getInvitationDetails",
      {},
      { invitedPortalType: "super_admin", status: "INVITED" },
    );

    if (isSuccess) {
      this.setState({ loderStastus: false });
      this.setState({
        companyData: data?.data,
      });
    }
  }

  statusSubmit = async (status, email) => {
    let obj = {};
    obj.email = email;
    obj.status = status;
    const { isSuccess } = await apiCall(
      `${config.ADMIN_API_URL}updateInvitationStatus`,
      {},
      obj,
      "POST"
    );
    if (isSuccess) {
      this.serverRequest();
    }
  };

  async componentDidMount() {
    const chargeType = localStorage.getItem("chargeType");
    if (chargeType === "FIXED") {
      this.setState({ chargeValue: localStorage.getItem("chargeValue") });
      this.setState({ invoiceCharge: localStorage.getItem("chargeValue") });
    }
    if (chargeType === "PERCENT") {
      this.setState({ chargeValue: localStorage.getItem("chargeValue") });
    }
    this.setState({ chargeType: localStorage.getItem("chargeType") });
    const role = localStorage.getItem("role");
    if (role == "group_admin") {
      this.setState({ userType: "assisted_company" });
    } else {
      this.setState({ userType: "company" });
    }
    if (role === "super_admin") {
      this.setState({ userRole: "super_admin" });
    }
    this.setState({ items: [] });
    this.serverRequest();
    if (role === "super_admin") {
      const { isSuccess, data } = await apiCall(
        config.ADMIN_API_URL + "getBrokers",
        {},
        {},
        "GET"
      );
      if (isSuccess) {
        this.setState({
          pageCount1: Math.ceil(data.length / this.state.perPage),
          totalData1: data?.count,
          broker: data?.data,
        });
      }
    }
  }

  render() {
    const {
      companyData,
      activeModal,
      loderStastus
    } = this.state;
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="hol_rell">
              <div className="steps-form">
                <div className="steps-row setup-panel">
                  <div className="tabs-top setting_admin my-0">
                    <ul>
                      <li>
                        <NavLink
                          to={"/companies/registered_companies"}
                          title="Click To View"
                        >
                          Registered Companies
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="activee"
                          to={"/companies/invited_companies"}
                        >
                          Invited Compnies
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                  <input
                    type="search"
                    className="form-control w-25 mx-2"
                    placeholder="Search Company, Category, Industry &amp; Business Number"
                    name="search"
                    onChange={(e) => this.applyGlobalSearch(e)}
                  />
                </div>
              </div>
              <div className="color_div_on framwork_2 pt-0">
                <div className="business_detail">
                  <div className="table-responsive">
                    <div className="table___height">
                      {!loderStastus ?
                        <Table striped bordered style={{ textWrap: "nowrap" }} className="m-0">
                          <thead>
                            <tr className="heading_color">
                              <th style={{ width: 55 }}>Sr. No</th>
                              <th>Company Name</th>
                              <th>Email</th>
                              <th>Discount (%)</th>
                              <th>Invited Date | Time</th>
                              <th style={{ width: 70 }}>Action</th>
                            </tr>
                          </thead>
                          {companyData && companyData?.length > 0 ?
                            <tbody>
                              {companyData?.map((item, key) => (
                                <tr key={key}>
                                  <td style={{ textAlign: "center" }}>{key + 1}</td>
                                  <td>{item?.company_name}</td>
                                  <td>{item?.email}</td>
                                  <td>{item?.discount}</td>
                                  <td>{new Date(item?.invitedAt).toLocaleString()}</td>
                                  <td style={{ textAlign: "center" }}>
                                    <div className="hstack gap-3 invited_company_icos">
                                      <i class="fas fa-eye" onClick={() => this.handleOpenModal(item?.plan_data)} title="View Plan"></i>
                                      <i class="fas fa-retweet" title="Resend" onClick={() => this.statusSubmit("RESEND", item?.email)}></i>
                                      <i class="fas fa-window-close" title="Cancel" style={{ color: "red" }} onClick={() => this.statusSubmit("CANCEL", item?.email)}></i>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody> :
                            <tbody>
                              <tr>
                                <td colSpan={6}>
                                  <div className="hstack justify-content-center">
                                    <img src={NoDataFound} />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          }
                        </Table>
                        : <Loader />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* View Plan Popup --------------------------- */}
        {activeModal?.plan_data && <Modal animation={true} className="modal_box" shadow-lg="border" show={this.state.showModal && activeModal} >
          <div className="modal-lg">
            <Modal.Header className="d-flex align-items-center justify-content-between">
              <Modal.Title className="">
                <h4 className="m-0"> Plan Detail </h4>
              </Modal.Title>
              <Button
                variant="outline-dark"
                onClick={this.handleCloseModal}
              >
                <i className="fa fa-times"></i>
              </Button>
            </Modal.Header>
            <div className="modal-body">
              <div className="pricing-table turquoise h-100">
                <h2>{activeModal?.title}</h2>
                <div className="price-tag">
                  <span className="symbol">{activeModal?.currency}</span>
                  <span className="amount">{activeModal?.price}</span>
                  <span className="after">/{activeModal?.renewal_type}</span>
                </div>
                {console.log("companyData>>", companyData)}
                <div className="mt-0 mb-1 border-bottom"></div>
                <div className="d-flex align-items-center justify-content-between">
                  <span>Financial Year</span>
                  <span>{activeModal?.year}</span>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <span>Global Framework for Supplier</span>
                  <span>
                    {(activeModal?.plan_data).ALLOW_GLOBAL_FRAMEWORK}
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <span>No. of Users</span>
                  <span>{(activeModal?.plan_data)?.NO_USERS}</span>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <span>No. of Framework (Can be create)</span>
                  <span>{(activeModal?.plan_data)?.NO_FRAMEWORK}</span>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <span>No. of Suppliers (Can be create)</span>
                  <span>{(activeModal?.plan_data)?.NO_SUPPLIER}</span>
                </div>
                <div className="pricing-features h-100">
                  <div className="mt-2 mb-1 border-bottom"></div>
                  <div className="feature">Framework Features :</div>
                  <div className="framework_feature">
                    <ul>
                      {(activeModal?.plan_data)?.MAPPED_FRAMEWORK &&
                        (activeModal?.plan_data)?.MAPPED_FRAMEWORK?.map(
                          (item) => (
                            <>
                              <li key={item.id}>{item.title}</li>
                            </>
                          )
                        )}
                    </ul>
                  </div>
                  <div className="feature">Menu Features :</div>
                  <div className="framework_feature">
                    <ul>
                      {(activeModal?.plan_data)?.MAPPED_MENUS &&
                        (activeModal?.plan_data)?.MAPPED_MENUS?.map((item) => (
                          <>
                            <li key={item.id}>{item.title}</li>
                          </>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>}
      </div>
    );
  }
}
