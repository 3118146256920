import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import AdminSidebar from "../../../sidebar/admin_sidebar";
import AdminHeader from "../../../header/admin_header";
import "../../../Company Sub Admin/Pages/management/management.css";
import Table from "react-bootstrap/Table";
import { Pagination, Icon } from "semantic-ui-react";
import "../../../sidebar/common.css";

export default class AuditSupplier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uuid: "",
      uri: "",
    };
  }
  componentDidMount() {
    let urlArr = window.location.pathname.split("/");
    this.setState({
      uuid: urlArr[urlArr.length - 2],
      uri: urlArr[urlArr.length - 1],
    });
  }
  render() {
    const { uuid } = this.state;
    return (
      <div>
        <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
        <div
            className={
              localStorage.getItem("role") === "company_admin"
                ? "tabs-tops my_profile_menus audit_tabs"
                : "tabs-tops my_profile_menus setting_tab_d audit_tabs"
            }
          >
            <ul>
              <li>
                <NavLink to={`/companies/${uuid}/company_audit`}>Company</NavLink>
              </li>
              <li>
                <NavLink to={`/companies/${uuid}/supplier_listing`} className="activee">
                  {" "}
                  Supplier
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="col-sm-12">
                        <div className="color_div_on framwork_2">
                          <div className="business_detail">
                            <div className="heading">
                              <h4 className="Environmental_text font-heading">
                                Suppliers List
                              </h4>
                              <div className="form-group has-search one">
                                <span className="fa fa-search form-control-feedback"></span>
                                <input type="text" className="form-control" placeholder="Search Supplier Name" name="search"  onChange={(e) => this.applyGlobalSearch(e) } />
                              </div>
                            </div>
                          </div>
                          <hr></hr>
                          <div className="col-md-12">
                          <div className="table_f table-responsive mt-3">
                              <Table striped bordered hover size="sm">
                                <thead>
                                  <tr className="heading_color">
                                    <th>ID</th>
                                    <th>Supplier</th>
                                    <th>Date Added</th>
                                    <th>Industry</th>
                                    <th>Criteria Assessed</th>
                                    <th>Audit Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>0</td>
                                    <td>fdchfg</td>
                                    <td>28-09-2022</td>
                                    <td>Security & Commodity Exchanges</td>
                                    <td>nti-bribery & Corruption Policy</td>
                                    <td>
                                      <a className="table-tag" href="/companies/:uuid/supplier_audit"><span><i className="fa fa-eye"></i></span> View </a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>1</td>
                                    <td>fdchfg</td>
                                    <td>28-09-2022</td>
                                    <td>Security & Commodity Exchanges</td>
                                    <td>nti-bribery & Corruption Policy</td>
                                    <td>
                                      <a className="table-tag" href="/companies/:uuid/supplier_audit"><span><i className="fa fa-eye"></i></span> View </a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>2</td>
                                    <td>fdchfg</td>
                                    <td>28-09-2022</td>
                                    <td>Security & Commodity Exchanges</td>
                                    <td>nti-bribery & Corruption Policy</td>
                                    <td>
                                      <a className="table-tag" href="/companies/:uuid/supplier_audit"><span><i className="fa fa-eye"></i></span> View </a>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                              <Pagination
                                className="mx-auto pagination"
                                defaultActivePage={1}
                                onPageChange={this.pageChange}
                                ellipsisItem={{
                                  content: <Icon name="ellipsis horizontal" />,
                                  icon: true,
                                }}
                                firstItem={{
                                  content: <Icon name="angle double left" />,
                                  icon: true,
                                }}
                                lastItem={{
                                  content: <Icon name="angle double right" />,
                                  icon: true,
                                }}
                                prevItem={{
                                  content: <Icon name="angle left" />,
                                  icon: true,
                                }}
                                nextItem={{
                                  content: <Icon name="angle right" />,
                                  icon: true,
                                }}
                                totalPages={Math.ceil(
                                  this.state.totalData / this.state.limit
                                )}
                              />
                            </div>
                          {/* <Form>
                            <div className="heading_h3 mt-3">
                              <span className="gement">
                                Customer Privacy &amp; Technology Standards
                              </span>
                            </div>
                            <Form.Group
                              className="mb-3 form_audit_d"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="energy mb-3 font-increase">
                                Q1. What is the company's strategy to secure
                                customer's personal health information records
                                and other personally identifiable information?
                              </Form.Label>
                              <div className="attachment_with_icons">
                                <span>
                                  <Image className="img-fluid pdf" src={Clip} />
                                </span>
                                <span className="icon_with_function">
                                  <NavLink to="#">
                                    <Image className="img-fluid" src={Eye} />
                                  </NavLink>
                                </span>
                              </div>
                            </Form.Group>
                            <Form.Group
                              className="mb-3 form_audit_d"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="energy mb-3 font-increase">
                                Q2. What is the company's strategy to secure
                                customer's personal health information records
                                and other personally identifiable information?
                              </Form.Label>
                              <div className="attachment_with_icons">
                                <span>
                                  <Image className="img-fluid pdf" src={Clip} />
                                </span>
                                <span className="icon_with_function">
                                  <NavLink to="#">
                                    <Image className="img-fluid" src={Eye} />
                                  </NavLink>
                                </span>
                              </div>
                            </Form.Group>
                            <Form.Group
                              className="mb-3 form_audit_d"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="energy mb-3 font-increase">
                                Q3. What is the company's strategy to secure
                                customer's personal health information records
                                and other personally identifiable information?
                              </Form.Label>
                              <div className="attachment_with_icons">
                                <span>
                                  <Image className="img-fluid pdf" src={Clip} />
                                </span>
                                <span className="icon_with_function">
                                  <NavLink to="#">
                                    <Image className="img-fluid" src={Eye} />
                                  </NavLink>
                                </span>
                              </div>
                            </Form.Group>
                            <Form.Group
                              className="mb-3 form_audit_d"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="energy mb-3 font-increase">
                                Q4. What is the company's strategy to secure
                                customer's personal health information records
                                and other personally identifiable information?
                              </Form.Label>
                              <div className="attachment_with_icons">
                                <span>
                                  <Image className="img-fluid pdf" src={Clip} />
                                </span>
                                <span className="icon_with_function">
                                  <NavLink to="#">
                                    <Image className="img-fluid" src={Eye} />
                                  </NavLink>
                                </span>
                              </div>
                            </Form.Group>
                            <Form.Group
                              className="mb-3 form_audit_d"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="energy mb-3 font-increase">
                                Q5. What is the company's strategy to secure
                                customer's personal health information records
                                and other personally identifiable information?
                              </Form.Label>
                              <div className="attachment_with_icons">
                                <span>
                                  <Image className="img-fluid pdf" src={Clip} />
                                </span>
                                <span className="icon_with_function">
                                  <NavLink to="#">
                                    <Image className="img-fluid" src={Eye} />
                                  </NavLink>
                                </span>
                              </div>
                            </Form.Group>
                            <Form.Group
                              className="mb-3 form_audit_d"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="energy mb-3 font-increase">
                                Q6. What is the company's strategy to secure
                                customer's personal health information records
                                and other personally identifiable information?
                              </Form.Label>
                              <div className="attachment_with_icons">
                                <span>
                                  <Image className="img-fluid pdf" src={Clip} />
                                </span>
                                <span className="icon_with_function">
                                  <NavLink to="#">
                                    <Image className="img-fluid" src={Eye} />
                                  </NavLink>
                                </span>
                              </div>
                            </Form.Group>
                          </Form> */}
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}
