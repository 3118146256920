import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { sweetAlert } from "../../utils/UniversalFunction";
import logo from "../../img/Zais_logo.png";
import "./login.css";
import "../sidebar/common.css";
import config from "../../config/config.json";
import env from "../../env";
import { apiCall } from "../../_services/apiCall";

export default class resetpass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        email: ""
      },
      submitted: false,
      captchaIsVerified: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleVerification = this.handleVerification.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value
      }
    });
  }
  handleVerification(e) {
    this.setState({
      captchaIsVerified: true
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    const { user, captchaIsVerified } = this.state;
    // document.getElementById('user_captcha_input').value = "";
    // if (captchaIsVerified)
    {
      if (user.email) {
        const {isSuccess,data} = await apiCall(config.API_URL + "resetPassword",{},{
          email: user.email,
          token:true
          // token: captchaIsVerified
        },"POST");
        setTimeout(() => {
          this.props.history.push("/reset_massage");
        }, 1000);
        //to do delete
        // axios
        //   .post(config.API_URL + "resetPassword", {
        //     email: user.email,
        //     token:true
        //     // token: captchaIsVerified
        //   })
        //   .then((response) => {
        //     sweetAlert("success", response.data.message);
        //     setTimeout(() => {
        //       this.props.history.push("/reset_massage");
        //     }, 1000);
        //   })
        //   .catch(function (error) {
        //     if (error.response) {
        //       sweetAlert("error", error.response.data.message);
        //     }
        //   });
      } else {
        sweetAlert("error", "Please fill Email Address");
      }
    } 
  }

  render() {
    const { user, submitted } = this.state;
    return (
      <div>
        <section className="login">
          <div className="login_part">
            <div className="sing_log">
              <div className="sing_one">
                <img src={logo} alt="logo" />
              </div>
              <div className="text_sing mb-4">
                <h4 className="Account">Reset Password</h4>
                <p className="faster_oval">
                  Please fill out your Registered Email Address
                </p>
              </div>
              <form name="form" onSubmit={this.handleSubmit}>
                <div className="ster_form">
                  <div className="make_form">
                    <div className="row">
                      <div className="col-lg-12 col-xs-12">
                        <div className="form_sign">
                          <div
                            className={
                              "form-group fg" +
                              (submitted && !user.register_company_name
                                ? " has-error"
                                : "")
                            }
                          >
                            <label className="st_name" htmlFor="name">
                              Email
                            </label>
                            <input
                              className="form-control name_nf"
                              type="text"
                              name="email"
                              placeholder="Enter Register Email Address"
                              value={user.email}
                              onChange={this.handleChange}
                            />
                            {submitted && !user.email && (
                              <div className="help-block">
                                Email is required
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*<div className="make_form">
                    <div className="row">
                      <div className="col-md-6">
                       <div className="bacei">
                          <ReCAPTCHA
                            sitekey={env.GOOGLE_RECAPTCHA_SITE_KEY}
                            onChange={(e) => this.handleVerification(e)}
                          />
                        </div>
                      </div>
                    </div>
                            </div>*/}
                  <div className="view_bottoma">
                    {user.email ? (
                      <button type="submit" value="Submit" className="btn">
                        Reset
                      </button>
                    ) : (
                      <button
                        type="submit"
                        value="Submit"
                        className="btn"
                        disabled
                      >
                        Reset
                      </button>
                    )}
                  </div>
                  <div className="global d-flex justify-content-center my-3">
                    <NavLink className="view_bottoma" to="/Login">
                      Login
                    </NavLink>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
