import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import AdminSidebar from "../../../sidebar/admin_sidebar";
import AdminHeader from "../../../header/admin_header";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { apiCall } from "../../../../_services/apiCall";
import config from "../../../../config/config.json";
import { authenticationService } from "../../../../_services/authentication";
import countriesData from "./countries.json";
import yearsData from "./year.json";
import slashYearsData from "./slash-year.json";
import Loader from "../../../loader/Loader";

export const FinancialYear = () => {
  const location = useLocation();
  const [menulist, setMenuList] = useState([]);
  const [financialYearData, setFinancialYearData] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedCountry, setSelectedCountry] = useState("India");
  const [selectedYear, setSelectedYear] = useState();
  const [isoCode, setIsoCode] = useState(getFinancialYear("India"));
  const [financialYear, setFinancialYear] = useState(getIsoCode("India"));

  function getFinancialYear(countryName) {
    const selectedCountry = countriesData.find(
      (country) => country.name.toLowerCase() === countryName.toLowerCase()
    );
    if (selectedCountry) {
      return selectedCountry.financialYear;
    } else {
      return "Country not found";
    }
  }

  function getIsoCode(countryName) {
    const selectedCountry = countriesData.find(
      (country) => country.name.toLowerCase() === countryName.toLowerCase()
    );
    if (selectedCountry) {
      return selectedCountry.isoCode;
    } else {
      return "ISO Code not found";
    }
  }

  const handleCountryChange = (e) => {
    const countryName = e.target.value;
    setSelectedCountry(countryName);
    setFinancialYear(getFinancialYear(countryName));
    setIsoCode(getIsoCode(countryName));
  };

  const handleYearChange = (e) => {
    const selectedYear = e.target.value;
    setSelectedYear(selectedYear);
  };

  const getFinancialYearData = async () => {
    const body = {

      iso_code: "ALL",

    };
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}getAllFinancialYear`,
      {},
      body
    );
    if (isSuccess) {
      setFinancialYearData(data?.data);
    }
  };

  const formatDate = (endDate) => {
    const formattedDate = new Date(endDate).toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    return formattedDate;
  };
  const submitHandler = async () => {
    const dates = financialYear.split(" - ");
    const endDate = dates[1];
    const endMonth = endDate.split(" ")[1];
    const endDateNum = endDate.split(" ")[0].replace(/\D/g, "");
    const monthsMap = {
      Jan: 1,
      Feb: 2,
      Mar: 3,
      Apr: 4,
      May: 5,
      June: 6,
      July: 7,
      Aug: 8,
      Sept: 9,
      Oct: 10,
      Nov: 11,
      Dec: 12,
    };
    const endMonthNumber = monthsMap[endMonth];

    const tmpYear = selectedYear && selectedYear?.includes(" - ");
    let finalYear;
    if (tmpYear) {
      const years = selectedYear.split(" - ");
      finalYear = years[1];
    } else {
      finalYear = selectedYear;
    }

    const body = {
      financial_year_value: selectedYear,
      country_name: selectedCountry,
      iso_code: isoCode,
      end_date: finalYear + "-" + endMonthNumber + "-" + endDateNum,
    };
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}createFinancialYear`,
      {},
      body,
      "POST"
    );
    if (isSuccess) {
      setFinancialYearData(data?.data);
      handleClose();
    }
  };

  useEffect(() => {
    const currentUser = authenticationService?.currentUserSubject?.getValue();
    const settingsMenu = currentUser?.data?.menu?.find(
      (item) => item?.url === "settings"
    );
    setMenuList(settingsMenu?.permissions);
    getFinancialYearData();
  }, []);

  return (
    <div>
      <AdminSidebar dataFromParent={location.pathname} />
      <AdminHeader />
      <div className="main_wrapper">
        <div className="inner_wraapper">
          <div className="steps-form">
            <div className="steps-row setup-panel">
              <div className="tabs-top setting_admin my-0">
                <ul>
                  <li>
                    <NavLink to="/settings"> My Profile </NavLink>
                  </li>
                  {menulist.includes(
                    "GET ALL SUBSCRIPTION",
                    "CREATE SUBSCRIPTION"
                  ) && (
                      <li>
                        <NavLink to="/settings/billing"> Billing </NavLink>
                      </li>
                    )}
                  <li>
                    <NavLink to="/settings/access_management"> Access </NavLink>
                  </li>
                  {menulist.includes("CREATE_FINANCIAL_YEAR") && (
                    <li>
                      <NavLink
                        to="/settings/financial_year"
                        className="activee"
                      >
                        Financial Year
                      </NavLink>
                    </li>
                  )}
                  {menulist.includes("CREATE SUB USER") && (
                    <li>
                      <NavLink to="/settings/sub-users">Sub Users</NavLink>
                    </li>
                  )}
                  <li>
                    <NavLink to="/settings/category">
                      Category
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/settings/carbon_emission">
                      Carbon Emission
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/settings/unit" >
                      Unit
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="hstack">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Country Name, Financial Year"
                  name="search"
                  onChange={(e) =>
                    this.applyGlobalSearch(e)
                  }
                />
                <Button
                  variant="info"
                  onClick={handleShow}
                  className="mx-2"
                >
                  Create Financial Year
                </Button>
              </div>
            </div>
          </div>
          <div className="color_div_on framwork_2 pt-0">
            <div className="table-responsive">
              <div className="table___height">
                {/* {!loderStastus ? */}
                <Table striped bordered className="m-0" style={{ whiteSpace: "nowrap" }}>
                  <thead>
                    <tr className="heading_color sticky_header">
                      <th style={{ width: 55 }}>Sr. No</th>
                      <th>Country Name  [ISO Code]</th>
                      <th>Financial Year</th>
                      <th>Financial Year End Date</th>
                    </tr>
                  </thead>
                  {financialYearData.length > 0 ?
                    <tbody>
                      {financialYearData &&
                        financialYearData?.map((item, key) => (
                          <tr key={key}>
                            <td>{key + 1}</td>
                            <td>{item.country_name}  [{item.iso_code}]</td>
                            <td>{item.financial_year_value}</td>
                            <td>{formatDate(item.end_date)}</td>
                          </tr>
                        ))}
                    </tbody>
                    :
                    <tbody>
                      <tr>
                        <td colSpan={8} className="text-center">No Data Found</td>
                      </tr>
                    </tbody>
                  }
                </Table>
                {/* :
                  <Loader />
                } */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Financial Year</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Select Country</Form.Label>
            <Form.Control
              as="select"
              value={selectedCountry}
              onChange={handleCountryChange}
            >
              {countriesData &&
                countriesData.map((country, index) => (
                  <option key={index}>{country.name}</option>
                ))}
            </Form.Control>
            <Form.Label className="pt-3">Select Financial Year</Form.Label>
            <Form.Control
              type="text"
              disabled
              value={financialYear}
              className="form-control"
            />
            <Form.Label className="pt-3">Select Year</Form.Label>
            <Form.Select
              as="select"
              value={selectedYear}
              onChange={handleYearChange}
            >
              {financialYear && financialYear?.includes("Jan") ? (
                <>
                  {yearsData &&
                    yearsData.map((years, index) => (
                      <option key={index}>{years.year}</option>
                    ))}
                </>
              ) : (
                <>
                  {slashYearsData &&
                    slashYearsData.map((slashYears, index) => (
                      <option key={index}>{slashYears.year}</option>
                    ))}
                </>
              )}
            </Form.Select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="link_bal_next page_width white"
            onClick={submitHandler}
          >
            Create
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
