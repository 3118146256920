import React, { useEffect, useState } from "react";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import config from "../../../config/config.json";
import { Country } from "country-state-city";
import { apiCall } from "../../../_services/apiCall";
import { getYear, format, parse } from 'date-fns';
import { tz } from 'date-fns-tz';
const findCountry = require('countries-and-timezones');

export default function CreateFinancialYear(props) {
  const [companyList, setcompanyList] = useState();
  const [companyId, setCompanyId] = useState();
  const [meterId, setMeterId] = useState();
  const [unit, setUnit] = useState(1);
  const [process, setProcess] = useState();
  const [error, setError] = useState("");
  const [token] = useState(JSON.parse(localStorage.getItem("currentUser")));
const handleChange = (e) => {
  console.log(e.target.value,"countrycode");
   const countryData = findCountry({ name: e.target.value });
     
            const countryCode = countryData.id;
            const countryTimezone = Intl.DateTimeFormat(undefined, { timeZone: countryData.timezones[0] }).resolvedOptions().timeZone;
            console.log(countryTimezone,"countryTimezone",countryCode);
        

      const currentDate = new Date();
  //     const currentDateInCountryTimezone = tz(currentDate, countryTimezone);

  //   const startOfYear = parse(`${getYear(currentDateInCountryTimezone)}-04-01`, 'yyyy-MM-dd', currentDateInCountryTimezone);
  //   const endOfYear = parse(`${getYear(currentDateInCountryTimezone) + 1}-03-31`, 'yyyy-MM-dd', currentDateInCountryTimezone);

  //   const financialYearString = `${format(startOfYear, 'yyyy', { timeZone: countryTimezone })}-${format(endOfYear, 'yyyy', { timeZone: countryTimezone })}`;
  //  console.log(financialYearString,"financialYearString");  
  
  
}
  const callApi = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}getCompanyList`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setcompanyList(data?.data);
    }
  };

  useEffect(() => {
    callApi();
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    // if (!meterId || !unit || !process) {
    //   setError("All fields are required.");
    //   return;
    // }
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}getAllFinancialYear`,
      {},
    
    );
    if (isSuccess) {
    
    }
    // const body = {
    //   financial_year_value: "2027-2028",
    //   country_name: "India",
    //   end_date:"2028-03-31",
    // };
    // const { isSuccess, data } = await apiCall(
    //   `${config.ADMIN_API_URL}createFinancialYear`,
    //   {},
    //   body,
    //   "POST"
    // );
    // if (isSuccess) {
    
    // }
  };
  return (
    <div>
      <AdminSidebar dataFromParent={props.location.pathname} />
      <AdminHeader />
      <div className="main_wrapper">
        <div className="inner_wraapper">
          <div className="container-fluid">
            <section className="d_text">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="col-sm-12">
                      <div className="color_div_on framwork_2">
                        <div className="business_detail">
                          <div className="">
                            <div className="saved_cards">
                              <form name="form">
                                <div className="business_detail">
                                  <div className="heading">
                                    <h4>Add Financial Year</h4>
                                  </div>
                                  <hr className="line"></hr>
                                  <div className="row">
                                    <div className="col-lg-4 col-xs-12">
                                      <div className="form-group pb-3">
                                        <label
                                          htmlFor="industryType"
                                          className="mb-2"
                                        >
                                          Select Country*
                                        </label>

                                        <select
                                          name="company_country"
                                          id="country"
                                          placeholder="Select Country Name"
                                          val
                                          className="form-control select_map"
                                          // value={user.country}
                                            onChange={handleChange}
                                          required
                                          title="Please Select Country Name"
                                        >
                                          <option className="bold" value="1">
                                            Select Country
                                          </option>
                                          {Country.getAllCountries().map(
                                            (country, i) => (
                                              <option>{country.name}</option>
                                            )
                                          )}
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-lg-4 col-xs-12">
                                      <div className="form-group">
                                        <label
                                          htmlFor="industryType"
                                          className="mb-2"
                                        >
                                          Financial Year
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={meterId}
                                          onChange={(e) =>
                                            setMeterId(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {error && <>{error}</>}
                                </div>
                                <div className="global_link mx-0 my-3">
                                  <button
                                    className="page_width page_save"
                                    onClick={submitHandler}
                                  >
                                    ADD NOW
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
