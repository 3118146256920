/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { Component } from "react";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import Table from "react-bootstrap/Table";
import { authenticationService } from "../../../_services/authentication";
import "./companies.css";
import { apiCall } from "../../../_services/apiCall";
import config from "../../../config/config.json";
import { Button, Col, Modal, Row, Tab, Tabs } from "react-bootstrap";
import ToggleSwitch from "./toggleSwitch";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "../../loader/Loader";

const currentUser = authenticationService.currentUserValue;
const numInstances = 1;
export default class RegisterCompanies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidePersaonalCompany: "PERSONAL",
      login: false,
      country: false,
      industry: false,
      update: false,
      category: false,
      activeModal: "",
      showModal: false,
      activeAllowedQuestionModal: "",
      showAllowedQuestion: false,
      paymentTrue: true,
      email: "",
      companyName: "",
      submitted: false,
      items: [],
      items1: [],
      companyData: [],
      broker: [],
      loading: false,
      totalRows: 0,
      currentPage: 1,
      skip: 0,
      startDate: "",
      endDate: "",
      limit: 100,
      countryFilter: "",
      industryFilter: "",
      categoryFilter: "",
      orderByName: "id",
      orderByValue: "DESC",
      search: "",
      pageCount: 0,
      totalData: 0,
      totalData1: 0,
      countriesList: [],
      industryList: [],
      searchKey: "",
      plan_id: "",
      discount: "",
      brokerId: "",
      companyId: "",
      compData: [],
      userType: "",
      userRole: "",
      data1: [],
      frameworks: [],
      finYear: "",
      selectedIds: [],
      selectedFinIds: [],
      updateType: "",
      status: "",
      chargeValue: "",
      chargeType: "",
      invoiceCharge: "",
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.pageChange = this.pageChange.bind(this);
    this.serverRequest = this.serverRequest.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.applySorting = this.applySorting.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.handleSwitch = this.handleSwitch.bind(this);
    this.serverRequest = this.serverRequest.bind(this);
    this.onPaymentHandler = this.paymentHandler.bind(this);
  }

  handleOpenModal(val) {
    this.setState({ activeModal: val });
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
    this.setState({ showModal: "" });
    this.setState({ email: "" });
    this.setState({ plan_id: "" });
    this.setState({ discount: "" });
    this.setState({ companyName: "" });
  }


  handleCloseDetail = () => {
    this.setState({ show: false });
    this.setState({ showAllowedQuestion: false });
  };



  handleShowDetail = (val) => {
    this.setState({ activeModal: val });
    this.setState({ show: true });
  };

  handleShowQuestionDetail = (val) => {
    this.setState({ activeAllowedQuestionModal: val });
    this.setState({ showAllowedQuestion: true });
  };

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    const { email, plan_id, discount, brokerId, companyName } = this.state;
    let body = {
      email: email,
      plan_id: plan_id,
      discount: discount,
      company_name: companyName,
    };
    if (email) {
      const { isSuccess, data } = await apiCall(
        config.API_URL + "api/v1/sendInviteForCompany",
        {},
        body,
        "POST"
      );
      console.log(isSuccess, data);
      if (isSuccess) {
        setTimeout(() => {
          this.handleCloseModal();
          this.setState({ email: "", plan_id: "" });
          this.setState({ submitted: false });
        }, 1000);
        this.getInvitedCompany();
      }
    }
  }

  async handleAllowedQuestionSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    const { question_type, company_id} = this.state;
    let body = {
      value: question_type,
      company_id: company_id,
    };
    if (question_type) {
      const { isSuccess, data } = await apiCall(
        config.API_URL + "api/v1/sendInviteForCompany",
        {},
        body,
        "POST"
      );
      console.log(isSuccess, data);
      if (isSuccess) {
       
      }
    }
  }

  async handleSwitch(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    const { companyId, compData } = this.state;
    if (companyId) {
      const { isSuccess, data } = await apiCall(
        config.ADMIN_API_URL + "loginGroupAdminAsCompany",
        {},
        {
          assisted_company_id: companyId,
        },
        "GET"
      );
      if (isSuccess) {
        this.setState({ compData: data.data });
        window.open(
          config.baseCompanyUrl +
          `#/sign_in/${data.data?.company_id}/${data.data?.token}`,
          "_blank"
        );
        setTimeout(() => {
          this.handleCloseModal();
          this.setState({ submitted: false });
        }, 2000);
      }
    }
  }
  applyFilter(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState({
      [name]: value,
    });
  }

  applyGlobalSearch(e) {
    let value = e.target.value;
    this.setState({
      searchKey: value,
    });
    setTimeout(() => {
      this.serverRequest();
    }, 200);
  }

  handleToggle(value) {
    this.setState({
      hidePersaonalCompany: value,
    });
  }

  applySorting(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState({
      orderByName: name,
      orderByValue: value,
    });
  }

  clearFilter(e) {
    let name = e.target.name;
    this.setState({
      [name]: "",
      orderByName: "id",
      orderByValue: "DESC",
    });
    setTimeout(() => {
      this.serverRequest();
    }, 500);
  }
  async updateInvitationStatus(emailId, status) {
    let body = {
      email: emailId,
      status: status,
    };
    const { isSuccess, data } = await apiCall(
      config.ADMIN_API_URL + "updateInvitationStatus",
      {},
      body,
      "POST"
    );
    if (isSuccess) {
      this.getInvitedCompany();
    }
  }

  async serverRequest() {
    const { isSuccess, data } = await apiCall(
      config.ADMIN_API_URL + "getInvitationDetails",
      {},
      { invitedPortalType: "super_admin", status: "DONE" },
    );

    if (isSuccess) {
      this.setState({
        companyData: data?.data,
      });
    }
  }

  getInvitedCompany = async (status, email) => {
    let obj = {};
    obj.email = email;
    obj.status = status;
    const { isSuccess } = await apiCall(
      `${config.ADMIN_API_URL}updateInvitationStatus`,
      {},
      obj,
      "POST"
    );
    if (isSuccess) {
      this.serverRequest();
    }
  };

  // async getInvitedCompany() {
  //   const { isSuccess, data } = await apiCall(
  //     config.ADMIN_API_URL + "getInvitationDetails",
  //     {},
  //     {invitedPortalType: "super_admin", status: "DONE"},
  //   );

  //   if (isSuccess) {
  //     this.setState({
  //       companyData: data?.data,
  //     });
  //   }
  // }


  // async serverRequest() {
  //   const {
  //     skip,
  //     limit,
  //     countryFilter,
  //     industryFilter,
  //     categoryFilter,
  //     orderByName,
  //     orderByValue,
  //     searchKey,
  //     showModal,
  //   } = this.state;

  //   if (showModal === true) {
  //     this.setState({
  //       showModal: false,
  //     });
  //   }

  //   const role = localStorage.getItem("role");
  //   let userType = "";
  //   if (role === "group_admin") {
  //     userType = "assisted_company";
  //   } else {
  //     userType = "company";
  //   }

  //   let body = {
  //     limit: limit,
  //     offset: skip,
  //     country: countryFilter,
  //     industry: industryFilter,
  //     category: categoryFilter,
  //     orderByName: orderByName,
  //     orderByValue: orderByValue,
  //     search: searchKey,
  //     userTypeCode: userType,
  //   };
  //   this.getInvitedCompany();
  //   const { isSuccess, data } = await apiCall(
  //     config.ADMIN_API_URL + "company",
  //     {},
  //     body
  //   );
  //   if (isSuccess) {
  //     this.setState({
  //       pageCount: Math.ceil(data.length / this.state.perPage),
  //       totalData: data?.count,
  //       items1: data?.data,
  //     });
  //     console.log(data.data);
  //   }
  // }

  pageChange(e, data) {
    console.log("Handle Click", data);
    let page = data?.activePage;
    let pageNo = page === 1 ? 0 : (page - 1) * this.state.limit;
    console.log("clicked", pageNo, page);
    this.setState({
      skip: pageNo,
    });
    this.serverRequest();
  }

  exportCSV(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        startDate: this.state.setStartDate,
        endDate: this.state.setEndDate,
      }),
    };

    fetch(config.ADMIN_API_URL + `company/export`, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          let url = config.BASE_URL + result?.data;
          window.open(url, "_blank");
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }
  async getAllSubscription() {
    const getLocalData = JSON.parse(localStorage.getItem("currentUser"));
    const getRole = getLocalData !== null ? getLocalData.data.role : "";
    let params = { subscription_type: "Invite" };
    const { isSuccess, data } = await apiCall(
      config.ADMIN_API_URL + "getAllSubscription",
      {},
      params,
      "GET"
    );
    if (isSuccess) {
      this.setState({
        items: data.data,
      });
    } else {
      this.setState({
        isLoaded: true,
      });
    }
  }
  getFrameworks = async () => {
    let obj = {};
    obj.assisted_company_id = this.state.data1.id;
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}getFrameworkForAssistedCompany`,
      {},
      obj,
      "GET"
    );
    if (isSuccess) {
      this.setState({ frameworks: data.data });
    }
  };
  callAPI = async (item) => {
    let obj = {};
    if (this.state.data1.user_type_code != "company") {
      obj.assisted_company_id = this.state.data1.id;
      const { isSuccess: isSuccess1, data: framework } = await apiCall(
        `${config.ADMIN_API_URL}getFrameworkForAssistedCompany`,
        {},
        obj,
        "GET"
      );
      if (isSuccess1) {
        this.setState({ frameworks: framework.data });
        const filteredIds = framework.data
          .filter((item) => item.status === 1)
          .map((item) => item.id);
        this.setState({ selectedIds: filteredIds });
      }
    }
    let obj1 = {};
    obj1.assisted_company_id = this.state.data1.id;
    obj1.financial_year_code = "APR-MAR";
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}getFinancialYearForAssistedCompany`,
      {},
      obj1,
      "GET"
    );
    if (isSuccess) {
      this.setState({ finYear: data.data });
      const filteredIds = data.data
        .filter((item) => item.status === 1)
        .map((item) => item.id);
      this.setState({ selectedFinIds: filteredIds });
    }
  };
  handleRowSelect = (id) => {
    this.setState((prevState) => {
      const { selectedIds } = prevState;
      const index = selectedIds.indexOf(id);
      const updatedIds = [...selectedIds];

      if (index > -1) {
        updatedIds.splice(index, 1);
      } else {
        updatedIds.push(id);
      }

      console.log(updatedIds, "selectedIds");
      return { selectedIds: updatedIds };
    });
  };
  handleRowSelectFinancial = (id) => {
    this.setState((prevState) => {
      const { selectedFinIds } = prevState;
      const index = selectedFinIds.indexOf(id);
      const updatedIds = [...selectedFinIds];

      if (index > -1) {
        updatedIds.splice(index, 1);
      } else {
        updatedIds.push(id);
      }
      return { selectedFinIds: updatedIds };
    });
  };
  frameworkSubmit = async (event) => {
    event.preventDefault();
    const { selectedIds, data1 } = this.state;
    if (selectedIds.length > 0) {
      let obj = {};
      obj.assisted_company_id = data1.id;
      obj.framework_ids = selectedIds;
      const { isSuccess } = await apiCall(
        `${config.ADMIN_API_URL}updateAssistedCompanyFrameworks`,
        {},
        obj,
        "POST"
      );
      if (isSuccess) {
        this.serverRequest();
      }
    }
  };
  async paymentDone(id) {
    {
      const { isSuccess, data, error } = await apiCall(
        config.ADMIN_API_URL + `paymentDone`,
        {},
        { orderId: id },
        "POST"
      );

      if (isSuccess) {
        this.setState({ showModal: false });
        this.setState({ paymentTrue: false });
        // paymentTrue
        // console.log("succes");
      }
    }
  }
  async paymentHandler() {
    const { selectedFinIds, invoiceCharge, data1 } = this.state;
    let obj = {};
    obj.company_id = data1.id;
    obj.financial_year_id = selectedFinIds;
    obj.price = invoiceCharge;
    const { isSuccess, data, error } = await apiCall(
      `${config.ADMIN_API_URL}generateOrderId`,
      {},
      obj,
      "GET"
    );
    if (isSuccess) {
      console.log("dipajk", isSuccess, data, error);
      const options = {
        key: "rzp_test_hCHVIASrXD6OsD",
        amount: "80",
        currency: "INR",
        name: "Acme Corp",
        description: "Test Transaction",
        image: "https://example.com/your_logo",
        order_id: data.data.orderId,
        // callback_url: "http://127.0.0.1:3001/#/Setting_Billing",
        handler: (response) => {
          this.paymentDone(response.razorpay_order_id);
          // ...
        },

        prefill: {
          name: "Gaurav Kumar",
          email: "gaurav.kumar@example.com",
          contact: "9000090000",
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        alert(response.error.code);
        alert(response.error.description);
        alert(response.error.source);
        alert(response.error.step);
        alert(response.error.reason);
        alert(response.error.metadata.order_id);
        alert(response.error.metadata.payment_id);
      });
      rzp1.open();
    }
  }
  financialSubmit = async (event) => {
    event.preventDefault();
    const { selectedFinIds, invoiceCharge, data1 } = this.state;
    let obj = {};
    obj.company_id = data1.id;
    obj.financial_year_id = selectedFinIds;
    obj.price = invoiceCharge;
    const { isSuccess } = await apiCall(
      `${config.ADMIN_API_URL}assignFinancialYear`,
      {},
      obj,
      "POST"
    );
    if (isSuccess) {
      this.serverRequest();
    }
  };
  statusSubmit = async (event) => {
    event.preventDefault();
    let obj = {};
    obj.company_id = this.state.data1.id;
    obj.status = this.state.status;
    const { isSuccess } = await apiCall(
      `${config.ADMIN_API_URL}updateCompanyStatus`,
      {},
      obj,
      "POST"
    );
    if (isSuccess) {
      this.serverRequest();
    }
  };

  async componentDidMount() {
    const chargeType = localStorage.getItem("chargeType");
    if (chargeType === "FIXED") {
      this.setState({ chargeValue: localStorage.getItem("chargeValue") });
      this.setState({ invoiceCharge: localStorage.getItem("chargeValue") });
    }
    if (chargeType === "PERCENT") {
      this.setState({ chargeValue: localStorage.getItem("chargeValue") });
    }
    this.setState({ chargeType: localStorage.getItem("chargeType") });
    const role = localStorage.getItem("role");
    if (role == "group_admin") {
      this.setState({ userType: "assisted_company" });
    } else {
      this.setState({ userType: "company" });
    }
    if (role === "super_admin") {
      this.setState({ userRole: "super_admin" });
    }
    this.setState({ items: [] });
    this.serverRequest();
    // this.getAllSubscription();
    if (role === "super_admin") {
      const { isSuccess, data } = await apiCall(
        config.ADMIN_API_URL + "getBrokers",
        {},
        {},
        "GET"
      );
      if (isSuccess) {
        this.setState({
          pageCount1: Math.ceil(data.length / this.state.perPage),
          totalData1: data?.count,
          broker: data?.data,
        });
      }
    }
  }




  render() {
    const {
      items,
      items1,
      broker,
      countriesList,
      industryList,
      compData,
      activeModal,
      activeAllowedQuestionModal,
      companyData,
    } = this.state;
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="hol_rell">
              <div className="steps-form">
                <div className="steps-row setup-panel">
                  <div className="tabs-top setting_admin my-0">
                    <ul>
                      <li>
                        <NavLink className="activee" to={"/companies/registered_companies"}> Registered Companies </NavLink>
                      </li>
                      <li>
                        <NavLink to={"/companies/invited_companies"} title="Click To View" > Invited Compnies </NavLink>
                      </li>
                    </ul>
                  </div>
                  <input
                    type="search"
                    className="form-control w-25 mx-2"
                    placeholder="Search Company, Category, Industry &amp; Business Number"
                    name="search"
                    onChange={(e) => this.applyGlobalSearch(e)}
                  />
                </div>
              </div>
              <div className="color_div_on framwork_2 pt-0">
                <div className="business_detail">
                  <div className="table-responsive">
                    <div className="table___height">
                      {companyData && companyData?.length > 0 ?
                        <Table striped bordered style={{ textWrap: "nowrap" }} className="m-0">
                          <thead>
                            <tr className="heading_color">
                              <th style={{ width: 55 }}>Sr. No</th>
                              <th>First Name</th>
                              <th>Last Name</th>
                              <th>Company Name</th>
                              <th>Email</th>
                              <th>Moblie No.</th>
                              <th>Country</th>
                              <th>Allowed Question</th>
                              <th>Plan</th>
                              <th>Registered Date | Time</th>
                              <th style={{ width: 75 }}>Status</th>
                              <th style={{ width: 75 }}>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {companyData && companyData?.map((item, key) => (
                              <tr key={key}>
                                <td style={{ textAlign: "center" }}>{key + 1}</td>
                                <td>{item?.userDetils?.first_name}</td>
                                <td>{item?.userDetils?.last_name}</td>
                                <td>{item?.userDetils?.register_company_name}</td>
                                <td>{item?.userDetils?.email}</td>
                                <td>{item?.userDetils?.mobile_number}</td>
                                <td>{item?.userDetils?.country}</td>
                                <td style={{ textAlign: "center" }}>
                                  <div className="invited_company_icos">
                                    <i class="fas fa-eye" onClick={() => this.handleShowQuestionDetail(item?.userDetils?.id)} title="View Details"></i>
                                  </div>
                                </td>
                                <td onClick={() => this.handleOpenModal(item?.plan_data)} title="View Plan" style={{ cursor: "pointer" }}><a href="/" style={{}}>{item?.plan_data?.title}</a></td>
                                <td>{new Date(item?.userDetils?.createdAt).toLocaleString()}</td>
                                <td>
                                  {[...Array(numInstances)].map((_, index) => (
                                    <ToggleSwitch key={index} />
                                  ))}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <div className="invited_company_icos">
                                    <i class="fas fa-eye" onClick={() => this.handleShowDetail(item?.userDetils)} title="View Details"></i>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        : <Loader />}
                    </div>
                    {activeModal?.plan_data && <Modal animation={true} className="modal_box" size="md" shadow-lg="border" show={this.state.showModal && activeModal} >
                      <div>
                        <Modal.Header className="d-flex align-items-center justify-content-between">
                          <Modal.Title className="">
                            <h4 className="m-0"> View Detail</h4>
                          </Modal.Title>
                          <Button
                            variant="outline-dark"
                            onClick={this.handleCloseModal}
                          >
                            <i className="fa fa-times"></i>
                          </Button>
                        </Modal.Header>
                        <div className="modal-body">
                          <div className="pricing-table turquoise h-100">
                            <h2>{activeModal?.title}</h2>
                            <div className="price-tag">
                              <span className="symbol">{activeModal?.currency}</span>
                              <span className="amount">{activeModal?.price}</span>
                              <span className="after">/{activeModal?.renewal_type}</span>
                            </div>
                            <div className="mt-0 mb-1 border-bottom"></div>
                            <div className="d-flex align-items-center justify-content-between">
                              <span>Discount</span>
                              <span>{activeModal?.discount}</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <span>Financial Year</span>
                              <span>{activeModal?.year}</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <span>Global Framework for Supplier</span>
                              <span>
                                {(activeModal?.plan_data).ALLOW_GLOBAL_FRAMEWORK}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <span>No. of Users</span>
                              <span>{(activeModal?.plan_data)?.NO_USERS}</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <span>No. of Framework (Can be create)</span>
                              <span>{(activeModal?.plan_data)?.NO_FRAMEWORK}</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <span>No. of Suppliers (Can be create)</span>
                              <span>{(activeModal?.plan_data)?.NO_SUPPLIER}</span>
                            </div>
                            <div className="pricing-features h-100">
                              <div className="mt-2 mb-1 border-bottom"></div>
                              <div className="feature">Framework Features :</div>
                              <div className="framework_feature">
                                <ul>
                                  {(activeModal?.plan_data)?.MAPPED_FRAMEWORK &&
                                    (activeModal?.plan_data)?.MAPPED_FRAMEWORK?.map(
                                      (item) => (
                                        <>
                                          <li key={item.id}>{item.title}</li>
                                        </>
                                      )
                                    )}
                                </ul>
                              </div>
                              <div className="feature">Menu Features :</div>
                              <div className="framework_feature">
                                <ul>
                                  {(activeModal?.plan_data)?.MAPPED_MENUS &&
                                    (activeModal?.plan_data)?.MAPPED_MENUS?.map((item) => (
                                      <>
                                        <li key={item.id}>{item.title}</li>
                                      </>
                                    ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>}

                    {activeModal &&
                      <Modal show={this.state.show} onHide={this.handleCloseDetail}>
                        {console.log(activeModal)}
                        <Modal.Header closeButton>
                          <Modal.Title>View Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="steps-form">
                            <div className="steps-row setup-panel justify-content-center">
                              <div className="tabs-top setting_admin my-0">
                                <ul>
                                  <li onClick={() => this.handleToggle("PERSONAL")}>
                                    <NavLink className={this.state.hidePersaonalCompany === "PERSONAL" ? "activee" : ""} to={"#"} > Personal Detail</NavLink>
                                  </li>
                                  <li onClick={() => this.handleToggle("COMPANY")}>
                                    <NavLink className={this.state.hidePersaonalCompany === "COMPANY" ? "activee" : ""} to={"#"} > Company Detail</NavLink>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          {/* <div className="hstack gap-3 justify-content-center mb-3">
                            <Button variant={this.state.hidePersaonalCompany === "PERSONAL" ? "info" : "outline-info"}>Personal Detail</Button>
                            <Button variant={this.state.hidePersaonalCompany === "COMPANY" ? "info" : "outline-info"} onClick={() => this.handleToggle("COMPANY")}>Company Detail</Button>
                          </div> */}
                          <div className="prsnl_detail">
                            <Table striped bordered style={{ textWrap: "nowrap" }}>
                              <thead>
                                <tr className="heading_color">
                                  <th style={{ width: 55 }}>Attribute</th>
                                  <th>Value</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.hidePersaonalCompany === "PERSONAL" ?
                                  <>
                                    <tr>
                                      <td>First Name</td>
                                      <td>{activeModal?.first_name}</td>
                                    </tr>
                                    <tr>
                                      <td>Last Name</td>
                                      <td>{activeModal?.last_name}</td>
                                    </tr>
                                    <tr>
                                      <td>Email</td>
                                      <td>{activeModal?.email}</td>
                                    </tr>
                                    <tr>
                                      <td>Phone Number</td>
                                      <td>{activeModal?.mobile_number}</td>
                                    </tr>
                                    <tr>
                                      <td>Position</td>
                                      <td>{activeModal?.position}</td>
                                    </tr>
                                  </> :
                                  <>
                                    <tr>
                                      <td>Company Name</td>
                                      <td>{activeModal?.register_company_name}</td>
                                    </tr>
                                    <tr>
                                      <td>Company Industry</td>
                                      <td>{activeModal?.company_industry}</td>
                                    </tr>
                                    <tr>
                                      <td>Business Number</td>
                                      <td>{activeModal?.business_number}</td>
                                    </tr>
                                    <tr>
                                      <td>Payment Frequency</td>
                                      <td>{activeModal?.frequency}</td>
                                    </tr>
                                    <tr>
                                      <td>Country</td>
                                      <td>{activeModal?.country}</td>
                                    </tr>
                                    <tr>
                                      <td>Register Date | Time</td>
                                      <td>{new Date(activeModal?.createdAt).toLocaleString()}</td>
                                    </tr>
                                  </>
                                }
                              </tbody>
                            </Table>
                          </div>
                        </Modal.Body>
                      </Modal>}

                    <Modal show={this.state.showAllowedQuestion} onHide={this.handleCloseDetail}>
                      <Modal.Header closeButton>
                        <Modal.Title>View Details</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>

                        <div className="prsnl_detail">
                          <Table striped bordered style={{ textWrap: "nowrap" }}>
                            <thead>
                              <tr className="heading_color">
                                <th>Attribute</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <>
                                <tr>
                                  <td>General</td>
                                  <td corr> {[...Array(numInstances)].map((_, index) => (
                                    <ToggleSwitch key={index} />
                                  ))}</td>
                                </tr>
                                <tr>
                                  <td>Essential</td>
                                  <td> {[...Array(numInstances)].map((_, index) => (
                                    <ToggleSwitch key={index} />
                                  ))}</td>
                                </tr>
                                <tr>
                                  <td>Leadership</td>
                                  <td> {[...Array(numInstances)].map((_, index) => (
                                    <ToggleSwitch key={index} />
                                  ))}</td>
                                </tr>
                              </>
                            </tbody>
                          </Table>
                        </div>
                      </Modal.Body>
                    </Modal>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
