import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Table } from "react-bootstrap";
import { apiCall } from "../_services/apiCall";
import config from "../config/config.json";

const FormulaModal = (props) => {
  console.log("trick",props);
  const openModalDirectly = props.openFormulaMOdal;
  console.log("openModalDirectly",openModalDirectly);
  const [state, setState] = useState({
    // createFormulaModal: false,
    // viewHistoryModal: false,
    input: "",
    suggestions: [],
    selectedSuggestionIndex: 0,
    showSuggestions: false,
    operators: [
      "+",
      "-",
      "*",
      "/",
      "=",
      "<",
      ">",
      "!",
      "&",
      "|",
      "^",
      "%",
      "(",
      ")",
      "{",
      "}",
      "[",
      "]",
    ],
    answerArray: [],
    getSectorQuestionData: [],
    inValidQuestionError: "",
    inValidExpressionError: "",
    formulaHistoryData: [],
  });
  const [createFormulaModal, setcreateFormulaModal] = useState(false);
  const [formulaData, setformulaData] = useState(false);
  const [viewHistoryModal, setviewHistoryModal] = useState(false);
  const [dynamicErrors, setDynamicErrors] = useState({});
  const [inputValues, setInputValues] = useState({});
  useEffect(() => {
    getSectorQuestion();
    getFormulaHistory();
  }, []);

  const {
    // createFormulaModal,
    // viewHistoryModal,
    input,
    suggestions,
    selectedSuggestionIndex,
    showSuggestions,
    operators,
    answerArray,
    getSectorQuestionData,
    inValidQuestionError,
    inValidExpressionError,
    formulaHistoryData,
  } = state;

  const handleInputChange = (e) => {
    const userInput = e.target.value;
    console.log("name",userInput)
    const operatorsAndBrackets = /[+\-*\/=<>!&|^%(){}\[\]]+|if/g;
    const userInputArray = userInput.split(operatorsAndBrackets);
    const lastInput = userInputArray[userInputArray.length - 1].trim();
    const extractedExpression = userInput.includes("=")
      ? userInput.split("=")[0]
      : null;
    const extractedSecondPartExpression = userInput.includes("=")
      ? userInput.split("=")[1]
      : null;
    let questionID;
    let suggestionValues = [];

    if (extractedExpression) {
      const match = extractedExpression.match(/Q(\d+)/);
      const extractedNumber = parseInt(match[1]);
      questionID = extractedNumber;
    }

    if (questionID) {
      const questionObject = state.answerArray.find(
        (obj) => obj.question_id === questionID
      );
      if (
        questionObject?.questionType === "tabular_question" ||
        questionObject?.questionType === "quantitative"
      ) {
        suggestionValues = state.answerArray
          .map((item) => {
            if (item?.questionType === "quantitative_trends") return null;
            else {
              if (item?.questionType === "tabular_question") {
                let rowIndex = 1;
                let columnIndex = 1;
                const result1 = [];
                const result2 = [];
                for (const questionDetailItem of item.question_detail) {
                  if (questionDetailItem.option_type === "column") {
                    result1.push(`C${columnIndex}`);
                    columnIndex++;
                  } else if (questionDetailItem.option_type === "row") {
                    result2.push(`R${rowIndex}`);
                    rowIndex++;
                  }
                }

                const combinedArray = [];

                for (const r2 of result2) {
                  for (const r1 of result1) {
                    combinedArray.push("Q" + item?.question_id + r2 + r1);
                  }
                }
                return combinedArray;
              } else {
                return "Q" + item?.question_id;
              }
            }
          })
          .filter((value) => value !== null);
      }
    }

    let tempSuggestionValues;
    if (suggestionValues.length === 0)
      tempSuggestionValues = state.getSectorQuestionData;
    else tempSuggestionValues = suggestionValues.flat();

    const filteredSuggestions =
      tempSuggestionValues &&
      tempSuggestionValues.filter((suggestion) => {
        if (suggestion && lastInput) {
          return (
            suggestion.toLowerCase().includes(lastInput.toLowerCase()) &&
            suggestion !== extractedExpression
          );
        }
        return false;
      });
    let questionError = "";
    let expressionError = "";
    if (extractedSecondPartExpression) {
      const expressionParts = extractedSecondPartExpression
        .split(operatorsAndBrackets)
        .filter((part) => part.length > 0);
      const allPartsInAnswerArray = expressionParts.every((part) =>
        tempSuggestionValues.includes(part)
      );

      if (allPartsInAnswerArray) {
      } else {
        questionError = "Invalid Question Number";
      }

      const modifiedExpression = extractedSecondPartExpression.replace(
        /Q|R|C/g,
        "1"
      );
      try {
        eval(modifiedExpression);
      } catch (error) {
        expressionError = "Invalid Formula Expression";
      }
    }

    setState({
      ...state,
      input: userInput,
      suggestions: filteredSuggestions,
      showSuggestions: true,
      inValidExpressionError: expressionError,
      inValidQuestionError: questionError,
    });
  };

  const handleInputChangeForFormula = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    console.log("name",name,value)
    const userInput = event.target.value;
    const operatorsAndBrackets = /[+\-*\/=<>!&|^%(){}\[\]]+|if/g;
    const userInputArray = userInput.split(operatorsAndBrackets);
    const lastInput = userInputArray[userInputArray.length - 1].trim();
    const extractedExpression = userInput.includes("=")
      ? userInput.split("=")[0]
      : null;
    const extractedSecondPartExpression = userInput.includes("=")
      ? userInput.split("=")[1]
      : null;
    let questionID;
    let suggestionValues = [];

    if (extractedExpression) {
      const match = extractedExpression.match(/Q(\d+)/);
      const extractedNumber = parseInt(match[1]);
      questionID = extractedNumber;
    }

    if (questionID) {
      const questionObject = state.answerArray.find(
        (obj) => obj.question_id === questionID
      );
      if (
        questionObject?.questionType === "tabular_question" ||
        questionObject?.questionType === "quantitative"
      ) {
        suggestionValues = state.answerArray
          .map((item) => {
            if (item?.questionType === "quantitative_trends") return null;
            else {
              if (item?.questionType === "tabular_question") {
                let rowIndex = 1;
                let columnIndex = 1;
                const result1 = [];
                const result2 = [];
                for (const questionDetailItem of item.question_detail) {
                  if (questionDetailItem.option_type === "column") {
                    result1.push(`C${columnIndex}`);
                    columnIndex++;
                  } else if (questionDetailItem.option_type === "row") {
                    result2.push(`R${rowIndex}`);
                    rowIndex++;
                  }
                }

                const combinedArray = [];

                for (const r2 of result2) {
                  for (const r1 of result1) {
                    combinedArray.push("Q" + item?.question_id + r2 + r1);
                  }
                }
                return combinedArray;
              } else {
                return "Q" + item?.question_id;
              }
            }
          })
          .filter((value) => value !== null);
      }
    }

    let tempSuggestionValues;
    if (suggestionValues.length === 0)
      tempSuggestionValues = state.getSectorQuestionData;
    else tempSuggestionValues = suggestionValues.flat();

    const filteredSuggestions =
      tempSuggestionValues &&
      tempSuggestionValues.filter((suggestion) => {
        if (suggestion && lastInput) {
          return (
            suggestion.toLowerCase().includes(lastInput.toLowerCase()) &&
            suggestion !== extractedExpression
          );
        }
        return false;
      });
    let questionError = "";
    let expressionError = "";
    if (extractedSecondPartExpression) {
      const expressionParts = extractedSecondPartExpression
        .split(operatorsAndBrackets)
        .filter((part) => part.length > 0);
      const allPartsInAnswerArray = expressionParts.every((part) =>
        tempSuggestionValues.includes(part)
      );

      if (allPartsInAnswerArray) {
      } else {
        questionError = "Invalid Question Number";
      }

      const modifiedExpression = extractedSecondPartExpression.replace(
        /Q|R|C/g,
        "1"
      );
      try {
        eval(modifiedExpression);
      } catch (error) {
        expressionError = "Invalid Formula Expression";
      }
    }

    setDynamicErrors((prevErrors) => ({
      ...prevErrors,
      [name]: {
        suggestions: filteredSuggestions,
        showSuggestions: true,
        inValidExpressionError: expressionError,
        inValidQuestionError: questionError,
      },
    }));
    setInputValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSuggestionClickForFormula = (name, suggestion) => {
    const input = inputValues[name];
    const { suggestions } = state;
    const operatorsAndBrackets = /[+\-*\/=<>!&|^%(){}\[\]]+|if/g;
    const operatorsAndBracket = /[+\-*\/=<>!&|^%{}[\]]+|(\()+|if/g;
    const operatorMatches = input.match(operatorsAndBracket);
    let updatedInput;

    if (operatorMatches) {
      const lastOperator = operatorMatches[operatorMatches.length - 1];
      const lastOperatorIndex = input.lastIndexOf(lastOperator);
      updatedInput = input.substring(0, lastOperatorIndex + 1) + suggestion;
    } else {
      updatedInput = suggestion;
    }

    const extractedSecondPartExpression = updatedInput.includes("=")
      ? updatedInput.split("=")[1]
      : null;
    let questionError = "";
    let expressionError = "";
    if (extractedSecondPartExpression) {
      const expressionParts = extractedSecondPartExpression
        .split(operatorsAndBrackets)
        .filter((part) => part.length > 0);

      const allPartsInAnswerArray = expressionParts.every((part) =>
        suggestions.includes(part)
      );

      if (allPartsInAnswerArray) {
      } else {
        questionError = "Invalid Question Number";
      }

      const modifiedExpression = extractedSecondPartExpression.replace(
        /Q|R|C/g,
        "1"
      );
      try {
        eval(modifiedExpression);
      } catch (error) {
        expressionError = "Invalid Formula Expression";
      }
    }

    setDynamicErrors((prevErrors) => ({
      ...prevErrors,
      [name]: {
        showSuggestions: false,
        inValidExpressionError: expressionError,
        inValidQuestionError: questionError,
      },
    }));
    setInputValues((prevState) => ({
      ...prevState,
      [name]: updatedInput,
    }));
  };
  const handleSuggestionClick = (suggestion) => {
    const { input, suggestions } = state;
    const operatorsAndBrackets = /[+\-*\/=<>!&|^%(){}\[\]]+|if/g;
    const operatorsAndBracket = /[+\-*\/=<>!&|^%{}[\]]+|(\()+|if/g;
    const operatorMatches = input.match(operatorsAndBracket);
    let updatedInput;

    if (operatorMatches) {
      const lastOperator = operatorMatches[operatorMatches.length - 1];
      const lastOperatorIndex = input.lastIndexOf(lastOperator);
      updatedInput = input.substring(0, lastOperatorIndex + 1) + suggestion;
    } else {
      updatedInput = suggestion;
    }

    const extractedSecondPartExpression = updatedInput.includes("=")
      ? updatedInput.split("=")[1]
      : null;
    let questionError = "";
    let expressionError = "";
    if (extractedSecondPartExpression) {
      const expressionParts = extractedSecondPartExpression
        .split(operatorsAndBrackets)
        .filter((part) => part.length > 0);

      const allPartsInAnswerArray = expressionParts.every((part) =>
        suggestions.includes(part)
      );

      if (allPartsInAnswerArray) {
      } else {
        questionError = "Invalid Question Number";
      }

      const modifiedExpression = extractedSecondPartExpression.replace(
        /Q|R|C/g,
        "1"
      );
      try {
        eval(modifiedExpression);
      } catch (error) {
        expressionError = "Invalid Formula Expression";
      }
    }

    setState({
      ...state,
      input: updatedInput,
      showSuggestions: false,
      inValidExpressionError: expressionError,
      inValidQuestionError: questionError,
    });
  };
  const handleKeyDownForFormula = (e, name) => {
    const { suggestions } = state;
    const selectedSuggestionIndex = dynamicErrors[name].selectedSuggestionIndex;
    if (e.key === "Enter") {
      setDynamicErrors((prevErrors) => ({
        ...prevErrors,
        [name]: {
          showSuggestions: false,
        },
      }));
      setInputValues((prevState) => ({
        ...prevState,
        [name]: suggestions[selectedSuggestionIndex],
      }));
    } else if (e.key === "ArrowUp" && selectedSuggestionIndex > 0) {
      setDynamicErrors((prevErrors) => ({
        ...prevErrors,
        [name]: {
          selectedSuggestionIndex: selectedSuggestionIndex - 1,
        },
      }));
    } else if (
      e.key === "ArrowDown" &&
      selectedSuggestionIndex < suggestions.length - 1
    ) {
      setDynamicErrors((prevErrors) => ({
        ...prevErrors,
        [name]: {
          selectedSuggestionIndex: selectedSuggestionIndex + 1,
        },
      }));
    }
  };
  const handleKeyDown = (e) => {
    const { selectedSuggestionIndex, suggestions } = state;

    if (e.key === "Enter") {
      setState({
        ...state,
        input: suggestions[selectedSuggestionIndex],
        showSuggestions: false,
      });
    } else if (e.key === "ArrowUp" && selectedSuggestionIndex > 0) {
      setState({
        ...state,
        selectedSuggestionIndex: selectedSuggestionIndex - 1,
      });
    } else if (
      e.key === "ArrowDown" &&
      selectedSuggestionIndex < suggestions.length - 1
    ) {
      setState({
        ...state,
        selectedSuggestionIndex: selectedSuggestionIndex + 1,
      });
    }
  };

  const handleOperatorChange = (e) => {
    const selectedOperator = e.target.value;
    const { input, operators } = state;
    const operatorRegex = new RegExp(`[${operators.join("\\")}]`);
    const hasOperator = operatorRegex.test(input);
    let updatedInput;
    if (hasOperator) {
      updatedInput = input.replace(/[-+*/=!<>&|^%()[\]{}]+$/, selectedOperator);
    } else {
      updatedInput = input + selectedOperator;
    }

    setState({ ...state, input: updatedInput });
  };
  const handleOperatorChangeForFormula = (e, name) => {
    const selectedOperator = e.target.value;
    const { operators } = state;
    const operatorRegex = new RegExp(`[${operators.join("\\")}]`);
    const hasOperator = operatorRegex.test(inputValues[name]);
    let updatedInput;
    if (hasOperator) {
      updatedInput = inputValues[name].replace(
        /[-+*/=!<>&|^%()[\]{}]+$/,
        selectedOperator
      );
    } else {
      updatedInput = String(inputValues[name]) + selectedOperator;
    }
    setInputValues((prevState) => ({
      ...prevState,
      [name]: updatedInput,
    }));
  };
  const getSectorQuestion = async () => {
    const apiUrl = props?.formulaData
      ? `${config.ADMIN_API_URL}getSectorQuestionForFormula?&framework_ids=${props.formulaData[0].frameworkId}&type=ALL`
      : `${config.ADMIN_API_URL}getSectorQuestionForFormula?type=ALL`;

    try {
      const response = await apiCall(apiUrl, {}, {}, "GET");

      if (response.isSuccess) {
        const suggestionData = response?.data?.data;
        console.log("sugggg",suggestionData)

        const updatedState = {
          ...state,
          answerArray: response?.data?.data,
          getSectorQuestionData: suggestionData
            .map((item) => {
              if (item?.questionType === "tabular_question") {
                let rowIndex = 1;
                let columnIndex = 1;
                const result1 = [];
                const result2 = [];
                for (const questionDetailItem of item.question_detail) {
                  if (questionDetailItem.option_type === "column") {
                    result1.push(`C${columnIndex}`);
                    columnIndex++;
                  } else if (questionDetailItem.option_type === "row") {
                    result2.push(`R${rowIndex}`);
                    rowIndex++;
                  }
                }

                const combinedArray = [];

                for (const r2 of result2) {
                  for (const r1 of result1) {
                    combinedArray.push("Q" + item?.question_id + r2 + r1);
                  }
                }

                return combinedArray;
              } else {
                return ["Q" + item?.question_id];
              }
            })
            .flat(),
        };
        setState(updatedState);
      } else {
        console.error("API call unsuccessful");
      }
    } catch (error) {
      console.error("Error occurred during API call:", error);
    }
  };

  const getFormulaHistory = async () => {
    const apiUrl = `${config.ADMIN_API_URL}getFormulaHistory`;

    try {
      const response = await apiCall(apiUrl, {}, {}, "GET");

      if (response.isSuccess) {
        const updatedState = {
          ...state,
          formulaHistoryData: response.data.data,
        };
        setState(updatedState);
      }
    } catch (error) {
      console.error("Error occurred during API call:", error);
    }
  };

  const saveSectorQuestionForFormula = async () => {
    const apiUrl = `${config.ADMIN_API_URL}saveSectorQuestionForFormula`;

    try {
      const response = await apiCall(
        apiUrl,
        {},
        // { formula: state.input },
        "POST"
      );

      if (response.isSuccess) {
        window.location.href = config.baseURL + "/#/questions_framework_wise";
      } else {
        console.error("API call unsuccessful");
      }
    } catch (error) {
      console.error("Error occurred during API call:", error);
    }
  };

  const handleClose = () => {
    setcreateFormulaModal(false);
    // window.location.href = config.baseURL + "/#/questions_framework_wise";
  };

  const handleViewHistoryClose = () => {
    setviewHistoryModal(false);
  };

  const handleOpenModalForFormula = () => {
    getSectorQuestion();
    setcreateFormulaModal(true);
  };

  const handleOpenModalForHistoryFormula = () => {
    getFormulaHistory();
    setviewHistoryModal(true);
  };

  useEffect(() => {
   
    if (openModalDirectly == true) {
      console.log("trickeddd")

      setformulaData(props.formulaData);
      // const initialValues = {};
      props.formulaData.forEach((item) => {
        if (item?.questionType !== "tabular_question") {
          const initialValues = `Q${item.question_id}=`;
          setInputValues((prevState) => ({
            ...prevState,
            [item.question_id]: initialValues,
          }));
          setDynamicErrors((prevErrors) => ({
            ...prevErrors,
            [`${item.question_id}`]: {
              showSuggestions: false,
              selectedSuggestionIndex: 0,
            },
          }));
        } else if (item?.questionType === "tabular_question") {
          item.question_detail.row.forEach((rowName, rowIndex) => {
            item.question_detail.column.forEach((colName, colIndex) => {
              const questionKey = `${item.question_id}R${rowIndex}C${colIndex}`;
              const initialValues = `Q${item.question_id}R${rowIndex + 1}C${
                colIndex + 1
              }=`;
              setInputValues((prevState) => ({
                ...prevState,
                [questionKey]: initialValues,
              }));
              setDynamicErrors((prevErrors) => ({
                ...prevErrors,
                [questionKey]: {
                  showSuggestions: false,
                  selectedSuggestionIndex: 0,
                },
              }));
            });
          });
        }
      });
      // setInputValues(initialValues);
      handleOpenModalForFormula();
    }
  }, [openModalDirectly]);
  return (
    <>
      <button className="link_bal_next" onClick={handleOpenModalForFormula}>
        Add Formula
      </button>

      <>
        <Modal
          show={createFormulaModal}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <div className=" w-100 d-flex align-items-center justify-content-between">
              <Modal.Title>
                <div> Add Formula</div>
              </Modal.Title>
              <div
                className="view_formula_history"
                onClick={() => handleOpenModalForHistoryFormula()}
              >
                View History
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            {!formulaData && (
              <Form>
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Add Formula</Form.Label>
                  <Form.Control
                    autoFocus
                    type="text"
                    value={input}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    placeholder="Type something..."
                    autoComplete="off"
                  />
                  {inValidQuestionError && (
                    <div className="error-message">{inValidQuestionError}</div>
                  )}
                  {inValidExpressionError && (
                    <div className="error-message">
                      {inValidExpressionError}
                    </div>
                  )}
                  {showSuggestions && (
                    <div
                      className={
                        suggestions &&
                        suggestions.length > 0 &&
                        "formula__suggesstions"
                      }
                    >
                      {suggestions.map((suggestion, index) => (
                        <div
                          key={index}
                          className={
                            index === selectedSuggestionIndex ? "selected" : ""
                          }
                          onClick={() => handleSuggestionClick(suggestion)}
                        >
                          {suggestion}
                        </div>
                      ))}
                    </div>
                  )}
                  <Form.Label className="mt-3">Select Operator</Form.Label>
                  <Form.Control
                    as="select"
                    className="form-control"
                    onChange={handleOperatorChange}
                  >
                    <option value="">Select Operator</option>
                    {operators.map((operator, index) => (
                      <option key={index} value={operator}>
                        {operator}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form>
            )}

            {formulaData &&
              formulaData.map((item, index) => (
                <Form>
                  {item?.questionType === "quantitative" && (
                    <Form.Group
                      className="mb-2"
                      controlId={`question-${item.question_id}`}
                    >
                      <Form.Label>Add Formula for {item.heading}</Form.Label>
                      <Form.Control
                        autoFocus
                        name={`${item.question_id}`}
                        type="text"
                        value={inputValues[`${item.question_id}`] || ""}
                        onChange={(event) =>
                          handleInputChangeForFormula(event, item.question_id)
                        }
                        onKeyDown={(event) =>
                          handleKeyDownForFormula(event, `${item.question_id}`)
                        }
                        placeholder="Type something..."
                        autoComplete="off"
                      />

                      {dynamicErrors[`${item.question_id}`] &&
                        dynamicErrors[`${item.question_id}`]
                          .inValidQuestionError && (
                          <div className="error-message">
                            {
                              dynamicErrors[`${item.question_id}`]
                                .inValidQuestionError
                            }
                          </div>
                        )}
                      {dynamicErrors[`${item.question_id}`] &&
                        dynamicErrors[`${item.question_id}`]
                          .inValidExpressionError && (
                          <div className="error-message">
                            {
                              dynamicErrors[`${item.question_id}`]
                                .inValidExpressionError
                            }
                          </div>
                        )}
                      {dynamicErrors[`${item.question_id}`] &&
                        dynamicErrors[`${item.question_id}`]
                          .showSuggestions && (
                          <div
                            className={
                              dynamicErrors[`${item.question_id}`]
                                .suggestions &&
                              dynamicErrors[`${item.question_id}`].suggestions
                                .length > 0 &&
                              "formula__suggesstions"
                            }
                          >
                            {dynamicErrors[
                              `${item.question_id}`
                            ].suggestions.map((suggestion, index) => (
                              <div
                                key={index}
                                className={
                                  index ===
                                  dynamicErrors[`${item.question_id}`]
                                    .selectedSuggestionIndex
                                    ? "selected"
                                    : ""
                                }
                                onClick={(e) =>
                                  handleSuggestionClickForFormula(
                                    `${item.question_id}`,
                                    suggestion
                                  )
                                }
                              >
                                {suggestion}
                              </div>
                            ))}
                          </div>
                        )}
                      <Form.Label className="mt-3">Select Operator</Form.Label>
                      <Form.Control
                        as="select"
                        className="form-control"
                        onChange={(event) =>
                          handleOperatorChangeForFormula(
                            event,
                            `${item.question_id}`
                          )
                        }
                      >
                        <option value="">Select Operator</option>
                        {operators.map((operator, index) => (
                          <option key={index} value={operator}>
                            {operator}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  )}
                  {item?.questionType === "quantitative_trends" && (
                    <Form.Group
                      className="mb-2"
                      controlId={`question-${item.question_id}`}
                    >
                      <Form.Label>Add Formula for {item.heading}</Form.Label>
                      <Form.Control
                        autoFocus
                        name={`${item.question_id}`}
                        type="text"
                        value={inputValues[`${item.question_id}`] || ""}
                        onChange={(event) =>
                          handleInputChangeForFormula(event, item.question_id)
                        }
                        onKeyDown={(event) =>
                          handleKeyDownForFormula(event, `${item.question_id}`)
                        }
                        placeholder="Type something..."
                        autoComplete="off"
                      />

                      {dynamicErrors[`${item.question_id}`] &&
                        dynamicErrors[`${item.question_id}`]
                          .inValidQuestionError && (
                          <div className="error-message">
                            {
                              dynamicErrors[`${item.question_id}`]
                                .inValidQuestionError
                            }
                          </div>
                        )}
                      {dynamicErrors[`${item.question_id}`] &&
                        dynamicErrors[`${item.question_id}`]
                          .inValidExpressionError && (
                          <div className="error-message">
                            {
                              dynamicErrors[`${item.question_id}`]
                                .inValidExpressionError
                            }
                          </div>
                        )}
                      {dynamicErrors[`${item.question_id}`] &&
                        dynamicErrors[`${item.question_id}`]
                          .showSuggestions && (
                          <div
                            className={
                              dynamicErrors[`${item.question_id}`]
                                .suggestions &&
                              dynamicErrors[`${item.question_id}`].suggestions
                                .length > 0 &&
                              "formula__suggesstions"
                            }
                          >
                            {dynamicErrors[
                              `${item.question_id}`
                            ].suggestions.map((suggestion, index) => (
                              <div
                                key={index}
                                className={
                                  index ===
                                  dynamicErrors[`${item.question_id}`]
                                    .selectedSuggestionIndex
                                    ? "selected"
                                    : ""
                                }
                                onClick={(e) =>
                                  handleSuggestionClickForFormula(
                                    `${item.question_id}`,
                                    suggestion
                                  )
                                }
                              >
                                {suggestion}
                              </div>
                            ))}
                          </div>
                        )}
                      <Form.Label className="mt-3">Select Operator</Form.Label>
                      <Form.Control
                        as="select"
                        className="form-control"
                        onChange={(event) =>
                          handleOperatorChangeForFormula(
                            event,
                            `${item.question_id}`
                          )
                        }
                      >
                        <option value="">Select Operator</option>
                        {operators.map((operator, index) => (
                          <option key={index} value={operator}>
                            {operator}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  )}
                  {item?.questionType === "tabular_question" && (
                    <Form.Group
                      className="mb-3"
                      style={{ overflow: "auto" }}
                      controlId={`question-${item.question_id}`}
                    >
                      <Form.Label>Add formula for {item.heading}</Form.Label>
                      <div>
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>{item?.firstRowAndColumn || "#"}</th>
                              {item.question_detail.column.map(
                                (colName, index) => (
                                  <th key={index}>{colName}</th>
                                )
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {item.question_detail.row.map(
                              (rowName, rowIndex) => (
                                <tr key={rowIndex}>
                                  <th>{rowName}</th>
                                  {item.question_detail.column.map(
                                    (colName, colIndex) => (
                                      <td key={colIndex}>
                                        <input
                                          type="text"
                                          className="input-for-formula"
                                          name={`${item.question_id}R${rowIndex}C${colIndex}`}
                                          value={
                                            inputValues[
                                              `${item.question_id}R${rowIndex}C${colIndex}`
                                            ] || ""
                                          }
                                          onChange={(event) =>
                                            handleInputChangeForFormula(
                                              event,
                                              item.question_id,
                                              rowIndex,
                                              colIndex
                                            )
                                          }
                                          onKeyDown={(event) =>
                                            handleKeyDownForFormula(
                                              event,
                                              `${item.question_id}R${rowIndex}C${colIndex}`
                                            )
                                          }
                                        />
                                        {dynamicErrors[
                                          `${item.question_id}R${rowIndex}C${colIndex}`
                                        ] &&
                                          dynamicErrors[
                                            `${item.question_id}R${rowIndex}C${colIndex}`
                                          ].inValidQuestionError && (
                                            <div className="error-message">
                                              {
                                                dynamicErrors[
                                                  `${item.question_id}R${rowIndex}C${colIndex}`
                                                ].inValidQuestionError
                                              }
                                            </div>
                                          )}
                                        {dynamicErrors[
                                          `${item.question_id}R${rowIndex}C${colIndex}`
                                        ] &&
                                          dynamicErrors[
                                            `${item.question_id}R${rowIndex}C${colIndex}`
                                          ].inValidExpressionError && (
                                            <div className="error-message">
                                              {
                                                dynamicErrors[
                                                  `${item.question_id}R${rowIndex}C${colIndex}`
                                                ].inValidExpressionError
                                              }
                                            </div>
                                          )}
                                        {dynamicErrors[
                                          `${item.question_id}R${rowIndex}C${colIndex}`
                                        ] &&
                                          dynamicErrors[
                                            `${item.question_id}R${rowIndex}C${colIndex}`
                                          ].showSuggestions && (
                                            <div
                                              className={
                                                dynamicErrors[
                                                  `${item.question_id}R${rowIndex}C${colIndex}`
                                                ].suggestions &&
                                                dynamicErrors[
                                                  `${item.question_id}R${rowIndex}C${colIndex}`
                                                ].suggestions.length > 0 &&
                                                "formula__suggesstions"
                                              }
                                            >
                                              {dynamicErrors[
                                                `${item.question_id}R${rowIndex}C${colIndex}`
                                              ].suggestions.map(
                                                (suggestion, index) => (
                                                  <div
                                                    key={index}
                                                    className={
                                                      index ===
                                                      dynamicErrors[
                                                        `${item.question_id}R${rowIndex}C${colIndex}`
                                                      ].selectedSuggestionIndex
                                                        ? "selected"
                                                        : ""
                                                    }
                                                    onClick={(e) =>
                                                      handleSuggestionClickForFormula(
                                                        `${item.question_id}R${rowIndex}C${colIndex}`,
                                                        suggestion
                                                      )
                                                    }
                                                  >
                                                    {suggestion}
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          )}
                                        <Form.Label className="mt-3">
                                          Select Operator
                                        </Form.Label>
                                        <Form.Control
                                          as="select"
                                          className="form-control"
                                          onChange={(event) =>
                                            handleOperatorChangeForFormula(
                                              event,
                                              `${item.question_id}R${rowIndex}C${colIndex}`
                                            )
                                          }
                                          // onChange={handleOperatorChange}
                                        >
                                          <option value="">
                                            Select Operator
                                          </option>
                                          {operators.map((operator, index) => (
                                            <option
                                              key={index}
                                              value={operator}
                                            >
                                              {operator}
                                            </option>
                                          ))}
                                        </Form.Control>
                                      </td>
                                    )
                                  )}
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </Form.Group>
                  )}
                </Form>
              ))}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="link_bal_next"
              onClick={() => saveSectorQuestionForFormula()}
            >
              Add Formula
            </button>
          </Modal.Footer>
        </Modal>
      </>
      <>
        <Modal
          show={viewHistoryModal}
          onHide={handleViewHistoryClose}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Formula</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Question ID</th>
                  <th>Question Type</th>
                  <th>Formula</th>
                </tr>
              </thead>
              <tbody>
                {formulaHistoryData &&
                  formulaHistoryData.map((item, index) => (
                    <tr key={index}>
                      <td>{item?.questionId}</td>
                      <td>{item?.questionType}</td>
                      <td>{item?.formula}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Modal.Body>
        </Modal>
      </>
    </>
  );
};

export default FormulaModal;
