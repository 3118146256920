import React, { Component } from "react";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import { NavLink } from "react-router-dom";
import WorldChart from "../component/dashboard/world_chart";
import { authenticationService } from "../../../_services/authentication";
import Table from "react-bootstrap/Table";
import "./dashboard.css";
import config from "../../../config/config.json";
import moment from "moment";
import RevenueChart from "../component/dashboard/RevenueChart";
const currentUser = authenticationService.currentUserValue;

export default class dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      totalCompanies: 0,
      totalUsers: 0,
      totalSuppliers: 0,
      subAdmins: [],
      login: false,
      items: [],
      loading: false,
      totalRows: 0,
      currentPage: 1,
      skip: 0,
      limit: 5,
      setStartDate: null,
      setEndDate: null,
      search: "",
    }
    this.pageChange = this.pageChange.bind(this);
    this.serverRequest = this.serverRequest.bind(this);
  }

  serverRequest() {
    const {
      skip,
      limit,
      search,
    } = this.state;

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };
    let parms = `acticity?skip=${skip}&limit=${limit}&search=${search}`;
    fetch(config.ADMIN_API_URL + parms, requestOptions)
      .then((res) => res.json())
      .then(
        (data) => {
          this.setState({
            pageCount: Math.ceil(data?.data?.length / this.state.perPage),
            totalData: data?.count,
            items: data?.data,
          });
        },
        (error) => {}
      );
  }

  pageChange(e, data) {
    // console.log("Handle Click", data);
    let page = data.activePage;
    let pageNo = page === 1 ? 0 : (page - 1) * this.state.limit;
    // console.log("clicked", pageNo);
    this.setState({
      skip: pageNo,
    });
    this.serverRequest();
  }

  componentDidMount() {

    this.setState({ items: [] });
    this.serverRequest();

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };

    fetch(config.ADMIN_API_URL + "getTotalCount", requestOptions)
      .then((res) => res.json())
      .then(
        (data) => {
          this.setState({
            isLoaded: true,
            totalCompanies: data.totalCompany,
            totalUsers: data.totalUsers,
            totalSuppliers: data.totalSuppliers,
            subAdmins: data.subAdmins.rows,
          });
          console.log("subadmins",data.subAdmins.rows)
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }
  render() {
    const { totalCompanies, totalUsers, totalSuppliers, subAdmins, items } =
      this.state;
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="color_div">
                    <div className="row">
                      <div className="col-xxl-4 col-lg-4 col-md-12 col-12">
                        <div className="div_1">
                          <NavLink to="/companies" className="nav_1">
                            <span>
                              +2.3% <i className="fa fa-caret-up" />
                            </span>
                            <h4>{totalCompanies}</h4>
                            <span>
                              Total Companies{" "}
                              <i className="fal fa-long-arrow-right" />
                            </span>
                          </NavLink>
                        </div>
                      </div>
                      <div className="col-xxl-4 col-lg-4 col-md-12 col-12">
                        <div className="div_2">
                          <NavLink to="/sub-users" className="nav_1">
                            <span>
                              +2.3% <i className="fa fa-caret-up" />
                            </span>
                            <h4>{totalUsers-1}</h4>
                            <span>
                              Total Users{" "}
                              <i className="fal fa-long-arrow-right" />
                            </span>
                          </NavLink>
                        </div>
                      </div>
                      <div className="col-xxl-4 col-lg-4 col-md-12 col-12">
                        <div className="div_3">
                          <NavLink
                            to="/Zoho-Form"
                            className="nav_1"
                          >
                            <span>
                              +2.3% <i className="fa fa-caret-up" />
                            </span>
                            <h4>{totalSuppliers}</h4>
                            <span>
                              Total Suppliers{" "}
                              <i className="fal fa-long-arrow-right" />
                            </span>
                          </NavLink>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 col-xs-12">
                        <RevenueChart />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xl-6 col-lg-12 col-12">
                        <div className="business_detail">
                          <div className="heading my-5">
                            <div className="heading_wth_text">
                              <h4>ESG Sub Admins</h4>
                            </div>
                            <NavLink to="/sub-users" className="anchor">
                              View All <i className="fal fa-long-arrow-right" />
                            </NavLink>
                          </div>
                        </div>
                        <div className="table_f table_fr">
                          <Table striped bordered hover size="sm">
                            <thead>
                              <tr className="heading_color heading_top">
                                <th>ID</th>
                                <th>Sub-Admin Name</th>
                                <th>Companies Assigned</th>
                              </tr>
                            </thead>
                            <tbody>
                              {subAdmins?.map((item, key) => (
                                <tr key={key}>
                                  <td>{key + 1}</td>
                                  <td>
                                    <NavLink className="non_underline_link"
                                      to={`/sub-users/${item.uuid}`}
                                    >
                                      <span className="black bold">{item?.first_name+' '+item?.last_name}</span>
                                    </NavLink>
                                  </td>
                                  <td>{item?.register_company_name
                                  }</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-12 col-12">
                        <div className="business_detail">
                          <div className="heading my-5">
                            <div className="heading_wth_text">
                              <h4>Bird's Eye View</h4>
                            </div>
                            {/* <NavLink to="/" className="anchor">
                              <i className="far fa-expand-arrows-alt" />
                            </NavLink> */}
                          </div>
                        </div>
                        <div className="world_chart">
                          <WorldChart />
                        </div>
                      </div>
                    </div>
                    <div className="table_f mt-5">
                      <div className="business_detail">
                        <div className="heading my-5">
                          <div className="heading_wth_text">
                            <h4>Latest Activities</h4>
                          </div>
                        </div>
                      </div>
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr className="heading_color">
                              <th style={{ width: "10%" }}>ID</th>
                              <th>COMPANY NAME</th>
                              <th>MODULE </th>
                              <th>DATE / TIME</th>
                            </tr>
                          </thead>
                          <tbody>
                          {items.map((item,key) => (
                            <tr key={key}>
                              <td>{this.state.skip+key+1}</td>
                              <td>{item.company_name}</td>
                              <td>{item.module_name}</td>
                              <td>{moment(item.createdAt).utc().format('DD-MM-YYYY / HH:mm')}</td>
                            </tr>
                          ))}
                          </tbody>
                        </Table>
                        <div className="mt-4">
                          <NavLink to="/activities" className="activities activities-view">
                            View All <i className="fal fa-long-arrow-right" />
                          </NavLink>
                        </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
