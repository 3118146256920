import React, { Component } from "react";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import ListComponent from "../../Company Sub Admin/Pages/board_skills/list";
import "../../Company Sub Admin/Pages/board_skills/board_skills.css";
import config from "../../../config/config.json";
import { authenticationService } from "../../../_services/authentication";
const ProfilePics = "https://res.cloudinary.com/dmklsntsw/image/upload/v1658480882/dummyPic.75a04487_fqfqey.png";
const currentUser = authenticationService.currentUserValue;
export default class boardSkillsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: true,
      items: [],
    };
  }
  componentDidMount() {
    let urlArr = window.location.pathname.split("/");
    const finalUUId = urlArr[urlArr.length - 3];

    const headers = {
      Authorization: `Bearer ${currentUser.data.token}`,
      Accept: "application/json",
    };

    fetch(
      config.ADMIN_API_URL + `company/${finalUUId}/modules/getBoardMembers`,
      {
        headers,
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result.result,
          });
          console.log("Board Skill", result)
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }
  render() {
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="governance">
                        <div className="text_Parts">
                          <div className="back_doll">
                            <div className="Member_chain">
                              <div className="back_mel">
                                <h4 className="critical_h">Members </h4>
                              </div>
                            </div>
                            <div className="new_bel">
                              {this.state.items.map((item3, key3) => (
                                <div key={key3} className="velle">
                                  <div className="text_tnp board_space">
                                    <div className="text_image">
                                      <img
                                        src={
                                          item3.uploadImage === null
                                            ? ProfilePics
                                            : config.BASE_URL +
                                              item3.uploadImage
                                        }
                                        alt=""
                                      />
                                    </div>
                                    <div className="helop_tex helop_text2">
                                      <div className="d-flex justify-content-between">
                                        <h4 className="Willi">{item3.name}</h4>
                                      </div>

                                      <p className="graphic_pri">
                                        {item3.biography}
                                      </p>

                                      <div className="t_matter">
                                        <div className="hoel_text">
                                          <h4 className="matterexperties">
                                            Subject matter expertise:
                                          </h4>
                                          <div className="helop">
                                            <ul className="grapic">
                                              <ListComponent
                                                items={item3.subjectInText.split(
                                                  ","
                                                )}
                                              />
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                      {/* <!--  --> */}
                                      <div className="a_maj">
                                        <div className="hoel_text">
                                          <div className="d-flex justify-content-between">
                                            <h4 className="matterexperties">
                                              Relevant Skills:
                                            </h4>
                                          </div>
                                          <div className="helop">
                                            <ul className="grapic">
                                              <ListComponent
                                                items={item3.relevantSkillsInText.split(
                                                  ","
                                                )}
                                              />
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="placerop">
                                    <div className="text_image_me"></div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
