import React, { useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import {
  Modal,
  Table,
  Button,
  Form,
  Row,
  Col,
  Accordion,
  Spinner,
} from "react-bootstrap";
import countriesData from "../Setting/FinactialYearTab/countries.json";
import Multiselect from "multiselect-react-dropdown";
import { apiCall } from "../../../_services/apiCall";
import config from "../../../config/config.json";
import { useEffect } from "react";
import { SectorQuestionCategory } from "./SectorQuestionView";

const SettingCarbonEmission = () => {
  const multiselectRefTracker = useRef();
  const [titleId, setTitleId] = useState(null);
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [frameworks, setFrameworks] = useState("");
  const [frameworkId, setFrameworkId] = useState(null);
  const [questionList, setQuestionList] = useState();
  const [mode, setMode] = useState("create");
  const [loading, setloading] = useState(false);
  const [title, setTitle] = useState(null);
  const [category, setCategory] = useState([]);
  const [topics, setTopics] = useState([]);
  const [financialYearId, setFinancialYearId] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [selectedQuestionId, setSelectedQuestionId] = useState([]);
  const [sectorQuestion, setSectorQuestion] = useState([]);
  const [selectedQuestionData, setSelectedQuestionData] = useState([]);
  const [categoryByFinancialYearId, setCategoryByFinancialYearId] = useState(
    []
  );
  const [financialYearData, setFinancialYearData] = useState([]);
  const [financialYearDataTab, setFinancialYearDataTab] = useState([]);

  const [selectedFinancialYearData, setSelectedFinancialYearData] = useState(
    []
  );

  const [selectedTopicData, setSelectedTopicData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("Select Country");
  const [selectedCountryQuestion, setSelectedCountryQuestion] = useState("Select Country");
  const [selectedFinancialYearId, setSelectedFinancialYearId] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [showQuestionData, setShowQuestionData] = useState(false);
  const handleCloseQuestionData = () => setShowQuestionData(false);
  const handleShowQuestionData = (fid, questionIds) => {
    setShowQuestionData(true);
    getCategoryQuestion(fid, questionIds);
  };

  const handleShow = (mode, id, title) => {
    if (mode === "edit") {
      setTitleId(id);
      setTitle(title);
    }
    if (mode === "assignFinanialYear") {
      setTitleId(id);
    }

    setMode(mode);
    setShow(true);
  };

  const handleClose = () => {
    setMode("create");
    setTitle(null);
    setShow(false);
  };

  const handelDeleteModalClose = () => {
    setDeleteModal(false);
    setTitleId(null);
  };

  const handleShowAssignSectorQuestions = (fid, cId) => {
    setMode("assignSectorQuestion");
    setShow(true);
    setFinancialYearId(fid);
    setTitleId(null);
    setCategoryId(cId);
    callApi(Number(fid));
  };

  const changeHandler = (e) => {
    setTitle(e.target.value);
  };

  const handleDelete = (id) => {
    setTitleId(id);
    setDeleteModal(true);
  };

  const createNewCategory = async () => {
    let obj = {};
    obj.title = title;
    setloading(true);
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}createEmissions`,
      {},
      obj,
      "POST"
    );
    setloading(false);
    if (isSuccess) {
      getCategory();
      setShow(false);
    }
  };

  const editCategory = async () => {
    let obj = {};
    obj.title = title;
    obj.id = titleId;
    setloading(true);
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}editEmissions`,
      {},
      obj,
      "POST"
    );
    setloading(false);
    setTitle(null);
    if (isSuccess) {
      getCategory();
      setShow(false);
    }
  };

  const handleCountryChange = (e) => { 
    setFinancialYearData([]);
    setSelectedFinancialYearData([]);
    setSelectedTopicData([]);
    setSelectedFinancialYearId([]);
    const countryName = e.target.value;
    setSelectedCountry(countryName);
    const tmpIsoCode = getIsoCode(countryName);
    if (tmpIsoCode) {
      getFinancialYearData(tmpIsoCode);
    }
  };

  const handleChangeCountryByFinancialYear = (e) => {
    const countryName = e.target.value;
    setSelectedCountryQuestion(countryName);
    const tmpIsoCode = getIsoCode(countryName);
    if (tmpIsoCode) {
      getFinancialYearDataTab(tmpIsoCode);
    }
  }

  function getIsoCode(countryName) {
    const selectedCountry = countriesData.find(
      (country) => country.name.toLowerCase() === countryName.toLowerCase()
    );
    if (selectedCountry) {
      return selectedCountry.isoCode;
    } else {
      return "ISO Code not found";
    }
  }

  const assignFinanctialYear = async () => {
    let obj = {};
    obj.id = titleId ? titleId : categoryId;
    obj.financial_year_ids = selectedFinancialYearId;
    obj.framework_ids = [Number(frameworkId)];
    obj.question_mapping_ids = selectedQuestionId;
    const { isSuccess } = await apiCall(
      `${config.ADMIN_API_URL}updateEmissionsAttributes`,
      {},
      obj,
      "POST"
    );
    if (isSuccess) {
      setShow(false);
      getCategoryByFinancialYearId(financialYearId);
      setSelectedFinancialYearData([]);
      setSelectedFinancialYearId([]);
      setSelectedQuestionData([]);
      setSelectedQuestionId([]);
      setSelectedTopicData([]);
      setFrameworkId(null);
    }
  };

  const deleteCategory = async () => {
    let obj = {};
    obj.id = titleId;
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}deleteEmissions`,
      {},
      obj,
      "POST"
    );
    if (isSuccess) {
      handelDeleteModalClose();
      getCategory();
    }
  };

  const getCategory = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}getEmissions`,
      {},
      { type: "ALL" },
      "GET"
    );
    if (isSuccess) {
      setCategory(data?.data?.reverse());
    }
  };

  const getCategoryByFinancialYearId = async (fId) => {
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}getEmissions`,
      {},
      { type: "CUSTOM", financial_year_id: fId },
      "GET"
    );
    if (isSuccess) {
      setCategoryByFinancialYearId(data?.data?.reverse());
    }
  };

  function getFinancialYear(countryName) {
    const selectedCountry = countriesData.find(
      (country) => country.name.toLowerCase() === countryName.toLowerCase()
    );
    if (selectedCountry) {
      return selectedCountry.financialYear;
    } else {
      return "Country not found";
    }
  }

  const getFinancialYearDataTab = async (tmpIsoCode) => {
    const body = {
      iso_code: tmpIsoCode,
    };
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}getAllFinancialYear`,
      {},
      body
    );
    if (isSuccess) {
      setFinancialYearDataTab(data?.data);
    }
  };

  const getFinancialYearData = async (tmpIsoCode) => {
    const body = {
      iso_code: tmpIsoCode,
    };
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}getAllFinancialYear`,
      {},
      body
    );
    if (isSuccess) {
      setFinancialYearData(data?.data);
    }
  };

  const callApi = async (fId) => {
    console.log("fId>>>", fId);
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}getFramework`,
      {},
      { type: "CUSTOM", financial_year_id: fId },
      "GET"
    );
    if (isSuccess) {
      setFrameworks(data.data);
    }
  };
  const handleFrameworkChange = async (fId) => {
    setFrameworkId(fId);
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}getTopic`,
      {},
      { type: "QUESTION", framework_id: fId },
      "GET"
    );
    if (isSuccess) {
      setTopics(data?.data);
    }
  };

  const getFrameworkQuestion = async (topicIds) => {
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}getSectorQuestion`,
      {},
      {
        type: "ALL",
        financial_year_id: financialYearId,
        framework_ids: frameworkId,
        topicIds: topicIds,
        questionnaire_type: "SQ",
      },
      "GET"
    );
    if (isSuccess) {
      setQuestionList(data?.data);
    }
  };

  const onSelectHandler = (data, type) => {
    const selectedIds =
      type === "QUESTION_ID"
        ? data && data.map(({ question_id }) => question_id)
        : data && data.map(({ id }) => id);
    if (type === "FINANCIAL_YEAR_ID") {
      setSelectedFinancialYearData(data);
      setSelectedFinancialYearId(selectedIds || []);
    } else if (type === "TOPIC_ID") {
      setSelectedTopicData(data);
      getFrameworkQuestion(selectedIds);
    } else if (type === "QUESTION_ID") {
      setSelectedQuestionData(data);
      setSelectedQuestionId(selectedIds || []);
    }
  };

  const onRemoveHandler = (data, type) => {
    if (data && data.length === 0) {
      if (type === "FINANCIAL_YEAR_ID") {
        setSelectedFinancialYearId([]);
      } else if (type === "TOPIC_ID") {
      } else if (type === "QUESTION_ID") {
        setSelectedQuestionId([]);
      }
    } else {
      onSelectHandler(data, type);
    }
  };

  const handleKey = async (fId) => {
    setFrameworks([]);
    setCategoryByFinancialYearId([]);
    getCategoryByFinancialYearId(fId);
  };

  const getCategoryQuestion = async (fId, questionIds) => {
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}getSectorQuestion`,
      {},
      {
        type: "ALL",
        financial_year_id: fId,
        question_Ids: [questionIds],
        questionnaire_type: "SQ",
      },
      "GET"
    );
    if (isSuccess) {
      setSectorQuestion(data?.data);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  return (
    <div>
      <AdminSidebar dataFromParent={location.pathname} />
      <AdminHeader />
      <div className="main_wrapper">
        <div className="inner_wraapper">
          <div className="steps-form">
            <div className="steps-row setup-panel">
              <div className="tabs-top setting_admin my-0">
                <ul>
                  <li>
                    <NavLink to="/settings"> My Profile </NavLink>
                  </li>
                  <li>
                    <NavLink to="/settings/billing"> Billing </NavLink>
                  </li>
                  <li>
                    <NavLink to="/settings/access_management"> Access </NavLink>
                  </li>
                  <li>
                    <NavLink to="/settings/financial_year">
                      Financial Year
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/settings/sub-users">Sub Users</NavLink>
                  </li>
                  <li>
                    <NavLink to="/settings/category">
                      Category
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/settings/carbon_emission" className="activee">
                      Carbon Emission
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/settings/unit" >
                      Unit
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="hstack gap-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Carbon Emissions Name"
                  name="search"
                  onChange={(e) => this.applyGlobalSearch(e)}
                />
                <Button
                  variant="info"
                  className="mx-2"
                  onClick={() => handleShow("create")}
                >
                  Create Carbon Emissions
                </Button>
              </div>
            </div>
          </div>
          <div className="color_div_on framwork_2 pt-0">
            <div className="business_detail">
              <div className="table-responsive" style={{ overflowX: "hidden" }}>
                <Row>
                  <Col md={4} style={{ borderRight: "1px dashed #c4c4c4" }}>
                    <div className="table___height">
                      <Table
                        striped
                        bordered
                        className="m-0"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <thead>
                          <tr style={{ background: "#ccc" }}>
                            <th style={{ width: 55 }}>Sr. No</th>
                            <th>Carbon Emissions Name</th>
                            <th className="text-center" style={{ width: 55 }}>
                              Action
                            </th>
                          </tr>
                        </thead>
                        {category.length > 0 ? (
                          <tbody>
                            {category &&
                              category?.map((item, key) => (
                                <tr key={key}>
                                  <td>{key + 1}</td>
                                  <td>{item.title} </td>
                                  <td className="Financial__add_plus hstack gap-3 justify-content-center">
                                    <i
                                      className="fas fa-plus-square"
                                      title="Assign Financial Year"
                                      onClick={() =>
                                        handleShow(
                                          "assignFinanialYear",
                                          item?.id
                                        )
                                      }
                                    ></i>
                                    {item?.is_editable && (
                                      <>
                                        <i
                                          class="fas fa-edit"
                                          title="Edit Carbon Emissions"
                                          onClick={() =>
                                            handleShow(
                                              "edit",
                                              item?.id,
                                              item?.title
                                            )
                                          }
                                        ></i>
                                        <i
                                          class="fas fa-trash-alt "
                                          title="Delete Carbon Emissions"
                                          style={{ color: "red" }}
                                          onClick={() => handleDelete(item?.id)}
                                        ></i>
                                      </>
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td colSpan={3} className="text-center">
                                No Data Found
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </Table>
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className="country___level">
                      <Form.Group
                        className="mb-3 d-flex align-items-center p-2"
                        controlId="formStatusType"
                      >
                        <Form.Label className="me-3 m-0">
                          Select Country :
                        </Form.Label>
                        <Form.Control
                          as="select"
                          className="w-50"
                          value={selectedCountry}
                          onChange={handleCountryChange}
                        >
                          <option>Select Country</option>
                          {countriesData &&
                            countriesData.map((country, index) => (
                              <option key={index}>{country.name}</option>
                            ))}
                        </Form.Control>
                      </Form.Group>
                    </div>
                    <div
                      className="access__group__section mb-2"
                      style={{ height: "calc(100vh - 138px)" }}
                    >
                      {/* -------------------- User Access management  ---------------------- */}
                      <div className="access__section">
                        <Accordion>
                          {financialYearData.map((financialYear, index) => (
                            <Accordion.Item eventKey={index}>
                              <Accordion.Header
                                onClick={() => handleKey(financialYear?.id)}
                              >
                                <p className="m-0">
                                  {financialYear.financial_year_value}
                                </p>
                              </Accordion.Header>
                              {categoryByFinancialYearId &&
                              categoryByFinancialYearId?.length > 0 ? (
                                <Accordion.Body className="border_box">
                                  <Row className="px-3">
                                    <Col md={12}>
                                      <div className="table__sequence">
                                        {categoryByFinancialYearId &&
                                          categoryByFinancialYearId?.map(
                                            (item) => (
                                              <div className="question__add_plus hstack justify-content-between p-1">
                                                <p className="m-0">
                                                  {item.title}
                                                </p>
                                                <div>
                                                  <i
                                                    className="fas fa-eye"
                                                    title="View Questions"
                                                    onClick={() =>
                                                      handleShowQuestionData(
                                                        financialYear?.id,
                                                        item?.question_mapping_ids
                                                      )
                                                    }
                                                  ></i>
                                                  <i
                                                    className="fas fa-plus-square"
                                                    title="Assign Questions"
                                                    onClick={() =>
                                                      handleShowAssignSectorQuestions(
                                                        financialYear?.id,
                                                        item?.id
                                                      )
                                                    }
                                                  ></i>
                                                </div>
                                              </div>
                                            )
                                          )}
                                      </div>
                                    </Col>
                                  </Row>
                                </Accordion.Body>
                              ) : (
                                <Accordion.Body className="border_box">
                                  <Row className="px-3">
                                    <Col md={12}>
                                      <div className="table__sequence">
                                        <div className="question__add_plus hstack justify-content-between p-1">
                                          <p className="m-0">
                                            No Carbon Emissions Fields
                                          </p>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </Accordion.Body>
                              )}
                            </Accordion.Item>
                          ))}
                        </Accordion>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {mode === "create"
              ? "Create Carbon Emissions"
              : mode === "edit"
              ? "Edit Carbon Emissions"
              : mode === "assignFinanialYear"
              ? "Assign Financial Year"
              : "Add Sector Questions"}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(mode === "edit" || mode === "create") && (
            <div className="mb-3">
              <input
                type="text"
                className="form-control py-2"
                value={title}
                onChange={changeHandler}
              />
            </div>
          )}
          {mode === "assignFinanialYear" && (
            <>
              <Form.Group className="mb-3" controlId="formStatusType">
                <Form.Label>Select Country</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedCountryQuestion}
                  onChange={handleChangeCountryByFinancialYear}
                >
                  <option>Select Country</option>
                  {countriesData &&
                    countriesData.map((country, index) => (
                      <option key={index}>{country.name}</option>
                    ))}
                </Form.Control>
              </Form.Group>
              {financialYearDataTab && financialYearDataTab?.length > 0 && (
                <Form.Group className="mb-3" controlId="formStatusType">
                  <Form.Label>Select Financial Year</Form.Label>
                  <Multiselect
                    placeholder="Select Status Type"
                    displayValue="financial_year_value"
                    className="multi_select_dropdown w-100"
                    selectedValues={selectedFinancialYearData}
                    options={financialYearDataTab}
                    ref={multiselectRefTracker}
                    onRemove={(removedItem) => {
                      onRemoveHandler(removedItem, "FINANCIAL_YEAR_ID");
                    }}
                    onSelect={(selectedItems) => {
                      onSelectHandler(selectedItems, "FINANCIAL_YEAR_ID");
                    }}
                  />
                </Form.Group>
              )}
            </>
          )}

          {mode === "assignSectorQuestion" && (
            <>
              {frameworks && frameworks.length ? (
                <select
                  className="mb-3 py-2 form-control"
                  onChange={(e) => handleFrameworkChange(e.target.value)}
                  required
                >
                  <option value="">Select Framework</option>
                  {frameworks &&
                    frameworks.map((item, key) => (
                      <option key={key} value={item.id}>
                        {item.title}
                      </option>
                    ))}
                </select>
              ) : (
                "No Framework here for this financial year."
              )}
              {topics && topics?.length > 0 && (
                <Form.Group className="mb-3" controlId="formStatusType">
                  <Form.Label>Select Topics</Form.Label>
                  <Multiselect
                    placeholder="Select Status Type"
                    displayValue="title"
                    className="multi_select_dropdown w-100"
                    selectedValues={selectedTopicData}
                    options={topics}
                    ref={multiselectRefTracker}
                    onRemove={(removedItem) => {
                      onRemoveHandler(removedItem, "TOPIC_ID");
                    }}
                    onSelect={(selectedItems) => {
                      onSelectHandler(selectedItems, "TOPIC_ID");
                    }}
                  />
                </Form.Group>
              )}
              {questionList && questionList?.length > 0 && (
                <Form.Group className="mb-3" controlId="formStatusType">
                  <Form.Label>Select Questions</Form.Label>
                  <Multiselect
                    placeholder="Select Status Type"
                    displayValue="title"
                    className="multi_select_dropdown w-100"
                    selectedValues={selectedQuestionData}
                    options={questionList}
                    ref={multiselectRefTracker}
                    onRemove={(removedItem) => {
                      onRemoveHandler(removedItem, "QUESTION_ID");
                    }}
                    onSelect={(selectedItems) => {
                      onSelectHandler(selectedItems, "QUESTION_ID");
                    }}
                  />
                </Form.Group>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {loading ? (
            <Button variant="info" disabled>
              <Spinner animation="border" /> Save
            </Button>
          ) : (
            <Button
              variant="info"
              onClick={() =>
                mode === "create"
                  ? createNewCategory()
                  : mode === "edit"
                  ? editCategory()
                  : assignFinanctialYear()
              }
            >
              Save
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      {deleteModal && (
        <Modal show={deleteModal} onHide={handelDeleteModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure to delete ?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handelDeleteModalClose}>
              No
            </Button>
            <Button variant="primary" onClick={deleteCategory}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <Modal
        show={showQuestionData}
        onHide={handleCloseQuestionData}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Question List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SectorQuestionCategory sectorQuestion={sectorQuestion} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SettingCarbonEmission;


