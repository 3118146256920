/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { authenticationService } from "../../_services/authentication";
import { history } from "../../_helpers/history";
import { Link } from "react-router-dom";
import config from "../../config/config.json";
import "./header.css";
const ProfilePics = "https://res.cloudinary.com/dmklsntsw/image/upload/v1658480882/dummyPic.75a04487_fqfqey.png"
export default class header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      usersData: [],
      name: "",
      profilePic: null,
    };
    this.goToPreviousPath = this.goToPreviousPath.bind(this);
  }
  goToPreviousPath() {
    window.history.back();
  }

  logout() {
    authenticationService.logout();
    history.push("/");
  }
  // getProfileData

  componentDidMount() {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser === null) {
      window.location.href = "/";
    } else {
      if (
        currentUser.data.role === "super_admin" ||
        currentUser.data.role === "sub_admin"
      ) {
        // window.location.href = "/";
      }
    }

    const headers = {
      Authorization: `Bearer ${currentUser.data?.token}`,
      Accept: "application/json",
    };

    // fetch(`${config.API_URL}api/v1/getProfileData`, { headers })
    //   .then((res) => res.json())
    //   .then(
    //     (result) => {
    //       localStorage.setItem(
    //         "user_temp_id",
    //         JSON.stringify(result.user[0]?.id)
    //       );
    //       localStorage.setItem(
    //         "user_temp_email",
    //         result.user[0]?.email
    //       );
    //       this.setState({
    //         isLoaded: true,
    //         usersData: result.user,
    //         name: result.user[0]?.first_name + " " + result.user[0]?.last_name,
    //         profilePic:
    //           result.user[0]?.logo === null
    //             ? ProfilePics
    //             : config.BASE_URL + result.user[0]?.logo,
    //       });
    //     },
    //     (error) => {
    //       this.setState({
    //         isLoaded: true,
    //         error,
    //       });
    //     }
    //   );
  }

  render() {
    const { name, profilePic } = this.state;
    let fullUrl = window.location.href.split("/");
    let urr = "";
    if (fullUrl[3] !== "user_detail") {
      urr = fullUrl.pop().toUpperCase();
    } else {
      urr = fullUrl[3];
    }
    let finalResult = urr.replaceAll("_", " ");
    if (finalResult === "SUPPLIER FAST") {
      finalResult = "SUPPLIER";
    }
    if (finalResult === "SUSTAINABLE") {
      finalResult = "SUSTAINABLE DEVELOPMENT GOALS";
    }

    return (
      <div>
        <div className="d-flex" id="wrapper">
          {/* <!-- Page content wrapper--> */}
          <div id="page-content-wrapper">
            {/* <!-- Top navigation--> */}
            <nav className="navbar navbar-expand-lg  border-bottom navclassName background">
              <div className="container-fluid">
                {/* <!--  <button className="desh" id="sidebarToggle">Deshboard</button> --> */}
                <div className="deahbord" id="sidebarToggle">
                  <h4 className="back_quninti back_quninti_2">
                    <a className="back_text">
                      <span className="step_icon">
                        <i
                          onClick={this.goToPreviousPath}
                          className="far fa-long-arrow-left"
                        ></i>
                      </span>
                      {finalResult}
                    </a>
                  </h4>
                </div>

                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
                    <li className="nav-item">
                      <div className="form-group has-search one">
                        {/* <span className="fa fa-search form-control-feedback"></span> */}
                        {/* <input type="text" className="form-control" placeholder="Search..."/> */}
                      </div>
                    </li>
                    <li className="nav-item dropdown text_down">
                      <div className="image_round">
                        <img
                          src={profilePic}
                          alt="Ban"
                          className="image--coverq"
                        />
                      </div>
                      <a
                        className="nav-link home_drop"
                        id="navbarDropdown"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {name}
                        <i
                          className="fa fa-caret-down elly"
                          aria-hidden="true"
                        ></i>
                        <p className="text_p">Business Owner</p>
                      </a>

                      <div
                        className="dropdown-menu dropdown-menu-end dropdown_menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <Link to="/settings" className="dropdown-item">
                          <i className="fa fa-user"></i>
                          <span>My Profile</span>
                        </Link>
                        <Link to="/Google2FA" className="dropdown-item">
                          <i className="fa fa-lock"></i>
                          <span>Two Factor Auth</span>
                        </Link>
                        <Link to="/Setting_Billing" className="dropdown-item">
                          <i className="fa fa-dollar-sign"></i>
                          <span>Subscription</span>
                        </Link>
                        <Link to="/Setting_Sub_Admin" className="dropdown-item">
                          <i className="fa fa-users"></i>
                          <span>Sub Admins</span>
                        </Link>
                        <Link
                          onClick={this.logout}
                          to="/"
                          className="dropdown-item"
                        >
                          <i className="fa fa-sign-out pull-right"></i>
                          <span>Logout</span>
                        </Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
            {/* <!-- Page content--> */}
          </div>
          {/* <!-- main --> */}
        </div>
      </div>
    );
  }
}
