/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import "react-datepicker/dist/react-datepicker.css";
import "../../Company Sub Admin/Pages/supplier_management/supplier_management.css";
import { NavLink } from "react-router-dom";

export default class supplierManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
 
  }

  render() {
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="color_div_on framwork_2">
                        <h5 className="frame">
                          Please select Framework you want to use:
                        </h5>
                        <div className="Global">
                          <div className="row">
                            <div className="col-sm-12 col-xs-12">
                              <div className="border_box p-3">
                                <div className="wel_fel">
                                  <div className="row">
                                    <div className="col-xxl-6 col-lg-6 col-md-12 col-12" >
                                      <div className="Global_text"  >
                                        <div className="form-check form-check-inline clobal_check input-padding">
                                          <input className="form-check-input input_one " name="frameworksUsed[]" type="checkbox" />
                                          <label className="form-check-label label_one" htmlFor={"xcfgvldskfjgosdfg"}> First Framework </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-xxl-6 col-lg-6 col-md-12 col-12" >
                                      <div className="Global_text"  >
                                        <div className="form-check form-check-inline clobal_check input-padding">
                                          <input className="form-check-input input_one " name="frameworksUsed[]" type="checkbox" />
                                          <label className="form-check-label label_one" htmlFor={"xcfgvldskfjgosdfg"}> Second Framework </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-xxl-6 col-lg-6 col-md-12 col-12" >
                                      <div className="Global_text"  >
                                        <div className="form-check form-check-inline clobal_check input-padding">
                                          <input className="form-check-input input_one " name="frameworksUsed[]" type="checkbox" />
                                          <label className="form-check-label label_one" htmlFor={"xcfgvldskfjgosdfg"}> Third Framework </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-xxl-6 col-lg-6 col-md-12 col-12" >
                                      <div className="Global_text"  >
                                        <div className="form-check form-check-inline clobal_check input-padding">
                                          <input className="form-check-input input_one " name="frameworksUsed[]" type="checkbox" />
                                          <label className="form-check-label label_one" htmlFor={"xcfgvldskfjgosdfg"}> Fourth Framework </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="variants">
                            <div className='file file--upload'>
                              <label htmlFor='input-file'>
                                <i className="fa fa-upload" aria-hidden="true"></i>Upload Report
                              </label>
                              <input type="file" id='input-file' accept='.pdf, .doc, .docx, .xlsx, .pptx, .ppt, .ott, .odp'  name=""  onChange={this.onFileChange} />
                            </div>
                            <NavLink className="outputFile" target="_blank" to={""} >
                              Preview
                            </NavLink>
                            <button className="next_page_one mx-3" type="button" onClick={this.exportCSV} >
                              <span className="Download_r">
                                <i className="fa fa-download"></i>
                              </span>
                              Download Supplier List
                            </button>
                            <NavLink className="page_width page_save" to={"/companies/:uuid/modules/supplier_management_detail"} > View Supplier List </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-sm-12">
                      <div className="color_div_on framwork_2 mt-3">
                        <div className="business_detail">
                          <div className="saved_cards">
                            <div className="business_detail">
                              <div className="heading justify-content-start">
                                <h5 className="frame">
                                  Which of the following criteria would you like
                                  to assess?
                                </h5>
                              </div>
                              <div className="checkbox5">
                                <div className="form-group clobal_check ">
                                  <div className="form-div2">
                                    <div className="row">
                                      {this.state.criteria.map((item, key) => (
                                        <div className="form-check my-3 col-md-6">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={item.status}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="flexCheckDefault"
                                          >
                                            {item.title}
                                          </label>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                  <div className="text-end">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
