import React, { useEffect, useState } from "react";
import axios from "axios";
import { Dropdown, Form, Modal, Button, NavLink } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { apiCall } from "../../../_services/apiCall";
import { authenticationService } from "../../../_services/authentication";
import config from "../../../config/config.json";
import "../../Sector_Question_Manage/control.css";
import CreateSectorQuestionModal from "./CreateSectionModal";
import CreateTriggerQuestion from "./CreateTriggerQuestion";
import AddFormTrigger from "./AddFormTrigger";

const TriggerQuestion = ({
  frameworkData,
  handleKPIChangeHandler,
  handleAddTopicQuestionChangeHandler,
}) => {
  const [topics, setTopics] = useState();
  const [addTopic, setAddTopic] = useState(false);
  const [token] = useState(JSON.parse(localStorage.getItem("currentUser")));
  const [sectorQuestion, setSectorQuestion] = useState([]);

  const [prevFormula, setPrevFormula] = useState(false);

  const [popupVisible, setPopupVisible] = useState(false);
  const [currentFormula, setCurrentFormula] = useState("");
  const [editableQuestionId, setEditableQuestionId] = useState("");
  const [editableQuestionType, setEditableQuestionType] = useState("");
  const [editableQuestionDetails, setEditableQuestionDetails] = useState("");
  const [currentTabularFormula, setCurrentTabularFormula] = useState([]);

  const [editModalShow, setEditModalShow] = useState(false);
  const handleCellChange = (event, rowIndex, columnIndex) => {
    const value = event.target.value;
    setCurrentTabularFormula((prevData) => {
      const newData = prevData.map((row) => [...row]);
      if (newData[rowIndex]) {
        newData[rowIndex][columnIndex] = value;
      }
      return newData;
    });
  };

  const handleEditUpdateFormula = async () => {
    const body = {
      formula:
        editableQuestionType === "tabular_question"
          ? currentTabularFormula
          : currentFormula,
      question_id: editableQuestionId,
      question_type: editableQuestionType,
    };
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}addFormulaForTrigger
 `,
      {},
      body,
      "POST"
    );
    if (isSuccess) {
      setEditModalShow(false);
      setEditableQuestionId("");
      setCurrentFormula("");
      getTriggerQuestion()
    }
  };

  const handleClose = () => setEditModalShow(false);
  const handleClosePopup = () => {
    setPopupVisible(false);
    setSelectedQuestion(null);
  };

  const [selectedQuestion, setSelectedQuestion] = useState();
  const closeAddTopicPopup = () => setAddTopic(false);
  const handleOpenPopup = (question) => {
    console.log('question',question)
    setSelectedQuestion(question);
    setPopupVisible(true);
  };

  const showAddTopicPopup = () => setAddTopic(true);

  const handleEditFormula = async (
    item,
    formula,
    question_id,
    question_type,
    question_detail
  ) => {
    setPrevFormula(formula);
    setCurrentFormula(formula);
    setEditableQuestionId(question_id);
    setEditableQuestionType(question_type);
    setEditableQuestionDetails(question_detail);
    // const [token] = useState(JSON.parse(localStorage.getItem("currentUser")));

    const numRows = question_detail.filter(
      (item) => item.optionType === "row"
    ).length;

    const numCols = question_detail.filter(
      (item) => item.optionType === "column"
    ).length;
    const initialData = Array.from({ length: numRows }, () =>
      Array.from({ length: numCols }, () => "")
    );

    setCurrentTabularFormula(initialData);
    setEditModalShow(true);
  };

  const handleEditQuestion = (item) => {
    console.log("item", item);
  };

  const deleteTriggerQuestion = async (item) => {
    console.log(item);
    let obj = {};
    obj.id = item?.questionId;
    const { isSuccess, data } = await apiCall(
      `${config.ADMIN_API_URL}deleteTriggerQuestion
 `,
      {},
      obj,
      "POST"
    );

    getTriggerQuestion();
  };

  const getTriggerQuestion = async (id) => {
    if (frameworkData?.id) {
      const { isSuccess, data } = await apiCall(
        `${config.ADMIN_API_URL}getTriggerQuestion`,
        {},
        { module_ids: [frameworkData?.id] },
        "GET"
      );
      setTopics(data?.data?.reverse());
      console.log("question", data?.data);
    }
  };



  useEffect(() => {
    const currentUser = authenticationService?.currentUserSubject?.getValue();
    const settingsMenu = currentUser?.data?.menu?.find(
      (item) => item?.url === "global_controls"
    );
    // setMenuList(settingsMenu?.permissions);
    if (frameworkData.id) {
      getTriggerQuestion(frameworkData.id);
    }
  }, [frameworkData.id]);
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <div className="heading">
          <h4 className="m-0">{frameworkData.title}</h4>
          <span className="global_link mx-0">
            <>
              <div
                onClick={showAddTopicPopup}
                className="mx-3 add_framework_control"
              >
                <i className="fas fa-plus-square fs-lg" title="Add Topic"></i>
                &nbsp;&nbsp;
              </div>
            </>
          </span>
        </div>
        <div>
          <input
            type="search"
            className="form-control w-100"
            placeholder="Search Topics"
            name="search"
          />
        </div>
      </div>
      <div className="table_f border-top mt-2 auto_scroll_by_design">
        <Table striped bordered className="mt-2 mb-0">
          <thead>
            <tr className="heading_color sticky_header">
              <th style={{ width: 50 }}>Sr.</th>
              <th>Question Title</th>
     
              <th>Add Formula</th>
              <th>Formula</th>
              <th>Edit Formula</th>
              <th style={{ width: 50 }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {topics?.map((item, key) => (
              <tr key={key}>
                <td>{key + 1}</td>
                <td>{item.title}</td>
         
                <td>
                  {!item.formula  ? (
                    <Button onClick={() => handleOpenPopup(item)}>+</Button>
                  ) : (
                    "N/A"
                  )}
                </td>
                <td>
                  {item.questionType === "tabular_question"
                    ? item?.formula && item?.formula.replace(/\\/g, "")
                    : item?.formula}
                </td>
                <td>
                  {item?.formula && (
                    <div
                      className="red"
                      onClick={(e) =>
                        handleEditFormula(
                          item,
                          item?.formula,
                          item?.questionId,
                          item?.questionType,
                          item?.details
                        )
                      }
                    >
                      <NavLink to="#" className="view_c">
                        <i
                          className="fas fa-edit"
                          title="Edit"
                          style={{ color: "green" }}
                        ></i>
                      </NavLink>
                    </div>
                  )}
                </td>
                <td className="text-center">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic"></Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={(e) => handleEditQuestion(item)}>
                        <i className="fas fa-plus-square"></i>&nbsp;&nbsp; Edit
                        Question
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={(e) => deleteTriggerQuestion(item)}
                      >
                        <i className="fas fa-eye"></i>&nbsp;&nbsp; Delete
                        Question
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {/* ------------ Update Topic --------- */}
      {/* <Modal show={upadteTopic} onHide={closeUpdateFrameworkPopup}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Topic</Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <button
            className="link_bal_next_cancel"
            onClick={closeUpdateFrameworkPopup}
          >
            Cancel
          </button>
          <button className="link_bal_next" type="submit">
            Update
          </button>
        </Modal.Footer>
      </Modal> */}
      <Modal show={addTopic} onHide={closeAddTopicPopup} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <Form.Label>Add Question</Form.Label>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <CreateTriggerQuestion
              module={frameworkData}
              getTriggerQuestion={getTriggerQuestion}
              closeAddTopicPopup={closeAddTopicPopup}
              // financial_year_id={financial_year_id}
            />
          </div>
        </Modal.Body>
        <Modal.Footer style={{ height: 65 }}> </Modal.Footer>
      </Modal>
      <Modal fullscreen={true} show={editModalShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Formula</Modal.Title>
        </Modal.Header>

        {editableQuestionType && editableQuestionType === "tabular_question" ? (
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                style={{ overflow: "auto" }}
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Previous Formula</Form.Label>
                <Table striped bordered hover style={{ textWrap: "nowrap" }}>
                  <thead>
                    <tr>
                      <th></th>
                      {editableQuestionDetails &&
                        editableQuestionDetails
                          .filter(function (item) {
                            return item.optionType === "column";
                          })
                          .map((col, ind) => <th key={ind}>{col.option}</th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {editableQuestionDetails &&
                      editableQuestionDetails
                        .filter(function (item) {
                          return item.optionType === "row";
                        })
                        .map((prow, indRow) => (
                          <tr key={indRow}>
                            <td className="input-for-formula">{prow.option}</td>
                            {JSON.parse(prevFormula)[indRow]?.map(
                              (cell, colIndex) => (
                                <td key={colIndex}>
                                  <input
                                    value={cell}
                                    type="text"
                                    className="input-for-formula"
                                    readOnly
                                  />
                                </td>
                              )
                            )}
                          </tr>
                        ))}
                  </tbody>
                </Table>
              </Form.Group>
              <Form.Group
                className="mb-3"
                style={{ overflow: "auto" }}
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Current Formula</Form.Label>
                <table className="table table-striped bordered hover">
                  <thead>
                    <tr>
                      <th></th>
                      {editableQuestionDetails
                        .filter((item) => item.optionType === "column")
                        .map((col, colIndex) => (
                          <th key={colIndex}>{col.option}</th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {editableQuestionDetails
                      .filter((item) => item.optionType === "row")
                      .map((prow, rowIndex) => (
                        <tr key={rowIndex}>
                          <td className="input-for-formula">{prow.option}</td>
                          {Array.from({
                            length: editableQuestionDetails.filter(
                              (item) => item.optionType === "column"
                            ).length,
                          }).map((_, colIndex) => (
                            <td key={colIndex}>
                              <input
                                key={colIndex}
                                value={
                                  currentTabularFormula[rowIndex]?.[colIndex] ||
                                  ""
                                }
                                type="text"
                                className="input-for-formula"
                                onChange={(event) =>
                                  handleCellChange(event, rowIndex, colIndex)
                                }
                              />
                            </td>
                          ))}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </Form.Group>
            </Form>
          </Modal.Body>
        ) : (
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Previous Formula</Form.Label>
                <Form.Control value={prevFormula} />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Current Formula</Form.Label>
                <Form.Control
                  autoFocus
                  type="text"
                  placeholder="Enter current formula"
                  required
                  onChange={(e) => setCurrentFormula(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
        )}

        <Modal.Footer>
          <Button variant="primary" onClick={handleEditUpdateFormula}>
            Update Formula
          </Button>
        </Modal.Footer>
      </Modal>
      <AddFormTrigger

      getTriggerQuestion={getTriggerQuestion}
    
        popupVisible={popupVisible}
        selectedQuestion={selectedQuestion}
        handleClosePopup={handleClosePopup}
        sectorQuestion={sectorQuestion}
      />

      {/* <QuestionModal show={addTopic} onHide={closeAddTopicPopup} handleSubmit={handleSubmit} setTitle={setTitle} setTopicType={setTopicType} /> */}
    </div>
  );
};

export default TriggerQuestion;
