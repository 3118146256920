import React, { Component } from "react";
import AdminSidebar from "../../sidebar/admin_sidebar";
import AdminHeader from "../../header/admin_header";
import Table from "react-bootstrap/Table";
import { Button, Modal } from "react-bootstrap";
import { authenticationService } from "../../../_services/authentication";
import { subAdminService } from "../../../_services/admin/subAdminService";

import "../companies/companies.css";
import { Pagination, Icon } from "semantic-ui-react";
import config from "../../../config/config.json";
const currentUser = authenticationService.currentUserValue;
export default class StatusDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: false,
      country: false,
      industry: false,
      category: false,
      activeModal: "",
      showModal: false,
      items: [],
      loading: false,
      totalRows: 0,
      currentPage: 1,
      skip: 0,
      startDate: "",
      endDate: "",
      limit: 10,
      countryFilter: "",
      industryFilter: "",
      categoryFilter: "",
      orderByName: "id",
      orderByValue: "DESC",
      search: "",
      pageCount: 0,
      totalData: 0,
      countriesList: [],
      industryList: [],
      checkedArray: [],
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.pageChange = this.pageChange.bind(this);
    this.serverRequest = this.serverRequest.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.applySorting = this.applySorting.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.getCheckedIds = this.getCheckedIds.bind(this);
    this.arrayRemove = this.arrayRemove.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.goToPreviousPath = this.goToPreviousPath.bind(this);
  }

  getCheckedIds(e) {
    let checked = e.target.checked;
    let checkedArray = this.state.checkedArray;
    let Id = e.target.getAttribute("data-id");
    if (checked === false) {
      let removeArray = this.arrayRemove(checkedArray, Id);
      this.setState({
        checkedArray: removeArray,
      });
    } else {
      checkedArray.push(Id);
    }
  }

  handleBack() {
    this.goToPreviousPath();
  }

  goToPreviousPath() {
    this.props.history.goBack();
  }

  async handleSubmit(event) {
    event.preventDefault();
    let arr = window.location.href.split("/")
    let uuid = arr[arr.length - 2];
    let string = "";
    let ii = 1;
    this.state.checkedArray.forEach(element => {
      if(this.state.checkedArray.length === ii){
        string += element
      }else{
        string += element+","
      }
      ii++;
    });
    let obj = {};
    obj.company_id = string;
    obj.uuid = uuid;
    let response = await subAdminService.assignCompanies(obj);
    if(response.statusCode === 200){
      setTimeout(() => {
        this.serverRequest()
      }, 2000);
    }
  }

  arrayRemove(arr, value) {
    return arr.filter(function (ele) {
      return ele !== value;
    });
  }

  handleOpenModal(val) {
    this.setState({ activeModal: val });
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
    this.setState({ showModal: "" });
  }

  applyFilter(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState({
      [name]: value,
    });
  }

  applyGlobalSearch(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState({
      [name]: value,
    });
    setTimeout(() => {
      this.serverRequest();
    }, 200);
  }

  applySorting(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState({
      orderByName: name,
      orderByValue: value,
    });
  }

  clearFilter(e) {
    let name = e.target.name;
    this.setState({
      [name]: "",
      orderByName: "id",
      orderByValue: "DESC",
    });
    setTimeout(() => {
      this.serverRequest();
    }, 500);
  }

  serverRequest() {
    const {
      skip,
      limit,
      countryFilter,
      industryFilter,
      categoryFilter,
      orderByName,
      orderByValue,
      search,
      showModal,
    } = this.state;
    let uuid = window.location.href.split('/')
    if (showModal === true) {
      this.setState({
        showModal: false,
      });
    }
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUser.data.token}`,
        "Content-Type": "application/json",
      },
    };
    let parms = `sub-admins/${uuid[4]}/companies/excluded/?offset=${skip}&limit=${limit}&country=${countryFilter}&industry=${industryFilter}&category=${categoryFilter}&orderByName=${orderByName}&orderByValue=${orderByValue}&search=${search}`;
    fetch(config.ADMIN_API_URL + parms, requestOptions)
      .then((res) => res.json())
      .then(
        (data) => {
          this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            totalData: data.data.count,
            items: data.data.rows,
          });
        },
        (error) => {}
      );
  }

  pageChange(e, data) {
    console.log("Handle Click", data);
    let page = data.activePage;
    let pageNo = page === 1 ? 0 : (page - 1) * this.state.limit;
    console.log("clicked", pageNo);
    this.setState({
      skip: pageNo,
    });
    this.serverRequest();
    // console.log("order", orders);
  }

  componentDidMount() {
    this.setState({ items: [] });
    this.serverRequest();
    fetch(config.API_URL + "getAllCountries")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            countriesList: result.countries,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
    fetch(config.API_URL + "getCompanyIndustoryName")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            industryList: result.companyIndustory,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  render() {
    const { items, countriesList, industryList } = this.state;
    return (
      <div>
        <AdminSidebar dataFromParent={this.props.location.pathname} />
        <AdminHeader />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="col-sm-12">
                        <div className="color_div_on framwork_2">
                          <div className="business_detail">
                            <div className="saved_cards">
                              <div className="business_detail">
                                <div className="heading d-flex justify-content-between">
                                  <h5>
                                    {/* Please select companies to be assigned under
                                    the sub admin.
                                    <span className="color-blue mx-3">
                                      (11 Selected out of 150)
                                    </span> */}
                                  </h5>
                                  <div className="form-group has-search one">
                                    <span className="fa fa-search form-control-feedback"></span>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Search..."
                                      name="search"
                                      onChange={(e) =>
                                        this.applyGlobalSearch(e)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="table_f table-responsive mt-3">
                                <Table striped bordered hover size="sm">
                                  <thead>
                                    <tr className="heading_color">
                                      <th>ID</th>
                                      <th>COMPANY NAME</th>
                                      <th>
                                        COUNTRY
                                        <span>
                                          <i
                                            className="fad fa-sort-amount-up mx-3"
                                            variant="none"
                                            onClick={() =>
                                              this.handleOpenModal("country")
                                            }
                                          ></i>
                                        </span>
                                      </th>
                                      <th>BUSINESS NUMBER</th>
                                      <th>
                                        INDUSTRY
                                        <span>
                                          <i
                                            className="fad fa-sort-amount-up mx-3"
                                            variant="none"
                                            onClick={() =>
                                              this.handleOpenModal("industry")
                                            }
                                          ></i>
                                        </span>
                                      </th>
                                      <th>
                                        CATEGORY
                                        <span>
                                          <i
                                            className="fad fa-sort-amount-up mx-3"
                                            variant="none"
                                            onClick={() =>
                                              this.handleOpenModal("category")
                                            }
                                          ></i>
                                        </span>
                                      </th>
                                      <th className="text-center">STATUS</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {items.map((item, key) => (
                                      <tr key={key}>
                                        <td>{key + 1}</td>
                                        <td>{item.register_company_name}</td>
                                        <td>{item.country}</td>
                                        <td>{item.businessNumber}</td>
                                        <td>{item.company_industry}</td>
                                        <td>{item.userCategory}</td>
                                        <td className="text-center">
                                          <div className="clobal_check">
                                            <input
                                              data-id={item.id}
                                              onChange={(e) =>
                                                this.getCheckedIds(e)
                                              }
                                              className="form-check-input"
                                              type="checkbox"
                                            ></input>
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                                <Pagination
                                  className="mx-auto pagination"
                                  defaultActivePage={1}
                                  onPageChange={this.pageChange}
                                  ellipsisItem={{
                                    content: (
                                      <Icon name="ellipsis horizontal" />
                                    ),
                                    icon: true,
                                  }}
                                  firstItem={{
                                    content: <Icon name="angle double left" />,
                                    icon: true,
                                  }}
                                  lastItem={{
                                    content: <Icon name="angle double right" />,
                                    icon: true,
                                  }}
                                  prevItem={{
                                    content: <Icon name="angle left" />,
                                    icon: true,
                                  }}
                                  nextItem={{
                                    content: <Icon name="angle right" />,
                                    icon: true,
                                  }}
                                  totalPages={Math.ceil(
                                    this.state.totalData / this.state.limit
                                  )}
                                />
                                <div className="global_link my-5 d-flex justify-content-center">
                                  <button
                                    onClick={(e) => this.handleBack(e)}
                                    className="link_bal_next"
                                  >
                                    BACK
                                  </button>
                                  <button
                                    onClick={(e) => this.handleSubmit(e)}
                                    className="page_save page_width mx-3"
                                  >
                                    SAVE
                                  </button>
                                </div>
                                <Modal
                                  animation={true}
                                  size="md"
                                  className="modal_box"
                                  shadow-lg="border"
                                  show={
                                    this.state.showModal &&
                                    this.state.activeModal === "industry"
                                  }
                                >
                                  <div className="modal-lg">
                                    <Modal.Header className="pb-0">
                                      <Button
                                        variant="outline-dark"
                                        onClick={this.handleCloseModal}
                                      >
                                        <i className="fa fa-times"></i>
                                      </Button>
                                    </Modal.Header>
                                    <div className="modal-body vekp pt-0">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="pb4">
                                            <div className="py-3">
                                              <div className="form-check-inline">
                                                <label
                                                  className="form-check-label"
                                                  htmlFor="flexCheckChecked"
                                                >
                                                  {" "}
                                                  Sort: A - Z{" "}
                                                </label>
                                                <input
                                                  className="form-check-input mx-3"
                                                  type="radio"
                                                  name="inlineRadioOptions"
                                                  id="inlineRadio1"
                                                  value="option1"
                                                />
                                              </div>
                                              <div className="form-check-inline">
                                                <label
                                                  className="form-check-label"
                                                  htmlFor="flexCheckChecked"
                                                >
                                                  {" "}
                                                  Sort: Z - A{" "}
                                                </label>
                                                <input
                                                  className="form-check-input mx-3"
                                                  type="radio"
                                                  name="inlineRadioOptions"
                                                  id="inlineRadio1"
                                                  value="option1"
                                                />
                                              </div>
                                            </div>

                                            <div className="input-group mb-3">
                                              <select
                                                className="form-control dropdown-toggle"
                                                name="industryFilter"
                                                onChange={(e) =>
                                                  this.applyFilter(e)
                                                }
                                              >
                                                <option value="">
                                                  Select Industry
                                                </option>

                                                {industryList.map((item2) => (
                                                  <option
                                                    key={item2.id}
                                                    value={item2.title}
                                                  >
                                                    {item2.title}
                                                  </option>
                                                ))}
                                              </select>
                                            </div>
                                            <div className="cenlr">
                                              <button
                                                className="page_save page_width"
                                                name="industryFilter"
                                                onClick={this.clearFilter}
                                              >
                                                CLEAR
                                              </button>
                                              <button
                                                className="page_save page_width"
                                                onClick={this.serverRequest}
                                              >
                                                APPLY
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Modal>

                                <Modal
                                  animation={true}
                                  size="md"
                                  className="modal_box"
                                  shadow-lg="border"
                                  show={
                                    this.state.showModal &&
                                    this.state.activeModal === "category"
                                  }
                                >
                                  <div className="modal-lg">
                                    <Modal.Header className="pb-0">
                                      <Button
                                        variant="outline-dark"
                                        onClick={this.handleCloseModal}
                                      >
                                        <i className="fa fa-times"></i>
                                      </Button>
                                    </Modal.Header>
                                    <div className="modal-body vekp pt-0">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="pb4">
                                            <div className="py-3">
                                              <div className="form-check-inline">
                                                <label
                                                  className="form-check-label"
                                                  htmlFor="flexCheckChecked"
                                                >
                                                  {" "}
                                                  Sort: A - Z{" "}
                                                </label>
                                                <input
                                                  className="form-check-input mx-3"
                                                  type="radio"
                                                  name="inlineRadioOptions"
                                                  id="inlineRadio1"
                                                  value="option1"
                                                />
                                              </div>
                                              <div className="form-check-inline">
                                                <label
                                                  className="form-check-label"
                                                  htmlFor="flexCheckChecked"
                                                >
                                                  {" "}
                                                  Sort: Z - A{" "}
                                                </label>
                                                <input
                                                  className="form-check-input mx-3"
                                                  type="radio"
                                                  name="inlineRadioOptions"
                                                  id="inlineRadio1"
                                                  value="option1"
                                                />
                                              </div>
                                            </div>

                                            <div className="input-group mb-3">
                                              <select
                                                type="text"
                                                className="form-control dropdown-toggle"
                                                placeholder="Search"
                                                name="categoryFilter"
                                                onChange={(e) =>
                                                  this.applyFilter(e)
                                                }
                                              >
                                                <option value="">
                                                  Select Category
                                                </option>
                                                <option value="Partner">
                                                  Partner
                                                </option>
                                                <option value="Business Account">
                                                  Business Account
                                                </option>
                                              </select>
                                            </div>
                                            <div className="cenlr">
                                              <button
                                                className="page_save page_width"
                                                name="categoryFilter"
                                                onClick={this.clearFilter}
                                              >
                                                CLEAR
                                              </button>
                                              <button
                                                className="page_save page_width"
                                                onClick={this.serverRequest}
                                              >
                                                APPLY
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Modal>

                                <Modal
                                  animation={true}
                                  size="md"
                                  className="modal_box"
                                  shadow-lg="border"
                                  show={
                                    this.state.showModal &&
                                    this.state.activeModal === "country"
                                  }
                                >
                                  <div className="modal-lg">
                                    <Modal.Header className="pb-0">
                                      <Button
                                        variant="outline-dark"
                                        onClick={this.handleCloseModal}
                                      >
                                        <i className="fa fa-times"></i>
                                      </Button>
                                    </Modal.Header>
                                    <div className="modal-body vekp pt-0">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="pb4">
                                            <div className="py-3">
                                              <div className="form-check-inline">
                                                <label
                                                  className="form-check-label"
                                                  htmlFor="flexCheckChecked"
                                                >
                                                  {" "}
                                                  Sort: A - Z{" "}
                                                </label>
                                                <input
                                                  className="form-check-input mx-3"
                                                  type="radio"
                                                  name="country"
                                                  value="ASC"
                                                  onChange={(e) =>
                                                    this.applySorting(e)
                                                  }
                                                />
                                              </div>
                                              <div className="form-check-inline">
                                                <label
                                                  className="form-check-label"
                                                  htmlFor="flexCheckChecked"
                                                >
                                                  {" "}
                                                  Sort: Z - A{" "}
                                                </label>
                                                <input
                                                  className="form-check-input mx-3"
                                                  type="radio"
                                                  name="country"
                                                  value="DESC"
                                                  onChange={(e) =>
                                                    this.applySorting(e)
                                                  }
                                                />
                                              </div>
                                            </div>

                                            <div className="input-group mb-3">
                                              <select
                                                className="form-control dropdown-toggle"
                                                placeholder="Search"
                                                name="countryFilter"
                                                onChange={(e) =>
                                                  this.applyFilter(e)
                                                }
                                              >
                                                <option value="">
                                                  Select Country
                                                </option>

                                                {countriesList.map((item) => (
                                                  <option
                                                    key={item.id}
                                                    value={item.nicename}
                                                  >
                                                    {item.name}
                                                  </option>
                                                ))}
                                              </select>
                                            </div>
                                            <div className="cenlr">
                                              <button
                                                className="page_save page_width"
                                                name="countryFilter"
                                                onClick={this.clearFilter}
                                              >
                                                CLEAR
                                              </button>
                                              <button
                                                className="page_save page_width"
                                                onClick={this.serverRequest}
                                              >
                                                APPLY
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Modal>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
